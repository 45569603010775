import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface DeleteCustomerRequest extends HttpRequestData {
}

export const deleteCustomer = httpUtil.createHttpRequestMeta<
    DeleteCustomerRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customers/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
