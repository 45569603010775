import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { PromotionConditionRequest } from './getAllPromotion';

export interface UpdatePromotionRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        code: string;
        isActive: boolean;
        unit: number;
        value: number;
        effectiveDate: string;
        expirationDate: string;
        promotionCondition?: PromotionConditionRequest;
    };
}

export const updatePromotion = httpUtil.createHttpRequestMeta<
    UpdatePromotionRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/promotions/:id',
    method: 'PATCH',
    authentication: 'bearer',
});