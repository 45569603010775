import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { TimeBlockRepeatBy, TimeBlockRepeatType } from './Types';

export interface TimeBlockCreateRequest extends HttpRequestData {
	body: {
		readonly date: string;
		readonly title: string;
		readonly endTime: string;
		readonly repeatTo: string;
		readonly startTime: string;
		readonly occurrences?: number;
		readonly specificDate?: string;
		readonly employeeIds: string[];
		readonly repeatBy: TimeBlockRepeatBy;
		readonly repeatType?: TimeBlockRepeatType;
	};
}

export const createTimeBlock = httpUtil.createHttpRequestMeta<TimeBlockCreateRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/time-blocks',
    method: 'POST',
    authentication: 'bearer',
});