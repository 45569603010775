import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';
import { SalePromotionItemResponse } from './createSalePromotion';

interface DeleteSalePromotionRequest extends HttpRequestData {
}

export const deleteSalePromotion = httpUtil.createHttpRequestMeta<
    DeleteSalePromotionRequest,
    SalePromotionItemResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/sale-promotions/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
