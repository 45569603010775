import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export interface IntegrationEmailDisconnectRequest extends HttpRequestData {
    body: {
        id: string;
    };
}

export const integrationDisconnect = httpUtil.createHttpRequestMeta<IntegrationEmailDisconnectRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/integration-settings/:id/disconnect',
    method: 'PATCH',
    authentication: 'bearer',
});