import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';
import { GetCustomerNoteDetailResponse } from './getCustomerDetail';

interface CreateCustomerNoteRequest extends HttpRequestData {
    readonly body: {
        readonly customerId: string;
        readonly value?: string;
    };
}

export const createCustomerNote = httpUtil.createHttpRequestMeta<
    CreateCustomerNoteRequest,
    GetCustomerNoteDetailResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-notes',
    method: 'POST',
    authentication: 'bearer',
});
