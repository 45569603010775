import React from 'react';

import { IconProps } from '../../Types';

export const IconEditDraft = (props: IconProps) => {
    return (
        <svg 
            className={props.className}
            onClick={props.onClick} 
            width='20' 
            height='20' 
            viewBox='0 0 20 20' 
            fill='none' 
            xmlns='http://www.w3.org/2000/svg' 
            {...props}
        >
            <path d='M17.5 14.9995L16.6666 15.9112C16.2245 16.3946 15.6251 16.6662 15.0001 16.6662C14.3751 16.6662 13.7757 16.3946 13.3337 15.9112C12.891 15.4288 12.2916 15.1579 11.6668 15.1579C11.042 15.1579 10.4426 15.4288 9.99998 15.9112M2.5 16.6662H3.89545C4.3031 16.6662 4.50693 16.6662 4.69874 16.6202C4.8688 16.5793 5.03138 16.512 5.1805 16.4206C5.34869 16.3175 5.49282 16.1734 5.78107 15.8852L16.25 5.4162C16.9404 4.72585 16.9404 3.60656 16.25 2.9162C15.5597 2.22585 14.4404 2.22585 13.75 2.9162L3.28105 13.3852C2.9928 13.6734 2.84867 13.8175 2.7456 13.9857C2.65422 14.1348 2.58688 14.2974 2.54605 14.4675C2.5 14.6593 2.5 14.8631 2.5 15.2708V16.6662Z' stroke='#475467' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
    );
};
