import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { GetRoleDetailResponse } from './getRoleDetail';

interface DeleteRoleRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export const deleteRole = httpUtil.createHttpRequestMeta<
    DeleteRoleRequest,
    GetRoleDetailResponse
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/roles/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
