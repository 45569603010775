import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetOverviewChartAppointmentRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export interface GetOverviewChartAppointmentResponse {
    overviewChartInfos: AppointmentReportOverview[];
}

interface AppointmentReportOverview {
    date: string;
    totalAppointment: number;
    totalAppointmentCancel: number;
    totalAppointmentOnline: number;
    totalAppointmentSalon: number;
    totalAppointmentValue: number;
}

export const getOverviewChartAppointment = httpUtil.createHttpRequestMeta<
    GetOverviewChartAppointmentRequest,
    GetOverviewChartAppointmentResponse
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/appointments-report/overview-chart',
    authentication: 'bearer',
});