import React from 'react';

import { IconProps } from '../../Types';

export const IconThankYouForVisiting = (props: IconProps) => {
    return (
        <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z' fill='#FEF0C7'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M39.9912 30.8478C37.3254 27.7312 32.88 26.8929 29.5399 29.7467C26.1999 32.6005 25.7296 37.372 28.3526 40.7472C30.5334 43.5535 37.1334 49.4721 39.2965 51.3878C39.5385 51.6021 39.6595 51.7093 39.8006 51.7514C39.9238 51.7881 40.0586 51.7881 40.1818 51.7514C40.3229 51.7093 40.4439 51.6021 40.6859 51.3878C42.849 49.4721 49.449 43.5535 51.6298 40.7472C54.2528 37.372 53.8399 32.5705 50.4425 29.7467C47.045 26.9229 42.657 27.7312 39.9912 30.8478Z' stroke='#DC6803' strokeWidth='1.67' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
    );
};
