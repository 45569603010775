import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';
import { SaleDetailGetAllResponseItem } from '../sale-detail';

interface UpdateSaleDetailRequest extends HttpRequestData {
    readonly body: {
        readonly id: string;
        readonly saleId: string;
        readonly objectId: string;
        readonly objectType: string;
        readonly objectPrice: number;
        readonly objectName: string;
        readonly quantity: number;
        readonly discount?: number;
        readonly disCountUnit?: string;
        readonly serviceId?: string;
    };
}

export const updateSaleDetail = httpUtil.createHttpRequestMeta<
    UpdateSaleDetailRequest,
    SaleDetailGetAllResponseItem
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/sale-details/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
