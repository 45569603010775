import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export interface DeleteShiftRequest extends HttpRequestData {

}

export const deleteShift = httpUtil.createHttpRequestMeta<
    DeleteShiftRequest,
    void
>({
    method: 'DELETE',
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    authentication: 'bearer',
    path: '/api/v1/shifts/:id',
});
