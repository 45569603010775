export enum AutomationEventType
{
    AppointmentReminder = 0,
    NewAppointment = 1,
    RescheduledAppointment = 2,
    CancelledAppointment = 3,
    DidNotShowUp = 4,
    ThankYouForVisiting = 5,
    ReminderToRebook = 6,
    CelebrateBirthdays = 7,
    RewardLoyalClients = 8,
    WelcomeNewClients = 9,
}
export enum ReminderType
{
    Before = 0,
    After = 1,
    Immediately = 2,
}

export enum ReminderTimeType
{
    Minute = 0,
    Hour = 1,
    Day = 3,
    Week = 4,
    Month = 5,
    Year = 6,
}

export enum ServiceType
{
    All = 0,
    Select = 1,
}
export enum AutomationEventChannelType
{
    Sms = 0,
    Mail = 1
}