import { useState, useRef, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { FlexBox, InputSearch, Spacer, Text, useHttpCommand, useLocalesFormat, usePagination } from '@esg/ui';
import { CheckOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { managementApis } from '@esg/business-management';
import { CustomerPromotionGetForSaleRequest, CustomerPromotionResponseItem } from '@esg/business-management/api/customer-promotion';
import { GetDetailSaleResponse, UnitEnum } from '@esg/business-pos';
import './SalePromotionDropdown.scss';
import { Empty, Spin } from 'antd';

interface SalePromotionProps {
    sale: GetDetailSaleResponse;
    onChange: (id: string) => void;
    disabled: boolean;
}

const SalePromotionDropdown = (props: SalePromotionProps) => {
    const {sale, onChange, disabled } = props;
    const { formatCurrency } = useLocalesFormat();
    const [options, setOptions] = useState<CustomerPromotionResponseItem[]>([]);
    const [visible, setVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [searchParams] = usePagination();
    const salePromotion = sale?.salePromotions?.[0];

    const {
        mutateAsync: customerPromotion,
        isPending : promotionLoading
    } = useHttpCommand(managementApis.getCustomerPromotionForSale);

    const fetchOptions = async (searchText: string) => {
         
        const [promotions] = await Promise.all([
            customerPromotion(
                {
                    query: { ...searchParams, search: searchText },
                    pathData: {
                        id: sale.id
                    }
                } as CustomerPromotionGetForSaleRequest
            )
        ]);

        setOptions(promotions?.items);
    };

    const handleFetchOptions = async (text: string) => {
        await fetchOptions(text);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((text) => handleFetchOptions(text), 500),
        []
    );

    // Xử lý khi nhập vào ô tìm kiếm
    const handleSearch = (value: string) => {
        setSearchText(value);
        debouncedSearch(value);
    };

    const handleClick = async () => {
        if (!visible) {
            await handleFetchOptions(searchText); 
        }
        setVisible(!visible);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const onHandleChange = (value: CustomerPromotionResponseItem) => {
        setVisible(false);
        onChange(value.id);
    };

    return (
        <div className='sale-promotion-dropdown-wrapper'>
            <button ref={buttonRef} onClick={handleClick} className={`sale-promotion-dropdown-button ${disabled ?'disabled': ''}`} >
                <PlusCircleOutlined style={{ fontSize: 20, color: '#0B5DCC' }} />
                <Text color='#0B5DCC' fontSize={14} fontWeight={600}>Add</Text>
            </button>

            {visible && (
                <div
                    ref={dropdownRef}
                    className='sale-promotion-dropdown'
                >
                    <InputSearch
                        height={36}
                        placeholder='Search by promotion name'
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    
                    <Spin spinning={promotionLoading}>
                        {
                            options.length > 0 ? (
                                <Spacer top={8}>
                                    <FlexBox direction='column'>
                                        {options.map((item, index) => (
                                            <FlexBox
                                                key={index}
                                                onClick={() => onHandleChange(item)}
                                                gap={8}
                                                cursor='pointer'
                                                className='sale-promotion-dropdown-item'
                                                justifyContent='space-between'
                                            >
                                                <FlexBox  gap={8}>
                                                    <Text ellipsis maxWidth={300}>{item.promotionApply?.promotionName}</Text>
                                                    <Text color='#667085' fontWeight={400} fontSize={14}>{`${item.promotionApply?.unit === UnitEnum.Percent ? `${item.promotionApply?.value}%` : formatCurrency(item.promotionApply?.value || 0)}`}{' off'}</Text>
                                                </FlexBox>
                                                {salePromotion?.customerPromotionId == item.id && <CheckOutlined style={{color:'#155EEF'}}/>}
                                            </FlexBox>
                                        ))}
                                    </FlexBox>
                                </Spacer>
                            ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </Spin>
                   
                </div>
            )}
        </div>
    );
};

export default SalePromotionDropdown;
