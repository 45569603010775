import { CategoryExternalTransaction, CodeExternalTransaction } from '@esg/business-management/api/external';
import { ReminderTimeValue } from '@esg/business-success-platform';
import { AutomationEventType, ReminderTimeType, ReminderType, TypeEmailTemplatesEnum } from '@esg/shared';

export const renderName = (type: AutomationEventType | TypeEmailTemplatesEnum): string => {
    const nameMap: Record<AutomationEventType | TypeEmailTemplatesEnum, string> = {
        [AutomationEventType.AppointmentReminder]: 'Appointment reminder',
        [AutomationEventType.NewAppointment]: 'Confirmed appointment',
        [AutomationEventType.RescheduledAppointment]: 'Rescheduled appointment',
        [AutomationEventType.CancelledAppointment]: 'Cancelled appointment',
        [AutomationEventType.DidNotShowUp]: 'Did not show up',
        [AutomationEventType.ThankYouForVisiting]: 'Thank you for visiting',
        [AutomationEventType.ReminderToRebook]: 'Reminder to rebook',
        [AutomationEventType.CelebrateBirthdays]: 'Celebrate birthdays',
        [AutomationEventType.RewardLoyalClients]: 'Reward loyal clients',
        [AutomationEventType.WelcomeNewClients]: 'Welcome new clients',
        [TypeEmailTemplatesEnum.Notification]: 'Notification',
        [TypeEmailTemplatesEnum.Promotion]: 'Promotion',
    };
    return nameMap[type] || 'Unknown event';
};

export const renderDescription = (type: AutomationEventType): string => {
    const descriptionMap: Record<AutomationEventType, string> = {
        [AutomationEventType.AppointmentReminder]: 'Trigger when a client’s appointment is coming up',
        [AutomationEventType.NewAppointment]: 'Trigger when a client’s appointment is confirmed',
        [AutomationEventType.RescheduledAppointment]: 'Trigger when a client’s appointment is changed',
        [AutomationEventType.CancelledAppointment]: 'Trigger when a client’s appointment is cancelled',
        [AutomationEventType.DidNotShowUp]: 'Trigger when a client does not show up for their appointment',
        [AutomationEventType.ThankYouForVisiting]: 'Trigger after a client completes payment',
        [AutomationEventType.ReminderToRebook]: 'Trigger a few weeks after a client’s last appointment',
        [AutomationEventType.CelebrateBirthdays]: 'Trigger on a client’s birthday',
        [AutomationEventType.RewardLoyalClients]: 'Trigger when a client reaches a loyalty milestone',
        [AutomationEventType.WelcomeNewClients]: 'Trigger when a new client completes their first transaction.',
    };
    return descriptionMap[type] || 'No description available';
};

export const renderDescriptionItem = (type: AutomationEventType): string => {
    const descriptionMap: Record<AutomationEventType, string> = {
        [AutomationEventType.AppointmentReminder]: 'Notify clients of their upcoming appointment',
        [AutomationEventType.NewAppointment]: 'Notify clients when their appointment is confirmed',
        [AutomationEventType.RescheduledAppointment]: 'Notify clients when their appointment is changed',
        [AutomationEventType.CancelledAppointment]: 'Automatically sends to clients when their appointment is cancelled',
        [AutomationEventType.DidNotShowUp]: 'Automatically sends to clients when their appointment is no-show',
        [AutomationEventType.ThankYouForVisiting]: 'Reach out to clients after sale with a review link',
        [AutomationEventType.ReminderToRebook]: 'Remind your clients to rebook a few weeks after their last appointment.',
        [AutomationEventType.CelebrateBirthdays]: 'Surprise clients on their special day to enhance loyalty and retention.',
        [AutomationEventType.RewardLoyalClients]: 'Offer top spenders a special deal to boost engagement.',
        [AutomationEventType.WelcomeNewClients]: 'Celebrate new clients joining your business by offering them a discount.',
    };
    return descriptionMap[type] || 'No description available';
};

export const renderReminderTimeString = (time: ReminderTimeValue): string => {
    const timeTypeMap: Record<ReminderTimeType, string> = {
        [ReminderTimeType.Minute]: 'minutes',
        [ReminderTimeType.Hour]: 'hours',
        [ReminderTimeType.Day]: 'days',
        [ReminderTimeType.Week]: 'weeks',
        [ReminderTimeType.Month]: 'months',
        [ReminderTimeType.Year]: 'years',
    };
    return `${time.reminderTime} ${timeTypeMap[time.reminderTimeType!]}`;
};
export const renderReminderTimeTypeCelebrateBirthday = (time: ReminderTimeValue): string => {
    const timeTypeMap: Record<ReminderTimeType, string> = {
        [ReminderTimeType.Minute]: 'minutes',
        [ReminderTimeType.Hour]: 'hours',
        [ReminderTimeType.Day]: 'days',
        [ReminderTimeType.Week]: 'weeks',
        [ReminderTimeType.Month]: 'months',
        [ReminderTimeType.Year]: 'years',
    };
    if (time.reminderTime == 0 && time.reminderTimeType === ReminderTimeType.Day) {
        return 'On the day of their birthday';
    }
    return `${time.reminderTime} ${timeTypeMap[time.reminderTimeType!]} before their birthday`;
};
export const renderReminderTimeStringValue = (time: ReminderTimeValue): string => {
    return `${time.reminderTime} ${time.reminderTimeType}`;
};

export const AutomationEventGroupMap: Record<AutomationEventType, string> = {
    [AutomationEventType.AppointmentReminder]: 'Reminder',
    [AutomationEventType.NewAppointment]: 'Appointment Updates',
    [AutomationEventType.RescheduledAppointment]: 'Appointment Updates',
    [AutomationEventType.CancelledAppointment]: 'Appointment Updates',
    [AutomationEventType.DidNotShowUp]: 'Appointment Updates',
    [AutomationEventType.ThankYouForVisiting]: 'Follow up sales',
    [AutomationEventType.ReminderToRebook]: 'Follow up sales',
    [AutomationEventType.CelebrateBirthdays]: 'Follow up sales',
    [AutomationEventType.RewardLoyalClients]: 'Follow up sales',
    [AutomationEventType.WelcomeNewClients]: 'Celebrate milestones',
};
export const renderAddons = (type: AutomationEventType): string => {
    const addonMap: Record<AutomationEventType, string> = {
        [AutomationEventType.AppointmentReminder]: 'Before',
        [AutomationEventType.CelebrateBirthdays]: 'Before',
        [AutomationEventType.ReminderToRebook]: 'After',
        [AutomationEventType.WelcomeNewClients]: 'After',
        [AutomationEventType.NewAppointment]: 'Immediately',
        [AutomationEventType.RescheduledAppointment]: 'Immediately',
        [AutomationEventType.CancelledAppointment]: 'Immediately',
        [AutomationEventType.DidNotShowUp]: 'Immediately',
        [AutomationEventType.ThankYouForVisiting]: 'Immediately',
        [AutomationEventType.RewardLoyalClients]: 'Immediately'
    };
    return addonMap[type] || 'Immediately';
};

export const isImmediately = (type: ReminderType): boolean => {
    const addonMap: Record<ReminderType, boolean> = {
        [ReminderType.After]: false,
        [ReminderType.Before]: false,
        [ReminderType.Immediately]: true,
    };
    return type in addonMap ? addonMap[type] : true;
};

export const appointmentReminderMockData: ReminderTimeValue[] = [
    { reminderTime: 1, reminderTimeType: ReminderTimeType.Hour },
    { reminderTime: 2, reminderTimeType: ReminderTimeType.Hour },
    { reminderTime: 4, reminderTimeType: ReminderTimeType.Hour },
    { reminderTime: 12, reminderTimeType: ReminderTimeType.Hour },
    { reminderTime: 1, reminderTimeType: ReminderTimeType.Day },
    { reminderTime: 2, reminderTimeType: ReminderTimeType.Day },
    { reminderTime: 3, reminderTimeType: ReminderTimeType.Day },
    { reminderTime: 1, reminderTimeType: ReminderTimeType.Week },
    { reminderTime: 2, reminderTimeType: ReminderTimeType.Week },
    { reminderTime: 3, reminderTimeType: ReminderTimeType.Week },
    { reminderTime: 4, reminderTimeType: ReminderTimeType.Week },
];
export const reminderToRebookMockData: ReminderTimeValue[] = [
    { reminderTime: 1, reminderTimeType: ReminderTimeType.Week },
    { reminderTime: 2, reminderTimeType: ReminderTimeType.Week },
    { reminderTime: 3, reminderTimeType: ReminderTimeType.Week },
    { reminderTime: 4, reminderTimeType: ReminderTimeType.Week },
    { reminderTime: 1, reminderTimeType: ReminderTimeType.Month },
    { reminderTime: 2, reminderTimeType: ReminderTimeType.Month },
    { reminderTime: 3, reminderTimeType: ReminderTimeType.Month },
    { reminderTime: 4, reminderTimeType: ReminderTimeType.Month },
];
export const celebrateBirthdayMockData: ReminderTimeValue[] = [
    { reminderTime: 0, reminderTimeType: ReminderTimeType.Day },
    { reminderTime: 3, reminderTimeType: ReminderTimeType.Day },
    { reminderTime: 1, reminderTimeType: ReminderTimeType.Week },
    { reminderTime: 2, reminderTimeType: ReminderTimeType.Week },
];
export const welcomeNewClientMockData: ReminderTimeValue[] = [
    { reminderTime: 1, reminderTimeType: ReminderTimeType.Day },
    { reminderTime: 2, reminderTimeType: ReminderTimeType.Day },
    { reminderTime: 3, reminderTimeType: ReminderTimeType.Day },
    { reminderTime: 1, reminderTimeType: ReminderTimeType.Week },
];
export const immediatelyMockData: ReminderTimeValue[] = [
    { reminderTime: 0, reminderTimeType: ReminderTimeType.Day },
];

export const renderReminderTimeOptions = (type: AutomationEventType): ReminderTimeValue[] => {
    const addonMap: Record<AutomationEventType, ReminderTimeValue[]> = {
        [AutomationEventType.AppointmentReminder]: appointmentReminderMockData,
        [AutomationEventType.ReminderToRebook]: reminderToRebookMockData,
        [AutomationEventType.CelebrateBirthdays]: celebrateBirthdayMockData,
        [AutomationEventType.WelcomeNewClients]: welcomeNewClientMockData,
        [AutomationEventType.NewAppointment]: immediatelyMockData,
        [AutomationEventType.RescheduledAppointment]: immediatelyMockData,
        [AutomationEventType.CancelledAppointment]: immediatelyMockData,
        [AutomationEventType.DidNotShowUp]: immediatelyMockData,
        [AutomationEventType.ThankYouForVisiting]: immediatelyMockData,
        [AutomationEventType.RewardLoyalClients]: immediatelyMockData
    };
    return addonMap[type] || appointmentReminderMockData;
};

export const renderCategoryExternalTransaction = (type: AutomationEventType): CategoryExternalTransaction => {
    const addonMap: Record<AutomationEventType, CategoryExternalTransaction> = {
        [AutomationEventType.AppointmentReminder]: CategoryExternalTransaction.Appointment,
        [AutomationEventType.CelebrateBirthdays]: CategoryExternalTransaction.CustomerCelebrateBirthday,
        [AutomationEventType.ReminderToRebook]: CategoryExternalTransaction.Appointment,
        [AutomationEventType.WelcomeNewClients]: CategoryExternalTransaction.Sale,
        [AutomationEventType.NewAppointment]: CategoryExternalTransaction.Appointment,
        [AutomationEventType.RescheduledAppointment]: CategoryExternalTransaction.Appointment,
        [AutomationEventType.CancelledAppointment]: CategoryExternalTransaction.Appointment,
        [AutomationEventType.DidNotShowUp]: CategoryExternalTransaction.Appointment,
        [AutomationEventType.ThankYouForVisiting]: CategoryExternalTransaction.Sale,
        [AutomationEventType.RewardLoyalClients]: CategoryExternalTransaction.Sale
    };
    return addonMap[type];
};

export const renderCodeExternalTransaction = (type: AutomationEventType): CodeExternalTransaction => {
    const addonMap: Record<AutomationEventType, CodeExternalTransaction> = {
        [AutomationEventType.AppointmentReminder]: CodeExternalTransaction.AppointmentAutomation,
        [AutomationEventType.CelebrateBirthdays]: CodeExternalTransaction.CustomerCelebrateBirthdayAutomation,
        [AutomationEventType.ReminderToRebook]: CodeExternalTransaction.AppointmentAutomation,
        [AutomationEventType.WelcomeNewClients]: CodeExternalTransaction.SaleAutomation,
        [AutomationEventType.NewAppointment]: CodeExternalTransaction.AppointmentAutomation,
        [AutomationEventType.RescheduledAppointment]: CodeExternalTransaction.AppointmentAutomation,
        [AutomationEventType.CancelledAppointment]: CodeExternalTransaction.AppointmentAutomation,
        [AutomationEventType.DidNotShowUp]: CodeExternalTransaction.AppointmentAutomation,
        [AutomationEventType.ThankYouForVisiting]: CodeExternalTransaction.SaleAutomation,
        [AutomationEventType.RewardLoyalClients]: CodeExternalTransaction.SaleAutomation
    };
    return addonMap[type] || CodeExternalTransaction.AppointmentAutomation;
};

