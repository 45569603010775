import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface DeleteSegmentRequest extends HttpRequestData {
}

export const deleteSegment = httpUtil.createHttpRequestMeta<
    DeleteSegmentRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/segments/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
