import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { GetSegmentResponseItem } from './getAllSegments';

interface GetSegmentDetailRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export const getDetailSegment = httpUtil.createHttpRequestMeta<GetSegmentDetailRequest, GetSegmentResponseItem>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/segments/:id',
    method: 'GET',
    authentication: 'bearer'
});