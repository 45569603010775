import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest, getEnv } from '@esg/shared';
interface CancelReasonGetAllRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface CancelReasonGetAllResponseItem {
    readonly id: string;
    readonly name: string;
}

export const getAllCancelReason = httpUtil.createHttpRequestMeta<
    CancelReasonGetAllRequest,
    PaginationResponse<CancelReasonGetAllResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'GET',
    path: '/api/v1/cancel-reasons',
    authentication: 'bearer',
});