import { PaginationResponse, HttpRequestData, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

interface GetAllBankAccountRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllBankAccountResponseItem {
    readonly id: string;
    accountName: string;
    accountNumber: string;
    bankName: string;
}

export const getAllBankAccount = httpUtil.createHttpRequestMeta<
    GetAllBankAccountRequest,
    PaginationResponse<GetAllBankAccountResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/bank-account-settings',
    method: 'GET',
    authentication: 'bearer',
});
