import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface DeleteJobTypeRequest extends HttpRequestData { }

export const deleteJobType = httpUtil.createHttpRequestMeta<DeleteJobTypeRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/job-types/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
