import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export interface EmployeeDeleteRequest extends HttpRequestData {
    readonly pathData: {
        id: string;
    };
}

export const deleteEmployee = httpUtil.createHttpRequestMeta<EmployeeDeleteRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/employees/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
