import { HttpRequestData, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/ui/Types';
import { getEnv } from '@esg/shared';
import { CampaignChannelItem } from './getAllCampaign';
import { CampaignType, CampaignTarget, CampaignStatus, CampaignScheduleType } from './enum';

export interface GetOverviewClientCampaignRequest extends HttpRequestData {
    body: {
        id?: string;
        name?: string;
        type?: CampaignType;
        target?: CampaignTarget;
        status?: CampaignStatus;
        targetValue?: string;
        promotionId?: string;
        dateApply?: string;
        timeApply?: string;
        campaignChannels?: CampaignChannelItem[];
        scheduleType?: CampaignScheduleType;
    };
    query: PaginationRequest;
}

export const getOverviewClientCampaign = httpUtil.createHttpRequestMeta<
    GetOverviewClientCampaignRequest,
    GetOverviewClientCampaignResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/campaigns/client-overview',
    method: 'POST',
    authentication: 'bearer',
});

export interface GetOverviewClientCampaignResponse {
    totalClient?: number;
}
