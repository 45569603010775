import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetOverviewRevenueRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}
export interface GetOverviewRevenueResponse {
    totalRevenue: number;
    highestSale: number;
    totalSale: number;
}

export const getOverviewRevenueReport = httpUtil.createHttpRequestMeta<
    GetOverviewRevenueRequest,
    GetOverviewRevenueResponse
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/revenue-report/overview',
    authentication: 'bearer',
});