import { httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export interface DisconnectProviderRequest {
    readonly body: {
        readonly userId: string;
        readonly provider?: string;
    };
}

export interface DisconnectProviderResponse {
    readonly id: string;
    readonly loginMethods: string[];
}

export const disconnectProvider = httpUtil.createHttpRequestMeta<
    DisconnectProviderRequest,
    DisconnectProviderResponse
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/account/disconnect-provider',
    method: 'POST',
});
