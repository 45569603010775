import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

interface GetAllCustomerGroupRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllCustomerGroupResponseItem {
    readonly id: string;
    readonly name: string;
    readonly active?: boolean;
}

export const getAllCustomersGroup = httpUtil.createHttpRequestMeta<GetAllCustomerGroupRequest, PaginationResponse<GetAllCustomerGroupResponseItem>>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-groups',
    method: 'GET',
    authentication: 'bearer'
});