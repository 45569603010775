import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { ShiftGetDetailResponse } from '../shift';

export interface EmployeeShiftsUpdateRequest extends HttpRequestData {
    readonly body: {
        readonly id: string;
        readonly shifts: ShiftGetDetailResponse[];
    };
}

export const updateEmployeeShifts = httpUtil.createHttpRequestMeta<EmployeeShiftsUpdateRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/employees/:id/shifts',
    method: 'PATCH',
    authentication: 'bearer',
});
