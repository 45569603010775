import { FlexBox, Text } from '@esg/ui';
import { Card } from 'antd';
import React from 'react';

interface AutomationEventPromotionOverviewCardProps {
    total: number;
    label: string;
    icon: React.ReactNode;
    percentTotal?: number;
    isLoading?: boolean;
}

const NumberDisplay: React.FC<{ value?: number }> = ({ value }) => {
    if (!value) {
        return null;
    }

    const formattedValue = Number.isInteger(value)
        ? value
        : value.toString().includes('.') && value.toString().split('.')[1].length === 1
            ? value
            : value.toFixed(2);

    return <Text fontWeight={400} fontSize={12} color='#98A2B3'>{` (${formattedValue}%)`}</Text>;
};

export const AutomationEventPromotionOverviewCard = (props: AutomationEventPromotionOverviewCardProps) => {

    return <FlexBox >
        <Card style={{ width: '100%' }} loading={props.isLoading}>
            <FlexBox justifyContent='space-between' width='100%' alignItems='center'>
                <FlexBox direction='column' >
                    <Text fontWeight={600} fontSize={14} lineHeight='20px' color='#344054'>{props.label}</Text>
                    <FlexBox alignItems='center' gap={4}>
                        <Text fontWeight={700} fontSize={20} lineHeight='30px' color='#101828'>{props.total}</Text>
                        <NumberDisplay value={props.percentTotal} />
                    </FlexBox>
                </FlexBox>
                {props.icon}
            </FlexBox>
        </Card>
    </FlexBox>;
};

