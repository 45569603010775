import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

export interface EmailTemplateSendTestMailRequest extends HttpRequestData {
    readonly body: {
        email: string;
        emailTemplate?: EmailTemplateSendTestMailResponseItem;
    };
}

export interface EmailTemplateSendTestMailResponseItem {
    subject?: string;
    body?: string;
    bodyCustom?: string;
    subjectCustom?: string;
}

export interface EmailTemplateSendTestMailResponse {
    status: boolean;
}

export const emailTemplateSendTestMail = httpUtil.createHttpRequestMeta<
    EmailTemplateSendTestMailRequest,
    EmailTemplateSendTestMailResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/email-templates/send-test-mail',
    method: 'POST',
    authentication: 'bearer',
});
