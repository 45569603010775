import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

interface GetAllCustomerForCreateSegmentRequest extends HttpRequestData {
    query: PaginationRequest;
    body: {
        customerIds?: string[];
        segmentId: string;
    };

}

export interface CustomerForCreateSegmentResponse {
    id: string;
    firstName?: string;
    lastName?: string;
    fullName: string;
    avatar?: string;
}

export const getAllCustomerForCreateSegments = httpUtil.createHttpRequestMeta<
    GetAllCustomerForCreateSegmentRequest,
    PaginationResponse<CustomerForCreateSegmentResponse>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-segments/for-create',
    method: 'POST',
    authentication: 'bearer',
});
