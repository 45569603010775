import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface DeletePromotionRequest extends HttpRequestData {
}

export const deletePromotion = httpUtil.createHttpRequestMeta<
    DeletePromotionRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/promotions/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
