import { FlexBox, Text } from '@esg/ui';
import { Card, Divider } from 'antd';
import React from 'react';

interface AutomationEventMetricOverviewCardProps {
    total: number;
    emailTotal: number;
    smsTotal: number;
    label: string;
    icon: React.ReactNode;
    percentTotal?: number;
    percentEmailTotal?: number;
    percentSmsTotal?: number;
    isLoading?: boolean;
}

const NumberDisplay: React.FC<{ value?: number }> = ({ value }) => {
    if (!value) {
        return null;
    }

    const formattedValue = Number.isInteger(value)
        ? value
        : value.toString().includes('.') && value.toString().split('.')[1].length === 1
            ? value
            : value.toFixed(2);

    return <Text fontWeight={400} fontSize={12} color='#98A2B3'>{` (${formattedValue}%)`}</Text>;
};

export const AutomationEventMetricOverviewCard = (props: AutomationEventMetricOverviewCardProps) => {
    return <FlexBox >
        <Card style={{width: '100%'}} loading={props.isLoading}>
            <FlexBox direction='column' width='100%' gap={8}>
                <FlexBox justifyContent='space-between' width='100%' alignItems='center'>
                    <FlexBox direction='column' >
                        <Text fontWeight={600} fontSize={14} lineHeight='20px' color='#344054'>{props.label}</Text>
                        <FlexBox alignItems='center' gap={4}>
                            <Text fontWeight={700} fontSize={20} lineHeight='30px' color='#101828'>{props.total}</Text>
                            <NumberDisplay value={props.percentTotal} />
                        </FlexBox>
                    </FlexBox>
                    {props.icon}
                </FlexBox>
                <Divider  style={{margin:0}}/>
                <FlexBox alignItems='center' gap={4}>
                    <Text>Email:</Text>
                    <Text fontWeight={600} color='#344054'>{props.emailTotal}</Text>
                    <NumberDisplay value={props.percentEmailTotal} />
                </FlexBox>
                <FlexBox alignItems='center' gap={4}>
                    <Text>Sms:</Text>
                    <Text fontWeight={600} color='#344054'>{props.smsTotal}</Text>
                    <NumberDisplay value={props.percentSmsTotal} />
                </FlexBox>
            </FlexBox>
        </Card>
    </FlexBox>;
};

