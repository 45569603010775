import { GetCampaignResponseItem } from '@esg/business-management';
import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';

import { PaginationRequest, StatusEmailTemPlate, TypeEmailTemplatesEnum, getEnv } from '@esg/shared';

export interface GetAllEmailTemplateRequest extends HttpRequestData {
    readonly query: PaginationRequest & {
        statusEmailTemPlate?: StatusEmailTemPlate;
    };

}

export interface EmailCampaignResponse {
    id: string;
    name: string;
}

export interface EmailCampaignChannelResponse extends GetCampaignResponseItem {
    campaign?: EmailCampaignResponse;
}

export interface GetAllEmailTemplateResponseItem {
    id: string;
    type?: TypeEmailTemplatesEnum;
    subject?: string;
    status?: StatusEmailTemPlate;
    body?: string;
    bodyCustom?: string;
    subjectCustom?: string;
    campaignChannels?: EmailCampaignChannelResponse[];
}

export const emailTemplateGetAll = httpUtil.createHttpRequestMeta<
    GetAllEmailTemplateRequest,
    PaginationResponse<GetAllEmailTemplateResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/email-templates',
    method: 'GET',
    authentication: 'bearer',
});
