import { FlexBox, Spacer, Text, globalDrawerState, globalModalState, subDrawerState, useHttpCommand, useLocalesFormat } from '@esg/ui';
import { HeaderDrawer } from '@esg/ui/components/drawer/HeaderDrawer';
import { Badge, Spin, Tag } from 'antd';
import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetDetailSaleResponse, SaleStatus, updateSaleActive } from '@esg/business-pos';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import CancelSaleForm from '../../../CancelSaleForm';
import { currentSaleState } from '@/states';
import { saleEvents } from '@/event/saleEvent';
import { translateCodes } from '@/locales/translateCodes';

const saleStatus = (status?: SaleStatus) => {
    switch(status) {
    case SaleStatus.Paid:
        return <Tag><Badge size='small' status='default' text='Paid'/></Tag>;
    case SaleStatus.Unpaid:
        return <Tag><Badge size='small' status='processing' text='Unpaid'/></Tag>;
    case SaleStatus.Cancelled:
        return <Tag><Badge size='small' status='error' text='Cancelled'/></Tag>;
    default:
        return <Tag><Badge size='small' status='processing' text='Unpaid'/></Tag>;
    }
};
interface SaleOrderHeaderProps {
    sale: GetDetailSaleResponse;
    height?: number | string;   
    readonly isFastSale?: boolean;
    readonly showModalOrderActivities?: () => void;
}

export default function SaleOrderHeader(props: SaleOrderHeaderProps) {
    const {isFastSale} = props;
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const {height} = props;
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);
    const [currentSale] = useRecoilState(currentSaleState);
    const [drawerState] = useRecoilState(globalDrawerState);
    const resetSubDrawerState = useResetRecoilState(subDrawerState);

    const { mutateAsync: onUpdateSaleActive } = useHttpCommand(updateSaleActive, {
        onSuccess: () => {
            saleEvents.onRefetchSaleActive.emit();
            saleEvents.onRefetchSaleList.emit();
            resetDrawerState();
        }
    });

    const handleMinimize = useCallback(async () => {
        await onUpdateSaleActive({pathData: {id: currentSale?.id},
            body: {
                id: currentSale?.id, 
                isActive: true
            }
        });
    }, [currentSale, onUpdateSaleActive]);

    const handleSaveUnpaid = useCallback(async () => {
        await onUpdateSaleActive({pathData: {id: currentSale?.id},
            body: {
                id: currentSale?.id, 
                isActive: true,
                isDisplay: false
            }
        });
    }, [currentSale, onUpdateSaleActive]);

    const showModalConfirmCancel = useCallback((sale: GetDetailSaleResponse) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: translateCodes.CANCEL_SALE,
            content: <CancelSaleForm
                sale={sale}
                onSuccess={() => {
                    resetGlobalModal();
                    resetDrawerState();
                    saleEvents.onRefetchSaleList.emit();
                }}
            />
        });
    }, [globalModal, resetDrawerState, resetGlobalModal, setGlobalModal]);
    
    const renderHeader = () => {
        if(!currentSale) {
            return <Spin size='small' />;
        }

        if (isFastSale) {
            return (
                <Spacer>
                    <FlexBox gap={16} alignItems='center'>
                        <Text fontSize={20} fontWeight={700} >ID: {currentSale?.code}</Text>
                        {isFastSale && <Text fontSize={14} fontWeight={400} >{formatDate(currentSale?.date, `${currentLocale?.format}  hh:mm A`)}</Text>}
                    </FlexBox>
                    <Spacer />
                </Spacer>
            );
        }

        return (
            <Spacer>
                <FlexBox gap={16} alignItems='center'>
                    <Text fontSize={20} fontWeight={700} >ID: {currentSale?.code}</Text>
                    {saleStatus(currentSale.status)}
                </FlexBox>
                <Spacer />
                <Text fontSize={14} fontWeight={400} >{formatDate(currentSale?.date, `${currentLocale?.format}  hh:mm A`)}</Text>
            </Spacer>
        );
    };

    return (
        <FlexBox backgroundColor='#fff' alignItems='center' height={height} borderBottom='1px solid #EAECF0'>
            <Spacer type='padding' left_right={16} width='100%'>
                <HeaderDrawer
                    sale={props.sale}
                    drawerTitle={renderHeader()}
                    onMinimize={handleMinimize}
                    handleSaveUnpaid={handleSaveUnpaid}
                    onClose={() => {
                        drawerState?.onClose?.();
                        resetSubDrawerState();
                        saleEvents.onRefetchSaleList.emit();
                    }}
                    isFastSale={isFastSale}
                    showModalConfirmCancel={showModalConfirmCancel}
                    showModalOrderActivities={() => props.showModalOrderActivities?.()}
                />
            </Spacer>
        </FlexBox>
    );
}
