import React from 'react';

import { IconProps } from '../../Types';

export const IconInfoCircle = (props: IconProps) => {
    return (
        <svg
            className={props.className}
            onClick={props.onClick}
            width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_3183_143838)'>
                <path d='M7.99967 10.6654V7.9987M7.99967 5.33203H8.00634M14.6663 7.9987C14.6663 11.6806 11.6816 14.6654 7.99967 14.6654C4.31778 14.6654 1.33301 11.6806 1.33301 7.9987C1.33301 4.3168 4.31778 1.33203 7.99967 1.33203C11.6816 1.33203 14.6663 4.3168 14.6663 7.9987Z' stroke='#D92D20' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
            </g>
            <defs>
                <clipPath id='clip0_3183_143838'>
                    <rect width='16' height='16' fill='white' />
                </clipPath>
            </defs>
        </svg>

    );
};

