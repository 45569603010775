import { UserOutlined } from '@ant-design/icons';
import { managementApis } from '@esg/business-management';
import { CampaignChannelResponse, CampaignChannelType, CampaignTarget, CampaignType, GetCampaignResponseItem } from '@esg/business-management/api/campaign';
import { GetPromotionResponseItem, ObjectType } from '@esg/business-management/api/promotion';
import { GetSegmentResponseItem } from '@esg/business-management/api/segment';
import { dayjs, OptionValueType } from '@esg/shared';
import { FlexBox, TableCustom, Text, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { Avatar, Card, TableColumnsType, Tag } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';

interface CampaignContentDetailProps {
  campaign: GetCampaignResponseItem;
}

export const CampaignContentDetail = (props: CampaignContentDetailProps) => {
    const { campaign } = props;
    const isSegmentTarget = campaign.target === CampaignTarget.SegmentClient;
    const isPromotion = campaign.type === CampaignType.Promotion;
    const { formatDate,  formatCurrencySymbol } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const members = currentLocale?.members || [];

    const {
        data: campaignConditionResponse,
        isLoading,
    } = useHttpQuery(managementApis.getConditionDetailCampaign, {
        pathData: {
            id: campaign.id,
        },
    });

    const renderCampaignCardDetail = (title: string, value?: string) =>{
        return <FlexBox direction='column' gap={8}>
            <Text translateCode={title}/>
            <Text color='#101828' fontWeight={600}>
                {value}
            </Text>
        </FlexBox>;
    };

    const renderCampaignCard = () => {
        const user = members.find(c => c.id === campaign.creatorId);
        const campaignType = campaign.type === CampaignType.Promotion 
            ? 'Promotion' : 'Notification';
        const activeMail = campaign?.campaignChannels?.find(c => c.type == CampaignChannelType.Mail)?.isActive;
        const activeSms = campaign?.campaignChannels?.find(c => c.type == CampaignChannelType.Sms)?.isActive;
        const campaignChannels = `${activeMail? 'Email': ''}${activeSms&&activeMail ? ', ': ''}${activeSms ? 'Sms': ''}`;
        const campaignTarget = campaign.target === CampaignTarget.AllClient
            ? 'All Client'
            : campaign.target === CampaignTarget.NewClient
                ? 'New Client'
                : CampaignTarget.ReturnClient
                    ? 'Returning client'
                    : `${campaign.targetValue?.length} segment selected`;
                    
        return <Card>
            <FlexBox>
                <FlexBox direction='column' gap={24}>
                    {renderCampaignCardDetail('Campaign ID', campaign.code)}
                    {renderCampaignCardDetail('Channels', campaignChannels)}
                    {renderCampaignCardDetail('Target clients', campaignTarget)}
                </FlexBox>
                <FlexBox direction='column' gap={24}>
                    {renderCampaignCardDetail('Campaign type', campaignType)}
                    {renderCampaignCardDetail('Created date', formatDate(dayjs(campaign.creationTime)))}
                    <FlexBox direction='column' gap={8}>
                        <Text translateCode='Created by' />
                        <FlexBox gap={12} alignItems='center'>
                            <Avatar size={32} shape='circle' src={user?.avatar} icon={<UserOutlined />} />
                            <Text>{user?.fullName}</Text>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </Card>;
    };

    const promotionColumns: TableColumnsType<GetPromotionResponseItem> = [
        {
            title: 'Promotion name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis
                >{record.name}</Text>;
            }
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            render: (_, record) => {
                return <Text >{`${record.value} ${record.unit === 0 ? '%': formatCurrencySymbol()}`}</Text>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => {
                return <Text>{record.promotionCondition?.objectType === ObjectType.Service
                    ? 'Service' : record.promotionCondition?.objectType === ObjectType.Product
                        ? 'Product' : 'Total sale promotion'}</Text>;
            },
        },
        {
            title: 'Effective date',
            dataIndex: 'effectiveDate',
            key: 'effectiveDate',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.effectiveDate), `${currentLocale?.format}`)} { }</Text>;
            },
        },
        {
            title: 'Expiration date',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: (_, record) => {
                const currentDate = dayjs().startOf('day');
                if (currentDate.add(22, 'year') < dayjs(record.expirationDate)) {
                    return <Text>Ongoing</Text>;
                }
                return <Text>{formatDate(dayjs(record.expirationDate), `${currentLocale?.format}`)} { }</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => {
                if(!record.isActive){
                    return <Tag color='default'>Inactive</Tag>;
                }

                const currentDate = dayjs().startOf('day');
                if (currentDate > dayjs(record.expirationDate)) {
                    return <Tag color='red'>Expired</Tag>;
                }

                return <Tag color='success'>Active</Tag>;
            },
        }
    ];

    const columnSegments: TableColumnsType<GetSegmentResponseItem> = [
        {
            title: 'Segment name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis>{record.name}</Text>;
            },
        },
        {
            title: 'Clients',
            dataIndex: 'numberOfClients',
            key: 'numberOfClients',
            render: (_, record) => {
                return record.numberOfClients;
            },
        },
        {
            title: 'Added date',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.creationTime), `${currentLocale?.format}`)} { }</Text>;
            },
        }
    ];

    const columnChannels: TableColumnsType<CampaignChannelResponse> = [
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis>{record.emailTemplateApply?.subjectCustom}</Text>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => {
                return record.emailTemplateApply?.type === OptionValueType.Notification
                    ? 'Notification' : 'Promotion';
            },
        },
        {
            title: 'Added date',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.emailTemplateApply?.creationTime), `${currentLocale?.format}`)} { }</Text>;
            },
        }
    ];

    const renderPromotionCard = () => {
        return <TableCustom
            titleTableTransCode='Promotion details'
            loading={isLoading}
            columns={promotionColumns}
            dataSource={campaignConditionResponse?.promotion ? [campaignConditionResponse.promotion] : []}
            showFooter={false}
        />;
    };

    const renderSegmentCard = () => {
        return <TableCustom
            titleTableTransCode='Segment details'
            loading={isLoading}
            columns={columnSegments}
            dataSource={campaignConditionResponse?.segments}
            showFooter={false}
        />;
    };

    const renderCampaignChannelCard = () => {
        if (campaignConditionResponse?.campaignChannels?.length === 0) { return null; }

        return <TableCustom
            titleTableTransCode='Template details'
            loading={isLoading}
            columns={columnChannels}
            dataSource={campaignConditionResponse?.campaignChannels}
            showFooter={false}
        />;
    };

    return (<FlexBox direction='column' gap={24}>
        {renderCampaignCard()}
        {renderCampaignChannelCard()}
        {isPromotion && campaign.promotionId && renderPromotionCard()}
        {isSegmentTarget && renderSegmentCard()}
    </FlexBox>);
};

