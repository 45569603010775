import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexBox, TableCustom, useHttpQuery, useLocalesFormat, usePagination } from '@esg/ui';
import { Rate, TableColumnsType, Tooltip } from 'antd';
import { GoogleRatingStatus } from '@esg/shared';
import { getAllClientReview, GetAllClientReviewResponseItem } from '../../../../../../packages/business/client-review';
import { ClientReviewOverview } from './children/ClientReviewOverview';
import { PageLayout } from '@/layouts/page-layout/PageLayout';
import { translateCodes } from '@/locales';
import SaleEmployeeColumn from '@/pages/fast-pos/sale/children/SaleEmployeeColumn';

export function ClientReview() {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = usePagination();
    const { formatDate } = useLocalesFormat();

    const { data: review, isLoading } = useHttpQuery(getAllClientReview, { query: searchParams });

    const columns: TableColumnsType<GetAllClientReviewResponseItem> = [
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            render: (_, review) => <Rate disabled value={review.rating} style={{ color: '#ff9800' }} />,
        },
        {
            title: 'Feedback',
            dataIndex: 'feedback',
            key: 'feedback',
            render: (_, review) => (
                <Tooltip title={review.feedback}>
                    <span>{(review.feedback ?? '').length > 25 ? `${review.feedback?.substring(0, 25)}...` : review.feedback}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Created date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (_, review) => formatDate(review.creationTime ?? ''),
        },
        {
            title: 'Client',
            dataIndex: 'client',
            key: 'client',
            render: (_, review) => <a href='#'>{review.customer?.fullName}</a>,
        },
        {
            title: 'Employees',
            dataIndex: 'employees',
            key: 'employees',
            render: (_, review) => <SaleEmployeeColumn saleDetails={review.sale?.saleDetails ?? []} />,
        },
        {
            title: 'Google rating',
            dataIndex: 'googleRating',
            key: 'googleRating',
            render: (_, review) => {
                if (review.googleRatingStatus === GoogleRatingStatus.Accepted) {
                    return 'Accepted';
                }
                if (review.googleRatingStatus === GoogleRatingStatus.None) {
                    return 'None';
                }
                return '-';
            },
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transactionId',
            key: 'transactionId',
            render: (_, review) => <a href='#'>{review.sale?.code}</a>,
        },
    ];

    return (
        <PageLayout
            pageTitleTransCode={t(translateCodes.CLIENT_REVIEWS)}
        >
            <FlexBox direction='column' gap={24}>
                <ClientReviewOverview />
                <TableCustom
                    onPageChange={(page) =>
                        setSearchParams({ ...searchParams, page: page.toString() })
                    }
                    // leftHeaderRender={() => (
                    //     <ClientReviewFilter />
                    // )}
                    // pageInfo={campaignResponse?.meta}
                    loading={isLoading}
                    placeholderSearchTransCode='Search by campaign name'
                    columns={columns}
                    dataSource={review?.items}
                />
            </FlexBox>
        </PageLayout>
    );
}