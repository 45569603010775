import { managementApis } from '@esg/business-management';
import {
    getAllTimeBlock,
    getAllTimeOffs,
    getShiftsByDay,
    resourceApis,
} from '@esg/business-resource-management';
import { schedulerApis } from '@esg/business-schedule';
import { useHttpQuery } from '@esg/ui';
import { useMemo } from 'react';
import { dayjs } from '@esg/shared';
import { flatten } from 'lodash';
import { ScheduleTimeDisableValue } from '../../Types';
import { getEmployeeTimeOffs, getEmployeeTimeOffsWork } from '@/helpers/schedulerHelper';
import { useBusinessHour } from '@/hooks/useBusinessHour';

export const useSchedulerResource = ({ defaultDate }: { defaultDate: string }) => {
    const { branchBusinessHour, branchWorkingTime } = useBusinessHour(defaultDate);

    const dayObject = useMemo(() => dayjs(defaultDate).startOf('day'), [defaultDate]);
    const endDayObject = useMemo(() => dayjs(defaultDate).endOf('day'), [defaultDate]);

    const { data: employeeResponse, isFetching: isEmployeeFetching } = useHttpQuery(
        resourceApis.getAllEmployees,
        {
            query: {
                pageSize: 50,
                isAllowBooking: true
            },
        }
    );

    const { data: appointmentSettings, refetch: refetchAppointmentSetting } = useHttpQuery(
        schedulerApis.getAllAppointmentSetting
    );

    const { data: servicesResponse } = useHttpQuery(managementApis.getAllServices, {
        query: {
            pageSize: 100,
        },
    });

    const { data: facilities } = useHttpQuery(resourceApis.getAllFacilities, {
        query: {
            pageSize: 100,
        },
    });

    const { data: shifts, refetch: refetchShifts, isFetching: isShiftFetching } = useHttpQuery(getShiftsByDay, {
        query: {
            dayOfWeek: dayObject.day(),
            date: dayObject.toISOString(),
        }
    });

    const { data: timeOffResponse } = useHttpQuery(getAllTimeOffs, {
        query: {
            startDate: dayObject.toISOString(),
            endDate: endDayObject.toISOString()
        }
    });

    const { data: timeBlockResponse, refetch: refetchTimeBlocks } = useHttpQuery(getAllTimeBlock, {
        query: {
            date: dayObject.toISOString(),
        },
    });

    const employeeTimeOffs = useMemo(() => getEmployeeTimeOffs({
        branchWorkingTime,
        shifts: shifts ?? [],
        timeOffs: timeOffResponse ?? [],
        employees: employeeResponse?.items ?? [],
    }), [branchWorkingTime, employeeResponse?.items, shifts, timeOffResponse]);

    const employeeTimeOffsWork = useMemo(()=> getEmployeeTimeOffsWork({
        branchWorkingTime,
        shifts: shifts ?? [],
        currentDate: defaultDate,
    }),[branchWorkingTime, defaultDate, shifts]);
    
    const breakTimes = useMemo<ScheduleTimeDisableValue[]>(() => {
        const result = flatten(shifts?.map(s => s.breakTimes));
        return result.map((bt) => ({ ...bt, type: 'BREAK_TIME' }));
    }, [shifts]);

    const timeBlocks = useMemo<ScheduleTimeDisableValue[]>(() => {
        return timeBlockResponse?.map((bt) => ({ ...bt, type: 'TIME_BLOCK' })) ?? [];
    }, [timeBlockResponse]);

    return {
        refetchShifts,
        isShiftFetching,
        refetchTimeBlocks,
        branchWorkingTime,
        isEmployeeFetching,
        branchBusinessHour,
        shifts: shifts ?? [],
        refetchAppointmentSetting,
        facilities: facilities?.items || [],
        services: servicesResponse?.items || [],
        employees: employeeResponse?.items || [],
        timeDisables: [...timeBlocks, ...breakTimes],
        appointmentSetting: appointmentSettings?.items[0],
        employeeTimeOffs: [...employeeTimeOffs, ...employeeTimeOffsWork],
    };
};
