import React from 'react';
import { IconProps } from '../../Types';

export const IconPromotionUnused = (props: IconProps) => {
    return (
        <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0.799805 24C0.799805 10.7452 11.545 0 24.7998 0C38.0546 0 48.7998 10.7452 48.7998 24C48.7998 37.2548 38.0546 48 24.7998 48C11.545 48 0.799805 37.2548 0.799805 24Z' fill='#DCFAE6' />
            <path d='M34.7998 33V31C34.7998 29.1362 33.525 27.5701 31.7998 27.126M28.2998 15.2908C29.7657 15.8841 30.7998 17.3213 30.7998 19C30.7998 20.6787 29.7657 22.1159 28.2998 22.7092M29.7998 33C29.7998 31.1362 29.7998 30.2044 29.4953 29.4693C29.0893 28.4892 28.3107 27.7105 27.3305 27.3045C26.5955 27 25.6636 27 23.7998 27H20.7998C18.936 27 18.0042 27 17.2691 27.3045C16.289 27.7105 15.5103 28.4892 15.1043 29.4693C14.7998 30.2044 14.7998 31.1362 14.7998 33M26.2998 19C26.2998 21.2091 24.5089 23 22.2998 23C20.0907 23 18.2998 21.2091 18.2998 19C18.2998 16.7909 20.0907 15 22.2998 15C24.5089 15 26.2998 16.7909 26.2998 19Z' stroke='#079455' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

