import { HttpRequestData, httpUtil } from '@esg/framework';

import { AutomationEventChannelType, ReminderTimeType, getEnv } from '@esg/shared';
import { AutomationEventGetAllResponseItem } from './automationEventGetAll';

interface GetDetailEmailTemplateRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export interface AutomationEventChannelResponse {
    id?: string;
    type: AutomationEventChannelType;
    emailTemplateId?: string;
    isActive: boolean;
}

export interface ReminderTimeValue {
    reminderTime?: number;
    reminderTimeType?: ReminderTimeType;
}

export const automationEventGetDetail = httpUtil.createHttpRequestMeta<
    GetDetailEmailTemplateRequest,
    AutomationEventGetAllResponseItem
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/automation-events/:id',
    method: 'GET',
    authentication: 'bearer',
});
