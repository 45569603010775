import './index.scss';
import { crmLocale } from '@esg/business-crm';
import { viewRequests } from '@esg/framework';
import {
    DrawerGlobal,
    ThemeProvider,
    uiLocale
} from '@esg/ui';
import { ModalGlobal } from '@esg/ui/components/modal';
import { notification } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { notifyIcon } from '@esg/ui/constants/notifyIcon';
import { AppConfig, setEnv } from '@esg/shared';
import { defaultLocale } from './locales';
import { Router } from './pages';
import { Initializer } from './components';
import PrintSale from './pages/fast-pos/sale/children/prints/PrintSale';
import { RealtimeRoot } from './contexts/realtime-context';
import { env } from './configs';

const getRootElement = () => document.getElementById('app')!;
let reactRoot: ReactDOM.Root | null = null;
setEnv(env);

const renderApp = () => {
    const root = getRootElement();
    const app = (
        <AppConfig i18nResources={[defaultLocale, crmLocale, uiLocale]}>
            <ThemeProvider>
                <RealtimeRoot />
                <DrawerGlobal />
                <ModalGlobal />
                <Initializer>
                    <Router />
                </Initializer>
                <PrintSale />
            </ThemeProvider>
        </AppConfig>
    );

    if (reactRoot == null) {
        reactRoot = ReactDOM.createRoot(root);
        reactRoot.render(app);
    } else {
        reactRoot.render(app);
    }
};

export const startApp = () => {
    viewRequests.showNotification.setHandler(async (payload) => {
        if (!payload) {
            throw new Error('Payload is required');
        }

        notification.open({
            style: { width: 400 },
            message: payload.title,
            description: payload.message,
            className: 'app-notify-status',
            placement: payload.placement ?? 'bottomLeft',
            icon: <img src={notifyIcon[payload.type]} width={38} alt='app-notify-status' />,
        });
    });

    renderApp();
};
