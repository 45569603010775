import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

interface GetServicesByCategoryRevenueRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetServicesByCategoryRevenueItem {
    id: string;
    name: string;
    quantity: number;
    revenue: number;
}

export const getServicesByCategoryRevenueReport = httpUtil.createHttpRequestMeta<
    GetServicesByCategoryRevenueRequest,
    PaginationResponse<GetServicesByCategoryRevenueItem>
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/revenue-report/service-category',
    authentication: 'bearer',
});