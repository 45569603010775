import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { } from './getAllExternal';
import { CategoryExternalTransaction, TypeExternalTransaction, ActionExternalTransaction, CodeExternalTransaction } from './enum';

interface GetExternalDetailRequest extends HttpRequestData {
    body: {
        category: CategoryExternalTransaction;
        type: TypeExternalTransaction;
        action: ActionExternalTransaction;
        code: CodeExternalTransaction;
        sourceId: string;
    };
}

export interface GetExternalOverviewResponse {
    totalSent: number;
    totalMailSent: number;
    totalSmsSent: number;
    totalDelivered: number;
    totalMailDelivered: number;
    totalSmsDelivered: number;
    totalFailed: number;
    totalMailFailed: number;
    totalSmsFailed: number;
}

export const getOverviewExternal = httpUtil.createHttpRequestMeta<
    GetExternalDetailRequest,
    GetExternalOverviewResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/external-transactions/overview',
    method: 'POST',
    authentication: 'bearer',
});