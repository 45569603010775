import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { GetDetailSaleResponse } from '@esg/business-pos/api/sale';
import { getEnv } from '@esg/shared';

interface GetRevenueDetailReportRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
        readonly customerId?: string;
    };
}

export const getClientDetailReport = httpUtil.createHttpRequestMeta<
    GetRevenueDetailReportRequest,
    PaginationResponse<GetDetailSaleResponse>
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/clients-report/detail-sales-by-client',
    authentication: 'bearer'
});
