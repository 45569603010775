import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { ShiftGetDetailResponse } from './getShiftsByEmployee';

interface ShiftsGetByDayRequest extends HttpRequestData {
	query: {
		date: string;
		dayOfWeek: number;
		employeeIds?: string[];
	};
}

export const getShiftsByDay = httpUtil.createHttpRequestMeta<
	ShiftsGetByDayRequest,
	ShiftGetDetailResponse[]
>({
    method: 'GET',
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    authentication: 'bearer',
    path: '/api/v1/shifts/by-day',
});
