import { UserOutlined } from '@ant-design/icons';
import { managementApis } from '@esg/business-management';
import { CampaignChannelType, GetCampaignResponseItem, GetClientCampaignResponseItem } from '@esg/business-management/api/campaign';
import { dayjs } from '@esg/shared';
import { Block, FlexBox, useHttpQuery, usePagination, Text, useLocalesFormat, TableCustom, IconSentCampaign, IconMailCampaign, IconFailedCampaign } from '@esg/ui';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { Avatar, TableColumnsType, Tag } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';
import { MetricOverviewCard } from './components';

interface CampaignContentMetricProps {
    activeKey: string;
    campaign: GetCampaignResponseItem;
}

export const CampaignContentScheduleMetric = (props: CampaignContentMetricProps) => {
    const { campaign } = props;
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [searchParams, setSearchParams] = usePagination();
    const activeMail = campaign.campaignChannels?.find(c => c.type == CampaignChannelType.Mail)?.isActive;
    const activeSms = campaign.campaignChannels?.find(c => c.type == CampaignChannelType.Sms)?.isActive;
    
    const { data: customerSegmentsResponse, isLoading } = useHttpQuery(managementApis.getClientCampaign, {
        body: {
            target: campaign.target,
            targetValue: campaign.targetValue,
        },
        query: searchParams,
    });

    const { data: customerOverviewResponse, isLoading : isLoadingOverview } = useHttpQuery(managementApis.getOverviewClientCampaign, {
        body: {
            target: campaign.target,
            targetValue: JSON.stringify(campaign.targetValue),
        }
    });

    const renderExternalCard = () => {
        return <TableCustom
            pageInfo={customerSegmentsResponse?.meta}
            loading={isLoading}
            columns={columns}
            dataSource={customerSegmentsResponse?.items}
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, page: page.toString() })
            }
            onSearch={(value) =>
                setSearchParams({ ...searchParams, search: value })
            }
            titleTableTransCode='Message Recipient List'
            placeholderSearchTransCode='Search by client name'
        />;
    };

    const renderChannelCampaign = () => {
        return <FlexBox gap={6}>
            {activeMail && <Tag style={{borderRadius:6}}>Email</Tag>}
            {activeSms && <Tag style={{borderRadius:6}}>Sms</Tag>}
        </FlexBox>;
    };

    const createDateTimeApply = (dateApply?: string, timeApply?: string) => {
        if(!dateApply || !timeApply) return dayjs();

        const datePart = dayjs(dateApply).startOf('day');
        const timePart = dayjs(timeApply);
        const combinedDateTime = datePart
            .set('hour', timePart.hour())
            .set('minute', timePart.minute()) 
            .set('second', 0);

        return combinedDateTime;
    };

    const columns: TableColumnsType<GetClientCampaignResponseItem> = [
        {
            title: 'Client',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (_, record) => {
                return (<FlexBox alignItems='center' gap={8}>
                    <Avatar size={32} shape='circle' src={record.avatar} icon={<UserOutlined />} />
                    <Block display='flex'>
                        <FlexBox direction='column'>
                            <Text maxWidth={300} ellipsis fontSize={14} lineHeight='20px' fontWeight={400}>{record.fullName}</Text>
                            {
                                record.customerLabel === 0
                                    ? <Text fontSize={14} lineHeight='20px' fontWeight={400} color='#079455'>New Client</Text>
                                    : record.customerLabel === 1
                                        ? <Text fontSize={14} lineHeight='20px' fontWeight={400} color='#155EEF'>Returning Client</Text>
                                        : null
                            }
                        </FlexBox>
                    </Block>
                </FlexBox>);
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => {
                return <Text >{record.email}</Text>;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => {
                return <Text >{record.phone}</Text>;
            },
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: 'channel',
            render: (_) => {
                return renderChannelCampaign();
            },
        },
        {
            title: 'Date & time',
            dataIndex: 'creationTime',
            key: 'creationTime',
            render: (_) => {
                return <Text>{formatDate(createDateTimeApply(campaign.dateApply, campaign.timeApply), `${currentLocale?.format} HH:mm A`)}</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_) => {
                return  <Tag color='blue'>Scheduled</Tag>;
            },
        }
    ];

    return (<FlexBox direction='column' gap={24}>
        <FlexBox gap={16} width='100%'>
            <MetricOverviewCard
                total={customerOverviewResponse?.totalClient || 0}
                emailTotal={customerOverviewResponse?.totalClient || 0}
                smsTotal={customerOverviewResponse?.totalClient || 0}
                label='Sent'
                icon={<IconSentCampaign />}
                isLoading={isLoadingOverview}
            />
            <MetricOverviewCard
                total={0}
                
                emailTotal={0}
                smsTotal={0}
                label='Delivered'
                icon={<IconMailCampaign />}
                isLoading={isLoadingOverview}
            />
            <MetricOverviewCard
                total={0}
                emailTotal={0}
                smsTotal={0}
                label='Failed'
                icon={<IconFailedCampaign />}
                isLoading={isLoadingOverview}
            />
        </FlexBox>
        {!isLoading && renderExternalCard()}
    </FlexBox>);
};

