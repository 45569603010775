import React from 'react';
import { AutomationEventGetAllResponseItem } from '@esg/business-success-platform';
import { AutomationEventDetailTabKey } from '../enum';
import { AutomationEventDetailContentMetric } from './AutomationEventDetailContentMetric';
import { AutomationEvenContentDetail } from './AutomationEvenContentDetail';
import { AutomationEventPromotion } from './AutomationEventPromotion';
screenX;
interface AutomationEventContentProps {
    activeKey: string;
    automationEvent: AutomationEventGetAllResponseItem;
}

export const AutomationEventContent = (props: AutomationEventContentProps) => {
    const { activeKey, automationEvent } = props;

    if (activeKey === AutomationEventDetailTabKey.Metric) {
        return <AutomationEventDetailContentMetric
            automationEvent={automationEvent} activeKey={activeKey}/>;
    }
    if (activeKey === AutomationEventDetailTabKey.Promotion) {
        return <AutomationEventPromotion
            activeKey={activeKey}
            automationEvent={automationEvent}
        />;
    }
    if (activeKey === AutomationEventDetailTabKey.Detail) {
        return <AutomationEvenContentDetail
            automationEvent={automationEvent}
        />;
    }

    return <div>Đây là nội dung của tab {activeKey}</div>;
};

