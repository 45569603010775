import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface GetAllSaleActivitiesRequest extends HttpRequestData {
    readonly query: {
        id: string;
    };
}

export interface SaleActivityData {
    readonly field: string;
    readonly originValue: string;
    readonly changedValue: string;
}

export interface SaleActivity {
    readonly createById: string;
    readonly createByName: string;
    readonly recordId: string;
    readonly parentRecordId?: string;
    readonly entityName: string;
    readonly action: string;
    readonly created: string;
    readonly createdTime: string;
    readonly data: Array<SaleActivityData>;
    readonly currentDataJson: string;
}

export interface GetAllSaleActivitiesResponse {
    readonly id: string;
    readonly activities: SaleActivity[][];
}

export const getAllSaleActivities = httpUtil.createHttpRequestMeta<
    GetAllSaleActivitiesRequest,
    GetAllSaleActivitiesResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/activities/sale/:id',
    method: 'GET',
    authentication: 'bearer',
});
