import './SaleFrom.scss';

import { FlexBox, FormBase, FormLayout, globalModalState, useHttpCommand } from '@esg/ui';
import { GetDetailSaleResponse, SaleDetailGetAllResponseItem, UnitEnum, deleteSaleDetail, getDetailSale, updateSale, updateSaleDetail } from '@esg/business-pos';
import { managementApis } from '@esg/business-management';
import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { updateMultipleSaleDetail } from '@esg/business-pos/api/sale-detail/updateMultipleSaleDetail';
import { SaleOrder } from './children/sale-order/SaleOrder';
import InitSaleForm from './InitSaleForm';
import { MultipleSaleDetailSubmit } from './EditMultipleSaleDetailForm';
import { currentSaleState } from '@/states';
import { saleHelper } from '@/helpers';
import { translateCodes } from '@/locales';
import { saleEvents } from '@/event/saleEvent';

interface SaleFormProps {
    loadingUpdate?: boolean;
    loadingCreate?: boolean;
}

export function FastPostForm(props: SaleFormProps) {
    const { t } = useTranslation();
    const resetModalState = useResetRecoilState(globalModalState);
    const [currentSale, setCurrentSale] = useRecoilState(currentSaleState);
    const salePromotion = currentSale?.salePromotions?.[0];

    const { mutateAsync: deleteSaleDetailMutate, isPending: loadingDelete } = useHttpCommand(deleteSaleDetail, {
        onSuccess: (response) => {
            if (salePromotion) {
                return getSaleDetailMutate({
                    pathData: {
                        id: currentSale.id,
                    },
                });
            }
            const saleDetails = currentSale?.saleDetails?.filter(o => o.id !== response?.id) ?? [];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.MM_FP_03),
            });
        }
    });

    const { mutateAsync: updateSaleDetailMutate, isPending: loadingUpdate } = useHttpCommand(updateSaleDetail, {
        onSuccess: (response) => {
            resetModalState();
            if(salePromotion)
            {
                return getSaleDetailMutate( {
                    pathData: {
                        id: currentSale.id,
                    },
                });
            }
            const saleDetails = currentSale?.saleDetails?.map(o => o.id === response?.id ? response : o) ?? [];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.MM_FP_02),
            });
        }
    });

    const { mutateAsync: updateSaleMutate } = useHttpCommand(updateSale, {
        onSuccess: (response) => {
            setCurrentSale(response);
        }
    });

    const { mutateAsync: onCreateSalePromotionMutate, isPending: loadingPromotion } = useHttpCommand(managementApis.createSalePromotion, {
        onSuccess: (response) => {
            setCurrentSale(response);
        }
    });

    const { mutateAsync: deleteSalePromotionMutate, isPending: loadingDeletePromotion } = useHttpCommand(managementApis.deleteSalePromotion, {
        onSuccess: (response) => {
            setCurrentSale(response);
        }
    });

    const { mutateAsync: onUpdateMultipleSaleDetailMutate, isPending: isUpdateMultipleLoading } = useHttpCommand(updateMultipleSaleDetail, {
        onSuccess: (response) => {
            const saleDetails = response?.saleDetails ?? [];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
            resetModalState();
        }
    });

    const { mutateAsync: getSaleDetailMutate, isPending: isLoadingSale } = useHttpCommand(getDetailSale,
        {
            onSuccess: (response) => {
                setCurrentSale(response);
            }
        }
    );

    const onUpdateSaleDetail = useCallback(async (saleDetail: SaleDetailGetAllResponseItem) => {
        await updateSaleDetailMutate({ pathData: { id: saleDetail.id }, body: saleDetail });
    }, [updateSaleDetailMutate]);

    const onUpdateSale = useCallback(async (sale: GetDetailSaleResponse) => {
        await updateSaleMutate({ pathData: { id: sale.id }, body: sale });
        saleEvents.onRefetchTabSaleList.emit();
    }, [updateSaleMutate]);

    const onDeleteSaleDetail = useCallback(async (id: string) => {
        await deleteSaleDetailMutate({ pathData: { id } });
    }, [deleteSaleDetailMutate]);

    const handleUpdateMultipleSaleDetail = useCallback(async (body: MultipleSaleDetailSubmit) => {
        await onUpdateMultipleSaleDetailMutate({ pathData: { id: body.saleId }, body: body });
    }, [onUpdateMultipleSaleDetailMutate]);

    const handleCreateSalePromotion = useCallback(async (customerPromotionId: string) => {
        await onCreateSalePromotionMutate({ body: {
            saleId: currentSale?.id,
            customerPromotionId: customerPromotionId
        } });
    }, [currentSale?.id, onCreateSalePromotionMutate]);

    const handleDeleteSalePromotion = useCallback(async (id: string) => {
        await deleteSalePromotionMutate({ pathData: { id: id } });
    }, [deleteSalePromotionMutate]);

    return (
        <FormBase onSubmit={() => { }}
            defaultValues={{
                ...currentSale,
                tipUnit: currentSale?.tipUnit ?? UnitEnum.Percent,
            }}
        >
            <FormLayout
                className='sale-form'
                width='100%'
                footer={<></>}
            >
                <InitSaleForm>
                    <FlexBox className='sale-container fast-sale'>
                        <SaleOrder
                            isLoading={
                                props.loadingCreate || props.loadingUpdate || loadingDelete || loadingUpdate 
                                || isUpdateMultipleLoading || loadingPromotion || loadingDeletePromotion ||isLoadingSale
                            }
                            handleUpdateSale={onUpdateSale}
                            handleDeleteSaleDetail={onDeleteSaleDetail}
                            handleUpdateSaleDetail={onUpdateSaleDetail}
                            handleUpdateMultipleSaleDetail={handleUpdateMultipleSaleDetail}
                            handleCreateSalePromotion={handleCreateSalePromotion}
                            handleDeleteSalePromotion={handleDeleteSalePromotion}
                            width='100%'
                            isFastSale={true}
                        />
                    </FlexBox>
                </InitSaleForm>
            </FormLayout>
        </FormBase>
    );
}
