import React from 'react';
import { Card, Divider, Progress, Rate } from 'antd/lib';
import { Block, FlexBox, Text, useHttpQuery } from '@esg/ui';
import { getAllClientReviewSummary } from '../../../../../../../packages/business/client-review';
export function ClientReviewOverview() {

    const { data: reviewSummary } = useHttpQuery(getAllClientReviewSummary);

    const reviewSummaryResult = reviewSummary?.items[0];
    const ratings = [
        { label: '5 stars', value: reviewSummaryResult?.fiveStarCount, percent: ((reviewSummaryResult?.fiveStarCount ?? 0) / (reviewSummaryResult?.totalReviews ?? 0) * 100) },
        { label: '4 stars', value: reviewSummaryResult?.fourStarCount, percent: ((reviewSummaryResult?.fourStarCount ?? 0) / (reviewSummaryResult?.totalReviews ?? 0) * 100) },
        { label: '3 stars', value: reviewSummaryResult?.threeStarCount, percent: ((reviewSummaryResult?.threeStarCount ?? 0) / (reviewSummaryResult?.totalReviews ?? 0) * 100) },
        { label: '2 stars', value: reviewSummaryResult?.twoStarCount, percent: ((reviewSummaryResult?.twoStarCount ?? 0) / (reviewSummaryResult?.totalReviews ?? 0) * 100) },
        { label: '1 star', value: reviewSummaryResult?.oneStarCount, percent: ((reviewSummaryResult?.oneStarCount ?? 0) / (reviewSummaryResult?.totalReviews ?? 0) * 100) },
    ];
    return (
        <Card>
            <FlexBox gap={10}>
                <Block width='35%'>
                    {ratings.map((rating, index) => (
                        <Block key={index} padding={3}>
                            <FlexBox gap={10} >
                                <Block width='12%'><Text>{rating.label}</Text></Block>
                                <Progress percent={rating.percent} showInfo={false} strokeColor='#ff9800' />
                                <Block width='10%'><Text>{rating.value}</Text></Block>
                            </FlexBox>
                        </Block>
                    ))}
                </Block>
                <Divider style={{ height: '140px' }} type='vertical' />
                <Block width='35%'>
                    <FlexBox gap={10} direction='column'>
                        <FlexBox gap={10}>
                            <Rate disabled style={{ color: '#ff9800' }} allowHalf defaultValue={reviewSummaryResult?.averageRating} value={reviewSummaryResult?.averageRating} />
                            <Text fontWeight={600}>{reviewSummaryResult?.averageRating.toFixed(1)}/5</Text>
                        </FlexBox>
                        <Text>({reviewSummaryResult?.totalReviews} rating)</Text>
                    </FlexBox>
                </Block>
            </FlexBox>
        </Card>
    );
}
