import { DownOutlined, } from '@ant-design/icons';
import { FlexBox, Text, IconShopping, IconFilePlus, useLocalesFormat, globalDrawerState, globalModalState, useHttpCommand, managementProfileState, IconPenActionCustomer, IconTrashActionCustomer } from '@esg/ui';
import React, { useCallback } from 'react';
import { Button, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetAllCustomerResponseItem, GetCustomerDetailResponse } from '@esg/business-crm';
import { createSale } from '@esg/business-pos';
import { CRM_URL } from '@/configs';
import { translateCodes } from '@/locales';
import { CustomerForm } from '@/components';
import { useCustomerDelete } from '@/hooks/useCustomerDelete';
import { CustomerDeleteConfirm } from '@/components/common/customer/CustomerDeleteConfirm';
import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';
import CustomerAddNoteForm from '@/components/form/customer/CustomerAddNoteForm';

interface ActionCustomerDetailProps {
    customer?: GetCustomerDetailResponse;
    refetch: () => void;
    refetchCustomerNotes: () => void;
    refetchCustomerActivity: () => void;
}

export const ActionCustomerDetail = ({ customer, refetch, refetchCustomerNotes, refetchCustomerActivity}: ActionCustomerDetailProps) => {
    const { t } = useTranslation();
    const { formatDate } = useLocalesFormat();
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const { onShowFormSale } = useOpenSaleFrom();
    const [currentProfile] = useRecoilState(managementProfileState);
    const resetGlobalModal = useResetRecoilState(globalModalState);

    const onDelete = useCustomerDelete(refetch, CRM_URL);

    const { mutateAsync: onCreateSale } = useHttpCommand(createSale, {
        onSuccess: (response) => {
            onShowFormSale(response);
            refetchCustomerActivity();
        }
    });

    const handleCreateSale = async () => {
        await onCreateSale({
            body: {
                branchId: currentProfile?.branch.id,
                customerId: customer?.id,
            }
        });
    };

    const handleDelete = React.useCallback(
        (customer: GetAllCustomerResponseItem) => {
            setGlobalModal({
                content: (
                    <CustomerDeleteConfirm {...{ customer }} />
                ),
                isOpen: true,
                onOk: () => {
                    onDelete(customer.id);
                },
                showModalDelete: true,
            });
        },
        [onDelete, setGlobalModal]
    );

    const handleEdit = () => {
        setDrawerState({
            subtitle: t(translateCodes.CREATED_ON) + ' ' + formatDate(customer?.profileCreationTime ?? ''),
            isOpen: true,
            titleTransCode: translateCodes.EDIT_CUSTOMER,
            content: (
                <CustomerForm
                    onSuccess={() => {
                        resetDrawerState();
                        refetch();
                    }}
                    defaultValues={customer as GetAllCustomerResponseItem}
                />
            ),
        });
    };

    const showModalAddNote = useCallback(() => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: translateCodes.ADD_NOTE,
            content: <CustomerAddNoteForm
                customerId={customer!.id}
                onSuccess={() => {
                    resetGlobalModal();
                    refetchCustomerNotes();
                } }
                onCancel={()=>{
                    resetGlobalModal();
                }} 
            />
        });
    }, [customer, globalModal, refetchCustomerNotes, resetGlobalModal, setGlobalModal]);

    const items = [
        {
            key: 'sale',
            label: (
                <a  onClick={()=>handleCreateSale()}>
                    <FlexBox gap={12} alignItems='center'>
                        <IconShopping />
                        <Text translateCode={translateCodes.SALES} />
                    </FlexBox>
                </a>
            ),
        },
        {
            key: 'note',
            label: (
                <a onClick={()=>showModalAddNote()}>
                    <FlexBox gap={12} alignItems='center'>
                        <IconFilePlus />
                        <Text translateCode={translateCodes.ADD_NOTE} />
                    </FlexBox>
                </a>
            ),
        },
        {
            key: 'edit_client',
            label: (
                <a onClick={() => handleEdit()}>
                    <FlexBox gap={12} alignItems='center'>
                        <IconTrashActionCustomer/>
                        <Text translateCode={translateCodes.EDIT_CLIENT_DETAIL} />
                    </FlexBox>
                </a>
            ),
        },
        {
            key: 'delete_client',
            label: (
                <a onClick={() => handleDelete(customer as GetAllCustomerResponseItem)}>
                    <FlexBox gap={12} alignItems='center'>
                        <IconPenActionCustomer color='red' />
                        <Text color='red' translateCode={translateCodes.DELETE_CLIENT} />
                    </FlexBox>
                </a>
            ),
        },
    ];

    return (
        <Dropdown menu={{ items }} placement='bottomRight' arrow>
            <Button>
                <FlexBox alignItems='center'>
                    <Text fontSize={14} fontWeight={400} translateCode='Actions' />
                    <DownOutlined style={{ marginLeft: 10 }} />
                </FlexBox>
            </Button>
        </Dropdown>
    );
};
