import { Paragraph, Spacer, Text } from '@esg/ui';
import React from 'react';

export const AutomationEventActiveConfirm = () => {
    return (
        <Spacer top='xs'>
            <Text fontWeight='bold' translateCode='Change status automation event' />
            <Spacer bottom='xs' />
            <Paragraph>
                <Text translateCode='Are you sure you want to change the status of the automation event?' />
            </Paragraph>
        </Spacer>
    );
};