import React from 'react';
import { IconProps } from '../../Types';

export const IconFailedCampaign = (props: IconProps) => {
    return (
        <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z' fill='#FEE4E2' />
            <path d='M23.9998 21.0002V25.0002M23.9998 29.0002H24.0098M22.6151 15.892L14.3902 30.0986C13.934 30.8866 13.7059 31.2806 13.7396 31.6039C13.769 31.886 13.9168 32.1423 14.1461 32.309C14.4091 32.5002 14.8643 32.5002 15.7749 32.5002H32.2246C33.1352 32.5002 33.5904 32.5002 33.8534 32.309C34.0827 32.1423 34.2305 31.886 34.2599 31.6039C34.2936 31.2806 34.0655 30.8866 33.6093 30.0986L25.3844 15.892C24.9299 15.1068 24.7026 14.7142 24.4061 14.5824C24.1474 14.4673 23.8521 14.4673 23.5935 14.5824C23.2969 14.7142 23.0696 15.1068 22.6151 15.892Z' stroke='#D92D20' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

