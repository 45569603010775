import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface getOverviewClientGenderRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export interface getOverviewClientGenderResponse {
    totalMale: number;
    totalFemale: number;
    totalUnknown: number;
    totalOther: number;
    totalClient: number;
}

export const getOverviewClientGenderReport = httpUtil.createHttpRequestMeta<
    getOverviewClientGenderRequest,
    getOverviewClientGenderResponse
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/clients-report/overview-gender',
    authentication: 'bearer',
});