import { DownOutlined, } from '@ant-design/icons';
import { FlexBox, Text } from '@esg/ui';
import React from 'react';
import { Button, Dropdown, DropdownProps } from 'antd';

export interface ActionButtonProps extends DropdownProps
{
} 

export const ActionButton = (props : ActionButtonProps) => {

    return (
        <Dropdown placement='bottomRight' arrow {...props}>
            <Button>
                <FlexBox alignItems='center'>
                    <Text fontSize={14} fontWeight={400} translateCode='Action' />
                    <DownOutlined style={{ marginLeft: 10 }} />
                </FlexBox>
            </Button>
        </Dropdown>
    );
};
