import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetCampaignOverviewPromotionRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export interface GetCampaignOverviewPromotionResponse {
    totalRevenue: number;
    totalPromotion: number;
    totalUsePromotion: number;
    totalUnusedPromotion: number;
    totalExpiredPromotion: number;
}

export const getCampaignOverviewPromotion = httpUtil.createHttpRequestMeta<
    GetCampaignOverviewPromotionRequest,
    GetCampaignOverviewPromotionResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/campaigns/promotion-overview/:id',
    method: 'GET',
    authentication: 'bearer',
});