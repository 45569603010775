import React from 'react';

import { IconProps } from '../../Types';

export const IconRewardLoyalClients = (props: IconProps) => {
    return (
        <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z' fill='#FEF0C7'/>
            <path d='M40.0003 31.9993V53.3327M40.0003 31.9993H35.286C34.5914 31.9993 33.9253 31.7184 33.4341 31.2183C32.9429 30.7182 32.667 30.0399 32.667 29.3327C32.667 28.6254 32.9429 27.9472 33.4341 27.4471C33.9253 26.947 34.5914 26.666 35.286 26.666C38.9527 26.666 40.0003 31.9993 40.0003 31.9993ZM40.0003 31.9993H44.7146C45.4092 31.9993 46.0754 31.7184 46.5666 31.2183C47.0577 30.7182 47.3337 30.0399 47.3337 29.3327C47.3337 28.6254 47.0577 27.9472 46.5666 27.4471C46.0754 26.947 45.4092 26.666 44.7146 26.666C41.0479 26.666 40.0003 31.9993 40.0003 31.9993ZM50.667 38.666V49.066C50.667 50.5595 50.667 51.3062 50.3763 51.8767C50.1207 52.3784 49.7127 52.7864 49.211 53.042C48.6405 53.3327 47.8938 53.3327 46.4003 53.3327L33.6003 53.3327C32.1069 53.3327 31.3601 53.3327 30.7897 53.042C30.2879 52.7864 29.88 52.3784 29.6243 51.8767C29.3337 51.3062 29.3337 50.5595 29.3337 49.066V38.666M26.667 34.1327L26.667 36.5327C26.667 37.2794 26.667 37.6528 26.8123 37.938C26.9401 38.1889 27.1441 38.3929 27.395 38.5207C27.6802 38.666 28.0536 38.666 28.8003 38.666L51.2003 38.666C51.9471 38.666 52.3204 38.666 52.6056 38.5207C52.8565 38.3929 53.0605 38.1889 53.1883 37.938C53.3337 37.6528 53.3337 37.2794 53.3337 36.5327V34.1327C53.3337 33.3859 53.3337 33.0126 53.1883 32.7274C53.0605 32.4765 52.8565 32.2725 52.6056 32.1447C52.3204 31.9993 51.9471 31.9993 51.2003 31.9993L28.8003 31.9993C28.0536 31.9993 27.6802 31.9993 27.395 32.1447C27.1441 32.2725 26.9401 32.4765 26.8123 32.7274C26.667 33.0126 26.667 33.3859 26.667 34.1327Z' stroke='#DC6803' strokeWidth='1.67' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};
