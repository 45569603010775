import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetAutomationOverviewPromotionRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export interface GetAutomationOverviewPromotionResponse {
    totalRevenue: number;
    totalPromotion: number;
    totalUsePromotion: number;
    totalUnusedPromotion: number;
    totalExpiredPromotion: number;
}

export const getAutomationOverviewPromotion = httpUtil.createHttpRequestMeta<
    GetAutomationOverviewPromotionRequest,
    GetAutomationOverviewPromotionResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/automation-events/promotion-overview/:id',
    method: 'GET',
    authentication: 'bearer',
});