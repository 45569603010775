import { Tag } from 'antd';
import { Text } from '@esg/ui';

import React from 'react';
import { customerLabelColorUtil, customerLabelSimpleUtil, customerLabelTagColorUtil, customerLabelUtil } from '@esg/shared';
import { ICustomerLabel } from './interface';

export interface CustomerLabelTagProps {
    customer?: ICustomerLabel;
    isTag?: boolean;
}

export const CustomerLabelTag = (props: CustomerLabelTagProps) => {
    const tagNumber = props.customer?.customerLabel;

    if (tagNumber == null) {
        return null;
    }

    if (props.isTag != false)  {
        return (
            <Tag color={customerLabelTagColorUtil(tagNumber)} style={{marginInlineEnd: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Text fontSize={12} color={customerLabelColorUtil(tagNumber)} >
                    {customerLabelUtil(tagNumber)}
                </Text>
            </Tag>
        );
    }

    return (
        <Text fontSize={12} color={customerLabelColorUtil(tagNumber)}>
            {customerLabelSimpleUtil(tagNumber)}
        </Text>
    );
};
