import React, { useState } from 'react';
import {
    AppButton, Text, FlexBox, globalDrawerState,
    globalModalState, TableCustom, useHttpCommand, useHttpQuery,
    usePagination, ColumnActions, useLocalesFormat,
    AppFilter,
    DatePickerField,
    SelectField
} from '@esg/ui';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetAllProductCategoryRequest, managementApis } from '@esg/business-management';
import {  TableColumnsType, Tag } from 'antd';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { dayjs, requestAllItem } from '@esg/shared';
import {  CreatePromotionRequest, GetPromotionResponseItem, ObjectStatus, ObjectType, PromotionConditionRequest, UpdatePromotionRequest } from '@esg/business-management/api/promotion';
import { GetAllServiceCategoryRequest } from '@esg/business-management/api/service-category';
import { viewRequests } from '@esg/framework';
import { PageLayout } from '@/layouts';
import { translateCodes } from '@/locales';
import { PromotionForm, promotionTypeOptions } from '@/components';

export interface FilterSegmentParams {
    readonly objectType?: ObjectType;
    readonly effectiveDate?: string;
    readonly expirationDate?: string;
    readonly status?: ObjectStatus;
}

const promotionStatusOptions = [
    {label: ObjectStatus[ObjectStatus.Active] , value: ObjectStatus.Active},
    {label: ObjectStatus[ObjectStatus.Inactive] , value: ObjectStatus.Inactive},
    // {label: 'Package promotion' , value: 2},
    {label:  ObjectStatus[ObjectStatus.Expired] , value: ObjectStatus.Expired}
];

export function PromotionOverview() {
    const { formatDate, formatCurrencySymbol } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const { t } = useTranslation();
    const [searchParams, setSearchParams] = usePagination();
    const [filterParams, setFilterParams] = useState<FilterSegmentParams>({});

    const {
        data: promotionsResponse,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(managementApis.getAllPromotion, {
        query: {...searchParams, ...filterParams},
    });

    const {  mutateAsync: serviceCategoryAsync, isPending: isServicePending  } =useHttpCommand(managementApis.getAllServiceCategory);

    const {  mutateAsync: productCategoryAsync, isPending: isProductPending } =useHttpCommand(managementApis.getAllProductCategory);

    const { mutateAsync: deleteSegmentAsync } = useHttpCommand(managementApis.deletePromotion,{
        onSuccess(_) {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SAM_06),
            });
            resetModalState();
            resetDrawerState();
            refetch();
        },
    });

    const { mutateAsync: createPromotionAsync } = useHttpCommand(managementApis.createPromotion, {
        onSuccess: () => {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SAM_04),
            });
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const { mutateAsync: updatePromotionAsync } = useHttpCommand(managementApis.updatePromotion, {
        onSuccess: () => {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SAM_05),
            });
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const onDelete = React.useCallback(
        async (id: string) => {
            await deleteSegmentAsync({ pathData: { id: id } });
        },
        [deleteSegmentAsync]
    );

    const showModalDelete = React.useCallback((id: string) => {
        const promotionName = promotionsResponse?.items.find((item) => item.id === id)?.name;
        setModalState({
            content: (
                <FlexBox direction='column'>
                    <Text fontSize={18} fontWeight={700}>
                        {t(translateCodes.DELETE_PROMOTION)}
                    </Text>
                    <Text fontSize={14}>
                        {`${t(translateCodes.DELETE_PROMOTION_MSG)} : `}
                        <Text fontSize={14} fontWeight={600}>{promotionName}?</Text>
                    </Text>
                </FlexBox>
            ),
            isOpen: true,
            onOk: () => {
                onDelete(id);
            },
            showModalDelete: true,
        });
    }, [onDelete, promotionsResponse?.items, setModalState, t]);

    const onEdit = React.useCallback(
        async (values: GetPromotionResponseItem) => {

            const promotionConditionValue =  values.promotionCondition;

            const promotionCondition = {
                objectType: promotionConditionValue?.objectType || 0,
                numberOfTimes: promotionConditionValue?.numberOfTimes || 0,
                objectMode: promotionConditionValue?.objectMode || 0,
                objectValue: promotionConditionValue?.objectValue
            } as PromotionConditionRequest;

            const payload: UpdatePromotionRequest['body'] = {
                id: values.id,
                name: values.name,
                code: values.code,
                isActive: values.isActive,
                unit: values.unit,
                value: values.value,
                effectiveDate: values.effectiveDate,
                expirationDate: values.expirationDate,
                promotionCondition: promotionCondition,
            };

            await updatePromotionAsync({ body: payload, pathData: { id: values.id } });
        },
        [updatePromotionAsync]
    );

    const onCreate = React.useCallback(
        async (values: GetPromotionResponseItem) => {
            const promotionConditionValue =  values.promotionCondition;

            const promotionCondition = {
                objectType: promotionConditionValue?.objectType || 0,
                numberOfTimes: promotionConditionValue?.numberOfTimes || 0,
                objectMode: promotionConditionValue?.objectMode || 0,
                objectValue: promotionConditionValue?.objectValue 
            } as PromotionConditionRequest;

            const payload: CreatePromotionRequest['body'] = {
                name: values.name,
                code: values.code,
                isActive: values.isActive,
                unit: values.unit,
                value: values.value,
                effectiveDate: values.effectiveDate,
                expirationDate: values.expirationDate,
                promotionCondition: promotionCondition,
            };
            await createPromotionAsync({ body: payload });
        }, [createPromotionAsync]
    );

    const onShowModalEdit = React.useCallback(async (values: GetPromotionResponseItem) => {
        const [serviceCategories, productCategories] = await Promise.all([
            serviceCategoryAsync({ query: requestAllItem } as GetAllServiceCategoryRequest ),
            productCategoryAsync({ query: requestAllItem } as GetAllProductCategoryRequest ),
        ]);
        
        setDrawerState({
            isOpen: true,
            titleTransCode: 'Edit Promotion',
            content: (
                <PromotionForm
                    onSubmit={onEdit}
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                    defaultValues={values}
                    serviceCategories={serviceCategories?.items}
                    productCategories={productCategories?.items}
                />
            ),
        });
    }, [onEdit, productCategoryAsync, refetch, resetDrawerState, serviceCategoryAsync, setDrawerState]
    );

    const onShowModalCreate = React.useCallback(async () => {
        const [serviceCategories, productCategories] = await Promise.all([
            serviceCategoryAsync({ query: requestAllItem } as GetAllServiceCategoryRequest ),
            productCategoryAsync({ query: requestAllItem } as GetAllProductCategoryRequest ),
        ]);

        setDrawerState({
            isOpen: true,
            titleTransCode: 'Create new promotion',
            content: (
                <PromotionForm
                    onSubmit={onCreate}
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                    serviceCategories={serviceCategories?.items}
                    productCategories={productCategories?.items}
                />
            ),
        });
    }, [onCreate, productCategoryAsync, refetch, resetDrawerState, serviceCategoryAsync, setDrawerState]
    );

    const columns: TableColumnsType<GetPromotionResponseItem> = [
        {
            title: 'Code',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => (a.code || '').localeCompare(b.code || ''),
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis
                >{record.code}</Text>;
            },
        },
        {
            title: 'Promotion name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis
                >{record.name}</Text>;
            }
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            render: (_, record) => {
                return <Text >{`${record.value} ${record.unit === 0 ? '%': formatCurrencySymbol()}`}</Text>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => {
                return <Text>{record.promotionCondition?.objectType === ObjectType.Service
                    ? 'Service' : record.promotionCondition?.objectType === ObjectType.Product
                        ? 'Product' : 'Total sale promotion'}</Text>;
            },
        },
        {
            title: 'Effective date',
            dataIndex: 'effectiveDate',
            key: 'effectiveDate',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.effectiveDate), `${currentLocale?.format}`)} { }</Text>;
            },
        },
        {
            title: 'Expiration date',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: (_, record) => {
                const currentDate = dayjs().startOf('day');
                if (currentDate.add(22, 'year') < dayjs(record.expirationDate)) {
                    return <Text>Ongoing</Text>;
                }
                return <Text>{formatDate(dayjs(record.expirationDate), `${currentLocale?.format}`)} { }</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => {
                const currentDate = dayjs().startOf('day');
                if (currentDate > dayjs(record.expirationDate)) {
                    return <Tag color='red'>Expired</Tag>;
                }

                if(!record.isActive){
                    return <Tag color='default'>Inactive</Tag>;
                }

                return <Tag color='success'>Active</Tag>;
            },
        },
        
        {
            title: t(translateCodes.ACTION),
            key: 'action',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={()=>{showModalDelete(record.id);}}
                        onClickEdit={() => onShowModalEdit(record)}
                    />
                );
            },
        },
    ];

    const showFilterRender = () => {
        return (
            <AppFilter<FilterSegmentParams>
                defaultValues={filterParams}
                handleSubmit={setFilterParams}
                onCancel={() => {}}
            >
                {({ renderKey }) => ( 
                    <FlexBox direction='column' gap={16} key={renderKey}>
                        <SelectField
                            label='Promotion type'
                            placeholder='Select promotion type'
                            style={{ width: '100%' }}
                            name='objectType'
                            options={promotionTypeOptions}
                        />
                        <DatePickerField
                            name='effectiveDate'
                            format={currentLocale?.format}
                            label='Effective date'
                            formatValue='day'
                        />
                        <DatePickerField
                            name='expirationDate'
                            format={currentLocale?.format}
                            label='Expiration date'
                            formatValue='day'
                        />
                        <SelectField
                            label='Status'
                            placeholder='Select status'
                            style={{ width: '100%' }}
                            name='status'
                            options={promotionStatusOptions}
                        />
                    </FlexBox>
                )}
            </AppFilter>
        );
    };

    return (
        <PageLayout
            pageTitleTransCode={t(translateCodes.PROMOTIONS)}
            rightHeaderPage={
                <AppButton
                    icon={<PlusOutlined />}
                    translateCode={t(translateCodes.CREATE_PROMOTION)}
                    type='primary'
                    onClick={onShowModalCreate}
                />
            }
        >
            <TableCustom
                showFilterDropdown={showFilterRender}
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                loading={isFetching || isLoading || isServicePending || isProductPending}
                titleTableTransCode='Promotion List'
                placeholderSearchTransCode='Search by promotion name'
                columns={columns}
                dataSource={promotionsResponse?.items}
                pageInfo={promotionsResponse?.meta}
                emptyOption={{
                    title: t(translateCodes.ADD_PROMOTION),
                    description: t(translateCodes.EMPTY_PROMOTION_DESCRIPTION),
                    actionButton: (
                        <AppButton
                            icon={<PlusOutlined />}
                            translateCode={t(translateCodes.CREATE_PROMOTION)}
                            type='primary'
                            onClick={onShowModalCreate}
                        />
                    )
                }}
            />
        </PageLayout>
    );
}
