import {
    BreakTimeGetDetailResponse,
    EmployeeGetDetailResponse,
    getAllTimeBlock,
    getShiftsByDay,
    resourceApis,
} from '@esg/business-resource-management';
import { convertSameDate, dayjs } from '@esg/shared';
import { useHttpQuery } from '@esg/ui';
import { useMemo } from 'react';
import { useBusinessHour } from '@/hooks/useBusinessHour';
import {
    getEmployeeTimeOffsWork,
    EmployeeTimeFrame,
    getEmployeeTimeOffs,
} from '@/helpers/schedulerHelper';

export const useEmployeeDisableTime = (date: string, employees: EmployeeGetDetailResponse[]) => {
    const { branchWorkingTime } = useBusinessHour(date);
    const dayObject = useMemo(() => dayjs(date).startOf('day'), [date]);
    const endDayObject = useMemo(() => dayjs(date).endOf('day'), [date]);

    const { data: shifts } = useHttpQuery(
        getShiftsByDay,
        {
            query: {
                dayOfWeek: dayObject.day(),
                date: dayObject.toISOString(),
            },
        },
        {
            enabled: !!date,
        }
    );

    const { data: timeOffResponse } = useHttpQuery(
        resourceApis.getAllTimeOffs,
        {
            query: {
                startDate: dayObject.toISOString(),
                endDate: endDayObject.toISOString(),
            },
        },
        {
            enabled: !!date,
        }
    );

    const { data: timeBlockResponse } = useHttpQuery(getAllTimeBlock, {
        query: {
            date: dayObject.toISOString(),
        },
    });

    const breakTimes = useMemo<EmployeeTimeFrame[]>(() => {
        const result: BreakTimeGetDetailResponse[] = [];
        shifts?.forEach((s) => {
            result.push(...s.breakTimes);
        });

        return result.map((bt) => ({
            id: bt.id,
            employeeId: bt.employeeId,
            startTime: convertSameDate(date, bt.startTime).toISOString(),
            endTime: convertSameDate(date, bt.endTime).toISOString(),
        }));
    }, [date, shifts]);

    const employeeTimeOffsWork = useMemo<EmployeeTimeFrame[]>(
        () =>
            getEmployeeTimeOffsWork({
                branchWorkingTime,
                currentDate: date,
                shifts: shifts ?? [],
            }),
        [branchWorkingTime, date, shifts]
    );

    const timeOffs = useMemo<EmployeeTimeFrame[]>(
        () =>
            getEmployeeTimeOffs({
                employees,
                branchWorkingTime,
                shifts: shifts ?? [],
                timeOffs: timeOffResponse ?? [],
            }),

        [branchWorkingTime, employees, shifts, timeOffResponse]
    );

    const timeBlocks = useMemo<EmployeeTimeFrame[]>(() => {
        return (
            timeBlockResponse?.map((tb) => ({
                id: tb.id,
                employeeId: tb.employeeId,
                endTime: convertSameDate(date, tb.endTime).toISOString(),
                startTime: convertSameDate(date, tb.startTime).toISOString(),
            })) ?? []
        );
    }, [date, timeBlockResponse]);

    return [...breakTimes, ...timeOffs, ...employeeTimeOffsWork, ...timeBlocks];
};
