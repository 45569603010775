import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface CreateCustomerSourceRequest extends HttpRequestData {
    readonly body: {
        readonly name: string;
        readonly active?: boolean;
    };
}

export interface CreateCustomerSourceResponse {
    readonly id: string;
    readonly name: string;
    readonly active?: boolean;
}

export const createCustomerSource = httpUtil.createHttpRequestMeta<
    CreateCustomerSourceRequest,
    CreateCustomerSourceResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-sources',
    method: 'POST',
    authentication: 'bearer',
});
