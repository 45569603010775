import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { CustomerPromotionStatus } from '../customer-promotion';

export interface CampaignGetCustomerPromotionRequest extends HttpRequestData {
    pathData: {
        id: string;
    };
    query: PaginationRequest;
}

export interface CampaignGetCustomerPromotionItemResponse {
    id: string;
    sourceId?: string;
    sourceCode: number;
    promotionApplyId: string;
    promotionApply?: PromotionApplyResponse;
    branchId: string;
    customerId: string;
    status: CustomerPromotionStatus;
    customer?: CustomerResponse;
    creationTime: string;
}

export interface CustomerResponse {
    id: string;
    email?: string;
    firstName: string;
    lastName?: string;
    phone?: string;
    fullName?: string;
    customerCode?: string;
    avatar?: string;
    customerLabel?: number;
}

export interface PromotionApplyResponse {
    id: string;
    name?: string;
    promotionName: string;
    code: string;
    value: number;
    unit: number;
    effectiveDate: string;
    expirationDate: string;
    promotionId: string;
    isActive: boolean;
    promotionCondition?: string;
    creationTime: string;
}

export const getCampaignCustomerPromotion = httpUtil.createHttpRequestMeta<
    CampaignGetCustomerPromotionRequest,
    PaginationResponse<CampaignGetCustomerPromotionItemResponse>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/campaigns/promotion/:id',
    method: 'GET',
    authentication: 'bearer',
});
