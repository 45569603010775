import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { GetAllServiceResponseItem } from '../service';

export interface GetAllServiceCategoryRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllServiceCategoryResponseItem {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly services: GetAllServiceResponseItem[];
}

export const getAllServiceCategory = httpUtil.createHttpRequestMeta<
    GetAllServiceCategoryRequest,
    PaginationResponse<GetAllServiceCategoryResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/service-categories',
    method: 'GET',
    authentication: 'bearer',
});
