import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Automation } from './automation';
import { Segment } from './segment';
import { Promotion } from './promotion';
import { Campaign } from './campaign';
import { AutomationEventDetail } from './automation/automation-event-detail/AutomationEventDetail';
import { EmailTemplate } from './automation/email-template/EmailTemplate';
import { ClientReview } from './client-review/ClientReview';
import { SubMenuLayout } from '@/layouts';
import { AUTOMATION_URL, salonMarketingMenu } from '@/configs';

export function SuccessPlatform() {
    return (
        <Routes>
            <Route
                element={<SubMenuLayout subMenu={salonMarketingMenu} />}
            >
                <Route path='/' element={<Navigate to={AUTOMATION_URL} />} />
                <Route path='/automation' Component={Automation} />
                <Route path='/template/:type' Component={EmailTemplate} />
                <Route path='/automation/:id' Component={AutomationEventDetail} />
                <Route path='/segment' Component={Segment}  />
                <Route path='/segment/:id' Component={Segment} />
                <Route path='/promotion' Component={Promotion}  />
                <Route path='/promotion/:id' Component={Promotion}  />
                <Route path='/campaign' Component={Campaign}  />
                <Route path='/campaign/:id' Component={Campaign}  />
                <Route path='/client-review' Component={ClientReview}  />
            </Route>
        </Routes>
    );
}
