import {
    Text,
    FlexBox,
    SwitchField,
    IconEmail,
    IconSms,
    SelectField,
    Spacer,
    useHttpQuery
} from '@esg/ui';
import React, { useMemo, useState } from 'react';
import { Card } from 'antd';
import { CampaignChannelItem, CampaignChannelType } from '@esg/business-management/api/campaign';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { DefaultOptionType } from 'antd/es/cascader';
import { GetAllEmailTemplateResponseItem } from '@esg/business-success-platform';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { templateVariableGetAll } from '@esg/business-success-platform/api/template-variable.ts';
import { campaignRules } from '../../rules';
import { translateCodes } from '@/locales';

interface ContentCampaignFormProps {
    readonly isUpdate?: boolean;
    form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>;
    segmentOptions?: DefaultOptionType[];
    promotionOptions?: DefaultOptionType[];
    emailTemplateOptions?: DefaultOptionType[];
    emailTemplate: GetAllEmailTemplateResponseItem[];
}

export const ContentCampaignForm = (props: ContentCampaignFormProps) => {
    const { form, emailTemplateOptions, emailTemplate } = props;
    const { t } = useTranslation();
    const [previewEmail, setPreviewEmail] = useState(false);
    const emailChannelKey = form.watch('campaignChannels').findIndex((item: CampaignChannelItem) => item.type === CampaignChannelType.Mail);
    const emailChannel = form.watch(`campaignChannels[${emailChannelKey}]`) as CampaignChannelItem;
    const smsChannelKey = form.watch('campaignChannels').findIndex((item: CampaignChannelItem) => item.type === CampaignChannelType.Sms);
    // const smsChannel = form.watch(`campaignChannels[${smsChannelKey}]`) as CampaignChannelItem;
    const emailActive = emailChannel.isActive;
    // const smsActive =  smsChannel.isActive;
    const emailTemplateId = emailChannel.emailTemplateId;

    const { data: templateVariables } = useHttpQuery(templateVariableGetAll,
        {
            query: {
                pageSize: 100
            }
        }
    );

    const templateVariableMap = useMemo(() =>
        templateVariables?.items?.map(item => [item.name, item.exampleValue]) || [],
        [templateVariables?.items]
    );

    const variables = Object.fromEntries(templateVariableMap);

    const replaceVariables = (template: string, variables: { [key: string]: string; }) => {
        let replacedTemplate = template;

        for (const variable in variables) {
            if (Object.prototype.hasOwnProperty.call(variables, variable)) {
                const regex = new RegExp(`{{${variable}}}`, 'g');
                replacedTemplate = replacedTemplate.replace(regex, variables[variable]);
            }
        }

        return replacedTemplate;
    };

    const renderPreviewEmail = () => {
        const emailTemplateSelected = emailTemplate.find(c => c.id == emailTemplateId);

        return (
            <Card style={{ backgroundColor: '#F0F2F5' }} bordered={false} >
                <div>
                    <div>
                        <Text fontWeight={700}>{t(translateCodes.EMAIL_TEMPLATE_SUBJECT)}: </Text>
                        <Text>{emailTemplateSelected?.subject}</Text>
                    </div>
                    <br />
                    <> {ReactHtmlParser(replaceVariables(emailTemplateSelected?.body || '', variables))} </>
                </div>
            </Card>
        );
    };

    return (
        <FlexBox direction='column' gap={8}>
            <Spacer bottom={24} />
            {/* {smsActive && emailActive && <Alert
                type='warning'
                message='The system will only send SMS notifications if email delivery fails. This ensures you stay updated without duplicate messages.'
                style={{ color: '#DC6803' }}
            />} */}

            <Text
                fontWeight={600}
                lineHeight='20px'
                color='#344054'
            >
                Channels
            </Text>
            <Card className='card-body'  >
                <FlexBox direction='column' gap={8}>
                    <FlexBox alignItems='center' justifyContent='space-between' gap={8}>

                        <FlexBox alignItems='center' justifyContent='space-between' gap={8}>
                            <IconEmail />
                            <FlexBox direction='column' gap={2}>
                                <Text
                                    fontWeight={600}
                                    lineHeight='20px'
                                    color='#344054'
                                >
                                    Email
                                </Text>
                                <Text
                                >
                                    Reach customers via email
                                </Text>
                            </FlexBox>
                        </FlexBox>
                        <SwitchField
                            className='switch-channel'
                            name={`campaignChannels[${emailChannelKey}].isActive`}
                            containerStyle={
                                {
                                    flex: 'none'
                                }
                            }
                        />
                    </FlexBox>
                    {
                        emailActive && <FlexBox direction='column' gap={8}>
                            <SelectField
                                options={emailTemplateOptions}
                                placeholder='Select email template'
                                label='Email template'
                                name={`campaignChannels[${emailChannelKey}].emailTemplateId`}
                                contentLabelRender={<Text
                                    cursor='pointer'
                                    color='#004EEB'
                                    fontSize={14}
                                    fontWeight={500}
                                    onClick={() => { setPreviewEmail(!previewEmail); }}
                                >{previewEmail ? 'Hide template' : 'Show template'}</Text>
                                }
                                rules={campaignRules.emailTemplate}
                            />
                            {
                                emailTemplateId && previewEmail && renderPreviewEmail()
                            }
                        </FlexBox>
                    }
                </FlexBox>
            </Card>
            <Card className='card-body' style={{ display: 'none' }} >
                <FlexBox alignItems='center' justifyContent='space-between' gap={8}>
                    <FlexBox alignItems='center' justifyContent='space-between' gap={8}>
                        <IconSms />
                        <FlexBox direction='column' gap={2}>
                            <Text
                                fontWeight={600}
                                lineHeight='20px'
                                color='#344054'
                            >
                                Sms
                            </Text>
                            <Text
                            >
                                Reach customers via sms
                            </Text>
                        </FlexBox>
                    </FlexBox>
                    <SwitchField
                        className='switch-channel'
                        name={`campaignChannels[${smsChannelKey}].isActive`}
                        containerStyle={
                            {
                                flex: 'none'
                            }
                        }
                    />
                </FlexBox>
            </Card>
        </FlexBox>
    );
};