import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetSummaryRevenueRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export interface GetSummaryRevenueResponse {
    readonly totalSale: number;
    readonly totalRevenue: number;
    readonly totalAppointmentRevenue: number;
    readonly totalProductRevenue: number;
    readonly totalServiceRevenue: number;
    readonly totalDiscount: number;
    readonly totalTip: number;
    readonly totalTax: number;
    readonly totalPayment: number;
}

export const getSummaryRevenueReport = httpUtil.createHttpRequestMeta<
    GetSummaryRevenueRequest,
    GetSummaryRevenueResponse
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/revenue-report/summary',
    authentication: 'bearer',
});