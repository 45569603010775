import React from 'react';
import { ActionButton, AppTab, Block, FlexBox, globalDrawerState, globalModalState, IconEditDraft, IconPause, IconPen, IconResume, IconTrash, Spacer, Text, useHttpCommand, useHttpQuery } from '@esg/ui';
import { Badge, Image, Spin, Tag } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { managementApis } from '@esg/business-management';
import { CampaignChannelType, CampaignStatus, CampaignType, GetCampaignResponseItem, SaveDraftCampaignRequest, UpdateCampaignRequest } from '@esg/business-management/api/campaign';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { emailTemplateApi, GetAllEmailTemplateRequest } from '@esg/business-success-platform';
import { GetAllPromotionRequest } from '@esg/business-management/api/promotion';
import { GetAllSegmentRequest } from '@esg/business-management/api/segment';
import { requestAllItem, StatusEmailTemPlate } from '@esg/shared';
import { useTranslation } from 'react-i18next';
import { viewRequests } from '@esg/framework';
import { CampaignContent } from './children';
import { CampaignTabKey } from './enum';
import { resourceManagementTheme } from '@/pages/resource-management/@shared';
import { translateCodes } from '@/locales';
import { CAMPAIGN_URL } from '@/configs';
import { CampaignForm } from '@/components';
import { ClientSegmentTable } from '@/components/form/campaign/steps';

export function CampaignDetail() {
    const { id: campaignId } = useParams();
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        data: campaignResponse,
        isLoading,
        refetch: refetchOverview
    } = useHttpQuery(managementApis.getDetailCampaign, {
        pathData: {
            id: campaignId,
        },
    });

    const {
        mutateAsync: segmentsResponse,
    } = useHttpCommand(managementApis.getAllSegments);

    const {
        mutateAsync: promotionsResponse,
    } = useHttpCommand(managementApis.getAllPromotion);

    const {
        mutateAsync: emailTemplates,
    } = useHttpCommand(emailTemplateApi.emailTemplateGetAll);

    const { mutateAsync: deleteCampaignAsync } = useHttpCommand(managementApis.deleteCampaign, {
        onSuccess(_) {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SAM_09),
            });
            resetModalState();
            resetDrawerState();
            navigate(`${CAMPAIGN_URL}`);
        },
    });
    const { mutateAsync: saveDraftCampaignAsync } = useHttpCommand(managementApis.saveDraftCampaign, {
        onSuccess: () => {
            refetchOverview();
            resetModalState();
        }
    });
    const { mutateAsync: updateCampaignAsync } = useHttpCommand(managementApis.updateCampaign, {
        onSuccess: () => {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SAM_08),
            });
            refetchOverview();
            resetModalState();
            resetDrawerState();
        }
    });

    const onDelete = React.useCallback(
        async (id: string) => {
            await deleteCampaignAsync({ pathData: { id: id } });
        },
        [deleteCampaignAsync]
    );

    const onSaveDraft = React.useCallback(
        async (values: GetCampaignResponseItem) => {
            const payload: SaveDraftCampaignRequest['body'] = {
                id: values.id,
                name: values.name,
                type: values.type,
                target: values.target,
                status: CampaignStatus.Draft,
                targetValue: values.targetValue,
                promotionId: values.promotionId,
                dateApply: values.dateApply,
                timeApply: values.timeApply,
                campaignChannels: values.campaignChannels,
                scheduleType: values.scheduleType
            };
            await saveDraftCampaignAsync({ body: payload });
        }, [saveDraftCampaignAsync]
    );

    const onEdit = React.useCallback(
        async (values: GetCampaignResponseItem, status?: CampaignStatus) => {
            const payload: UpdateCampaignRequest['body'] = {
                id: values.id,
                name: values.name,
                type: values.type,
                target: values.target,
                status: status !== undefined ? status : values.status,
                targetValue: values.targetValue,
                promotionId: values.promotionId,
                dateApply: values.dateApply,
                timeApply: values.timeApply,
                campaignChannels: values.campaignChannels,
                scheduleType: values.scheduleType
            };
            await updateCampaignAsync({ body: payload, pathData: { id: values.id } });
        },
        [updateCampaignAsync]
    );

    const showModalViewClientCampaign = React.useCallback((clientSize: number, values: GetCampaignResponseItem) => {
        setModalState({
            content: (
                <ClientSegmentTable values={values} clientSize={clientSize} />
            ),
            isOpen: true,
            footer: null,
            width: 1024,
            titleTransCode: 'Client list'
        });
    }, [setModalState]);

    const onShowModalEdit = React.useCallback(async (values: GetCampaignResponseItem) => {
        if (values.status === CampaignStatus.Draft || values.status === CampaignStatus.Scheduled) {
            const [segments, promotions, emails] = await Promise.all([
                segmentsResponse({query: requestAllItem} as GetAllSegmentRequest),
                promotionsResponse({query: {...requestAllItem, isActive: true, isValid: true}} as GetAllPromotionRequest),
                emailTemplates({query: {...requestAllItem, statusEmailTemPlate : StatusEmailTemPlate.CustomTemplate}} as GetAllEmailTemplateRequest)
            ]);
            return setDrawerState({
                isOpen: true,
                titleTransCode: 'Edit Campaign',
                content: (
                    <CampaignForm
                        onSubmit={(values) => onEdit(values, CampaignStatus.Scheduled)}
                        refetch={refetchOverview}
                        resetDrawerState={resetDrawerState}
                        defaultValues={values}
                        segments={segments?.items || []}
                        promotions={promotions?.items || []}
                        onSaveDraft={onSaveDraft}
                        emailTemplate={emails?.items || []}
                        showModalViewClientCampaign={showModalViewClientCampaign}
                    />
                ),
            });
        }

        if (values.status === CampaignStatus.Running) {
            setModalState({
                content: (
                    <FlexBox direction='column'>
                        <Text fontSize={18} fontWeight={700} translateCode={translateCodes.PAUSE_CAMPAIGN}>?</Text>
                        <Text fontSize={14} translateCode={translateCodes.PAUSE_CAMPAIGN_MSG}>
                            <Text color='#101828' fontWeight={700}>
                                {` ${values.name}?`}
                            </Text>
                        </Text>
                    </FlexBox>
                ),
                isOpen: true,
                onOk: () => {
                    onEdit(values, CampaignStatus.Paused);
                },
                showModalConfirm: true,
            });
        }

        if (values.status === CampaignStatus.Paused) {
            setModalState({
                content: (
                    <FlexBox direction='column'>
                        <Text fontSize={18} fontWeight={700} translateCode={translateCodes.RUN_CAMPAIGN}>?</Text>
                        <Text fontSize={14} translateCode={translateCodes.RUN_CAMPAIGN_MSG}>
                            <Text color='#101828' fontWeight={700}>
                                {` ${values.name}?`}
                            </Text>
                        </Text>
                    </FlexBox>
                ),
                isOpen: true,
                onOk: () => {
                    onEdit(values, CampaignStatus.Running);
                },
                showModalConfirm: true,
            });
        }

    }, [emailTemplates, onEdit, onSaveDraft, promotionsResponse, refetchOverview, resetDrawerState, segmentsResponse, setDrawerState, setModalState, showModalViewClientCampaign]
    );

    const showModalDelete = React.useCallback((values: GetCampaignResponseItem) => {
        setModalState({
            content: (
                <FlexBox direction='column'>
                    <Text fontSize={18} fontWeight={700} translateCode={translateCodes.DELETE_CAMPAIGN}>?</Text>
                    <Text fontSize={14} translateCode={translateCodes.DELETE_CAMPAIGN_MSG}>
                        <Text color='#101828' fontWeight={700}>
                            {` ${values.name}?`}
                        </Text>
                    </Text>
                </FlexBox>
            ),
            isOpen: true,
            onOk: () => {
                onDelete(values.id || '');
            },
            showModalDelete: true,
        });
    }, [onDelete, setModalState]);

    const renderActionCampaignItem = () => {

        const continueDisabled = campaignResponse?.status !== CampaignStatus.Draft;
        const pauseDisabled = campaignResponse?.status !== CampaignStatus.Running;
        const resumeDisabled = campaignResponse?.status !== CampaignStatus.Paused;
        const editDisabled = campaignResponse?.status != CampaignStatus.Scheduled && campaignResponse?.status != CampaignStatus.InProgress;

        const items = [
            {
                key: 'continue',
                label: (
                    <FlexBox 
                        gap={12} 
                        alignItems='center' 
                        opacity={continueDisabled ? 0.5 : 1}
                        onClick={()=> onShowModalEdit(campaignResponse!)}
                    >
                        <IconEditDraft/>
                        <Text translateCode={translateCodes.CONTINUE_EDIT_CAMPAIGN} />
                    </FlexBox>
                ),
                disabled: continueDisabled
            },
            {
                key: 'pause',
                label: (
                    <FlexBox 
                        gap={12} 
                        alignItems='center' 
                        opacity={pauseDisabled ? 0.5 : 1}
                        onClick={()=> onShowModalEdit(campaignResponse!)}
                    >
                        <IconPause/>
                        <Text translateCode={translateCodes.PAUSE_CAMPAIGN} />
                    </FlexBox>
                ),
                disabled: pauseDisabled
            },
            {
                key: 'resume',
                label: (
                    <FlexBox 
                        gap={12} 
                        alignItems='center' 
                        opacity={resumeDisabled ? 0.5 : 1}
                        onClick={()=> onShowModalEdit(campaignResponse!)}
                    >
                        <IconResume/>
                        <Text translateCode={translateCodes.RUN_CAMPAIGN} />
                    </FlexBox>
                ),
                disabled: resumeDisabled
            },
            {
                key: 'edit',
                label: (
                    <FlexBox 
                        gap={12} 
                        alignItems='center' 
                        opacity={editDisabled ? 0.5 : 1}
                        onClick={()=> onShowModalEdit(campaignResponse!)}
                    >
                        <IconPen/>
                        <Text translateCode={translateCodes.EDIT_CAMPAIGN} />
                    </FlexBox>
                ),
                disabled: editDisabled
            },
            {
                key: 'delete',
                label: (
                    <FlexBox 
                        gap={12} 
                        alignItems='center' 
                        onClick={()=>showModalDelete(campaignResponse!)}
                    >
                        <IconTrash color='red' />
                        <Text translateCode={translateCodes.DELETE_CAMPAIGN} color='red' />
                    </FlexBox>
                )
            },
        ];

        return items;
    };

    const renderStatusCampaign = () => {
        if (campaignResponse?.status == CampaignStatus.Running) {
            return <Tag color='success'>Running</Tag>;
        }

        if (campaignResponse?.status == CampaignStatus.Completed) {
            return <Tag color='default'>Completed</Tag>;
        }

        if (campaignResponse?.status == CampaignStatus.Draft) {
            return <Tag color='purple'>Draft</Tag>;
        }

        if (campaignResponse?.status == CampaignStatus.Scheduled) {
            return <Tag color='blue'>Scheduled</Tag>;
        }

        if (campaignResponse?.status == CampaignStatus.InProgress) {
            return <Tag color='blue'>In progress</Tag>;
        }

        if (campaignResponse?.status == CampaignStatus.Paused) {
            return <Tag color='volcano'>Paused</Tag>;
        }
        
        return <Tag color='blue'>In progress</Tag>;
    };

    const renderChannelCampaign = () => {
        const activeMail = campaignResponse?.campaignChannels?.find(c => c.type == CampaignChannelType.Mail)?.isActive;
        const activeSms = campaignResponse?.campaignChannels?.find(c => c.type == CampaignChannelType.Sms)?.isActive;
        return <FlexBox gap={6}>
            {activeMail && <Tag style={{borderRadius:6}}>Email</Tag>}
            {activeSms && <Tag style={{borderRadius:6}}>Sms</Tag>}
        </FlexBox>;
    };

    const renderTypeCampaign = () => {
        const label = campaignResponse?.type === CampaignType.Promotion ? 'Promotion' : 'Notification';
        const color = campaignResponse?.type === CampaignType.Promotion ? 'hwb(205 6% 9%)' : '#f50';
        return <Tag style={{borderRadius:6, margin: 0}} ><Badge color={color} text={label} className='badge-point-8'/></Tag>;
    };

    const renderTabCampaign = () => {
        let items = [
            { key:  CampaignTabKey.Metric, render:  CampaignTabKey.Metric },
            { key:  CampaignTabKey.Promotion, render:  CampaignTabKey.Promotion },
            { key:  CampaignTabKey.Detail, render:  CampaignTabKey.Detail },
        ];
    
        if (campaignResponse?.status === CampaignStatus.Draft || campaignResponse?.status === CampaignStatus.InProgress) {
            items = items.filter(c => c.key ===  CampaignTabKey.Detail);
            return items;
        }

        const activeMail = campaignResponse?.campaignChannels?.find(c => c.type == CampaignChannelType.Mail)?.isActive;
        const activeSms = campaignResponse?.campaignChannels?.find(c => c.type == CampaignChannelType.Sms)?.isActive;

        if(campaignResponse?.type !== CampaignType.Promotion)
        {
            items = items.filter(c => c.key !==  CampaignTabKey.Promotion);
        }

        if (!activeMail && !activeSms) {
            items = items.filter(c => c.key !== CampaignTabKey.Metric);
        }
    
        return items;
    };

    return (
        <Spacer size={24} >
            <FlexBox className='customer-detail' direction='column' >
                <Spacer height={8} />
                <FlexBox gap={20} >
                    <Link to={CAMPAIGN_URL}>
                        <Text translateCode={translateCodes.CAMPAIGN_LIST} fontWeight={500} fontSize={14} color='#475467' />
                    </Link>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowRightIcon}
                        width={14}
                        height={14}
                    />
                    <Text translateCode={campaignResponse?.name} fontWeight={600} fontSize={14} color='#0C6FF3' />
                </FlexBox>
                <Spacer height={16} />
            </FlexBox>
            <FlexBox justifyContent='space-between'>
                <FlexBox direction='column' flex='none' gap={8}>
                    <FlexBox gap={8} alignItems='center'>
                        <Text color='#101828' fontWeight={700} fontSize={20}>{campaignResponse?.name}</Text>
                        <Block>
                            {renderStatusCampaign()}
                        </Block>
                    </FlexBox>
                    <FlexBox gap={8}>
                        {renderTypeCampaign()}
                        <Block width={1} backgroundColor='#D0D5DD'/>
                        {renderChannelCampaign()}
                    </FlexBox>
                </FlexBox>
                <ActionButton menu={{items:renderActionCampaignItem()}}/>
            </FlexBox>
            {
                (isLoading && !campaignResponse)
                    ? <Spin />
                    : <AppTab items={renderTabCampaign()}>
                        {({ activeKey }) => (
                            <CampaignContent 
                                activeKey={activeKey} 
                                campaign={campaignResponse!}
                            />
                        )}
                    </AppTab>
            }
        </Spacer>
    );
}