import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
interface AppointmentDeleteRequest extends HttpRequestData { }

export const deleteAppointment = httpUtil.createHttpRequestMeta<AppointmentDeleteRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'DELETE',
    path: '/api/v1/appointments/:id',
    authentication: 'bearer',
});
