import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetStatusesAppointmentRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export interface GetStatusesAppointmentResponse {
    readonly totalBook: number;
    readonly totalConfirm: number;
    readonly totalCheckIn: number;
    readonly totalComplete: number;
    readonly totalCancel: number;
    readonly totalNoShow: number;
}

export const getStatusesAppointment = httpUtil.createHttpRequestMeta<
    GetStatusesAppointmentRequest,
    GetStatusesAppointmentResponse
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/appointments-report/statuses',
    authentication: 'bearer',
});