import React from 'react';
import { AutomationEventGetAllResponseItem } from '@esg/business-success-platform';
import { AutomationEventDetailContentRunningMetric } from './AutomationEventDetailContentRunningMetric';

interface AutomationEventDetailContentMetricProps {
  activeKey: string;
  automationEvent: AutomationEventGetAllResponseItem;
}

export const AutomationEventDetailContentMetric = (props: AutomationEventDetailContentMetricProps) => {
    const {automationEvent } = props;

    return <AutomationEventDetailContentRunningMetric activeKey={props.activeKey} automationEvent={automationEvent}/>;
};

