import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface DeleteCustomerSegmentRequest extends HttpRequestData {
}

export const deleteCustomerSegment = httpUtil.createHttpRequestMeta<
    DeleteCustomerSegmentRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-segments/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
