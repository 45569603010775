import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { IntegrationEmailConfig } from './Types';

export interface IntegrationEmailUpdateAsConnectRequest extends HttpRequestData {
    body: {
        readonly configuration: IntegrationEmailConfig;
    };
}

export const integrationEmailUpdateAsConnect = httpUtil.createHttpRequestMeta<IntegrationEmailUpdateAsConnectRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/integration-settings/:id',
    method: 'PATCH',
    authentication: 'bearer',
});