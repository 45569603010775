import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface getOverviewClientGroupRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export interface getOverviewClientGroupResponse {
    newClient?: number;
    name?: number;
}

export const getOverviewClientGroupReport = httpUtil.createHttpRequestMeta<
    getOverviewClientGroupRequest,
    getOverviewClientGroupResponse[]
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/clients-report/overview-group',
    authentication: 'bearer',
});