import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { CampaignChannelItem } from './getAllCampaign';
import { CampaignType, CampaignTarget, CampaignStatus, CampaignScheduleType } from './enum';

export interface UpdateCampaignRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name?: string;
        type?: CampaignType;
        target?: CampaignTarget;
        status?: CampaignStatus;
        targetValue?: string[];
        promotionId?: string;
        dateApply?: string;
        timeApply?: string;
        campaignChannels?: CampaignChannelItem[];
        scheduleType?: CampaignScheduleType;
    };
}

export const updateCampaign = httpUtil.createHttpRequestMeta<
    UpdateCampaignRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/campaigns/:id',
    method: 'PATCH',
    authentication: 'bearer',
});