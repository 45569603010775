import {
    FormBase,
    FormLayout
} from '@esg/ui';
import React, { useMemo } from 'react';
import { CampaignChannelType, CampaignScheduleType, CampaignStatus, CampaignTarget, CampaignType, GetCampaignResponseItem } from '@esg/business-management/api/campaign';
import { useTranslation } from 'react-i18next';
import { GetSegmentResponseItem } from '@esg/business-management/api/segment';
import { GetPromotionResponseItem } from '@esg/business-management/api/promotion';
import { GetAllEmailTemplateResponseItem } from '@esg/business-success-platform';
import { CreateCampaignFormStep } from './steps/campaignEnum';
import { InfoCampaignForm, ContentCampaignForm, ScheduleCampaignForm } from './steps';
import { translateCodes } from '@/locales';
interface CampaignFormProps {
    readonly onDelete?: () => void;
    readonly refetch: () => void;
    readonly resetDrawerState: () => void;
    readonly defaultValues?: GetCampaignResponseItem;
    readonly onSubmit?: (value: GetCampaignResponseItem) => void;
    readonly segments: GetSegmentResponseItem[];
    readonly promotions: GetPromotionResponseItem[];
    readonly emailTemplate: GetAllEmailTemplateResponseItem[];
    readonly onSaveDraft?: (value: GetCampaignResponseItem) => void;
    readonly showModalViewClientCampaign: (clientSize: number, values: GetCampaignResponseItem) => void;
}

const stepsFormCampaign = (t: any) => [
    {
        title: 'Setting',
        label: t(translateCodes.SETTING),
        value: CreateCampaignFormStep.Info,
    },
    {
        title: 'Content',
        label: t(translateCodes.CONTENT),
        value: CreateCampaignFormStep.Channel,
    },
    {
        title: 'Schedule & Send',
        label: t(translateCodes.SCHEDULE_AND_SEND),
        value: CreateCampaignFormStep.Schedule,
    },
];

export const CampaignForm = (props: CampaignFormProps) => {
    const { t } = useTranslation();
    const isUpdate = !!props.defaultValues?.id && props.defaultValues.status != CampaignStatus.Draft;
    const segmentOptions = useMemo(() => {
        return (
            props.segments?.map((i) => ({
                label: i.name,
                value: i.id,
            })) ?? []
        );
    }, [props.segments]);

    const promotionOptions = useMemo(() => {
        return (
            props.promotions?.map((i) => ({
                label: i.name,
                value: i.id,
            })) ?? []
        );
    }, [props.promotions]);

    const emailTemplateOptions = useMemo(() => {
        return (
            props.emailTemplate?.map((i) => ({
                label: i.subject,
                value: i.id,
            })) ?? []
        );
    }, [props.emailTemplate]);

    return (
        <FormBase
            onSubmit={props.onSubmit}
            defaultValues={{
                ...props.defaultValues,
                scheduleType: props.defaultValues?.scheduleType || CampaignScheduleType.Immediately,
                target: props.defaultValues?.target || CampaignTarget.AllClient,
                type: props.defaultValues?.type || CampaignType.Promotion
            }}
        >
            {
                (form) => (
                    <FormLayout
                        labelSubmitCode={
                            isUpdate
                                ? translateCodes.SAVE
                                : translateCodes.CREATE
                        }
                        uxMode={isUpdate ? 'tabs' : 'steps'}
                        itemsStep={stepsFormCampaign(t)}
                        onDraft={isUpdate
                            ? undefined
                            : () => { props.onSaveDraft?.(form.getValues()); }
                        }
                    >
                        {(step) => (
                            <>
                                {step == CreateCampaignFormStep.Info && <InfoCampaignForm
                                    form={form}
                                    segmentOptions={segmentOptions}
                                    promotionOptions={promotionOptions}
                                />}
                                {step == CreateCampaignFormStep.Channel && <ContentCampaignForm
                                    form={form}
                                    emailTemplateOptions={emailTemplateOptions}
                                    emailTemplate={props.emailTemplate}
                                />}
                                {step == CreateCampaignFormStep.Schedule && <ScheduleCampaignForm
                                    form={form}
                                    onViewClient={(clientSize) => { props.showModalViewClientCampaign?.(clientSize, form.getValues()); }}
                                />}
                            </>
                        )}
                    </FormLayout >
                )
            }
        </FormBase>
    );
};
CampaignForm.defaultProps = {
    defaultValues: {
        target: CampaignTarget.AllClient,
        type: CampaignType.Promotion,
        campaignChannels: [
            {
                isActive: false,
                type: CampaignChannelType.Sms
            },
            {
                isActive: false,
                type: CampaignChannelType.Mail
            }
        ],
        scheduleType: CampaignScheduleType.Immediately
    },
};