import {
    Text,
    FlexBox,
    RadioGroupField,
    Spacer,
    DatePickerField,
    TimePickerField,
    usePagination,
    useHttpCommand,
} from '@esg/ui';
import React, { useEffect, useState } from 'react';
import { Alert, Card, Divider, Radio } from 'antd';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { ExportOutlined } from '@ant-design/icons';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { CampaignScheduleType, CampaignTarget, GetClientCampaignResponseItem } from '@esg/business-management/api/campaign';
import { managementApis } from '@esg/business-management';
import { PageInfo } from '@esg/framework';
import { campaignRules } from '../../rules';
interface ScheduleCampaignFormProps {
    form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>;
    readonly onViewClient: (clientSize: number) => void;
}

export const ScheduleCampaignForm = (props: ScheduleCampaignFormProps) => {
    const { form, onViewClient } = props;
    const [currentLocale] = useRecoilState(currentLocaleState);
    const scheduleType = form.watch('scheduleType') as CampaignScheduleType;
    const target = form.watch('target') as CampaignTarget;
    const targetValue = form.watch('targetValue') as string[];
    const [ , setClients ] = useState<GetClientCampaignResponseItem[]>([]);
    const [ meta, setMeta ] = useState<PageInfo>();
    const [searchParams] = usePagination();

    const { mutateAsync: fetchClientAsync } = useHttpCommand(managementApis.getClientCampaign);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchClientAsync({
                body: {
                    target: target,
                    targetValue: targetValue,
                },
                query: searchParams,
            });

            setClients(response.items);
            setMeta(response.meta);

        };
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <FlexBox direction='column' gap={8}>
            <Spacer bottom={24}/>
            <Text
                fontWeight={600}
                lineHeight='20px'
                color='#344054'
            >
                Schedule & Send
            </Text>
            <Card className='card-body' >
                <Alert
                    type='warning'
                    message='The system will automatically send messages on schedule, which means your account will be deducted.'
                    style={{color: '#DC6803' }}
                />
                <Spacer bottom={8} top={8}>
                    <Text fontWeight={500} fontSize={14}>When do you want to run this campaign?</Text>
                </Spacer>
                <RadioGroupField
                    name='scheduleType'
                    required
                    rules={campaignRules.valueRequire}
                >
                    <FlexBox direction='column' gap={8}>
                        <FlexBox direction='column'>
                            <Radio value={0}><Text color='#101828' fontWeight={700}>Immediately</Text></Radio>
                            <Spacer left={24}><Text fontWeight={500} fontSize={14} color='#475467'>When do you want to run this campaign?</Text></Spacer>
                        </FlexBox>
                        <FlexBox direction='column'>
                            <Radio value={1}><Text color='#101828' fontWeight={700}>Later</Text></Radio>
                            <Spacer left={24}><Text fontWeight={500} fontSize={14} color='#475467'>When do you want to run this campaign?</Text></Spacer>
                        </FlexBox>
                    </FlexBox>
                </RadioGroupField>
                {
                    scheduleType == CampaignScheduleType.Later && <Spacer left={24} top={8}>
                        <FlexBox gap={8}>
                            <DatePickerField
                                label='Date'
                                placeholder='Select date'
                                formatValue='day'
                                format={currentLocale?.format}
                                name='dateApply'
                                rules={campaignRules.dayApply}
                            />
                            <TimePickerField
                                label='Time'
                                format='h:mm A'
                                name='timeApply'
                                style={{ width: '100%' }}
                                isRequired
                                rules={campaignRules.timeApply}
                            />
                        </FlexBox>
                    </Spacer>
                }

                <Divider style={{ margin: '12px 0' }} />
                <Text fontWeight={500} fontSize={14}>How many clients will receive the campaign?</Text>
                <FlexBox gap={4}>
                    <Text 
                        color='#004EEB' 
                        fontSize={14} 
                        fontWeight={700} 
                        cursor='pointer'
                        onClick={()=> onViewClient(meta?.totalItems || 0)}
                    >{`${meta?.totalItems} clients`}</Text>
                    <ExportOutlined style={{cursor: 'pointer'}}/>
                    <Text color='#475467' fontSize={14}>match the trigger conditions</Text></FlexBox>
            </Card>
        </FlexBox>
    );
};