import { SaleActivity } from '@esg/business-management';
import { TFunction } from 'i18next';
import { UnitEnum } from '@esg/business-pos';
import { translateCodes } from '@/locales';

export const saleActivityHelper = {
    getContentSaleCreate: (
        activity: SaleActivity[], 
        t: TFunction<'translation', undefined>
    ) => {
        let content = '';
        const employeeActivitiesAll = activity.filter(o => o.entityName === 'SaleDetailEmployee');
        activity.forEach(o => {
            switch (o.entityName) {
            case 'SaleDetail':
            {
                const currentData = JSON.parse(o.currentDataJson ?? '{}');
                const objectName = currentData?.ObjectName;
                const objectType = currentData?.ObjectType?.toLowerCase();
                const objectQuantity = currentData?.Quantity;
                const showQuantity = Number(objectQuantity) > 1;

                const saleDetailName = `${objectType} ${objectName}`;
                content += `${t(translateCodes.ACTIVITY_ADD_SALE_DETAIL, { objectName: saleDetailName })}. 
                ${showQuantity ? `${t(translateCodes.ACTIVITY_QUANTITY, { quantity: objectQuantity })}.` : ''} <br/>`;

                const employeeActivitiesOfSaleDetail = employeeActivitiesAll.filter(e => e.parentRecordId === currentData?.Id);
                content += saleActivityHelper.getSaleDetailEmployeeContent(saleDetailName, employeeActivitiesOfSaleDetail, t);

                break;
            }

            default:
                return null;
            }
        });

        return  content || null;
    },
    getContentSaleEdit: (
        activity: SaleActivity, 
        t: TFunction<'translation', undefined>,
        currencySymbol: string
    ) => { 
        let content = '';
        const currentData = JSON.parse(activity?.currentDataJson ?? '{}');
        const tipUnitSymbol = currentData?.TipUnit === UnitEnum.Percent ? '%' : currencySymbol;

        activity.data.forEach(o => {
            switch (o.field) {
            case 'Tip':
            {
                const isRemoveTip = Number(o.changedValue) === 0;
                const isAddTip = Number(o.originValue) === 0 && Number(o.changedValue) > 0;
                const isChangeTip = !isRemoveTip && !isAddTip;

                if (isRemoveTip) {
                    content += t(translateCodes.ACTIVITY_REMOVE_TIP) + ' . <br/>';
                }  
                if (isAddTip) {
                    content += t(translateCodes.ACTIVITY_ADD_TIP, { 
                        value: `${Number(o.changedValue)}${tipUnitSymbol}`
                    }) + ' .<br/>';
                }  
                if (isChangeTip) {
                    content += t(translateCodes.ACTIVITY_CHANGE_TIP, { 
                        originValue: `${Number(o.originValue)}${tipUnitSymbol}`, 
                        changedValue: `${Number(o.changedValue)}${tipUnitSymbol}` 
                    }) + ' .<br/>';
                }
                
                break;
            }
            case 'TipUnit':
            {
                const oldTipUnitSymbol = currentData?.TipUnit === UnitEnum.Percent ? currencySymbol : '%';
                content += t(translateCodes.ACTIVITY_CHANGE_TIP, { 
                    originValue: `${Number(currentData.Tip)}${oldTipUnitSymbol}`, 
                    changedValue: `${Number(currentData.Tip)}${tipUnitSymbol}` 
                }) + ' .<br/>';
                break;
            }
            case 'CustomerId':
                if (o.changedValue) {
                    content += t(translateCodes.ACTIVITY_ADD_CLIENT, { clientName: o.changedValue }) + ' .<br/>';
                } else {
                    content += t(translateCodes.ACTIVITY_REMOVE_CLIENT, { clientName: o.originValue }) + ' .<br/>';
                }
                break;
            case 'CheckoutAt':
                content += t(translateCodes.ACTIVITY_CHECKOUT_SALE) + ' .<br/>';
                break;
            default:
                break;
            }
        });

        return content;
    },
    getContentSaleDetail: (
        activitySaleDetail: SaleActivity, 
        employeeActivities: SaleActivity[], 
        t: TFunction<'translation', undefined>,
        formatCurrency: (value: number) => string,
        currencySymbol: string
    ) => {
        const currentData = JSON.parse(activitySaleDetail?.currentDataJson ?? '{}');
        const objectName = currentData?.ObjectName;
        const obJectType = currentData?.ObjectType?.toLowerCase();
        const saleDetailName = `${obJectType} ${objectName}`;

        switch (activitySaleDetail?.action) {
        case 'Added':
            return t(translateCodes.ACTIVITY_ADD_SALE_DETAIL, { objectName: saleDetailName });
        case 'Deleted':
            return t(translateCodes.ACTIVITY_DELETE_SALE_DETAIL, { objectName: saleDetailName });
        case 'Updated': {
            let content = saleActivityHelper.getSaleDetailEmployeeContent(saleDetailName, employeeActivities, t);
            let flagDiscount = false;

            activitySaleDetail.data.forEach(o => {
                switch (o.field) {
                case 'Quantity':
                    content += t(translateCodes.ACTIVITY_CHANGE_QUANTITY, { 
                        originValue: Number(o.originValue), 
                        changedValue: Number(o.changedValue) 
                    }) + ' .<br/>';
                    break;
                case 'ObjectPrice':
                    content += t(translateCodes.ACTIVITY_CHANGE_PRICE, { 
                        originValue: formatCurrency(Number(o.originValue)), 
                        changedValue: formatCurrency(Number(o.changedValue)) 
                    }) + ' .<br/>';
                    break;
                case 'Discount':
                case 'DiscountUnit':
                {
                    if(flagDiscount) break;

                    flagDiscount = true;
                    const symbols = ['%', currencySymbol];
                    const isChangeUnit = activitySaleDetail.data.some(o => o.field === 'DiscountUnit');
                    const currentDiscountSymbol = symbols[currentData?.DiscountUnit];
                    const originDiscountSymbol = isChangeUnit  ? symbols.find(o => o !== currentDiscountSymbol) : currentDiscountSymbol;
                    const originDiscount = activitySaleDetail.data.find(o => o.field === 'Discount')?.originValue ?? currentData?.Discount;
                    const currentDiscount = currentData?.Discount;
                    
                    content += t(translateCodes.ACTIVITY_CHANGE_DISCOUNT, { 
                        originValue: `${Number(originDiscount)}${originDiscountSymbol}`, 
                        changedValue: `${Number(currentDiscount)}${currentDiscountSymbol}` ,
                        objectName: saleDetailName
                    }) + ' .<br/>';
                    break;
                }
                
                default:
                    break;
                }
            });
            return content;
        }
        default:
            return '';
        }
    },
    getContentSalePromotion: (
        activitySalePromotion: SaleActivity, 
        t: TFunction<'translation', undefined>
    ) => {
        const promotionName = activitySalePromotion?.recordId;
        switch (activitySalePromotion?.action) {
        case 'Added':
            return t(translateCodes.ACTIVITY_ADD_PROMOTION, { promotionName: promotionName });
        case 'Deleted':
            return t(translateCodes.ACTIVITY_DELETED_PROMOTION, { promotionName: promotionName });
        default:
            return '';
        }
    },

    getSaleDetailEmployeeContent: (
        objectName: string, 
        employeeActivities: SaleActivity[], 
        t: TFunction<'translation', undefined>
    ) => {

        const employeeAdd = employeeActivities.filter(o => o.action === 'Added');
        const employeeDelete = employeeActivities.filter(o => o.action === 'Deleted');

        const isOnlyAddEmployee = employeeAdd.length === employeeActivities.length;
        const isOnlyDeleteEmployee = employeeDelete.length === employeeActivities.length;

        const addEmployeeNames = employeeAdd.map(o => {
            const employeeName = o.data.find(o => o.field === 'EmployeeId')?.changedValue;
            return employeeName;
        }).join(', ');

        const deleteEmployeeNames = employeeDelete.map(o => {
            const employeeName = o.data.find(o => o.field === 'EmployeeId')?.originValue;
            return employeeName;
        }).join(', ');

        if (isOnlyAddEmployee && addEmployeeNames) {
            return t(translateCodes.ACTIVITY_ADD_EMPLOYEE, { employees: addEmployeeNames, objectName }) + ' .<br/>';
        }

        if (isOnlyDeleteEmployee && deleteEmployeeNames) {
            return t(translateCodes.ACTIVITY_REMOVE_EMPLOYEE, { employees: deleteEmployeeNames, objectName }) + ' .<br/>';
        }

        if (!isOnlyAddEmployee && !isOnlyDeleteEmployee && addEmployeeNames) {
            return t(translateCodes.ACTIVITY_CHANGE_EMPLOYEE, { objectName, originValue: deleteEmployeeNames, changedValue: addEmployeeNames }) + ' .';
        }

        return '';
    },
};
