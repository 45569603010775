import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface AppointmentSettingGetDetailRequest extends HttpRequestData {
}

export interface AppointmentSettingDetailResponse {
    id: string;
    timeSlotDuration: number;
    doubleAppointmentEnabled: boolean;
    doubleAppointmentMaxSlot: number;
    employeeSelectionRequired: boolean;
    autoNoShowEnabled: boolean;
    autoNoShowAfterTime: number;
    autoNoShowPromptEnabled: boolean;
}

export const getDetailAppointmentSetting = httpUtil.createHttpRequestMeta<
    AppointmentSettingGetDetailRequest,
    AppointmentSettingDetailResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/appointment-Settings/current',
    method: 'GET',
    authentication: 'bearer',
});
