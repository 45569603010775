import { translateCodes } from '@/locales';

export const passwordRules = {
    password: {
        required: translateCodes.NEW_PASSWORD_REQUIRED,
        pattern: {
            value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            message: translateCodes.PASSWORD_INVALID,
        },
        minLength: {
            value: 6,
            message: translateCodes.PASSWORD_MIN_LENGTH,
        },
        maxLength: {
            value: 20,
            message: translateCodes.PASSWORD_MAX_LENGTH,
        },
    },
    currentPassword: {
        required: translateCodes.CURRENT_PASSWORD_REQUIRED,
        pattern: {
            value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            message: translateCodes.PASSWORD_INVALID,
        },
        minLength: {
            value: 6,
            message: translateCodes.PASSWORD_MIN_LENGTH,
        },
        maxLength: {
            value: 20,
            message: translateCodes.PASSWORD_MAX_LENGTH,
        },
    },
    confirmPassword: {
        required: translateCodes.CONFIRM_PASSWORD_REQUIRED,
        pattern: {
            value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]:;<>.,?/|\\~`]).{8,}$/,
            message: translateCodes.PASSWORD_INVALID,
        },
        minLength: {
            value: 6,
            message: translateCodes.PASSWORD_MIN_LENGTH,
        },
        maxLength: {
            value: 20,
            message: translateCodes.PASSWORD_MAX_LENGTH,
        },
    },
};
