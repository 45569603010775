import { httpUtil } from '@esg/framework';
import { StatusEmailTemPlate, TypeEmailTemplatesEnum , getEnv } from '@esg/shared';

interface EmailTemplateCreateRequest {
    readonly body: {
        type?: TypeEmailTemplatesEnum;
        subject?: string;
        status?: StatusEmailTemPlate;
        body?: string;
        bodyCustom?: string;
        subjectCustom?: string;
    };
}
export interface EmailTemplateCreateResponseItem {
    id?: string;
    type?: TypeEmailTemplatesEnum;
    subject?: string;
    status?: StatusEmailTemPlate;
    body?: string;
}

export const emailTemplateCreate = httpUtil.createHttpRequestMeta<
    EmailTemplateCreateRequest,
    EmailTemplateCreateResponseItem
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/email-templates',
    method: 'POST',
    authentication: 'bearer',
});
