import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { CategoryExternalTransaction, TypeExternalTransaction, ActionExternalTransaction, CodeExternalTransaction, StatusExternalTransaction } from './enum';

interface GetAllExternalRequest extends HttpRequestData {
    query: PaginationRequest;
    body: {
        category: CategoryExternalTransaction;
        type: TypeExternalTransaction;
        action: ActionExternalTransaction;
        code: CodeExternalTransaction;
        sourceId: string;
    };
}

export interface GetExternalResponseItem {
    id: string;
    name: string;
    sourceId?: string;
    objectId?: string;
    code: string;
    type: string;
    action: string;
    category: string;
    status: StatusExternalTransaction;
    errorMessage?: string;
    retryCount: number;
    branchId: string;
    handleTime?: string;
    creationTime?: string;
    customerId?: string;
    customer?: CustomerItemResponse;
}

export interface ExternalTransactionGetAllResponseItem {
    id: string;
    name: string;
    sourceId?: string;
    objectId?: string;
    code: string;
    type: string;
    action: string;
    category: string;
    status: StatusExternalTransaction;
    errorMessage?: string;
    retryCount: number;
    branchId: string;
    handleTime?: string;
    creationTime?: string;
    customerId?: string;
    customer?: CustomerItemResponse;
}

export interface CustomerItemResponse {
    id: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    customerCode?: string;
    avatar?: string;
    phone?: string;
    email?: string;
    customerLabel?: number;
    creationTime?: string;
}

export const getAllExternal = httpUtil.createHttpRequestMeta<
    GetAllExternalRequest,
    PaginationResponse<GetExternalResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/external-transactions/get-all',
    method: 'POST',
    authentication: 'bearer',
});

