import { HttpRequestData, httpUtil } from '@esg/framework';
import { GetDetailSaleResponse, UnitEnum } from '@esg/business-pos';
import { getEnv } from '@esg/shared';
import { ObjectMode, ObjectType } from '../promotion';
import { SalePromotionStatus } from './enum/salePromotionEnum';

export interface CreateSalePromotionRequest extends HttpRequestData {
    readonly body: {
        saleId?: string;
        customerPromotionId: string;
    };
}

export interface SalePromotionItemResponse extends GetDetailSaleResponse {

}

export interface SalePromotionGetDetailResponse {
    id: string;
    name: string;
    saleId: string;
    customerPromotionId: string;
    promotionUnit: UnitEnum;
    promotionValue: number;
    promotionAmount: number;
    promotionRevenue: number;
    objectType: ObjectType;
    objectMode: ObjectMode;
    objectRangeIds?: string[];
    objectApplyIds?: string[];
    status: SalePromotionStatus;
}

export const createSalePromotion = httpUtil.createHttpRequestMeta<
    CreateSalePromotionRequest,
    SalePromotionItemResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/sale-promotions',
    method: 'POST',
    authentication: 'bearer',
});