import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { ShiftGetDetailResponse } from '../shift';
import { EmployeeGetDetailResponse } from '.';

export interface EmployeeCreateRequest extends HttpRequestData {
	readonly body: {
		readonly dateOfBirth?: string;
		readonly phone?: string;
		readonly firstName: string;
		readonly lastName: string;
		readonly email: string;
		readonly address?: string;
		readonly avatar?: string;
		readonly gender?: number;
		readonly jobStatus?: number;
		readonly isAllowBooking: boolean;
		readonly jobStartDate?: string;
		readonly jobTypeId?: string;
		readonly locationId: string;
		readonly serviceIds: string[];
		readonly shifts: ShiftGetDetailResponse[];
	};
}

export const createEmployee = httpUtil.createHttpRequestMeta<EmployeeCreateRequest, EmployeeGetDetailResponse>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/employees',
    method: 'POST',
    authentication: 'bearer',
});
