import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface FacilityTimeOffGetAllRequest extends HttpRequestData {
    query: {
        date: string;
    };
}

export interface FacilityTimeOffGetAllResponseItem {
    facilityId: string;
    startTime: string;
    endTime: string;
}

export const facilityTimeOffGetAll = httpUtil.createHttpRequestMeta<
    FacilityTimeOffGetAllRequest,
    FacilityTimeOffGetAllResponseItem[]
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/facilities/time-offs',
    method: 'GET',
    authentication: 'bearer',
});