import { HttpRequestData, httpUtil } from '@esg/framework';
import { AppointmentStatus , getEnv } from '@esg/shared';

import { AppointmentGetDetailResponse } from './getAppointmentDetail';

interface NoShowAppointmentRequest extends HttpRequestData {
    body: {
        id: string;
        status: AppointmentStatus;
    };
}

export const changeAppointmentStatus = httpUtil.createHttpRequestMeta<NoShowAppointmentRequest, AppointmentGetDetailResponse>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'PATCH',
    path: '/api/v1/appointments/:id/change-status',
    authentication: 'bearer',
});