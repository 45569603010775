import React from 'react';
import { IconProps } from '../../Types';

export const IconSentCampaign = (props: IconProps) => {
    return (
        <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z' fill='#D1E0FF' />
            <path d='M22.4995 25.5002L32.9995 15.0002M22.6271 25.8282L25.2552 32.5862C25.4867 33.1816 25.6025 33.4793 25.7693 33.5662C25.9139 33.6415 26.0862 33.6416 26.2308 33.5664C26.3977 33.4797 26.5139 33.1822 26.7461 32.5871L33.3364 15.6994C33.5461 15.1622 33.6509 14.8936 33.5935 14.722C33.5437 14.5729 33.4268 14.456 33.2777 14.4062C33.1061 14.3488 32.8375 14.4536 32.3003 14.6633L15.4126 21.2536C14.8175 21.4858 14.52 21.602 14.4333 21.7689C14.3581 21.9135 14.3582 22.0858 14.4335 22.2304C14.5204 22.3972 14.8181 22.513 15.4135 22.7445L22.1715 25.3726C22.2923 25.4196 22.3527 25.4431 22.4036 25.4794C22.4487 25.5115 22.4881 25.551 22.5203 25.5961C22.5566 25.647 22.5801 25.7074 22.6271 25.8282Z' stroke='#155EEF' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

