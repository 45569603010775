export enum ObjectMode {
    All = 0,
    Fist = 1,
    LowestValue = 2,
    HighestValue = 3
}

export enum ObjectType {
    Service = 0,
    Product = 1,
    Package = 2,
    TotalSale = 3
}

export enum ObjectStatus {
    Inactive = 1,
    Active = 0,
    Expired = 3
}