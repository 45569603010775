import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { authActions } from '../requests/authRequest';
import { HttpRequestData, HttpRequestMeta } from '../types';
import { HttpClient, HttpRequestError } from '../http';
import { viewRequests } from '../requests';
import { useLoginState } from './useLoginState';

interface HttpQueryOptions<TResponse> extends Omit<UndefinedInitialDataOptions<TResponse, Error>, 'queryKey'> {
    readonly queryKey?: unknown[];
}

export const useHttpQuery = <TRequest, TResponse>(
    requestMeta: HttpRequestMeta<TRequest, TResponse>,
    requestData?: HttpRequestData,
    options?: HttpQueryOptions<TResponse>) => {

    const [loginInfo] = useLoginState();

    const baseUrl = requestMeta.baseUrl();

    if (!baseUrl) {
        throw new Error('Base URL is null');
    }

    const query = useQuery<TResponse, Error>({
        ...options,
        placeholderData: (previousData) => previousData,
        queryKey: [requestMeta.method, baseUrl, requestMeta.path, { ...requestData?.query }, { ...requestData?.pathData }],
        queryFn: async (): Promise<TResponse> => {
            const httpClient = new HttpClient();
            if (requestMeta.authentication === 'bearer') {
                const accessToken = loginInfo?.token;
                if (!accessToken) {
                    throw new Error('Access token is null');
                }

                httpClient.setAuthorization('bearer', accessToken);
            }

            if (requestMeta.authentication === 'basic') {
                const accessKey = requestData?.header?.basic;
                if (!accessKey) {
                    throw new Error('Access key is null');
                }

                httpClient.setAuthorization('basic', accessKey);
            }

            try {
                return await httpClient.send<TResponse>({
                    ...requestMeta,
                    ...requestData,
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'HttpRequestError') {
                    const requestError = error as HttpRequestError;

                    if (requestError.statusCode === 401) {
                        await authActions.logout.send();
                        location.href = '/error/unauthorized';
                    }

                    throw error;
                }

                if (error instanceof Error && error.message === 'Failed to fetch') {
                    viewRequests.showNotification.send({
                        type: 'error',
                        title: 'Network Error',
                        message: 'Please check your internet connection and try again.',
                    });
                    throw error;
                }

                viewRequests.showNotification.send({
                    type: 'error',
                    title: (error as Error).name,
                    message: (error as Error).message,
                });

                throw error;
            }
        },

    });

    return query;
};
