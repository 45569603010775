import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface DeleteBankAccountRequest extends HttpRequestData {
}

export const deleteBankAccount = httpUtil.createHttpRequestMeta<
    DeleteBankAccountRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/bank-account-settings/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
