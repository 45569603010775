import React from 'react';
import { ActionButton, AppTab, Block, FlexBox, globalDrawerState, globalModalState, IconInactive, IconPen, Spacer, Text, useHttpCommand, useHttpQuery } from '@esg/ui';
import { Image, Spin, Tag } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { automationEventApi, AutomationEventGetAllResponseItem } from '@esg/business-success-platform';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { AutomationEventChannelType } from '@esg/shared';
import { AutomationEventUpdateRequest } from '@esg/business-success-platform/api/automation-event/automationEventUpdate';
import { AutomationEventActiveConfirm } from '../automation-event/children/AutomationEventActiveConfirm';
import { AutomationEventDetailTabKey } from './enum';
import { AutomationEventContent } from './children/AutomationEventContent';
import { resourceManagementTheme } from '@/pages/resource-management/@shared';
import { AUTOMATION_URL } from '@/configs';
import { AutomationEventForm, AutomationEventFormValues } from '@/components/form/automation-event';
import { renderDescription, renderName } from '@/helpers';

export function AutomationEventDetail() {
    const location = useLocation();
    
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const { mutateAsync: updateAutomationEvent } = useHttpCommand(automationEventApi.automationEventUpdate);
    const {
        data: automationEventResponse,
        isLoading,
        refetch: refetchOverview
    } = useHttpQuery(automationEventApi.automationEventGetDetail, {
        pathData: {
            id: location.state?.item.id,
        },
    });

    const onActiveAutomationEvent = React.useCallback(
        async (values: AutomationEventGetAllResponseItem, isActive: boolean) => {
            const newValues: AutomationEventUpdateRequest['body'] = {
                ...values,
                isActive: isActive,
                reminderTime: JSON.stringify(values.reminderTime),
                serviceValue: JSON.stringify(values.serviceValue),
            };
    
            await updateAutomationEvent({
                pathData: { id: newValues.id },
                body: newValues,
            },
            {
                onSuccess:()=>{
                    refetchOverview();
                }
            }
            );
    
        },
        [refetchOverview, updateAutomationEvent]
    );
    const renderTabAutomationEventDetail = () => {
        const items = [
            { key:  AutomationEventDetailTabKey.Metric, render:  AutomationEventDetailTabKey.Metric },
        ];
        if (automationEventResponse?.promotionId) {
            items.push({ key:  AutomationEventDetailTabKey.Promotion, render:  AutomationEventDetailTabKey.Promotion },);
        }
        items.push({ key:  AutomationEventDetailTabKey.Detail, render:  AutomationEventDetailTabKey.Detail },);
        return items;
    };

    const renderStatusAutomation = () => {
        if (automationEventResponse?.isActive) {
            return <Tag color='success'>Active</Tag>;
        }
        return <Tag color='volcano'>Disable</Tag>;
    };

    const renderChannelAutomationEvent = () => {
        const activeMail = automationEventResponse?.automationEventChannels?.find(c => c.type == AutomationEventChannelType.Mail)?.isActive;
        const activeSms = automationEventResponse?.automationEventChannels?.find(c => c.type == AutomationEventChannelType.Sms)?.isActive;
        return <FlexBox gap={6}>
            {activeMail && <Tag style={{borderRadius:6}}>Email</Tag>}
            {activeSms && <Tag style={{borderRadius:6}}>Sms</Tag>}
        </FlexBox>;
    };

    const onEdit = React.useCallback(
        (values: AutomationEventGetAllResponseItem) => {
            const defaultValues: AutomationEventFormValues = {
                ...values,
                reminderTime: values.reminderTime?.map(item =>
                    JSON.stringify({
                        reminderTime: item.reminderTime,
                        reminderTimeType: item.reminderTimeType,
                    })
                ) || [],
                serviceValue: values.serviceValue,
            };

            setDrawerState({
                isOpen: true,
                titleTransCode: 'Edit Automation',
                content: (
                    <AutomationEventForm onSuccess={()=>{
                        resetDrawerState();
                        refetchOverview();
                    }} defaultValues={defaultValues} />
                )
            });
        },
        [refetchOverview, resetDrawerState, setDrawerState]
    );

    const showChangeConfirm = React.useCallback((emailTemplate: AutomationEventGetAllResponseItem, isActive: boolean) => {
        setModalState({
            content: (
                <AutomationEventActiveConfirm />
            ),
            isOpen: true,
            onOk: () => {
                onActiveAutomationEvent(emailTemplate, isActive);
                resetModalState();
            },
            showModalConfirm: true,
        });
    }, [onActiveAutomationEvent, resetModalState, setModalState]);

    const renderActionAutomationEventItem = () => {
    
        const items = [
            {
                key: 'edit',
                label: (
                    <FlexBox 
                        gap={12} 
                        alignItems='center' 
                        onClick={()=> onEdit(automationEventResponse!)}
                    >
                        <IconPen/>
                        <Text translateCode='Edit event' />
                    </FlexBox>
                ),
            },
            {
                key: 'Inactive',
                label: (
                    <FlexBox 
                        gap={12} 
                        alignItems='center' 
                        onClick={()=>showChangeConfirm(automationEventResponse!, !automationEventResponse?.isActive)}
                    >
                        <IconInactive/>
                        <Text translateCode={!automationEventResponse?.isActive ?'Inactive event': 'Active event'} />
                    </FlexBox>
                )
            },
        ];
    
        return items;
    };
    if (isLoading || !automationEventResponse) {
        return (
            <FlexBox alignItems='center' justifyContent='center' height='100%' >
                <Spin />
            </FlexBox>
        );
    }
    return (
        <Spacer size={24} >
            <FlexBox className='customer-detail' direction='column' >
                <Spacer height={8} />
                <FlexBox gap={20} >
                    <Link to={AUTOMATION_URL}>
                        <Text translateCode='Automation list' fontWeight={500} fontSize={14} color='#475467' />
                    </Link>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowRightIcon}
                        width={14}
                        height={14}
                    />
                    <Text translateCode={renderName(automationEventResponse.type)} fontWeight={600} fontSize={14} color='#0C6FF3' />
                </FlexBox>
                <Spacer height={16} />
            </FlexBox>
            <FlexBox justifyContent='space-between'>
                <FlexBox direction='column' flex='none' gap={8}>
                    <FlexBox gap={8} alignItems='center'>
                        <Text color='#101828' fontWeight={700} fontSize={20}>{renderName(automationEventResponse.type)}</Text>
                        <Block>
                            {renderStatusAutomation()}
                        </Block>
                    </FlexBox>
                    <FlexBox gap={8}>
                        <Text>{renderDescription(automationEventResponse.type)}</Text>
                        <Block width={1} backgroundColor='#D0D5DD'/>
                        {renderChannelAutomationEvent()}
                    </FlexBox>
                </FlexBox>
                <ActionButton menu={{items:renderActionAutomationEventItem()}}/>
            </FlexBox>
            {
                (!automationEventResponse)
                    ? <Spin />
                    : <AppTab items={renderTabAutomationEventDetail()}>
                        {({ activeKey }) => (
                            <AutomationEventContent 
                                activeKey={activeKey} 
                                automationEvent={automationEventResponse}
                            />
                        )}
                    </AppTab>
            }
        </Spacer>
    );
}