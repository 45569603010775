import {
    AppAvatar,
    AvatarItem,
    Block,
    ColumnAvatarGroup,
    FlexBox,
    SelectField,
    Text,
    TextProps,
} from '@esg/ui';
import React, { useCallback, useState } from 'react';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { Checkbox, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { SelectProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { DisplayValueType } from 'rc-select/lib/BaseSelect';
import { UseControllerProps } from 'react-hook-form';
import { LabelInValueType } from 'rc-select/lib/Select';
import { translateCodes } from '@/locales';
import './CustomerSegmentSelectField.scss';
import { CustomerAvatar } from '@/components/common';

export interface CustomerSegmentOptionsProps {
  value: string;
  label?: string;
  avatar?: string;
  isNoProvider?: boolean;
  isOverlap?: boolean;
}

interface CustomerSegmentSelectFieldProps extends SelectProps {
  name: string;
  label?: string;
  labelStyle?: TextProps;
  type?: 'Appointment' | 'default';
  optionRender?: (option: BaseOptionType) => React.ReactNode;
  handleChangedCustomerSegment?: (value: string[]) => void;
  width?: string | number;
  maxCountAvatar?: number;
  customerSegmentOptions: CustomerSegmentOptionsProps[];
  allCustomerSegment?: boolean;
  rules?: UseControllerProps['rules'];
  placeholder?: string;
}

export const CustomerSegmentSelectField = (props: CustomerSegmentSelectFieldProps) => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');

    const {
        placeholder =translateCodes.SELECT_EMPLOYEE,
        maxCountAvatar = 2,
        customerSegmentOptions = [],
        handleChangedCustomerSegment,
        type,
        width,
        allCustomerSegment,
        rules,
        mode,
        onSearch
    } = props;

    const customerSegmentOptionsRender = (option: BaseOptionType) => {
        const { label, isNoProvider } = option;
        const customerSegment = customerSegmentOptions.find((item) => item.value === option.value);

        if (type === 'default') {
            return (
                <FlexBox gap={12} alignItems='center'>
                    <Checkbox checked={props.value?.includes(option.value)} />
                    <FlexBox gap={12} alignItems='center'>
                        <CustomerAvatar customer={customerSegment} />
                        <Text>{option.label}</Text>
                    </FlexBox>
                </FlexBox>
            );
        }

        const isNotValid = option.isOverlap || option.isNoProvider;
        return (
            <Tooltip
                title={
                    option.isOverlap
                        ? t(translateCodes.IS_NOT_AVAILABLE)
                        : isNoProvider
                            ? t(translateCodes.DOES_NOT_PROVIDER_THIS_SERVICE, {
                                customerSegmentName: customerSegment?.label,
                            })
                            : ''
                }
            >
                <span>
                    <FlexBox gap={12} alignItems='center'>
                        <Block overflow='unset' className='customerSegment-avatar'>
                            <AppAvatar size='sm' data={option} />
                            {isNotValid && <ExclamationCircleOutlined className='warning-icon' />}
                        </Block>
                        <Text>{label}</Text>
                    </FlexBox>
                </span>
            </Tooltip>
        );
    };

    const customerSegmentLabelRender = useCallback((label: LabelInValueType) => {
        const customerSegmentSelected = customerSegmentOptions.find((o) => o.value == label.value);
        if (!customerSegmentSelected) return null;
        const combined: AvatarItem[] = [
            {
                id: customerSegmentSelected.value,
                src: customerSegmentSelected.avatar,
                fullName: customerSegmentSelected.label,
                avatar: customerSegmentSelected.avatar,
            }
        ];

        return (
            <ColumnAvatarGroup
                items={combined}
                maxCount={0}
                prefixEnd={`${label.label}`}
            />
        );
    }, [customerSegmentOptions]);

    const handleSearch = (val: string) => {
        setSearchText(val); // Giữ nguyên text search
        onSearch?.(val);
    };

    // const handleKeyDown = (event: React.KeyboardEvent) => {
    //     if (event.key === "Backspace" && value.length > 0) {
    //       event.preventDefault(); // Chặn xóa khi bấm Backspace
    //     }
    //   };

    const maxTagPlaceholder = useCallback(
        (omittedValues: DisplayValueType[], selectOptions: DefaultOptionType[]) => {
            // Chuyển đổi omittedValues sang AvatarItem[]
            const combined: AvatarItem[] = omittedValues
                .map((o) => {
                    const match = selectOptions.find((ov) => ov.value === o.value);
                    return match
                        ? {
                            id: match.value,
                            src: match.avatar,
                            fullName: match.label,
                            avatar: match.avatar,
                        }
                        : null;
                })
                .filter(Boolean) as AvatarItem[]; // Lọc bỏ các giá trị null
            const isOne = omittedValues.length === 1;

            return (
                <span>
                    <ColumnAvatarGroup
                        size='small'
                        items={combined}
                        maxCount={maxCountAvatar}
                        prefixEnd={
                            isOne
                                ? `${omittedValues[0].label}` || ''
                                : `${omittedValues.length} client segments`
                        }
                    />
                </span>
            );
        },
        [maxCountAvatar]
    );
    
    return (
        <SelectField
            {...props}
            className={`customCustomerSegmentSelect ${allCustomerSegment ? 'placeholderSelectAll' : ''}`}
            placeholder={allCustomerSegment ? translateCodes.ALL_EMPLOYEES : placeholder}
            labelRender={mode ==='multiple' ? undefined : customerSegmentLabelRender}
            menuItemSelectedIcon={type === 'default' ? null : undefined}
            popupClassName='popup-customerSegment-select'
            style={{ width: width }}
            options={customerSegmentOptions}
            onSearch={handleSearch}
            listHeight={376}
            searchValue={searchText}
            showSearch={true}
            filterOption={false}
            optionRender={({ data }) => customerSegmentOptionsRender(data)}
            onChange={handleChangedCustomerSegment}
            maxTagCount={0}
            maxTagPlaceholder={(value) => maxTagPlaceholder(value, customerSegmentOptions)}
            rules={rules}
        />
    );
};
