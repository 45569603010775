import { CampaignGetCustomerPromotionItemResponse, GetCampaignResponseItem } from '@esg/business-management/api/campaign';
import { FlexBox, Text,
    usePagination, 
    TableCustom,
    IconPromotionRevenue,
    IconPromotionTotal,
    IconPromotionUsed,
    IconPromotionUnused,
    IconPromotionExpired,
    useHttpQuery,
    useLocalesFormat,
    AppFilter,
    SelectField,
    DatePickerField} from '@esg/ui';
import React, { useState } from 'react';
import { TableColumnsType, Tag } from 'antd';
import { managementApis } from '@esg/business-management';
import { dayjs } from '@esg/shared';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { CustomerPromotionStatus } from '@esg/business-management/api/customer-promotion';
import { SalePromotionStatus } from '@esg/business-management/api/sale-promotion/enum/salePromotionEnum';
import { PromotionOverviewCard } from './components';
import { CustomerColumnView } from '@/components';

interface CampaignContentPromotionProps {
    activeKey: string;
    campaign: GetCampaignResponseItem;
}

export interface FilterCampaignPromotionParams {
    readonly grantingDate?: string;
    readonly status?: SalePromotionStatus;
}

export const promotionStatusOptions = [
    {label: 'Unused' , value: SalePromotionStatus.Active},
    {label: 'Used' , value: SalePromotionStatus.Completed},
    {label: SalePromotionStatus[SalePromotionStatus.Expired] , value: SalePromotionStatus.Expired},
];

export const CampaignContentPromotion = (props: CampaignContentPromotionProps) => {
    const { campaign } = props;
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [searchParams, setSearchParams] = usePagination();
    const [filterParams, setFilterParams] = useState<FilterCampaignPromotionParams>({});
    const { formatDate } = useLocalesFormat();
    const {
        data: campaignOverviewPromotionResponse,
        isLoading: isLoadingOverview,
    } = useHttpQuery(managementApis.getCampaignOverviewPromotion, {
        pathData: {
            id: campaign.id,
        }
    });

    const {
        data: campaignCustomerPromotionResponse,
        isLoading: isLoadingPromotion,
    } = useHttpQuery(managementApis.getCampaignCustomerPromotion, {
        pathData: {
            id: campaign.id,
        },
        query: {...searchParams, ...filterParams},
    });

    const renderPromotionCard = () => {
        return <TableCustom
            showFilterDropdown={showFilterRender}
            pageInfo={campaignCustomerPromotionResponse?.meta}
            loading={isLoadingPromotion}
            columns={columns}
            dataSource={campaignCustomerPromotionResponse?.items}
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, page: page.toString() })
            }
            onSearch={(value) =>
                setSearchParams({ ...searchParams, search: value })
            }
            titleTableTransCode='Promotion Recipient List'
            placeholderSearchTransCode='Search by client name, phone number...'
        />;
    };

    const columns: TableColumnsType<CampaignGetCustomerPromotionItemResponse> = [
        {
            title: 'Client',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (_, record) => {
                return (<CustomerColumnView  customer={record.customer}/>);
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => {
                return <Text >{record.customer?.email}</Text>;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => {
                return <Text >{record.customer?.phone}</Text>;
            },
        },
        {
            title: 'Granting date',
            dataIndex: 'creationTime',
            key: 'creationTime',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.creationTime), `${currentLocale?.format}`)} { }</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                if(record.status == CustomerPromotionStatus.Used)
                {
                    return  <Tag>Used</Tag>;
                }

                const currentDate = dayjs().startOf('day');

                if(currentDate > dayjs(record.promotionApply?.expirationDate))
                {
                    return  <Tag color='red' >Expired</Tag>;
                }
                
                return <Tag color='success'>Unused</Tag>;
            },
        }
    ];

    const showFilterRender = () => {
        return (
            <AppFilter<FilterCampaignPromotionParams>
                defaultValues={filterParams}
                handleSubmit={setFilterParams}
                onCancel={() => { }}
            >
                {({ renderKey }) => (
                    <FlexBox direction='column' gap={16} key={renderKey}>
                        <DatePickerField
                            name='grantingDate'
                            format={currentLocale?.format}
                            label='Granting date'
                            formatValue='day'
                        />
                        <SelectField
                            label='Status'
                            placeholder='Select status'
                            style={{ width: '100%' }}
                            name='status'
                            options={promotionStatusOptions}
                        />
                    </FlexBox>
                )}
            </AppFilter>
        );
    };

    return <FlexBox direction='column' gap={24}>
        <FlexBox gap={8}>
            <PromotionOverviewCard
                total={campaignOverviewPromotionResponse?.totalRevenue || 0}
                label='Total revenues'
                icon={<IconPromotionRevenue />}
                isLoading={isLoadingOverview}
                useCurrency={true}
            />
            <PromotionOverviewCard
                total={campaignOverviewPromotionResponse?.totalPromotion || 0}
                label='Total Promotions'
                icon={<IconPromotionTotal />}
                isLoading={isLoadingOverview}
            />
            <PromotionOverviewCard
                total={campaignOverviewPromotionResponse?.totalUsePromotion || 0}
                label='Used'
                icon={<IconPromotionUsed />}
                isLoading={isLoadingOverview}
            />
            <PromotionOverviewCard
                total={campaignOverviewPromotionResponse?.totalUnusedPromotion || 0}
                label='Unused'
                icon={<IconPromotionUnused />}
                isLoading={isLoadingOverview}
            />
            <PromotionOverviewCard
                total={campaignOverviewPromotionResponse?.totalExpiredPromotion || 0}
                label='Expired'
                icon={<IconPromotionExpired />}
                isLoading={isLoadingOverview}
            />
        </FlexBox>
        {renderPromotionCard()}
    </FlexBox>;
};

