import { HttpRequestData, httpUtil } from '@esg/framework';

import { AutomationEventChannelType, AutomationEventType, ReminderType, ServiceType , getEnv } from '@esg/shared';

import { AutomationEventChannelResponse } from './automationEventGetAll';

export interface AutomationEventUpdateRequest extends HttpRequestData {
    readonly body: {
        id: string;
        reminderTime?: string;
        isActive: boolean;
        serviceType?: ServiceType;
        serviceValue?: string;
        promotionId?: string;
        spentAtLeast?: number;
        automationEventChannels?: AutomationEventChannelRequest[];
    };
}

export interface AutomationEventUpdateResponse {
    id: string;
    type: AutomationEventType;
    code: string;
    reminderType: ReminderType;
    isActive: boolean;
    serviceType?: ServiceType;
    serviceValue?: string[];
    promotionId?: string;
    spentAtLeast?: number;
    automationEventChannels?: AutomationEventChannelResponse[];
}

export interface AutomationEventChannelRequest {
    id?: string;
    type?: AutomationEventChannelType;
    emailTemplateId?: string;
    isActive?: boolean;
}

export const automationEventUpdate = httpUtil.createHttpRequestMeta<
    AutomationEventUpdateRequest,
    AutomationEventUpdateResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/automation-events/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
