import React from 'react';

import { IconProps } from '../../Types';

export const IconCelebrateBirthdays = (props: IconProps) => {
    return (
        <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z' fill='#FEF0C7'/>
            <path d='M29.0912 53.3327C29.0912 53.3327 29.0912 50.4439 29.0912 48.8882V41.1105C29.0912 39.5548 29.0912 38.7769 29.4876 38.1827C29.8362 37.66 30.3925 37.2351 31.0767 36.9688C31.8546 36.666 32.8729 36.666 34.9094 36.666H45.0912C47.1278 36.666 48.1461 36.666 48.9239 36.9688C49.6082 37.2351 50.1645 37.66 50.5131 38.1827C50.9094 38.7769 50.9094 39.5548 50.9094 41.1105V48.8882C50.9094 50.4439 50.9094 51.9438 50.9094 51.9438V53.3327M29.0912 53.3327C29.0912 53.3327 30.3033 53.3327 30.9094 53.3327C32.1215 53.3327 32.8729 53.3327 34.9094 53.3327H45.0912C47.1278 53.3327 50.9094 53.3327 50.9094 53.3327M29.0912 53.3327H26.667H50.9094M50.9094 53.3327H53.3337M29.0912 42.7771C29.8993 43.5179 32.1215 43.8882 33.9397 41.1105C34.5458 42.2216 35.5716 43.3327 36.97 43.3327C38.3684 43.3327 39.3943 42.2216 40.6064 41.1105C41.8185 42.2216 42.8304 43.3327 44.2428 43.3327C45.6551 43.3327 46.667 41.8512 47.2731 41.1105C47.8791 42.2216 49.0912 43.3327 50.9094 43.3327M40.0003 33.3327V36.666M40.0028 32.511C40.7299 33.4102 41.9422 33.6522 42.8532 32.8287C43.7641 32.0052 43.8923 30.6284 43.177 29.6544C42.7188 29.0306 41.5454 27.8738 40.7609 27.1227C40.5002 26.8731 40.3698 26.7483 40.2137 26.6983C40.0795 26.6553 39.9261 26.6553 39.7919 26.6983C39.6358 26.7483 39.5054 26.8731 39.2448 27.1227C38.4603 27.8738 37.2868 29.0306 36.8287 29.6544C36.1133 30.6284 36.2259 32.0139 37.1525 32.8287C38.0791 33.6435 39.2758 33.4102 40.0028 32.511Z' stroke='#DC6803' strokeWidth='1.67' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};
