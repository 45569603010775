import { getAppointmentsDetailReport } from '@esg/business-report';
import { AppAvatar, Block, ColumnAvatarGroup, FlexBox, ListNameColumn, TableCustom, Text, useHttpQuery, useLocalesFormat } from '@esg/ui';
import React from 'react';
import { AppointmentStatus, getAppointmentStatusLabel, getAppointmentTagColor, Dayjs, dayjs } from '@esg/shared';
import { Avatar, TableColumnsType, Tag } from 'antd';
import { AppointmentGetDetailResponse, AppointmentService } from '@esg/business-schedule';
import { flatten } from 'lodash';
import { managementApis } from '@esg/business-management';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import { getAppointmentSummary } from '@/components/form/Appointment/@share/appointmentUtils';
import { translateCodes } from '@/locales';
import { CustomerLabelTag } from '@/components/common/customer/CustomerLabelTag';
import { usePaginationReport } from '@/pages/sale-analyzer/report-hooks';

interface AppointmentDetailTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly appointmentStatus?: AppointmentStatus;
    readonly serviceIds?: string[];
    readonly employeeIds?: string[];
    readonly categoryId?: string;
}

export default function AppointmentDetailTable(props: AppointmentDetailTableProps) {
    const { formatCurrency, formatDate } = useLocalesFormat();
    const { t } = useTranslation();
    const { dateRange } = props;
    const [searchParams, setSearchParams] = usePaginationReport({
        pageSizeDetail: '10',
        orderByDetail: 'appointmentCode',
        detailDirection: 'desc'
    });

    const { data: appointmentDetail, isFetching } = useHttpQuery(getAppointmentsDetailReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            status: props.appointmentStatus,
            serviceIds: props.serviceIds,
            employeeIds: props.employeeIds,
            categoryId: props.categoryId,
            search: searchParams.searchByDetail,
            orderBy: searchParams.orderByDetail,
            orderDirection: searchParams.detailDirection,
            page: searchParams.pageDetail,
            pageSize: searchParams.pageSizeDetail,
            ...searchParams,
        },
    });

    const { data: serviceResponse } = useHttpQuery(managementApis.getAllServices);

    const columns: TableColumnsType<AppointmentGetDetailResponse> = [
        {
            title: 'ID',
            dataIndex: 'appointmentCode',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.detailDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, detailDirection: order });
                }
            }),
        },
        {
            title: t(translateCodes.CLIENT),
            dataIndex: 'customer',
            render: (_, record) => {
                return (
                    <FlexBox alignItems='center' gap={5}>
                        <AppAvatar data={record.customer} />
                        <FlexBox direction='column'>
                            <Text maxLines={1} maxWidth={100} ellipsis>{t(record.customer.fullName)}</Text>
                            <CustomerLabelTag customer={record.customer} isTag={false} />
                        </FlexBox>
                    </FlexBox>
                );
            },
        },
        {
            title: 'Client Email',
            dataIndex: 'customer',
            render: (_, record) => {
                return <Text maxLines={1} ellipsis={true} maxWidth={100}>{record.customer.email}</Text>;
            },
        },
        {
            title: t(translateCodes.NUMBER_OF_GUEST),
            dataIndex: 'numberOfGuest',
        },
        {
            title: t(translateCodes.EMPLOYEES),
            dataIndex: 'appointmentServices',
            render: (appointmentServices: AppointmentService[]) => {
                const listEmployees = flatten(appointmentServices.map(a => flatten(a.appointmentServiceDetails.map(f => flatten(f.appointmentServiceEmployees?.map(t => t.employee))))));
                const employees = listEmployees.filter(x=> !!x).map(o=> ({
                    id: o?.id,
                    avatar: o?.avatar,
                    fullName: o?.fullName,
                }));
                if(employees?.length == 0){
                    return <FlexBox gap={8} alignItems='center'>
                        <Avatar 
                            icon={<UserOutlined/>}
                            style={{ backgroundColor: '#F2F4F7' }}
                        />{t(translateCodes.UNASSIGNED)}
                    </FlexBox>;
                }
                return ( <ColumnAvatarGroup
                    items={employees}
                    maxCount={1}
                    prefixEnd={employees?.length > 1 ? `${employees?.length} employees` : employees[0].fullName }
                />
                );
            },
        },
        {
            title: t(translateCodes.FACILITY),
            dataIndex: 'appointmentServices',
            render: (appointmentServices: AppointmentService[]) => {
                const listFacility = flatten(appointmentServices.map(a => flatten(a.appointmentServiceDetails.map(o => o.facility))));
                const names = listFacility.filter(x=> !!x).map(o => o?.name);

                return <ListNameColumn names={names} limit={2}/>;
            },
        },
        {
            title: t(translateCodes.DATE_AND_TIME),
            dataIndex: 'appointmentServices',
            render: (_, record) => {
                const createdAt = dayjs(record.creationTime);
                const createdTime = createdAt.format('hh:mm A');
                const createdDate = formatDate(createdAt);
                const serviceTime = flatten(record.appointmentServices.map(a => flatten(a.appointmentServiceDetails.map(o => o.service.duration))));
                const createdTimeEnd = createdAt.add(serviceTime.reduce((total, item) => total + item), 'm').format('hh:mm A');
                return (
                    <div>
                        <span>{createdTime} - {createdTimeEnd}</span>
                        <br />
                        <span>{createdDate}</span>
                    </div>
                );
            },
        },
        {

            title: `${t(translateCodes.SERVICE)}(s)`,
            dataIndex: 'appointmentServices',
            render: (appointmentServices: AppointmentService[]) => {
                const listService = flatten(appointmentServices.map(a => flatten(a.appointmentServiceDetails.map(o => o.service))));
                const names = listService.filter(o => o?.name !== null && o?.name !== undefined).map(o => o?.name);
                const namesUnique = [...new Set(names)];                
                return <ListNameColumn names={namesUnique} limit={2} />;
            },
        },
        {
            title: t(translateCodes.TOTAL_AMOUNT),
            dataIndex: 'appointmentServices',
            render: (appointmentServices: AppointmentService[]) => {
                const { price } = getAppointmentSummary(appointmentServices, serviceResponse?.items ?? []);
                return <Text>{formatCurrency(price)}</Text>;
            },
        },
        {
            title: t(translateCodes.STATUS),
            dataIndex: 'status',
            render: (_, record) => {
                return (
                    <Tag color={getAppointmentTagColor(record.status)}>
                        {getAppointmentStatusLabel(record.status)}
                    </Tag>
                );
            },
        },
    ];

    return (
        <Block width={1300}>
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, pageDetail: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, searchByDetail: value })
                }
                pageInfo={appointmentDetail?.meta}
                titleTableTransCode={t(translateCodes.APPOINTMENT_LIST)}
                placeholderSearchTransCode='Search ID, Client, Phone, Email'
                showHeader={true}
                loading={isFetching}
                columns={columns}
                dataSource={appointmentDetail?.items}
                subTitleTable={<Tag color='processing'>{`${appointmentDetail?.meta.totalItems} ${t(translateCodes.BILLS)}`}</Tag>}
            />
        </Block>
    );
}
