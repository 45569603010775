import { Block, ListNameColumn, TableCustom, Text, useHttpQuery, useLocalesFormat, usePagination } from '@esg/ui';
import { Dayjs } from 'dayjs';
import React, { useMemo } from 'react';
import { TableColumnsType, Tag } from 'antd';
import { flatten } from 'lodash';
import { GetDetailSaleResponse, SaleDetailGetAllResponseItem } from '@esg/business-pos';
import { TypeRevenueDetail, getSummaryDetailRevenueReport } from '@esg/business-report/api/revenue-report/getSummaryRevenueDetail';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';
import SaleEmployeeColumn from '@/pages/fast-pos/sale/children/SaleEmployeeColumn';
import { saleHelper } from '@/helpers';
import SaleDateTimeColumn from '@/pages/fast-pos/sale/children/SaleDateTimeColumn';
import { CustomerColumnView } from '@/components';

interface RevenueDetailTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly typeRevenueDetail?: TypeRevenueDetail;
}

export function RevenueSummaryDetailTable(props: RevenueDetailTableProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const { formatCurrency, formatCurrencySymbol } = useLocalesFormat();
    const [ searchParams, setSearchParams ] = usePagination({
        pageSize: '10',
        orderBy: 'code',
    });

    const { data: revenueSummaryDetailReport, isFetching } = useHttpQuery(getSummaryDetailRevenueReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            typeRevenueDetail: props.typeRevenueDetail,
            ...searchParams,
        },
    });

    const columns: TableColumnsType<GetDetailSaleResponse> = useMemo(()=>([
        {
            title: 'ID',
            dataIndex: 'code',
            key: 'id',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.orderDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, orderDirection: order });
                }
            }),
        },
        {
            title: t(translateCodes.CLIENT),
            dataIndex: 'customer',
            render: (_, record) => {
                return <CustomerColumnView customer={record.customer} />;
            },
        },
        {
            title: t(translateCodes.EMPLOYEES),
            dataIndex: 'saleDetails',
            render: (saleDetails: SaleDetailGetAllResponseItem[]) => <SaleEmployeeColumn saleDetails={saleDetails} />
        },
        {
            title: t(translateCodes.DATE_AND_TIME),
            dataIndex: 'date',
            render: (_, record) => <SaleDateTimeColumn createdAt={record.date} checkoutAt={record.checkoutAt}/>
        },
        {
            title: t(translateCodes.SERVICES_TIME),
            dataIndex: 'saleDetails',
            render: (saleDetails?: SaleDetailGetAllResponseItem[]) => {
                const listService = flatten(saleDetails?.map(a => a.service));
                const names = listService.filter(o => o?.name !== null && o?.name !== undefined).map(o => o?.name);
                const namesUnique = [...new Set(names)];
                return <ListNameColumn names={namesUnique} limit={2} />;
            },
        },
        {
            title: t(translateCodes.TIP),
            dataIndex: 'tip',
            render: (_, record) => {
                return `${record.tip} ${record.tipUnit == 0 ? '%' : formatCurrencySymbol()}`;
            },
        },
        {
            title: t(translateCodes.DISCOUNT),
            dataIndex: 'Discount',
            render: (_, record) => saleHelper.calcDiscountAmount(record.saleDetails ?? []),
        },
        {
            title: t(translateCodes.TOTAL),
            dataIndex: 'totalAmount',
            render: (_, record) => {
                return (
                    <Text>
                        {formatCurrency(record.totalAmount)}
                    </Text>
                );
            },
        },
    ]),[formatCurrency, formatCurrencySymbol, searchParams, setSearchParams, t]);

    return (
        <Block width={1300}>
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                pageInfo={revenueSummaryDetailReport?.meta}
                titleTableTransCode={translateCodes.BILL_LIST}
                placeholderSearchTransCode={translateCodes.CLIENT_PLACEHOLDER_SEARCH}
                showHeader={true}
                loading={isFetching}
                columns={columns}
                dataSource={revenueSummaryDetailReport?.items}
                subTitleTable={<Tag color='processing'>{`${revenueSummaryDetailReport?.meta.totalItems} ${t(translateCodes.BILLS)}`}</Tag>}
            />
        </Block>
    );
}
