import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

interface GetAllAppointmentSettingRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllAppointmentSettingResponseItem {
    id: string;
    timeSlotDuration: number;
    doubleAppointmentEnabled: boolean;
    doubleAppointmentMaxSlot: number;
    employeeSelectionRequired: boolean;
    autoNoShowEnabled: boolean;
    autoNoShowAfterTime: number;
    autoNoShowPromptEnabled: boolean;
}

export const getAllAppointmentSetting = httpUtil.createHttpRequestMeta<
    GetAllAppointmentSettingRequest,
    PaginationResponse<GetAllAppointmentSettingResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/appointment-Settings',
    method: 'GET',
    authentication: 'bearer',
});
