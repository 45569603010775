import { managementApis } from '@esg/business-management';
import {
    FormLayout,
    FlexBox,
    FormBase,
    Text,
    Block,
    Spacer,
    SelectField,
    IconEmail,
    SwitchField,
    SelectFieldCheckBox,
    InputNumberField,
    AppButton,
    IconMail,
    globalModalState
} from '@esg/ui';
import { Card } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useHttpCommand, useHttpQuery, viewRequests } from '@esg/framework';
import { automationEventApi, AutomationEventChannelResponse, emailTemplateApi, ReminderTimeValue } from '@esg/business-success-platform';
import { AutomationEventChannelType, AutomationEventType, ReminderType, ServiceType } from '@esg/shared';
import { AutomationEventChannelRequest, AutomationEventUpdateRequest } from '@esg/business-success-platform/api/automation-event/automationEventUpdate';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { EmailTemplateSendTestMailResponseItem } from '@esg/business-success-platform/api/email-template/emailTemplateSendTestMail';
import { SendMailTestForm } from '../send-mail-test';
import { EmailTemplatePreview } from './children/EmailTemplatePreview';
import { translateCodes } from '@/locales';
import { renderIcon } from '@/pages/success-platform/automation/automation-event/children/AutomationEventItem';
import { isImmediately, renderAddons, renderDescription, renderName, renderReminderTimeOptions, renderReminderTimeString, renderReminderTimeTypeCelebrateBirthday } from '@/helpers';
import './AutomationEventForm.scss';

export interface AutomationEventFormValues {
    id: string;
    type: AutomationEventType;
    reminderTime?: string[];
    reminderType?: ReminderType;
    isActive: boolean;
    serviceType?: ServiceType;
    serviceValue?: string[];
    promotionId?: string;
    spentAtLeast?: number;
    automationEventChannels?: AutomationEventChannelRequest[];
}

interface AutomationEventFormProps {
    readonly defaultValues?: AutomationEventFormValues;
    readonly onSuccess?: () => void;
}

export const AutomationEventForm = (props: AutomationEventFormProps) => {
    const [isShowTemplate, setIsShowTemplate] = React.useState<boolean>(false);
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);

    const { mutateAsync: updateAutomationEvent, isPending: isLoadingUpdate } = useHttpCommand(automationEventApi.automationEventUpdate);
    const { data: emailTemplates } = useHttpQuery(emailTemplateApi.emailTemplateGetAll);
    const { data: servicesCategories } = useHttpQuery(managementApis.getAllServiceCategory);
    const { data: promotionsResponse } = useHttpQuery(managementApis.getAllPromotion);
    const renderReminderTimeForType = useCallback((i: ReminderTimeValue) => {
        if (props.defaultValues!.type === AutomationEventType.CelebrateBirthdays) {
            return renderReminderTimeTypeCelebrateBirthday(i);
        }
        return renderReminderTimeString(i);
    }, [props.defaultValues]);

    const automationOptions = useMemo(() => {
        return (
            renderReminderTimeOptions(props.defaultValues!.type!)?.map((i) => ({
                label: renderReminderTimeForType(i),
                value: JSON.stringify({
                    reminderTime: i.reminderTime,
                    reminderTimeType: i.reminderTimeType,
                }),
            })) ?? []
        );
    }, [props.defaultValues, renderReminderTimeForType]);

    const promotionOptions = useMemo(() => {
        return (
            promotionsResponse?.items?.map((i) => ({
                label: i.name,
                value: i.id,
            })) ?? []
        );
    }, [promotionsResponse?.items]);

    const emailTemplateOptions = useMemo(() => {
        return emailTemplates?.items?.map((i) => ({
            label: renderName(i.type!),
            value: i.id,
        })) ?? [];
    }, [emailTemplates?.items]);

    const handlerSendMailTest = useCallback((emailTemplate: EmailTemplateSendTestMailResponseItem) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: 'Send a test message',
            content: <SendMailTestForm onCancel={resetGlobalModal} defaultValues={{
                email: '',
                emailTemplate: emailTemplate
            }} />,
            onOk: () => {
                resetGlobalModal();
            },
            onCancel: () => {
                resetGlobalModal();
            }
        });
    }, [globalModal, resetGlobalModal, setGlobalModal]);

    const isUiCelebrateBirthdayEvent = [AutomationEventType.CelebrateBirthdays].includes(props.defaultValues!.type!);
    const isUiRewardLoyalClients = [AutomationEventType.RewardLoyalClients].includes(props.defaultValues!.type!);
    const isUiWelcomeNewClients = [AutomationEventType.WelcomeNewClients].includes(props.defaultValues!.type!);

    const onSubmit = React.useCallback(
        async (values: AutomationEventFormValues) => {

            if (isUiCelebrateBirthdayEvent && values.reminderTime) {
                values.reminderTime = [values.reminderTime?.toString()];
            }

            let servicesValue = values.serviceValue;
            if (values.serviceValue?.includes(ServiceType.All.toString())) {
                servicesValue = [...servicesCategories?.items.flatMap(o => o.services)?.map(o => o.id) ?? []];
            }
            const newValues: AutomationEventUpdateRequest['body'] = {
                ...values,
                reminderTime: JSON.stringify(values.reminderTime?.map((i: string) => JSON.parse(i))),
                serviceValue: JSON.stringify(servicesValue),
            };

            await updateAutomationEvent({
                pathData: { id: props.defaultValues?.id },
                body: newValues,
            },
                {
                    onSuccess: () => {
                        viewRequests.showNotification.send({
                            type: 'success',
                            message: 'Update Automation Event success',
                        });
                        props.onSuccess?.();
                    }
                }
            );

        },
        [isUiCelebrateBirthdayEvent, props, servicesCategories?.items, updateAutomationEvent]
    );

    const renderReminderTimeSelect = () => {
        if (isImmediately(props.defaultValues!.reminderType!)) {
            return <SelectField
                disabled
                name='reminderTime'
                label='Reminder time'
                placeholder={
                    translateCodes.IMMEDIATELY
                }
                options={automationOptions}

            />;
        }
        if (isUiCelebrateBirthdayEvent) {
            return <SelectField
                addonBefore={renderAddons(props.defaultValues!.type)}
                addonBeforeWidth='13%'
                name='reminderTime'
                label='Reminder time'
                placeholder={
                    translateCodes.TIME
                }
                options={automationOptions}
            />;
        }
        return <SelectFieldCheckBox
            className='ant-select-selector-custom'
            addonBefore={renderAddons(props.defaultValues!.type)}
            addonBeforeWidth='13%'
            name='reminderTime'
            label='Reminder time'
            placeholder={
                translateCodes.TIME
            }
            options={automationOptions}

        />;

    };

    const renderUiTriggerEvent = () => {
        return (<>
            <Block>
                <Text fontWeight={600} className='automation-event-form-card-title' translateCode='Trigger event' />
                <Spacer height={4} />
                <Card className='automation-event-form-card'>
                    <FlexBox gap={16}>
                        {renderIcon(props.defaultValues!.type, 48, 48)}
                        <FlexBox direction='column' >
                            <Text fontWeight={600} translateCode={renderName(props.defaultValues!.type)} />
                            <Text fontWeight={400} translateCode={renderDescription(props.defaultValues!.type)} />
                        </FlexBox>
                    </FlexBox>
                </Card>
            </Block>
            <FlexBox className='automation-event-form-input-group'>
                {renderReminderTimeSelect()}
            </FlexBox>
        </>);
    };
    const renderUiClientEvent = () => {
        return (
            <SelectField
                name='promotionId'
                label='Promotion'
                placeholder='Select Promotion'
                options={promotionOptions}
            />
        );
    };
    const renderUiRewardLoyalClients = () => {
        return (
            <>
                <Block>
                    <InputNumberField
                        type='number'
                        name='spentAtLeast'
                        label='Who spent at least'
                        placeholder={
                            translateCodes.VALUE
                        }
                        style={{ width: '100%' }}
                    />
                    <Text color='#667085' fontWeight={400} translateCode='Set the minimum total value of paid sales for clients to receive this campaign' />
                </Block>
                <SelectField
                    name='promotionId'
                    label='Promotion'
                    placeholder='Select Promotion'
                    options={promotionOptions}
                />
            </>
        );
    };

    const renderUiWelcomeNewClients = () => {
        return (
            <>
                <SelectField
                    name='promotionId'
                    label='Promotion'
                    placeholder='Select Promotion'
                    options={promotionOptions}
                />
            </>
        );
    };

    const renderUiTemplateEvent = (form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>) => {
        const automationEventChannels = form.watch('automationEventChannels')?.filter((item: AutomationEventChannelResponse) => item.type === AutomationEventChannelType.Mail);
        return (
            <Block>
                <Text fontWeight={600} className='automation-event-form-card-title' translateCode='Channel' />
                <Spacer height={4} />
                <FlexBox direction='column' gap={16}>
                    {automationEventChannels.map((item: AutomationEventChannelResponse, index: number) => {
                        const template = emailTemplates?.items.find(o => o.id === item.emailTemplateId);
                        return (
                            <>
                                <Card className='automation-event-form-card'>
                                    <FlexBox direction='column' gap={16}>
                                        <FlexBox gap={16} justifyContent='space-between'>
                                            <Block>
                                                <FlexBox gap={8}>
                                                    {item.type === AutomationEventChannelType.Mail && <IconEmail height={48} width={48} />}
                                                    <FlexBox direction='column' >
                                                        <Text fontWeight={600} translateCode='Email' />
                                                        <Text fontWeight={400} translateCode='Reach customers via email' />
                                                    </FlexBox>
                                                </FlexBox>
                                            </Block>
                                            <Block>
                                                <SwitchField
                                                    name={`automationEventChannels.${index}.isActive`}
                                                    onChange={(value) => {
                                                        form.setValue(`automationEventChannels.${index}.isActive`, value);
                                                    }}
                                                />
                                            </Block>
                                        </FlexBox>
                                        {form.watch('automationEventChannels')[index].isActive && (
                                            <>
                                                <hr color='#EAECF0' />
                                                <Block>
                                                    <FlexBox justifyContent='space-between'>
                                                        <Text fontWeight={600} translateCode='Email template' />
                                                        <Text onClick={() => { setIsShowTemplate(!isShowTemplate); }} cursor='pointer' color='#004EEB' fontWeight={400} translateCode={isShowTemplate ? 'Hide template' : 'Show template'} />
                                                    </FlexBox>
                                                    <Spacer height={4} />
                                                    <SelectField
                                                        disabled
                                                        name={`automationEventChannels.${index}.emailTemplateId`}
                                                        placeholder={
                                                            translateCodes.EMAIL_TEMPLATE_AVAILABLE
                                                        }
                                                        options={emailTemplateOptions}
                                                    />
                                                </Block>
                                                {isShowTemplate && <>
                                                    <EmailTemplatePreview
                                                        subjectCustom={template?.subjectCustom}
                                                        bodyCustom={template?.bodyCustom}
                                                    />
                                                    <Block>
                                                        <AppButton
                                                            onClick={() => handlerSendMailTest(template ?? {})}
                                                            ghost
                                                            type='primary'
                                                            translateCode='Send test email'
                                                            icon={<IconMail color='#004EEB' />}
                                                        />
                                                    </Block>
                                                </>}

                                            </>
                                        )}

                                    </FlexBox>
                                </Card>
                            </>
                        );

                    })}
                </FlexBox>
            </Block>
        );
    };

    return (
        <FormBase onSubmit={onSubmit} defaultValues={props.defaultValues}>
            {(form) => {
                return (
                    <FormLayout
                        isLoadingButton={isLoadingUpdate}
                        labelSubmitCode={
                            props.defaultValues?.id
                                ? translateCodes.SAVE
                                : translateCodes.CREATE
                        }
                    >
                        <Card bordered={false}>
                            <FlexBox direction='column' gap={16}>
                                {renderUiTriggerEvent()}
                                {isUiCelebrateBirthdayEvent && renderUiClientEvent()}
                                {isUiRewardLoyalClients && renderUiRewardLoyalClients()}
                                {isUiWelcomeNewClients && renderUiWelcomeNewClients()}
                                {renderUiTemplateEvent(form)}
                            </FlexBox>
                        </Card>
                    </FormLayout>
                );
            }}
        </FormBase>
    );
};