import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface CreateServiceCategoryRequest extends HttpRequestData {
    readonly body: {
        name: string;
        description?: string;
    };
}

export interface CreateServiceCategoryResponse {
    readonly id: string;
    name: string;
    description: string;
}

export const createServiceCategory = httpUtil.createHttpRequestMeta<
    CreateServiceCategoryRequest,
    CreateServiceCategoryResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/service-categories',
    method: 'POST',
    authentication: 'bearer',
});
