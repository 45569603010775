import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { GetRoleDetailResponse } from './getRoleDetail';

interface CreateRoleRequest extends HttpRequestData {
    readonly body: {
        readonly name: string;
        readonly description?: string;
    };
}

export const createRole = httpUtil.createHttpRequestMeta<
    CreateRoleRequest,
    GetRoleDetailResponse
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/roles',
    method: 'POST',
    authentication: 'bearer',
});
