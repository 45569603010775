import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export interface CreateCustomerSegmentRequest extends HttpRequestData {
    readonly body: {
        customerIds?: string[];
        segmentId: string;
    };
}

export interface CustomerSegmentCreateItem {
    customerIds?: string[];
}

export const createCustomerSegment = httpUtil.createHttpRequestMeta<
    CreateCustomerSegmentRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-segments',
    method: 'POST',
    authentication: 'bearer',
});

