import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface UpdateJobTypeRequest extends HttpRequestData {
	readonly body: {
		id?: string;
		name: string;
	};
}

export interface UpdateJobTypeResponse {
	readonly id: string;
	name: string;
}

export const updateJobType = httpUtil.createHttpRequestMeta<
	UpdateJobTypeRequest,
	UpdateJobTypeResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/job-types/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
