import { httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';
import { GetCurrentProfileResponse } from '.';

export interface UpdateCurrentProfileRequest {
    readonly body: {
        readonly id: string;
        readonly firstName: string;
        readonly lastName?: string;
        readonly email: string;
        readonly avatar?: string;
        readonly phone?: string;
        readonly branchActive?: string;
    };
}

export const updateCurrentProfile = httpUtil.createHttpRequestMeta<
    UpdateCurrentProfileRequest,
    GetCurrentProfileResponse
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/profiles/me',
    method: 'PATCH',
    authentication: 'bearer'
});
