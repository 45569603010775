import { GetDetailSaleResponse, SaleStatus, UnitEnum } from '@esg/business-pos';
import { FlexBox, IconPromotion, IconTrash, InputNumberField, Spacer, Text, TextAreaField, useLocalesFormat } from '@esg/ui';
import React, { useCallback } from 'react';
import { SaleDetailGetAllResponseItem } from '@esg/business-pos/api/sale-detail';
import { Alert, Card, Divider, Select, Spin } from 'antd';
import { useFormContext } from 'react-hook-form';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SalePromotionStatus } from '@esg/business-management/api/sale-promotion/enum/salePromotionEnum';
import SalePromotionDropdown from '../sale-order-component/SalePromotionDropdown';
import SaleOrderList from './order-item/SaleOrderList';
import { SaleGeneralFastTip } from './SaleGeneralFastTip';
import { CustomerSelectField } from '@/components/form/customer/children/select-customer/CustomerSelectField';
import { saleHelper } from '@/helpers';
import { translateCodes } from '@/locales';
import { saleEvents } from '@/event/saleEvent';

interface SaleOrderContainerProps {
    sale?: GetDetailSaleResponse;
    handleUpdateSale: (sale: GetDetailSaleResponse) => void;
    handleDeleteSaleDetail: (id: string) => void;
    handleUpdateSaleDetail: (saleDetail: SaleDetailGetAllResponseItem) => void;
    handleCreateSalePromotion: (customerPromotionId: string) => Promise<void>;
    handleDeleteSalePromotion: (id: string) => Promise<void>;
    isLoading?: boolean;
    readonly isFastSale?: boolean;
}

export default function SaleGeneralInfo(props: SaleOrderContainerProps) {
    const { formatCurrency, formatCurrencySymbol } = useLocalesFormat();
    const {setValue, getValues, watch} = useFormContext();
    const { t } = useTranslation();
    const { sale, handleUpdateSale, handleDeleteSaleDetail, isLoading, handleCreateSalePromotion, handleDeleteSalePromotion } = props;

    const saleDetails = sale?.saleDetails || [];
    const salePromotion = sale?.salePromotions?.[0];
    const tipAmount = saleHelper.calcTipAmount(sale!);
    const taxAmount = saleHelper.calcTaxAmount(sale?.saleDetails ?? []);
    const isDisabled = sale?.status !== SaleStatus.Unpaid;

    const tipUnitSelect = useCallback(() => {
        return (
            <Select 
                onChange={(value) => {
                    if (isDisabled) return;
                    setValue('tipUnit', value);
                    handleUpdateSale(getValues() as GetDetailSaleResponse);
                }}
                value={watch('tipUnit')}
                disabled={isDisabled}
            >
                <Select.Option value={UnitEnum.Percent}>%</Select.Option>
                <Select.Option value={UnitEnum.Amount}>{formatCurrencySymbol()}</Select.Option>
            </Select>
        );
    },[formatCurrencySymbol, getValues, handleUpdateSale, isDisabled, setValue, watch]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandleUpdate = useCallback(
        debounce((value: GetDetailSaleResponse) => {
            handleUpdateSale?.(value);
        }, 1000),
        []
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandleUpdateTip = useCallback(
        debounce((value: GetDetailSaleResponse) => {
            handleUpdateSale?.(value);
            saleEvents.onRefetchFastTipSale.emit();
        }, 1000),
        []
    );

    const onChangeFastTip =(value : number) =>{
        setValue('tipUnit', UnitEnum.Percent);
        setValue('tip', value);
        handleUpdateSale?.(getValues() as GetDetailSaleResponse);
    };

    const isSaleDetail = sale && sale.saleDetails?.[0];

    return (
        <Spacer type='padding' left={20} right={10} top={12}>
            <CustomerSelectField
                isRequired={false}
                disabled={sale?.status !== SaleStatus.Unpaid}
                name='customerId'
                isFastSale={props.isFastSale}
                handleSelectCustomer={(customer) => {
                    handleUpdateSale({
                        ...sale!,
                        customerId: customer?.id
                    });
                }}
            />
            <Spin spinning={isLoading}>
                <SaleOrderList
                    saleDetails={saleDetails}
                    onDeleteSaleDetail={handleDeleteSaleDetail}
                    onEditSaleDetail={props.handleUpdateSaleDetail}
                    disabled={sale?.status !== SaleStatus.Unpaid}
                />
            </Spin>
            <FlexBox direction='column' gap={16}>
                {
                    isSaleDetail &&
                    <FlexBox direction='column' gap={8}>
                        <Text fontWeight={700} fontSize={16}>{t(translateCodes.TIP)}</Text>
                        <Card className='card-body'>
                            <Spacer bottom={8}>
                                <Text fontWeight={600} lineHeight='20px' color='#344054'>Tip Amount</Text>
                            </Spacer>
                            {
                                sale
                                && <SaleGeneralFastTip
                                    handleUpdateFastTip={(value) => onChangeFastTip(value)}
                                    saleId={sale.id}
                                    disabled={sale?.status !== SaleStatus.Unpaid}
                                />
                            }
                            <InputNumberField
                                style={{ flex: 1 }}
                                name='tip'
                                onChange={() => debouncedHandleUpdateTip(getValues() as GetDetailSaleResponse)}
                                disabled={sale?.status !== SaleStatus.Unpaid}
                                // label='Tip Amount'
                                placeholder='Enter a specific amount...'
                                addonBefore={tipUnitSelect()}
                                min={0}
                            />
                        </Card>
                    </FlexBox>
                }
                {
                    sale?.customerId
                    && <FlexBox direction='column' gap={8}>
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={700} fontSize={16}>{t(translateCodes.PROMO_CODE)}{`${salePromotion ? ' (1)' : ''}`}</Text>
                            {
                                isSaleDetail && <SalePromotionDropdown
                                    sale={sale}
                                    onChange={handleCreateSalePromotion}
                                    disabled={isDisabled}
                                />
                            }
                        </FlexBox>
                        <Spin spinning={isLoading}>
                            <Card className={`card-body ${isDisabled? 'disabled': ''}`}>
                                {
                                    salePromotion
                                    && <FlexBox direction='column' gap={5} >
                                        <FlexBox justifyContent='space-between'>
                                            <FlexBox gap={4}>
                                                <IconPromotion />
                                                <Text>
                                                    {salePromotion.name}
                                                </Text>
                                            </FlexBox>
                                            <Text fontWeight={600}>{formatCurrency(salePromotion.promotionAmount)}</Text>
                                        </FlexBox>
                                        <FlexBox justifyContent='space-between'>
                                            <Text>
                                                {`${salePromotion.promotionUnit == UnitEnum.Percent ? `${salePromotion.promotionValue}%` : `${formatCurrency(salePromotion.promotionValue)}`} off`}
                                            </Text>
                                            <IconTrash  onClick={() => { handleDeleteSalePromotion(salePromotion.id); }} width={16} height={16} cursor='pointer' />
                                        </FlexBox>
                                        {
                                            salePromotion.status === SalePromotionStatus.Inactive
                                            && <Alert
                                                type='warning'
                                                message='The promo code has already been used.'
                                                style={{ color: '#DC6803' }}
                                            />
                                        }
                                    </FlexBox>
                                    || <FlexBox justifyContent='center'>
                                        <Text color='#667085'>You haven’t applied any promo code</Text>
                                    </FlexBox>
                                }
                            </Card>
                        </Spin>
                    </FlexBox>
                }
            </FlexBox>
            <Spacer type='padding' top_bottom={16}>
                {
                    isSaleDetail
                    && <TextAreaField
                        name='note'
                        placeholder={t(translateCodes.ENTER_YOUR_NOTE)}
                        onChange={() => debouncedHandleUpdate(getValues() as GetDetailSaleResponse)}
                        disabled={sale?.status !== SaleStatus.Unpaid}
                        rows={3}
                        label='Note'
                    />
                }
                <Spacer type='margin' top={12} />
                <Card className='card-body'>
                    <FlexBox direction='column' gap={8}>
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={600}>Subtotal</Text>
                            <Text fontWeight={600} >{formatCurrency(saleHelper.calcSubTotalDetail(saleDetails))}</Text>
                        </FlexBox>
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={600} translateCode={translateCodes.DISCOUNT}/>
                            <Text fontWeight={600} >{formatCurrency(saleHelper.calcTotalDiscount(saleDetails))}</Text>
                        </FlexBox>
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={600}>{t(translateCodes.TAX)}</Text>
                            <Text fontWeight={600}>{formatCurrency(taxAmount)}</Text>
                        </FlexBox>
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={600}>{t(translateCodes.TIP)}</Text>
                            <Text fontWeight={600}>{formatCurrency(tipAmount)}</Text>
                        </FlexBox>
                        <Divider  style={{ marginTop: 8, marginBottom: 8 }}/>
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={700} fontSize={16}>{t(translateCodes.TOTAL_AMOUNT)}</Text>
                            <Text fontWeight={700} fontSize={16}>{formatCurrency(sale?.totalAmount ?? 0)}</Text>
                        </FlexBox>
                    </FlexBox>
                </Card>
            </Spacer>
        </Spacer>
    );
}
