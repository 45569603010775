import { GetAllPaymentMethodResponseItem } from '@esg/business-setting';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { Dayjs, getEnv } from '@esg/shared';
import { HttpRequestData, httpUtil } from '@esg/framework';

import { SalePromotionGetDetailResponse } from '@esg/business-management';

import { SaleDetailGetAllResponseItem } from '../sale-detail';

interface GetDetailSaleRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export enum SaleStatus {
    Unpaid,
    Paid,
    Cancelled
}

export enum UnitEnum {
    Percent = 0,
    Amount = 1
}

export interface CancelReasonSale {
    readonly id: string;
    readonly name: string;
}

export interface GetDetailSaleResponse {
    readonly id: string;
    readonly code: string;
    readonly date: Dayjs;
    readonly checkoutAt: Dayjs;
    readonly totalAmount: number;
    readonly tip: number;
    readonly tipUnit: UnitEnum;
    readonly subTotal: number;
    readonly note?: string;
    readonly cancelNote?: string;
    readonly cancelReasonId?: string;
    readonly cancelReason?: CancelReasonSale;
    readonly taxAmount: number;
    readonly subTotalDetail: number;
    readonly paymentMethodSettingId?: string;
    readonly paymentMethodSetting?: GetAllPaymentMethodResponseItem;
    readonly status: SaleStatus;
    readonly customerId?: string;
    readonly customer?: GetCustomerDetailResponse;
    readonly saleDetails?: SaleDetailGetAllResponseItem[];
    readonly isActive: boolean;
    readonly paidFromScheduler?: boolean;
    readonly salePromotions?: SalePromotionGetDetailResponse[];
}

export const getDetailSale = httpUtil.createHttpRequestMeta<GetDetailSaleRequest, GetDetailSaleResponse>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/sales/:id',
    method: 'GET',
    authentication: 'bearer'
});