import {  FlexBox, Spacer, Text, useHttpQuery } from '@esg/ui';
import React from 'react';
import { automationEventApi, AutomationEventGetAllResponseItem } from '@esg/business-success-platform';
import { AutomationEventItem } from './children/AutomationEventItem';
import { AutomationEventGroupMap } from '@/helpers';

const groupAutomationEvent = (items: AutomationEventGetAllResponseItem[]) => {
    return items.reduce<Record<string, AutomationEventGetAllResponseItem[]>>((groups, item) => {
        const category = AutomationEventGroupMap[item.type!] || 'Reminder';

        if (!groups[category]) {
            groups[category] = [];
        }
        groups[category].push(item);
        return groups;
    }, {});
};

export function AutomationEvent() {

    const {
        data: automationEventData,
        refetch,
    } = useHttpQuery(automationEventApi.automationEventGetAll);
        
    const groupedItems = groupAutomationEvent(automationEventData?.items || []);

    return (
        <FlexBox direction='column' gap={20} >
            {Object.entries(groupedItems).map(([category, items]) => (
                <div key={category}>
                    <Text fontSize={18} fontWeight={700}>
                        {category}
                    </Text>
                    <Spacer height={8} />
                    <FlexBox direction='column' gap={8}>
                        {items.map((item, index) => (
                            <AutomationEventItem onSuccess={refetch} key={index} item={item} />
                        ))}
                    </FlexBox>
                </div>
            ))}
        </FlexBox>
    );
}
