import { DownOutlined } from '@ant-design/icons';
import { FlexBox, Text } from '@esg/ui';
import { Card, Tree } from 'antd';
import React from 'react';
import './CustomerDetailTable.scss';
import { translateCodes } from '@/locales/translateCodes';

interface CustomerActivityDetailProps {
    isLoading: boolean;
    treeData?: {
        title: React.JSX.Element;
        selectable: boolean;
        key: string;
        children: {
            title: React.JSX.Element;
            key: string;
            selectable: boolean;
        }[];
    } [] | undefined;
}

export function CustomerActivityDetail({
    isLoading,
    treeData
}: CustomerActivityDetailProps) {

    return (
        <Card style={{ maxHeight: 450, overflowY: 'auto' }} loading={isLoading}>
            <FlexBox direction='column' gap={20}>
                <Text translateCode={translateCodes.ACTIVITY_HISTORY} fontSize={18} fontWeight='bold' />
                <Tree
                    className='custom-tree'
                    showLine
                    switcherIcon={<DownOutlined />}
                    treeData={treeData}
                    defaultExpandAll
                />
            </FlexBox>
        </Card>
    );
}

