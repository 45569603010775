export const env = {
    MANAGEMENT_SERVER_ID: import.meta.env.VITE_MANAGEMENT_SERVER_ID,
    MANAGEMENT_SERVER: import.meta.env.VITE_MANAGEMENT_SERVER,
    MANAGEMENT_LOGIN_CALLBACK_PAGE: `${import.meta.env.VITE_MANAGEMENT_CLIENT}${import.meta.env.VITE_LOGIN_CALLBACK}`,
    MANAGEMENT_CLIENT_ID: import.meta.env.VITE_MANAGEMENT_CLIENT_ID,

    GOOGLE_CHALLENGE_URL: `${import.meta.env.VITE_IDENTITY_SERVER}/challenge-google`,

    BOOKING_CLIENT: import.meta.env.VITE_BOOKING_CLIENT,

    REALTIME_API_SERVER: import.meta.env.VITE_REALTIME_API_SERVER,

    IDENTITY_SERVER: import.meta.env.VITE_IDENTITY_SERVER,
    IDENTITY_CLIENT: import.meta.env.VITE_IDENTITY_CLIENT,
    IDENTITY_LOGIN_PAGE: `${import.meta.env.VITE_IDENTITY_CLIENT}${import.meta.env.VITE_LOGIN_PAGE}`,

    REPORT_SERVER: import.meta.env.VITE_MANAGEMENT_REPORT_SERVER,

    COUNTRY_API_SERVER: import.meta.env.VITE_COUNTRY_API_SERVER,
    COUNTRY_API_KEY: import.meta.env.VITE_COUNTRY_API_KEY
};