import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

export interface CreateFacilityResponse {
	readonly id: string;
	readonly name: string;
	readonly description: string;
}

export interface CreateFacilityRequest extends HttpRequestData {
	readonly body: {
		readonly name: string;
		readonly description?: string;
		readonly serviceIds?: string[];
	};
}

export const createFacility = httpUtil.createHttpRequestMeta<CreateFacilityRequest, CreateFacilityResponse>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/facilities',
    method: 'POST',
    authentication: 'bearer',
});