import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetAppointmentQuantityByEmployeeRequest extends HttpRequestData {
    query: {
        date: string;
        currentAppointmentId: string;
    };
}

export interface AppointmentQuantityByEmployee {
    readonly employeeId: string;
    readonly quantity: number;
    readonly limit: number;
    readonly isFull: boolean;
    readonly startTime: string;
    readonly endTime: string;
}

export const getAppointmentQuantityByEmployee = httpUtil.createHttpRequestMeta<
    GetAppointmentQuantityByEmployeeRequest,
    AppointmentQuantityByEmployee[]
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'GET',
    path: '/api/v1/appointments/quantity-by-employee',
    authentication: 'bearer',
});