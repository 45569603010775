import './TreeSelectField.scss';

import { Form, InputProps, Tooltip, TreeSelect, TreeSelectProps } from 'antd';
import React, { ReactNode } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DefaultOptionType } from 'antd/es/select';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { valueType } from 'antd/es/statistic/utils';
import type { ChangeEventExtra } from 'rc-tree-select/lib/interface.d.ts';
import { FormConnector } from '../../FormConnector';
import { Block, Spacer } from '../../../layouts';
import { Text } from '../../../typo';
import { AppButton } from '../../../buttons';

interface TreeSelectFieldProps {
    readonly type?: InputProps['type'];
    readonly name: string;
    readonly label?: string;
    readonly placeholder?: string;
    readonly rules?: UseControllerProps['rules'];
    readonly addonBefore?: ReactNode;
    readonly addonAfter?: ReactNode;
    readonly tooltipContent?: string;
    readonly onChange?: (value: valueType, labelList: React.ReactNode[], extra: ChangeEventExtra) => void;
    readonly onBlur?: InputProps['onBlur'];
    readonly disabled?: boolean;
    readonly description?: string;
    readonly treeData?: DefaultOptionType[];
    readonly treeDefaultExpandAll?: boolean;
}

export const TreeSelectField = (props: React.PropsWithChildren<TreeSelectFieldProps>) => {
    const { t } = useTranslation();

    const { label, name, placeholder, treeData, rules, tooltipContent, treeDefaultExpandAll } = props;

    const onRender = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (renderProps: any) => {
            const { value, onChange } = renderProps.field;
            const { error } = renderProps.fieldState;

            const handleChange = (value: valueType, labelList: React.ReactNode[], extra: ChangeEventExtra) => {
                onChange(value);
                if (props.onChange) {
                    props.onChange(value, labelList, extra);
                }
            };

            const tProps = {
                treeData,
                value,
                onChange: handleChange,
                treeCheckable: true,
                placeholder: placeholder,
                style: {
                    width: '100%',
                },
                treeNodeLabelProp: 'key',
                dropdownStyle: {
                    paddingTop: '10px'
                }

            } as TreeSelectProps;

            return (
                <Form.Item
                    validateStatus={error ? 'error' : undefined}
                    help={error?.message && t(error?.message)}
                >
                    <TreeSelect {...tProps} treeDefaultExpandAll={treeDefaultExpandAll} />
                </Form.Item>
            );
        },
        [placeholder, props, t, treeData, treeDefaultExpandAll]
    );

    const isRequired = !!rules?.required;

    return (
        <FormConnector>
            {(form) => {
                const { control } = form;
                return (
                    <Block>
                        {
                            label && <Spacer bottom={6}>
                                <Text
                                    fontWeight={600}
                                    lineHeight='20px'
                                    color='#344054'
                                >
                                    {t(label)}
                                </Text>
                                {isRequired && (
                                    <span style={{ color: 'red' }}> *</span>
                                )}
                                {tooltipContent && <Tooltip placement='top' title={<span>{t(tooltipContent)}</span>}>
                                    <AppButton type='text' icon={<QuestionCircleOutlined />} />
                                </Tooltip>}
                            </Spacer>
                        }
                        <Controller
                            name={name}
                            control={control}
                            render={onRender}
                            rules={rules}
                        />
                    </Block>
                );
            }}
        </FormConnector>
    );
};
