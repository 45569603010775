import { checkoutSale } from '@esg/business-pos';
import { FlexBox, Spacer, Text, Translate, globalModalState, useHttpCommand } from '@esg/ui';
import { Progress } from 'antd';
import React, { useEffect } from 'react';
import { useResetRecoilState, useRecoilState } from 'recoil';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { HttpRequestError, viewRequests } from '@esg/framework';
import { IconType } from 'antd/es/notification/interface';
import { currentSaleState } from '@/states';
import { saleEvents } from '@/event/saleEvent';
import { translateCodes } from '@/locales';
import { appointmentEvents } from '@/event';
import { notifyConstants, notifyKey } from '@/constants';

interface PaymentProcessingProps {
    readonly saleId: string;
    readonly isPrintBillAfterPayment: boolean;
    readonly paymentMethodId: string;
}

export const PaymentProcessing = (props: PaymentProcessingProps) => {
    const resetGlobalModal = useResetRecoilState(globalModalState);
    const [currentSale, setCurrentSale] = useRecoilState(currentSaleState);
    const [percent, setPercent] = React.useState(0);
    const {t} = useTranslation();

    const handlePrint = useReactToPrint({
        documentTitle: t(translateCodes.PRINT_THIS_DOCUMENT),
        removeAfterPrint: true,
        content: () => document.getElementById('print-bill') as HTMLElement,
        onBeforeGetContent: (): Promise<void> => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 500);
            });
        },
    });

    const { mutateAsync } = useHttpCommand(checkoutSale, {
        onSuccess: (response) => {
            setPercent(100);
            setCurrentSale(response);
            if (props.isPrintBillAfterPayment) {
                handlePrint();
            }
            saleEvents.onRefetchSaleList.emit();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.MM_FP_01)
            });
            saleEvents.onPaid.emit();
            if (currentSale?.paidFromScheduler) {
                appointmentEvents.refetchAppointments.emit();
            }
        },
        showNotificationError: false,
        onError: (error) => {
            resetGlobalModal();
            const errorResponse = error as HttpRequestError;

            switch (errorResponse.message) {
            case notifyKey.SalePromotionInValid:
                // eslint-disable-next-line no-case-declarations
                const badRequest = notifyConstants.find(c => c.key == notifyKey.SalePromotionInValid);

                viewRequests.showNotification.send({
                    type: badRequest?.type as IconType,
                    message: (
                        <Translate
                            type='html'
                            translateCode={badRequest?.message || ''}
                        />
                    ),
                });
                break;
            default:
                viewRequests.showNotification.send({
                    type: 'error',
                    title: (error as Error).name,
                    message: (error as Error).message,
                });
                break;
            }    
        }
    });

    useEffect(() => {
        if (percent === 40) {
            mutateAsync({
                pathData: { id: props.saleId },
                body: {
                    id: props.saleId,
                    paymentMethodSettingId: props.paymentMethodId,
                }
            });
        }

        const interval = setInterval(async () => {
            setPercent(percent + 20);
        }, 300);

        if (percent > 100) {
            clearInterval(interval);
            resetGlobalModal();
        }

        return () => clearInterval(interval);

    }, [mutateAsync, percent, props.paymentMethodId, props.saleId, resetGlobalModal]);

    return (
        <FlexBox direction='column' gap={8}>
            <Text fontSize={18} fontWeight={700}>{t(translateCodes.PAYMENT_IS_PROCESSED)}</Text>
            <Text>{ t(translateCodes.PAYMENT_MESSAGE)}</Text>
            <Spacer type='margin' />
            <Progress percent={percent} showInfo={false} />
        </FlexBox>
    );
};
