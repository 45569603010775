import {
    AppButton,
    Block,
    DatePickerField,
    FlexBox,
    Spacer,
    SelectField,
    InputNumberField,
    IconTrash,
} from '@esg/ui';
import React from 'react';
import { SegmentConditionItem } from '@esg/business-management/api/segment';
import { Select } from 'antd';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { GetAllProductCategoryResponseItem, GetAllProductResponseItem, GetAllServiceCategoryResponseItem, GetAllServiceResponseItem } from '@esg/business-management';
import { segmentConditionRules } from '../rules';

const {Option} = Select;

interface SegmentConditionFormProps {
    form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>;
    condition: SegmentConditionItem;
    index: number;
    disabled?: boolean;
    serviceCategories?: GetAllServiceCategoryResponseItem[];
    productCategories?: GetAllProductCategoryResponseItem[];
    services: GetAllServiceResponseItem[];
    products: GetAllProductResponseItem[];
}

const formatLabel = (value: string): string => {
    return value
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .toLowerCase()
        .replace(/^\w/, (c) => c.toUpperCase());
};

export enum ConditionalSegmentCode {
    TotalPayments = 0,
    LastVisit = 1,
    UsedService = 2,
    UsedServiceCategory = 3,
    // PurchasedServicePackage = 4,
    NewClient = 5,
    ReferredNewClient = 6,
    NotUsedService = 7,
    Gender = 8,
    Age = 9,
    Birthday = 10,
    ClientType = 11,
    PurchasedProduct = 12,
    PurchasedProductCategory = 13,
    // PurchasedMembership = 14
}

function getEnumKeyByValue<T extends Record<string, number | string>>(
    enumObj: T,
    value: T[keyof T]
): keyof T | undefined {
    return Object.entries(enumObj).find(([_, v]) => v === value)?.[0];
}

const genderOptions = [
    {label: 'Male' , value: 1},
    {label: 'FeMale' , value: 2},
    {label: 'Other' , value: 0},
];

const clientTypeOptions = [
    {label: 'New client' , value: 0},
    {label: 'Return client' , value: 1},
];

export const SegmentConditionForm = (props: SegmentConditionFormProps) => {
    const [currentLocale] = useRecoilState(currentLocaleState);
    const {condition, index, form, disabled,
        serviceCategories,
        productCategories,
        services,
        products
    } = props;

    const serviceOptions = services.map((e) => ({
        label: e.name,
        value: e.id
    }));

    const serviceCategoryOptions = serviceCategories?.map((e) => ({
        label: e.name,
        value: e.id
    })) || [];

    const productCategoryOptions = productCategories?.map((e) => ({
        label: e.name,
        value: e.id
    })) || [];

    const productOptions = products?.map((e) => ({
        label: e.name,
        value: e.id
    })) || [];

    const renderEnumOptions = (enumObject: typeof ConditionalSegmentCode) => {
        return Object.entries(enumObject)
            .filter(([, value]) => !isNaN(Number(value)))
            .map(([key, value]) => (
                <Option key={value} value={value as number}>
                    {formatLabel(key)}
                </Option>
            ));
    };

    const renderInput = (condition: SegmentConditionItem) => {
        switch (condition.code) {
        case ConditionalSegmentCode.TotalPayments:
            return (
                <FlexBox direction='column' gap={8}>
                    <InputNumberField
                        style={{width: '100%'}}
                        placeholder='Enter amount'
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.totalPayment`}
                        type='number'
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='From'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.from`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='To'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.to`}
                        rules={segmentConditionRules.valueRequire}
                    />
                </FlexBox>
            );
        case ConditionalSegmentCode.LastVisit:
            return (
                <FlexBox direction='column' gap={8}>
                    <DatePickerField
                        placeholder='From'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}.value.from]`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='To'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.to`}
                        rules={segmentConditionRules.valueRequire}
                    />
                </FlexBox>
            );
        case ConditionalSegmentCode.UsedService:
            return (
                <FlexBox direction='column' gap={8}>
                    <SelectField
                        placeholder='Select service'
                        options={serviceOptions}
                        style={{ width: '100%' }}
                        disabled={disabled}
                        name={`segmentConditions[${index}.value.serviceId]`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <InputNumberField
                        style={{width: '100%'}}
                        name={`segmentConditions[${index}.value.numberUsedService]`}
                        placeholder='Number of used service'
                        disabled={disabled}
                        type='number'
                    />
                    <DatePickerField
                        placeholder='From'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled} 
                        name={`segmentConditions[${index}].value.from`}
                        rules={segmentConditionRules.valueRequire}              
                    />
                    <DatePickerField
                        placeholder='To'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={ currentLocale?.format }
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.to`}
                        rules={segmentConditionRules.valueRequire}
                    />
        
                </FlexBox>
            );
        case ConditionalSegmentCode.UsedServiceCategory:
            return (
                <FlexBox direction='column' gap={8}>
                    <SelectField
                        options={serviceCategoryOptions}
                        placeholder='Select service category'
                        style={{ width: '100%' }}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.serviceGroupId`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <InputNumberField
                        placeholder='Number of used'
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.numberOfUsed`}
                        type='number'
                        style={{width: '100%'}}
                    />
                    <DatePickerField
                        placeholder='From'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={ currentLocale?.format }
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.from`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='To'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={ currentLocale?.format }
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.to`}
                        rules={segmentConditionRules.valueRequire}
                    />
                </FlexBox>
            );
            // case ConditionalSegmentCode.PurchasedServicePackage:
            //     return (
            //         <FlexBox direction='column' gap={8}>
            //             <SelectField
            //                 options={[]}
            //                 placeholder='Select service package'
            //                 style={{ width: '100%' }}
            //                 disabled={disabled}
            //                 name={`segmentConditions[${index}].value.servicePackageId`}
            //             />
            
        //             <DatePickerField
        //                 placeholder='From'
        //                 style={{ width: '100%' }}
        //                 formatValue='day'
        //                 format={ currentLocale?.format }
        //                 disabled={disabled}
        //                 name={`segmentConditions[${index}].value.from`}
        //             />
        //             <DatePickerField
        //                 placeholder='To'
        //                 style={{ width: '100%' }}
        //                 formatValue='day'
        //                 format={ currentLocale?.format }
        //                 disabled={disabled}
        //                 name={`segmentConditions[${index}].value.to`}
        //             />
        //         </FlexBox>
        //     );
        case ConditionalSegmentCode.NewClient:
            return (
                <FlexBox direction='column' gap={8}>
                    <DatePickerField
                        placeholder='From'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.from`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='To'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.to`}
                        rules={segmentConditionRules.valueRequire}
                    />
                </FlexBox>
            );
        case ConditionalSegmentCode.ReferredNewClient:
            return (
                <FlexBox direction='column' gap={8}>
                    <InputNumberField
                        style={{width: '100%'}}
                        placeholder='Enter a number'
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.totalReferred`}
                        type='number'
                    />
                    <DatePickerField
                        placeholder='From'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.from`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='To'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.to`}
                        rules={segmentConditionRules.valueRequire}
                    />
    
                </FlexBox>
            );
        case ConditionalSegmentCode.NotUsedService:
            return (
                <FlexBox direction='column' gap={8}>
                    <SelectField
                        options={serviceOptions}
                        placeholder='Select service'
                        style={{ width: '100%' }}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.serviceId`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='From'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.from`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='To'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.to`}
                        rules={segmentConditionRules.valueRequire}
                    />

                </FlexBox>
            );
        case ConditionalSegmentCode.Gender:
            return (
                <FlexBox direction='column' gap={8}>
                    <SelectField
                        placeholder='Select gender'
                        style={{ width: '100%' }}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.gender`}
                        rules={segmentConditionRules.valueRequire}
                        options={genderOptions}
                    />
                    <DatePickerField
                        placeholder='From'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.from`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='To'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={currentLocale?.format}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.to`}
                        rules={segmentConditionRules.valueRequire}
                    />
                </FlexBox>
            );
        case ConditionalSegmentCode.ClientType:
            return (
                <FlexBox direction='row' gap={8}>
                    <SelectField
                        options={clientTypeOptions}
                        placeholder='Select client type'
                        style={{ width: '100%' }}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.clientType`}
                        rules={segmentConditionRules.valueRequire}
                    />
                </FlexBox>
            );
        case ConditionalSegmentCode.Age:
            return (
                <FlexBox direction='row' gap={8}>
                    <InputNumberField
                        style={{width: '100%'}}
                        placeholder='Age range from'
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.ageRangeFrom`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <InputNumberField
                        style={{width: '100%'}}
                        placeholder='Age range to'
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.ageRangeTo`}
                        type='number'
                        rules={segmentConditionRules.valueRequire}
                    />
                </FlexBox>
            );
        case ConditionalSegmentCode.PurchasedProduct:
            return (
                <FlexBox direction='column' gap={8}>
                    <SelectField
                        options={productOptions}
                        placeholder='Select product'
                        style={{ width: '100%' }}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.productId`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <InputNumberField
                        style={{width: '100%'}}
                        placeholder='Number of product'
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.numberOfProduct`}
                    />
                    <DatePickerField
                        placeholder='From'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={ currentLocale?.format }
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.from`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='To'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={ currentLocale?.format }
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.to`}
                        rules={segmentConditionRules.valueRequire}
                    />
                </FlexBox>
            );
        case ConditionalSegmentCode.Birthday:
            return (
                <FlexBox direction='column' gap={8}>
                    <DatePickerField
                        placeholder='From'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format='MMM D'
                        disabled={disabled}
                        picker='date'
                        name={`segmentConditions[${index}].value.from`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='To'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format='MMM D'
                        disabled={disabled}
                        picker='date'
                        name={`segmentConditions[${index}].value.to`}
                        rules={segmentConditionRules.valueRequire}
                    />
                        
                </FlexBox>
            );
        case ConditionalSegmentCode.PurchasedProductCategory:
            return (
                <FlexBox direction='column' gap={8}>
                    <SelectField
                        options={productCategoryOptions}
                        placeholder='Select group product'
                        style={{ width: '100%' }}
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.groupProductId`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <InputNumberField
                        style={{width: '100%'}}
                        placeholder='Number of product'
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.numberOfProduct`}
                    />
                    <DatePickerField
                        placeholder='From'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={ currentLocale?.format }
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.from`}
                        rules={segmentConditionRules.valueRequire}
                    />
                    <DatePickerField
                        placeholder='To'
                        style={{ width: '100%' }}
                        formatValue='day'
                        format={ currentLocale?.format }
                        disabled={disabled}
                        name={`segmentConditions[${index}].value.to`}
                        rules={segmentConditionRules.valueRequire}
                    />
                        
                </FlexBox>
            );
            // case ConditionalSegmentCode.PurchasedMembership:
            //     return (
            //         <FlexBox direction='column' gap={8}>
            //             <SelectField
            //                 placeholder='Select group membership'
            //                 style={{ width: '100%' }}
            //                 disabled={disabled}
            //                 name={`segmentConditions[${index}].value.membershipId`}
            //                 rules={segmentConditionRules.valueRequire}
            //             >
            //                 <Option value='membership1'>membership 1</Option>
            //                 <Option value='membership2'>membership 2</Option>
            //             </SelectField>
            //             <DatePickerField
            //                 placeholder='From'
            //                 style={{ width: '100%' }}
            //                 formatValue='day'
            //                 format={ currentLocale?.format }
            //                 disabled={disabled}
            //                 name={`segmentConditions[${index}].value.from`}
            //                 rules={segmentConditionRules.valueRequire}
            //             />
            //             <DatePickerField
            //                 placeholder='To'
            //                 style={{ width: '100%' }}
            //                 formatValue='day'
            //                 format={ currentLocale?.format }
            //                 disabled={disabled}
            //                 name={`segmentConditions[${index}].value.to`}
            //                 rules={segmentConditionRules.valueRequire}
            //             />
                            
        //         </FlexBox>
        //     );
        default:
            return null;
        }
    };
    
    return (
        <Block key={index} bottom={20}>
            <FlexBox direction='column' gap={8}>
                <FlexBox gap={8}>
                    <FlexBox direction='column' gap={8}>
                        <Select
                            placeholder='Select condition'
                            value={condition?.code}
                            onChange={(value) => {
                                const updatedConditions = [...form.watch('segmentConditions')];
                                updatedConditions[index].code = value;
                                updatedConditions[index].name = getEnumKeyByValue(ConditionalSegmentCode, value);
                                form.setValue('segmentConditions', updatedConditions);
                            }}
                            style={{ width: '100%' }}
                            disabled={disabled}
                        >{renderEnumOptions(ConditionalSegmentCode)}
                        </Select>
                        {
                            condition.code != undefined && <FlexBox gap={16}>
                                {renderInput(condition)}
                            </FlexBox>
                        }
                    </FlexBox>
                    <AppButton
                        icon={<IconTrash />}
                        onClick={() => {
                            const updatedConditions = [...form.watch('segmentConditions')];
                            updatedConditions.splice(index, 1);
                            form.setValue('segmentConditions', updatedConditions);
                        }}
                        disabled={disabled}
                    />
                </FlexBox>
            </FlexBox>
            <Spacer bottom={16}/>
        </Block>    
    );
};

