import React from 'react';
import { IconProps } from '../../Types';

export const IconRevert = (props: IconProps) => {
    return (
        <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M1.66699 7.33333C1.66699 7.33333 3.33781 5.05685 4.69519 3.69854C6.05257 2.34022 7.92832 1.5 10.0003 1.5C14.1425 1.5 17.5003 4.85786 17.5003 9C17.5003 13.1421 14.1425 16.5 10.0003 16.5C6.58108 16.5 3.69625 14.2119 2.79346 11.0833M1.66699 7.33333V2.33333M1.66699 7.33333H6.66699' stroke='#D92D20' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
    );
};

IconRevert.defaultProps = {
    className: '',
    onClick: () => {},
    color: 'red',
};
