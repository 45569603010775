import React from 'react';
import { AppButton, Text, FlexBox, globalDrawerState, globalModalState, TableCustom, useHttpCommand, useHttpQuery, usePagination, ColumnActions, useLocalesFormat} from '@esg/ui';
import { LoadingOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetAllProductCategoryRequest, managementApis } from '@esg/business-management';
import { Avatar, TableColumnsType } from 'antd';
import { createSegment, CreateSegmentRequest, GetSegmentResponseItem, SegmentConditionCreateItem, updateSegment, UpdateSegmentRequest } from '@esg/business-management/api/segment';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { dayjs, requestAllItem } from '@esg/shared';

import { GetAllServiceCategoryRequest } from '@esg/business-management/api/service-category';
import { viewRequests } from '@esg/framework';
import { PageLayout } from '@/layouts';
import { translateCodes } from '@/locales';
import { SegmentForm } from '@/components';

interface SegmentOverviewProps {
    handleViewDetail: (segmentDetailId: string) => void;
}

export function SegmentOverview({ handleViewDetail }: SegmentOverviewProps) {
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const members = currentLocale?.members || [];

    const {t} = useTranslation();
    const [searchParams, setSearchParams] = usePagination();

    const {
        data: segmentsResponse,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(managementApis.getAllSegments, {
        query: searchParams,
    });

    const {  mutateAsync: serviceCategoryAsync, isPending: isServicePending  } =useHttpCommand(managementApis.getAllServiceCategory);
    const {  mutateAsync: productCategoryAsync, isPending: isProductPending } = useHttpCommand(managementApis.getAllProductCategory);

    const { mutateAsync : deleteSegmentAsync } = useHttpCommand(managementApis.deleteSegment,{
        onSuccess: () => {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SAM_03),
            });
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });
    const { mutateAsync: createSegmentAsync } = useHttpCommand(createSegment, {
        onSuccess: () => {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SAM_01),
            });
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const { mutateAsync: updateSegmentAsync } = useHttpCommand(updateSegment, {
        onSuccess: () => {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SAM_02),
            });
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const onDelete = React.useCallback(
        async (id: string) => {
            await deleteSegmentAsync({ pathData: { id: id } });
        },
        [deleteSegmentAsync]
    );

    const showModalDelete = React.useCallback((id: string) => {
        const segmentName = segmentsResponse?.items.find(c => c.id === id)?.name;
        setModalState({
            content: (
                <FlexBox direction='column'>
                    <Text fontSize={18} fontWeight={700}>
                        {t(translateCodes.DELETE_SEGMENT)}
                    </Text>
                    <Text fontSize={14}>
                        {`${t(translateCodes.DELETE_SEGMENT_MSG)} : `} 
                        <Text fontSize={14} fontWeight={600}>{segmentName}</Text>
                    </Text>
                </FlexBox>
            ),
            isOpen: true,
            onOk: async () => {
                await onDelete(id);
            },
            showModalDelete: true,
            
        });
    }, [onDelete, segmentsResponse?.items, setModalState, t]);

    const onEdit = React.useCallback(
        async (values: GetSegmentResponseItem) => {
            const payload: UpdateSegmentRequest['body'] = {
                id: values.id,
                name: values.name,
                description: values.description,
                isAutoUpdate: values.isAutoUpdate,
                operator: values.operator || 0,
            };

            await updateSegmentAsync({ body: payload, pathData: {id: values.id} });
        },
        [updateSegmentAsync]
    );

    const onCreate = React.useCallback(
        async (values: GetSegmentResponseItem) => {
            const segmentConditionItems = values.segmentConditions?.filter(c => c.code != undefined);
            const segmentConditionCreateItems: SegmentConditionCreateItem[] = segmentConditionItems?.map(c => ({
                name: c.name,
                code: c.code,
                value: JSON.stringify(c.value),
            })) || [];

            const payload: CreateSegmentRequest['body'] = {
                name: values.name,
                description: values.description,
                isAutoUpdate: values.isAutoUpdate,
                operator: values.operator || 0,
                segmentConditions: segmentConditionCreateItems
            };
            await createSegmentAsync({ body: payload });
        }, [createSegmentAsync]
    );

    const onShowModalEdit = React.useCallback(async (values: GetSegmentResponseItem) => {
        const [serviceCategories, productCategories] = await Promise.all([
            serviceCategoryAsync({ query: requestAllItem } as GetAllServiceCategoryRequest),
            productCategoryAsync({ query: requestAllItem } as GetAllProductCategoryRequest),
        ]);
        
        setDrawerState({
            isOpen: true,
            titleTransCode: 'Edit client segment',
            content: (
                <SegmentForm
                    onSubmit={onEdit}
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                    defaultValues={values}
                    serviceCategories={serviceCategories?.items}
                    productCategories={productCategories?.items}
                />
            ),
        });
    }, [onEdit, productCategoryAsync, refetch, resetDrawerState, serviceCategoryAsync, setDrawerState]
    );

    const onShowModalCreate = React.useCallback(async () => {
        const [serviceCategories, productCategories] = await Promise.all([
            serviceCategoryAsync({ query: requestAllItem } as GetAllServiceCategoryRequest),
            productCategoryAsync({ query: requestAllItem } as GetAllProductCategoryRequest),
        ]);
        
        setDrawerState({
            isOpen: true,
            titleTransCode: 'Create new segment',
            content: (
                <SegmentForm
                    onSubmit={onCreate}
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                    serviceCategories={serviceCategories?.items}
                    productCategories={productCategories?.items}
                />
            ),
        });
    }, [onCreate, productCategoryAsync, refetch, resetDrawerState, serviceCategoryAsync, setDrawerState]
    );

    const columns: TableColumnsType<GetSegmentResponseItem> = [
        {
            title: 'Segment name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (_, record) => {
                return <Text 
                    cursor='pointer' 
                    color='#004EEB' 
                    fontSize={14} 
                    fontWeight={500}
                    onClick={()=>{handleViewDetail(record.id);}}
                >{record.name}</Text>;
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis>{record.description || '-' }</Text>;
            },
        },
        {
            title: 'Number of clients',
            dataIndex: 'numberOfClients',
            key: 'numberOfClients',
            render: (_, record) => {
                if(!record.status)
                {
                    return <LoadingOutlined />;
                }
                
                return <Text >{record.numberOfClients}</Text>;
            },
        },
        {
            title: 'Created date',
            dataIndex: 'creationTime',
            key: 'creationTime',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.creationTime), `${currentLocale?.format}`)} {}</Text>;
            },
        },
        {
            title: 'Created by',
            dataIndex: 'createBy',
            key: 'createBy',
            render: (_, record) => {
                const user = members.find(c => c.id === record.creatorId);
                return <FlexBox gap={12} alignItems='center'>
                    <Avatar size={32} shape='circle' src={user?.avatar} icon={<UserOutlined />} />
                    <Text>{user?.fullName}</Text>
                </FlexBox>;
            },
        },
        {
            title: t(translateCodes.ACTION),
            key: 'action',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={() => {
                            showModalDelete(record.id);
                        }}
                        onClickEdit={() => onShowModalEdit(record)}
                    />
                );
            },
        },
    ];

    return (
        <PageLayout
            pageTitleTransCode={t(translateCodes.CLIENT_SEGMENTS)}
            rightHeaderPage={
                <AppButton
                    icon={<PlusOutlined />}
                    translateCode={t(translateCodes.CREATE_SEGMENT)}
                    type='primary'
                    onClick={onShowModalCreate}
                />
            }
        >
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                loading={isFetching || isLoading || isServicePending || isProductPending}
                titleTableTransCode='Segment List'
                placeholderSearchTransCode='Search by segment name'
                columns={columns}
                dataSource={segmentsResponse?.items}
                pageInfo={segmentsResponse?.meta}
                emptyOption={{
                    title: t(translateCodes.ADD_SEGMENT),
                    description: t(translateCodes.EMPTY_SEGMENT_DESCRIPTION),
                    actionButton: (
                        <AppButton
                            icon={<PlusOutlined />}
                            translateCode={t(translateCodes.CREATE_SEGMENT)}
                            type='primary'
                            onClick={onShowModalCreate}
                        />
                    )
                }}
            />
        </PageLayout>
    );
}
