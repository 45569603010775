import { httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export interface TimeBlockDisableCreateRequest {
	readonly body: {
		readonly date: string;
		readonly employeeId: string;
		readonly timeBlockId: string;
	};
}

export interface TimeBlockDisableDetailResponse {
	readonly date: string;
	readonly timeBlockId: string;
	readonly employeeId: string;
}

export const createTimeBlockDisable = httpUtil.createHttpRequestMeta<
	TimeBlockDisableCreateRequest,
	void
>({
    method: 'POST',
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    authentication: 'bearer',
    path: '/api/v1/time-block-disables',
});
