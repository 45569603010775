import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface DeleteProductCategoryRequest extends HttpRequestData {
}

export const deleteProductCategory = httpUtil.createHttpRequestMeta<
    DeleteProductCategoryRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/product-categories/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
