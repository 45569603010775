import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

interface GetServiceSummaryRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}
export interface GetServiceSummaryItem {
    id: string;
    name: string;
    quantity: number;
    discount: number;
    revenue: number;
}

export const getServicesSummaryRevenueReport = httpUtil.createHttpRequestMeta<
    GetServiceSummaryRequest,
    PaginationResponse<GetServiceSummaryItem>
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/revenue-report/service',
    authentication: 'bearer',
});