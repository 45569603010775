
export enum StatusEmailTemPlate {
    SystemTemPlate = 0,
    CustomTemplate = 1
}
export enum TypeEmailTemplatesEnum {
    AppointmentReminder = 0,
    NewAppointment = 1,
    RescheduledAppointment = 2,
    CancelledAppointment = 3,
    DidNotShowUp = 4,
    ThankYouForVisiting = 5,
    ReminderToRebook = 6,
    CelebrateBirthdays = 7,
    RewardLoyalClients = 8,
    WelcomeNewClients = 9,
    Notification = 10,
    Promotion = 11,
}
export enum TemplateVariableType
{
    BranchLocation = 0,
    BranchName = 1,
    TimeSinceLastVisit = 2,
    ClientFirstName = 3,
    ReservationServiceName = 4,
    ReviewLink = 5,
    ReservationDate = 6,
    ReservationStartTime = 7,
    EmployeeName = 8,
    ServiceDuration = 9,
    AppointmentStatus = 10,
    TransactionDate = 11,
    TransactionTime = 12,
    TransactionDetailLink = 13,
    PromotionExpirationDate = 14,
    PromotionDiscountValue = 15,
    PromotionName = 16,
    BranchPhoneNumber = 17,
    BookingLink = 18,
}
export enum OptionValueType
{
    Notification = 0,
    Promotion = 1
}