import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export interface BreakTimeUpdateRequest extends HttpRequestData {
	body: {
		readonly id: string;
		readonly date?: string;
		readonly endTime: string;
		readonly shiftId: string;
		readonly isFixed: boolean;
		readonly startTime: string;
		readonly dayOfWeek: number;
		readonly employeeId: string;
		readonly DayOfWeekTitle?: string;
	};
}

export const updateBreakTime = httpUtil.createHttpRequestMeta<
	BreakTimeUpdateRequest,
	void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/break-times/:id',
    method: 'PATCH',
    authentication: 'bearer',
});