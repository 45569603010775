import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface AppointmentServiceDetailUpdateRequest extends HttpRequestData {
    readonly body: {
        id: string;
        serviceId: string;
        facilityId: string;
    };
}

export const updateAppointmentServiceDetail = httpUtil.createHttpRequestMeta<
    AppointmentServiceDetailUpdateRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'PATCH',
    path: '/api/v1/appointment-service-details/:id',
    authentication: 'bearer',
});