import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetProductClientDetailRequest extends HttpRequestData {
    readonly pathData: {
        readonly clientId: string;
    };
}

export interface GetProductClientDetailResponse {
    id?: string,
    productName?: string;
    productId?: string;
    quantity?: number;
    orderDate?: Date;
    saleId?: string;
    saleCode?: string;
    totalAmount?: number;
}

export const getProductClientDetailReport = httpUtil.createHttpRequestMeta<
    GetProductClientDetailRequest,
    PaginationResponse<GetProductClientDetailResponse>
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/clients-detail/product-sales/:clientId',
    authentication: 'bearer',
});