import React from 'react';

import { IconProps } from '../../Types';

export const IconReminderToRebook = (props: IconProps) => {
    return (
        <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z' fill='#FEF0C7'/>
            <path d='M52 37.3327H28M52 39.3327V35.7327C52 33.4925 52 32.3724 51.564 31.5167C51.1805 30.7641 50.5686 30.1521 49.816 29.7687C48.9603 29.3327 47.8402 29.3327 45.6 29.3327H34.4C32.1598 29.3327 31.0397 29.3327 30.184 29.7687C29.4314 30.1521 28.8195 30.7641 28.436 31.5167C28 32.3724 28 33.4925 28 35.7327V46.9327C28 49.1729 28 50.293 28.436 51.1486C28.8195 51.9013 29.4314 52.5132 30.184 52.8967C31.0397 53.3327 32.1598 53.3327 34.4 53.3327H40.6667M45.3333 26.666V31.9993M34.6667 26.666V31.9993M47.3301 44.9486C46.3971 43.9097 44.8412 43.6303 43.6722 44.5816C42.5032 45.5329 42.3386 47.1233 43.2566 48.2484C44.1747 49.3735 47.3301 51.9993 47.3301 51.9993C47.3301 51.9993 50.4856 49.3735 51.4036 48.2484C52.3217 47.1233 52.1772 45.5228 50.9881 44.5816C49.799 43.6403 48.2632 43.9097 47.3301 44.9486Z' stroke='#DC6803' strokeWidth='1.67' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};
