import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';
import { GetCustomerDetailResponse } from './getCustomerDetail';

interface CreateCustomerRequest extends HttpRequestData {
    readonly body: {
        readonly firstName: string;
        readonly lastName?: string;
        readonly email?: string;
        readonly avatar?: string;
        readonly phone?: string;
        readonly dateOfBirth?: string;
        readonly gender?: number;
        readonly address?: string;
        readonly referredByType?: number;
        readonly employeeReferralId?: string;
        readonly customerReferralId?: string;
        readonly note?: string;
        readonly customerGroupId?: string;
        readonly customerSourceId?: string;
    };
}

export const createCustomer = httpUtil.createHttpRequestMeta<
    CreateCustomerRequest,
    GetCustomerDetailResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customers',
    method: 'POST',
    authentication: 'bearer',
});
