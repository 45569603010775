import { HttpRequestData, httpUtil } from '@esg/framework';

import { ProductUnitType } from '@esg/shared/enum';
import { getEnv } from '@esg/shared';
import { GetAllProductUnitResponseItem } from './getAllProductUnit';

interface CreateProductUnitRequest extends HttpRequestData {
    readonly body: {
        name: string;
        type: ProductUnitType;
    };
}

export const createProductUnit = httpUtil.createHttpRequestMeta<
    CreateProductUnitRequest,
    GetAllProductUnitResponseItem
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/product-units',
    method: 'POST',
    authentication: 'bearer',
});
