import { OptionValueType, TemplateVariableType, TypeEmailTemplatesEnum } from '@esg/shared';

export const variableEventThankYourForVisitingMockData = [
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.ReviewLink,
    TemplateVariableType.TransactionDate,
    TemplateVariableType.TransactionTime,
    TemplateVariableType.BranchName,
    TemplateVariableType.TransactionDetailLink,
];

export const variableEventCelebrateBirthdayMockData = [
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.BookingLink,
    TemplateVariableType.BranchName,
    TemplateVariableType.PromotionName,
    TemplateVariableType.PromotionDiscountValue,
    TemplateVariableType.PromotionExpirationDate,
];

export const variableEventAppointmentReminderMockData = [
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.ReservationServiceName,
    TemplateVariableType.EmployeeName,
    TemplateVariableType.BranchName,
    TemplateVariableType.BranchLocation,
    TemplateVariableType.ReservationDate,
    TemplateVariableType.ReservationStartTime,
    TemplateVariableType.BranchPhoneNumber,
];
export const variableEventNewAppointmentMockData = [
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.AppointmentStatus,
    TemplateVariableType.ReservationServiceName,
    TemplateVariableType.EmployeeName,
    TemplateVariableType.BranchName,
    TemplateVariableType.BranchLocation,
    TemplateVariableType.ReservationDate,
    TemplateVariableType.ReservationStartTime,
    TemplateVariableType.BranchPhoneNumber,
];
export const variableEventRescheduledAppointmentMockData = [
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.BranchName,
    TemplateVariableType.ReservationServiceName,
    TemplateVariableType.EmployeeName,
    TemplateVariableType.BranchLocation,
    TemplateVariableType.ReservationDate,
    TemplateVariableType.ReservationStartTime,
    TemplateVariableType.BranchPhoneNumber,
];
export const variableEventCancelledAppointmentMockData = [
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.BranchName,
    TemplateVariableType.ReservationServiceName,
    TemplateVariableType.EmployeeName,
    TemplateVariableType.BranchLocation,
    TemplateVariableType.ReservationDate,
    TemplateVariableType.ReservationStartTime,
    TemplateVariableType.BranchPhoneNumber,
];

export const variableEventDidNotShowUpMockData = [
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.ReservationServiceName,
    TemplateVariableType.EmployeeName,
    TemplateVariableType.BranchName,
    TemplateVariableType.BranchLocation,
    TemplateVariableType.ReservationDate,
    TemplateVariableType.ReservationStartTime,
    TemplateVariableType.BranchPhoneNumber,
    TemplateVariableType.BookingLink,
];
export const variableEventReminderToRebookMockData = [
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.BookingLink,
    TemplateVariableType.BranchName,
    TemplateVariableType.TimeSinceLastVisit,
];
export const variableEventRewardLoyalClientMockData = [
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.BookingLink,
    TemplateVariableType.BranchName,
    TemplateVariableType.PromotionName,
    TemplateVariableType.PromotionDiscountValue,
    TemplateVariableType.PromotionExpirationDate,
];
export const variableEventWelcomeNewClientMockData = [
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.BookingLink,
    TemplateVariableType.BranchName,
    TemplateVariableType.PromotionName,
    TemplateVariableType.PromotionDiscountValue,
    TemplateVariableType.PromotionExpirationDate,
];

export const variableEventNotificationMockData = [
    TemplateVariableType.BranchName,
    TemplateVariableType.BranchLocation,
    TemplateVariableType.BranchPhoneNumber,
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.BookingLink,
];

export const variableEventMockData = [
    TemplateVariableType.BranchName,
    TemplateVariableType.BranchLocation,
    TemplateVariableType.BranchPhoneNumber,
    TemplateVariableType.ClientFirstName,
    TemplateVariableType.BookingLink,
    TemplateVariableType.PromotionName,
    TemplateVariableType.PromotionDiscountValue,
    TemplateVariableType.PromotionExpirationDate,
];

export const renderTriggerEventOptions = (type: TypeEmailTemplatesEnum): TemplateVariableType[] => {
    const addonMap: Record<TypeEmailTemplatesEnum, TemplateVariableType[]> = {
        [TypeEmailTemplatesEnum.ThankYouForVisiting]: variableEventThankYourForVisitingMockData,
        [TypeEmailTemplatesEnum.CelebrateBirthdays]: variableEventCelebrateBirthdayMockData,
        [TypeEmailTemplatesEnum.AppointmentReminder]: variableEventAppointmentReminderMockData,
        [TypeEmailTemplatesEnum.NewAppointment]: variableEventNewAppointmentMockData,
        [TypeEmailTemplatesEnum.RescheduledAppointment]: variableEventRescheduledAppointmentMockData,
        [TypeEmailTemplatesEnum.CancelledAppointment]: variableEventCancelledAppointmentMockData,
        [TypeEmailTemplatesEnum.DidNotShowUp]: variableEventDidNotShowUpMockData,
        [TypeEmailTemplatesEnum.ReminderToRebook]: variableEventReminderToRebookMockData,
        [TypeEmailTemplatesEnum.RewardLoyalClients]: variableEventRewardLoyalClientMockData,
        [TypeEmailTemplatesEnum.WelcomeNewClients]: variableEventWelcomeNewClientMockData,
        [TypeEmailTemplatesEnum.Notification]: variableEventNotificationMockData,
        [TypeEmailTemplatesEnum.Promotion]: variableEventMockData,
    };
    return addonMap[type] || variableEventMockData;
};

export const renderTypeEventOptions = (OptionType: OptionValueType): TemplateVariableType[] => {
    const addonMap: Record<OptionValueType, TemplateVariableType[]> = {
        [OptionValueType.Notification]: variableEventNotificationMockData,
        [OptionValueType.Promotion]: variableEventMockData, 
    };
    return addonMap[OptionType] || variableEventMockData;
};

export const renderDescriptionTemplateVariable = (type: TemplateVariableType): string => {
    const descriptionMap: Record<TemplateVariableType, string> = {
        [TemplateVariableType.BranchLocation]: 'Branch Location',
        [TemplateVariableType.BranchName]: 'Branch Name',
        [TemplateVariableType.TimeSinceLastVisit]: 'Time since last visit (Reminder time)',
        [TemplateVariableType.ClientFirstName]: 'Client first name',
        [TemplateVariableType.ReservationServiceName]: 'Reserved service name',
        [TemplateVariableType.ReviewLink]: 'Review link',
        [TemplateVariableType.ReservationDate]: 'Reservation date',
        [TemplateVariableType.ReservationStartTime]: 'Reservation booking time',
        [TemplateVariableType.EmployeeName]: 'Employee Name',
        [TemplateVariableType.ServiceDuration]: 'Service Duration',
        [TemplateVariableType.AppointmentStatus]: 'Appointment status',
        [TemplateVariableType.TransactionDate]: 'Transaction Date',
        [TemplateVariableType.TransactionTime]: 'Transaction Time',
        [TemplateVariableType.TransactionDetailLink]: 'Transaction detail link',
        [TemplateVariableType.PromotionExpirationDate]: 'Promotion expiration date',
        [TemplateVariableType.PromotionDiscountValue]: 'Promotion discount value',
        [TemplateVariableType.PromotionName]: 'Promotion name',
        [TemplateVariableType.BranchPhoneNumber]: 'Branch phone number',
        [TemplateVariableType.BookingLink]: 'Booking link'
    };
    return descriptionMap[type] || 'No description available';
};

