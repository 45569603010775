import { Block, FlexBox, Text, useHttpQuery } from '@esg/ui';
import { Card } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MailOutlined } from '@ant-design/icons';
import { templateVariableGetAll } from '@esg/business-success-platform/api/template-variable.ts';
import { translateCodes } from '@/locales/translateCodes';
import '../AutomationEventForm.scss';

export interface EmailTemplatePreviewProps {
    bodyCustom?: string;
    subjectCustom?: string;
}

export function EmailTemplatePreview(props: EmailTemplatePreviewProps) {
    const { t } = useTranslation();
    const { data: templateVariables } = useHttpQuery(templateVariableGetAll,
        {
            query: {
                pageSize: 100
            }
        }
    );

    const templateVariableMap = useMemo(() =>
        templateVariables?.items?.filter(item => item.name && item.exampleValue)?.map(item => [item.name, item.exampleValue]) || [],
    [templateVariables?.items]
    );

    const variables = Object.fromEntries(templateVariableMap);

    const replaceVariables = (template: string, variables: { [key: string]: string }) => {
        let replacedTemplate = template;

        for (const variable in variables) {
            if (Object.prototype.hasOwnProperty.call(variables, variable)) {
                const regex = new RegExp(`{{${variable}}}`, 'g');
                replacedTemplate = replacedTemplate.replace(regex, variables[variable]);
            }
        }

        return replacedTemplate;
    };
    return (
        <Card 
            title={
                <Block>
                    <FlexBox gap={8}>
                        <MailOutlined/>
                        <Text fontWeight={500} translateCode={replaceVariables(props.subjectCustom?? '', variables)} />
                    </FlexBox>
                </Block>
            }
        >
           
            <Block className='body-email-template-container'>
                <Block>
                    <Text fontWeight={700}>{t(translateCodes.EMAIL_TEMPLATE_SUBJECT)}: </Text>
                    <Text>{replaceVariables(props.subjectCustom ?? '', variables)}</Text>
                </Block>
                <br />
                <div dangerouslySetInnerHTML={{ __html: replaceVariables(props.bodyCustom ?? '', variables) }} />
                <br />
            </Block>
                
        </Card>
    );
}
