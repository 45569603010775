import { Block, FlexBox, IconNotifyInfo, Spacer, Text } from '@esg/ui';
import React from 'react';
import { GetCurrentPageBookingSettingResponse } from '@esg/business-setting/api/page-booking';
import { getEnv } from '@esg/shared';

interface AlertBookingUrlProps {
    pageBookingSetting?: GetCurrentPageBookingSettingResponse;
}

export default function AlertBookingUrl(props: AlertBookingUrlProps) {
    const { pageBookingSetting } = props;
    const bookingUrl = `https://${pageBookingSetting?.bookingSlug ?? 'your-domain'}${getEnv().BOOKING_CLIENT}`;

    return (
        <Block backgroundColor='#EFF6FF'>
            <Spacer type='padding' top_bottom={12} left_right={24}>
                <FlexBox gap={12} alignItems='center'>
                    <IconNotifyInfo />
                    <Text color='#3685FB'>
                        Your booking website can be found at &nbsp;
                        <a href={bookingUrl} target='_blank' rel='noreferrer'>
                            {bookingUrl}
                        </a>
                    </Text>
                </FlexBox>
            </Spacer>
        </Block>
    );
}
