import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface ConfirmAppointmentRequest extends HttpRequestData {
    body: {
        id: string;
    };
}

export const confirmAppointment = httpUtil.createHttpRequestMeta<
    ConfirmAppointmentRequest,
    void
>({
    method: 'PATCH',
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    authentication: 'bearer',
    path: '/api/v1/appointments/:id/confirm',
});