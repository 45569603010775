import { GetCampaignResponseItem, GetClientCampaignResponseItem } from '@esg/business-management/api/campaign';
import {
    FlexBox,
    Spacer,
    TableCustom,
    Text,
    useHttpQuery,
    useLocalesFormat,
    usePagination
} from '@esg/ui';
import { Card, TableColumnsType } from 'antd';
import React from 'react';
import { dayjs } from '@esg/shared';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { managementApis } from '@esg/business-management';
import { CustomerColumnView } from '@/components/common';

interface ClientSegmentTableProps {
    values: GetCampaignResponseItem;
    clientSize: number;
}

export const ClientSegmentTable = (props: ClientSegmentTableProps) => {
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [searchParams, setSearchParams] = usePagination();

    const { data: customerSegmentsResponse, isLoading } = useHttpQuery(managementApis.getClientCampaign, {
        body: {
            target: props.values.target,
            targetValue: props.values.targetValue,
        },
        query: searchParams,
    });

    const clientOverview = () => {
        return (
            <Text><Text fontWeight={700} color='#101828'>{`${props.clientSize} clients `}</Text>match the trigger conditions</Text>
        );
    };

    const columns: TableColumnsType<GetClientCampaignResponseItem> = [
        {
            title: 'Client',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (_, record) => {
                return (<CustomerColumnView customer={record}/>);
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => {
                return <Text >{record.email}</Text>;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => {
                return <Text >{record?.phone}</Text>;
            },
        },
        {
            title: 'Added date',
            dataIndex: 'addedDate',
            key: 'addedDate',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.creationTime), `${currentLocale?.format}`)} { }</Text>;
            },
        }
    ];

    return (
        <FlexBox direction='column' gap={24} >
            <Spacer/>
            <Card>
                <TableCustom
                    onPageChange={(page) =>
                        setSearchParams({ ...searchParams, page: page.toString() })
                    }
                    onSearch={(value) =>
                        setSearchParams({ ...searchParams, search: value })
                    }
                    loading={isLoading}
                    placeholderSearchTransCode='Search by client name'
                    columns={columns}
                    dataSource={customerSegmentsResponse?.items}
                    pageInfo={customerSegmentsResponse?.meta}
                    leftHeaderRender={clientOverview}
                />
            </Card>
        </FlexBox>
    );
};