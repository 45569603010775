import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { CancelReasonGetAllResponseItem } from './getAllCancelReason';

interface CreateCancelReasonRequest extends HttpRequestData {
    body: {
        readonly name?: string;
    };
}

export const createCancelReason = httpUtil.createHttpRequestMeta<
    CreateCancelReasonRequest,
    CancelReasonGetAllResponseItem
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/cancel-reasons',
    method: 'POST',
    authentication: 'bearer',
});
