import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { GetDetailSaleResponse } from './getDetailSale';

interface CreateSaleRequest extends HttpRequestData {
    body: {
        readonly branchId?: string;
        readonly customerId?: string;
    };
}

export const createSale = httpUtil.createHttpRequestMeta<
    CreateSaleRequest,
    GetDetailSaleResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/sales',
    method: 'POST',
    authentication: 'bearer',
});
