import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/ui';
import { getEnv } from '@esg/shared';
import { CustomerResponse, PromotionApplyResponse } from '../campaign';
import { CustomerPromotionStatus } from './enum';

export interface getCustomerPromotionByCustomerRequest extends HttpRequestData {
    pathData: {
        readonly customerId: string;
    };
    query: PaginationRequest;
}

export interface CustomerPromotionByCustomerResponseItem {
    id: string;
    sourceId?: string;
    sourceCode: string;
    promotionApplyId: string;
    promotionApply?: PromotionApplyResponse;
    branchId: string;
    customerId: string;
    status: CustomerPromotionStatus;
    customer?: CustomerResponse;
    creationTime: string;
}

export const getCustomerPromotionByCustomer = httpUtil.createHttpRequestMeta<
    getCustomerPromotionByCustomerRequest,
    PaginationResponse<CustomerPromotionByCustomerResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-promotions/by-customer/:customerId',
    method: 'GET',
    authentication: 'bearer',
});