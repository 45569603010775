import './FormLayout.scss';
import React from 'react';

import { Divider } from 'antd';
import { Text } from '@esg/ui';
import { translateCodes } from '../../locales';
import { Block } from '../layouts';
import { ModalFilterFooter } from './children/ModalFilterFooter';

interface FormModalFilterProps {
    readonly titleTranslateCodes?: string;
    readonly children: React.ReactNode;
    readonly isLoadingButton?: boolean;
    readonly onCancel?: () => void;
    readonly onReset?: () => void;
}

export const FormModalFilter = (props: FormModalFilterProps) => {
    return (
        <Block className='form-layout app-filter-model'>
            {
                props.titleTranslateCodes &&
                <Block padding='13px 24px 13px 24px'>
                    <Text fontWeight={700} fontSize={18}>{props.titleTranslateCodes}</Text>
                </Block>
            }
            {
                props.titleTranslateCodes &&  <Divider style={{margin: 0}}/>
            }
            <Block className='form-body'>
                {props.children}
            </Block>
            <Divider  style={{margin: 0}}/>
            <ModalFilterFooter
                isLoadingButton={props.isLoadingButton}
                onCancel={props.onCancel}
                onReset={props.onReset}
            />
        </Block>
    );
};

FormModalFilter.defaultProps = {
    cancelTextCode: translateCodes.CANCEL,
};

