import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest } from '@esg/ui/Types';
import { getEnv } from '@esg/shared';
import { CustomerInfo } from '../customer-segment';
import { CampaignChannelItem } from './getAllCampaign';
import { CampaignType, CampaignTarget, CampaignStatus, CampaignScheduleType } from './enum';

export interface GetClientCampaignRequest extends HttpRequestData {
    body: {
        id?: string;
        name?: string;
        type?: CampaignType;
        target?: CampaignTarget;
        status?: CampaignStatus;
        targetValue?: string[];
        promotionId?: string;
        dateApply?: string;
        timeApply?: string;
        campaignChannels?: CampaignChannelItem[];
        scheduleType?: CampaignScheduleType;
    };
    query: PaginationRequest;
}

export const getClientCampaign = httpUtil.createHttpRequestMeta<
    GetClientCampaignRequest,
    PaginationResponse<GetClientCampaignResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/campaigns/client',
    method: 'POST',
    authentication: 'bearer',
});

export interface GetClientCampaignResponseItem {
    id: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    customerCode?: string;
    avatar?: string;
    phone?: string;
    email?: string;
    customerLabel?: number;
    creationTime?: string;
    customerReferral?: CustomerInfo;
}