import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface DeleteProductRequest extends HttpRequestData {
}

export const deleteProduct = httpUtil.createHttpRequestMeta<
    DeleteProductRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/products/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
