
import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';
import { Dayjs } from 'dayjs';

export interface IWorkingTimeItem {
    readonly isDayOff: boolean;
    readonly startTime: string | Dayjs;
    readonly endTime: string | Dayjs;
    readonly dayOfWeek: number;
    readonly dayOfWeekTitle: string;
    readonly breakTimes?: IBreakTimes[];
}

export interface IBreakTimes {
    readonly startTime: Dayjs;
    readonly endTime: Dayjs;
    readonly dayOfWeek: number;
    readonly dayOfWeekTitle: string;
    readonly isFixed?: boolean;
}

export type IWorkingTime = IWorkingTimeItem[];
export interface BranchGetAllRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface BranchGetAllResponseItem {
    readonly id: string;
    readonly name: string;
    readonly logo?: string;
    readonly countryCode?: string;
    readonly phoneNumber?: string;
    readonly currency?: string;
    readonly tax?: string;
    readonly dateTimeFormat?: string;
    readonly timezone?: string;
    readonly languages?: string;
    readonly address: string;
    readonly city: string;
    readonly state: string;
    readonly postalCode: string;
    readonly country: string;
    readonly organizationId: string;
    readonly businessHour: IWorkingTime;
}

export const getAllBranches = httpUtil.createHttpRequestMeta<
    BranchGetAllRequest,
    PaginationResponse<BranchGetAllResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'GET',
    path: '/api/v1/branches',
    authentication: 'bearer',
});