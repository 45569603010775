import { TableCustom, Text, globalDrawerState, useHttpQuery, useLocalesFormat, usePagination } from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import { Dayjs } from '@esg/shared';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { getSalesByClientItem, getSalesByClientReport } from '@esg/business-report';
import { useTranslation } from 'react-i18next';
import {ClientDetailTable} from './ClientDetailTable';
import { translateCodes } from '@/locales';
import { CustomerColumnView } from '@/components';

interface SalesByClientTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function SalesByClientTableTable( props: SalesByClientTableProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const [, setDrawerGlobalState] = useRecoilState(globalDrawerState);
    const { formatCurrency } = useLocalesFormat();

    const [searchParams, setSearchParams] = usePagination({
        pageSize: '10',
        orderBy: 'id'
    });
    
    const {data: saleByClient, isFetching} = useHttpQuery(getSalesByClientReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            ...searchParams
        },
    });

    const viewDetail = useCallback((customerId: string) => {
        setDrawerGlobalState({
            titleTransCode: 'View Detail',
            isOpen: true,
            hideHeader: true,
            content: (
                <ClientDetailTable
                    dateRange={dateRange}
                    customerId={customerId}
                />
            ),
            footer: null,
        });
    },[dateRange, setDrawerGlobalState]);

    const columns: TableColumnsType<getSalesByClientItem> = useMemo(()=>([
        {
            key: 'client',
            title: t(translateCodes.CLIENT),
            dataIndex: 'client',
            render: (_, record) => {
                return <CustomerColumnView customer={record.customer} />;
            },
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.orderDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, orderDirection: order });
                }
            }),
        },
        {
            title: t(translateCodes.TOTAL_SALE),
            dataIndex: 'totalSale',
            key: 'totalSale',
        },
        {
            title: t(translateCodes.NUMBER_OF_SERVICE),
            dataIndex: 'totalService',
            key: 'totalService',
        },
        {
            title: t(translateCodes.NUMBER_OF_PRODUCT),
            dataIndex: 'totalProduct',
            key: 'totalProduct',
        },
        {
            title: t(translateCodes.TOTAL_DISCOUNT),
            dataIndex: 'totalDiscount',
            key: 'totalDiscount',
            render: (_, record) => {
                return (
                    <Text>
                        {formatCurrency(record.totalDiscount)}
                    </Text>
                );
            },
        },
        {
            title: t(translateCodes.TOTAL_AMOUNT),
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (_, record) => {
                return (
                    <Text>
                        {formatCurrency(record.totalAmount)}
                    </Text>
                );
            },
        },
        {
            title: t(translateCodes.LINKS),
            key: 'links',
            dataIndex: 'links',
            width: 150,
            render: (_, record) => {
                return (
                    <Tag className='cursor-pointer' onClick={()=>viewDetail(record.customer.id)}>View Detail</Tag>
                );
            }
        },
    ]),[formatCurrency, searchParams, setSearchParams, t, viewDetail]);

    return (
        <TableCustom
            titleTableTransCode={translateCodes.SALES_BY_CLIENT}
            loading={isFetching}
            columns={columns}
            dataSource={saleByClient?.items}
            pageInfo={saleByClient?.meta}
            placeholderSearchTransCode={translateCodes.SEARCH_PLACEHOLDER}
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, page: page.toString() })
            }
            onSearch={(value) =>
                setSearchParams({ ...searchParams, search: value })
            }
        />
    );
}
