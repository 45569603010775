import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetOverviewChartClientRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export interface GetOverviewChartClientResponse {
    overviewChartInfos: ClientReportOverview[];
}

interface ClientReportOverview {
    date: string;
    newClient: number;
    retentionClient: number;
    walkInClient: number;
}

export const getOverviewChartClientReport = httpUtil.createHttpRequestMeta<
    GetOverviewChartClientRequest,
    GetOverviewChartClientResponse
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/clients-report/overview-chart',
    authentication: 'bearer',
});