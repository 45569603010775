import { FlexBox, FormBase, FormLayoutModal, InputField, useHttpCommand } from '@esg/ui';
import React from 'react';
import { emailTemplateApi } from '@esg/business-success-platform';
import { EmailTemplateSendTestMailResponseItem } from '@esg/business-success-platform/api/email-template/emailTemplateSendTestMail';
import { viewRequests } from '@esg/framework';
import { translateCodes } from '@/locales/translateCodes';

interface SendMailTestFormFormValues {
    email: string;
    emailTemplate?: EmailTemplateSendTestMailResponseItem;
}
interface SendMailTestFormProps {
    defaultValues?: SendMailTestFormFormValues;
    onCancel?: () => void;
}
export function SendMailTestForm(props: SendMailTestFormProps) {
    const { mutateAsync: updateEmailTemplate } = useHttpCommand(
        emailTemplateApi.emailTemplateSendTestMail
    );
    const onSubmit = React.useCallback(
        async (values: SendMailTestFormFormValues) => {

            await updateEmailTemplate(
                {
                    body: {
                        email: values.email,
                        emailTemplate: {
                            body: values.emailTemplate?.body,
                            subject: values.emailTemplate?.subject,
                            bodyCustom: values.emailTemplate?.bodyCustom,
                            subjectCustom: values.emailTemplate?.subjectCustom,
                        },
                    },
                },
                {
                    onSuccess: () => {
                        props.onCancel?.();
                        viewRequests.showNotification.send({
                            type: 'success',
                            message: 'Send test mail success',
                        });
                    },
                }
            );
        },
        [props, updateEmailTemplate]
    );
    return (
        <FormBase onSubmit={onSubmit} defaultValues={props.defaultValues}>
            {() => (
                <FormLayoutModal
                    useSubmitButton={true}
                    onCancel={props.onCancel}
                    okTextCode='Send'
                >
                    <FlexBox direction='column' gap={16} alignItems='flex-start'>
                        <InputField
                            name='email'
                            label={translateCodes.EMAIL}
                            placeholder='Enter your email'
                        />
                    </FlexBox>
                </FormLayoutModal>
            )}
        </FormBase>
    );
}
