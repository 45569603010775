export enum CategoryExternalTransaction {
    Appointment = 0,
    Sale = 1,
    Campaign = 2,
    CustomerCelebrateBirthday = 3,
}

export enum TypeExternalTransaction {
    Mail = 0,
    Sms = 1,
}

export enum ActionExternalTransaction {
    Reminder = 0,
    Confirm = 1,
    Checkout = 3,
}

export enum CodeExternalTransaction {
    AppointmentAutomation = 0,
    PromotionCampaign = 1,
    NotificationCampaign = 2,
    SaleAutomation = 3,
    CustomerCelebrateBirthdayAutomation = 4,
}

export enum StatusExternalTransaction {
    Pending = 0,
    Processing = 1,
    Success = 2,
    Failed = 3,
    Scheduled = 4,
}