import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { BreakTimeGetDetailResponse } from '../break-time';

export interface UpdateShiftsRequest extends HttpRequestData {
	readonly body: {
		readonly id: string;
		readonly date?: string;
		readonly endTime: string;
		readonly employeeId: string;
		readonly startTime: string;
		readonly isDayOff: boolean;
		readonly breakTimes: BreakTimeGetDetailResponse[];
	};
}

export const updateShifts = httpUtil.createHttpRequestMeta<
	UpdateShiftsRequest,
	void
>({
    method: 'PATCH',
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    authentication: 'bearer',
    path: '/api/v1/shifts/:id',
});
