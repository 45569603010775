import React from 'react';

import { IconProps } from '../../Types';

export const IconPromotion = (props: IconProps) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path fillRule='evenodd' clipRule='evenodd' d='M14.0137 17L14.0079 19.0029C14.0065 19.4731 14.0058 19.7081 13.8591 19.8541C13.7124 20 13.4767 20 13.0054 20H9.99502C6.21438 20 4.32407 20 3.14958 18.8284C2.34091 18.0218 2.08903 16.8766 2.01058 15.0105C1.99502 14.6405 1.98724 14.4554 2.05634 14.332C2.12545 14.2085 2.40133 14.0545 2.95308 13.7463C3.56586 13.4041 3.98007 12.7503 3.98007 12C3.98007 11.2497 3.56586 10.5959 2.95308 10.2537C2.40133 9.94554 2.12545 9.79147 2.05634 9.66802C1.98724 9.54458 1.99502 9.35954 2.01058 8.98947C2.08903 7.12339 2.34091 5.97823 3.14958 5.17157C4.32407 4 6.21439 4 9.99502 4H13.5052C13.7814 4 14.0056 4.22298 14.0064 4.49855L14.0137 7C14.0137 7.55228 14.4625 8 15.0162 8L15.0162 10C14.4625 10 14.0137 10.4477 14.0137 11V13C14.0137 13.5523 14.4625 14 15.0162 14V16C14.4625 16 14.0137 16.4477 14.0137 17Z' fill='#3D8CF5' />
            <path opacity='0.8' d='M15.0156 15.9998C15.5693 15.9998 16.0181 16.4475 16.0181 16.9998V18.9763C16.0181 19.4578 16.0181 19.6986 16.1725 19.8462C16.3269 19.9939 16.5632 19.9839 17.0356 19.9639C18.8986 19.885 20.0431 19.633 20.8499 18.8282C21.6585 18.0216 21.9104 16.8764 21.9889 15.0104C22.0044 14.6403 22.0122 14.4552 21.9431 14.3318C21.874 14.2083 21.5981 14.0543 21.0464 13.7462C20.4336 13.404 20.0194 12.7501 20.0194 11.9998C20.0194 11.2495 20.4336 10.5957 21.0464 10.2535C21.5981 9.94536 21.874 9.7913 21.9431 9.66785C22.0122 9.5444 22.0044 9.35936 21.9889 8.98929C21.9104 7.12321 21.6585 5.97805 20.8499 5.17139C19.9721 4.29586 18.6947 4.07463 16.5272 4.01872C16.2476 4.01151 16.0181 4.237 16.0181 4.516V6.99982C16.0181 7.55211 15.5693 7.99982 15.0156 7.99982L15.0156 9.99982C15.5693 9.99982 16.0181 10.4475 16.0181 10.9998V12.9998C16.0181 13.5521 15.5693 13.9998 15.0156 13.9998V15.9998Z' fill='#ACCEFC' />
        </svg>
    );
};

