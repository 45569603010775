import './SaleFrom.scss';

import { Block, FlexBox, FormBase, FormLayout, globalModalState, useHttpCommand } from '@esg/ui';
import { CreateSaleDetailRequest, GetDetailSaleResponse, SaleDetailGetAllResponseItem, SaleStatus, UnitEnum, createSaleDetail, deleteSaleDetail, getDetailSale, updateSale, updateSaleDetail } from '@esg/business-pos';
import { GetAllServiceCategoryResponseItem, managementApis } from '@esg/business-management';

import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetAllProductCategoryResponseItem } from '@esg/business-management/api/product-category';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { updateMultipleSaleDetail } from '@esg/business-pos/api/sale-detail/updateMultipleSaleDetail';
import { SaleOrder } from './children/sale-order/SaleOrder';
import MenuOrder from './children/menu-order/MenuOrder';
import InitSaleForm from './InitSaleForm';
import { MultipleSaleDetailSubmit } from './EditMultipleSaleDetailForm';
import { currentSaleState } from '@/states';
import { saleHelper } from '@/helpers';
import { translateCodes } from '@/locales';

export interface InitDataSaleForm {
    serviceCategories?: GetAllServiceCategoryResponseItem[];
    productCategories?: GetAllProductCategoryResponseItem[];
}

interface SaleFormProps {
    initDataSaleForm?: InitDataSaleForm;
}

export default function SaleForm(props: SaleFormProps) {
    const { t } = useTranslation();
    const resetModalState = useResetRecoilState(globalModalState);
    const [currentSale, setCurrentSale] = useRecoilState(currentSaleState);
    const salePromotion = currentSale?.salePromotions?.[0];
    const [showMenu, setShowMenu] = React.useState<boolean>(true); 

    const { mutateAsync: deleteSaleDetailMutate, isPending: loadingDelete } = useHttpCommand(deleteSaleDetail, {
        onSuccess: (response) => {
            if (salePromotion) {
                return getSaleDetailMutate({
                    pathData: {
                        id: currentSale.id,
                    },
                });
            }
            const saleDetails = currentSale?.saleDetails?.filter(o => o.id !== response?.id) ?? [];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.MM_FP_03),
            });
        }
    });

    const { mutateAsync: updateSaleDetailMutate, isPending: loadingUpdate } = useHttpCommand(updateSaleDetail, {
        onSuccess: (response) => {
            resetModalState();
            if (salePromotion) {
                return getSaleDetailMutate({
                    pathData: {
                        id: currentSale.id,
                    },
                });
            }
            const saleDetails = currentSale?.saleDetails?.map(o => o.id === response?.id ? response : o) ?? [];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.MM_FP_02),
            });
        }
    });

    const { mutateAsync: createSaleDetailMutate, isPending: loadingCreate } = useHttpCommand(createSaleDetail, {
        onSuccess: (response) => {
            if (salePromotion) {
                return getSaleDetailMutate({
                    pathData: {
                        id: currentSale.id,
                    },
                });
            }
            const saleDetails = [...(currentSale?.saleDetails ?? []), response];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
        }
    });

    const { mutateAsync: updateSaleMutate } = useHttpCommand(updateSale, {
        onSuccess: (response) => {
            setCurrentSale(response);
        }
    });

    const { mutateAsync: onUpdateMultipleSaleDetailMutate, isPending: isUpdateMultipleLoading } = useHttpCommand(updateMultipleSaleDetail, {
        onSuccess: (response) => {
            const saleDetails = response?.saleDetails ?? [];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
            resetModalState();
        }
    });

    const { mutateAsync: onCreateSalePromotionMutate, isPending: loadingPromotion } = useHttpCommand(managementApis.createSalePromotion, {
        onSuccess: (response) => {
            setCurrentSale(response);
        }
    });

    const { mutateAsync: deleteSalePromotionMutate, isPending: loadingDeletePromotion } = useHttpCommand(managementApis.deleteSalePromotion, {
        onSuccess: (response) => {
            setCurrentSale(response);
        }
    });

    const { mutateAsync: getSaleDetailMutate, isPending: isLoadingSale } = useHttpCommand(getDetailSale,
        {
            onSuccess: (response) => {
                setCurrentSale(response);
            }
        }
    );

    const onUpdateSaleDetail = useCallback(async (saleDetail: SaleDetailGetAllResponseItem) => {
        await updateSaleDetailMutate({ pathData: { id: saleDetail.id }, body: saleDetail });
    }, [updateSaleDetailMutate]);

    const onCreateSaleDetail = useCallback(async (saleDetailValue: CreateSaleDetailRequest['body']) => {
        await createSaleDetailMutate({ body: saleDetailValue });
    }, [createSaleDetailMutate]);

    const onUpdateSale = useCallback(async (sale: GetDetailSaleResponse) => {
        await updateSaleMutate({ pathData: { id: sale.id }, body: sale });
    }, [updateSaleMutate]);

    const onDeleteSaleDetail = useCallback(async (id: string) => {
        await deleteSaleDetailMutate({ pathData: { id } });
    }, [deleteSaleDetailMutate]);

    const handleUpdateMultipleSaleDetail = useCallback(async (body: MultipleSaleDetailSubmit) => {
        await onUpdateMultipleSaleDetailMutate({ pathData: { id: body.saleId }, body: body });
    }, [onUpdateMultipleSaleDetailMutate]);

    const handleCreateSalePromotion = useCallback(async (customerPromotionId: string) => {
        await onCreateSalePromotionMutate({
            body: {
                saleId: currentSale?.id,
                customerPromotionId: customerPromotionId
            }
        });
    }, [currentSale?.id, onCreateSalePromotionMutate]);

    const handleDeleteSalePromotion = useCallback(async (id: string) => {
        await deleteSalePromotionMutate({ pathData: { id: id } });
    }, [deleteSalePromotionMutate]);

    const arrowRightIcon = '/assets/layout/icons/Sider_Arrow_Right_Icon.svg';
    const arrowLeftIcon = '/assets/layout/icons/Sider_Arrow_Left_Icon.svg';

    return (
        <FormBase onSubmit={() => {}} 
            defaultValues={{
                ...currentSale,
                tipUnit: currentSale?.tipUnit ??  UnitEnum.Percent,
            }}
        >
            <FormLayout
                className='sale-form'
                width={(showMenu && currentSale?.status === SaleStatus.Unpaid) ? '66vw' : '33vw'}
                footer={<></>}
            >
                <InitSaleForm>
                    <FlexBox className='sale-container' >
                        {currentSale?.status === SaleStatus.Unpaid && (
                            <>
                                <Block cursor='pointer' position='fixed' right={showMenu ? 'calc(66vw - 11px)' : 'calc(33vw - 11px)'} top={24} zIndex={999}>
                                    <img
                                        src={showMenu ? arrowRightIcon : arrowLeftIcon}
                                        onClick={() => setShowMenu(!showMenu)}
                                        alt={showMenu ? 'hide' : 'show'}
                                    />
                                </Block>
                                <MenuOrder
                                    showMenu={showMenu}
                                    initDataSaleForm={props.initDataSaleForm}
                                    updateSaleDetail={onUpdateSaleDetail}
                                    createSaleDetail={onCreateSaleDetail}
                                />
                            </>
                        )}
                        <SaleOrder
                            isLoading={
                                loadingDelete || loadingUpdate || loadingCreate || isUpdateMultipleLoading 
                                || loadingPromotion || loadingDeletePromotion || isLoadingSale
                            }
                            handleUpdateSale={onUpdateSale}
                            handleDeleteSaleDetail={onDeleteSaleDetail}
                            handleUpdateSaleDetail={onUpdateSaleDetail}
                            handleUpdateMultipleSaleDetail={handleUpdateMultipleSaleDetail}
                            handleCreateSalePromotion={handleCreateSalePromotion}
                            handleDeleteSalePromotion={handleDeleteSalePromotion}
                        />
                    </FlexBox>
                </InitSaleForm>
            </FormLayout>
        </FormBase>
    );
}
