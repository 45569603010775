import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface UpdatePaymentMethodRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        isActive: boolean;
    };
}

export interface UpdatePaymentMethodResponse {
    id: string;
    name: string;
    isActive: boolean;
}

export const updatePaymentMethod = httpUtil.createHttpRequestMeta<
    UpdatePaymentMethodRequest,
    UpdatePaymentMethodResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/payment-method-settings/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
