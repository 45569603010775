import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { GetDetailSaleResponse } from './getDetailSale';

interface SaleGetAllRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export const getAllSale = httpUtil.createHttpRequestMeta<
    SaleGetAllRequest,
    PaginationResponse<GetDetailSaleResponse>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'GET',
    path: '/api/v1/sales',
    authentication: 'bearer',
});