import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetOverviewClientRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export interface GetOverviewClientResponse {
    totalClient: number;
    newClient: number;
    retentionClient: number;
    walkInClient: number;
    totalRevenue: number;
}

export const getOverviewClientReport = httpUtil.createHttpRequestMeta<
    GetOverviewClientRequest,
    GetOverviewClientResponse
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/clients-report/overview',
    authentication: 'bearer',
});