import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetTotalNewClientsAppointmentRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export interface GetTotalNewClientsAppointmentResponse {
    readonly totalClientsNew: number;
}

export const getTotalNewClientsAppointment = httpUtil.createHttpRequestMeta<
    GetTotalNewClientsAppointmentRequest,
    GetTotalNewClientsAppointmentResponse
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/appointments-report/total-new-clients',
    authentication: 'bearer',
});