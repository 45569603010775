import { getAllSale } from '@esg/business-pos';
import { createService, deleteService, getAllServices } from './service';
import { updateService } from './service/updateService';
import {
    GetAllServiceCategoryResponseItem,
    createServiceCategory,
    deleteServiceCategory,
    getAllServiceCategory,
    updateServiceCategory
} from './service-category';
import { createProductCategory, getAllProductCategory, deleteProductCategory, updateProductCategory } from './product-category';
import { createProduct, getAllProduct, deleteProduct, updateProduct } from './product';
import { createProductUnit, getAllProductUnits, deleteProductUnit, updateProductUnit } from './unit';
import { createPackageCategory, updatePackageCategory, deletePackageCategory, getAllPackageCategory } from './package-category';
import { createPackage, deletePackage, getAllPackages, updatePackage } from './package';
import { getAllAppointmentActivities, getAllSaleActivities } from './activities';
import { createSegment, deleteSegment, getAllSegments, updateSegment, getDetailSegment } from './segment';
import { getAllCustomerSegments, deleteCustomerSegment, getAllCustomerForCreateSegments, createCustomerSegment } from './customer-segment';
import { getAllPromotion, getDetailPromotion, createPromotion, deletePromotion, updatePromotion } from './promotion';
import { getAllCampaign, getDetailCampaign, createCampaign, deleteCampaign, updateCampaign, saveDraftCampaign, 
    getClientCampaign, getConditionDetailCampaign, getOverviewClientCampaign, getCampaignOverviewPromotion,
    getCampaignCustomerPromotion 
} from './campaign';
import {getCustomerPromotionForSale} from './customer-promotion';
import { getAllExternal, getOverviewExternal } from './external';
import { createSalePromotion, deleteSalePromotion } from './sale-promotion';

export type {
    GetAllServiceCategoryResponseItem
};

export const managementApis = {
    getAllServices,
    createService,
    updateService,
    deleteService,
    createServiceCategory,
    getAllServiceCategory,
    updateServiceCategory,
    deleteServiceCategory,
    createProductCategory,
    getAllProductCategory,
    deleteProductCategory,
    updateProductCategory,
    createProduct,
    getAllProduct,
    deleteProduct,
    updateProduct,
    createProductUnit,
    getAllProductUnits,
    deleteProductUnit,
    updateProductUnit,
    createPackageCategory,
    updatePackageCategory,
    deletePackageCategory,
    getAllPackageCategory,
    createPackage,
    deletePackage,
    getAllPackages,
    updatePackage,
    getAllAppointmentActivities,
    getAllSaleActivities,
    getAllSale,
    getAllSegments,
    createSegment,
    updateSegment,
    deleteSegment,
    getDetailSegment,
    getAllCustomerSegments,
    deleteCustomerSegment,
    getAllPromotion,
    getDetailPromotion,
    createPromotion,
    deletePromotion,
    updatePromotion,
    getAllCampaign,
    getDetailCampaign,
    createCampaign,
    deleteCampaign,
    updateCampaign,
    saveDraftCampaign,
    getAllCustomerForCreateSegments,
    createCustomerSegment,
    getClientCampaign,
    getConditionDetailCampaign,
    getAllExternal,
    getOverviewExternal,
    getOverviewClientCampaign,
    getCampaignOverviewPromotion,
    getCampaignCustomerPromotion,
    getCustomerPromotionForSale,
    createSalePromotion, 
    deleteSalePromotion
};
