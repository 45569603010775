import { HttpRequestData, httpUtil } from '@esg/framework';

import { ProductCapacityType } from '@esg/shared/enum';
import { getEnv } from '@esg/shared';
import { GetAllProductResponseItem } from './getAllProduct';

interface UpdateProductRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        productCategoryId: string;
        productUnitId?: string;
        productCapacityValue?: number;
        productCapacityType?: ProductCapacityType;
        originalPrice: number;
        sellingPrice: number;
        taxSettingId?: string;
        description?: string;
    };
}

export const updateProduct = httpUtil.createHttpRequestMeta<
    UpdateProductRequest,
    GetAllProductResponseItem
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/products/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
