import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface DeleteEmailTemplateRequest extends HttpRequestData {
}

export const emailTemplateDelete = httpUtil.createHttpRequestMeta<
    DeleteEmailTemplateRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/email-templates/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
