import React from 'react';
import { IconProps } from '../../Types';

export const IconPromotionExpired = (props: IconProps) => {
    return (
        <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z' fill='#FEE4E2' />
            <path d='M28.5 28L33.5 33M33.5 28L28.5 33M24 27.5H19.5C18.1044 27.5 17.4067 27.5 16.8389 27.6722C15.5605 28.06 14.56 29.0605 14.1722 30.3389C14 30.9067 14 31.6044 14 33M26.5 19.5C26.5 21.9853 24.4853 24 22 24C19.5147 24 17.5 21.9853 17.5 19.5C17.5 17.0147 19.5147 15 22 15C24.4853 15 26.5 17.0147 26.5 19.5Z' stroke='#D92D20' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

