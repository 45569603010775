import { IconType } from 'antd/es/notification/interface';
import { translateCodes } from '@/locales';

export interface NotifyConstantsType {
    key: string;
    type: IconType;
    title: string;
    message: string;
}

export const notifyKey =
{
    SalePromotionInValid: 'SalePromotionInValid'
};

export const notifyType =
{
    success: 'success',
    info: 'info',
    error: 'error', 
    warning: 'warning'
};

export const notifyConstants = [
    { 
        key: notifyKey.SalePromotionInValid,  
        type: notifyType.warning, 
        title: translateCodes.PROMOTION_INVALID_ERROR_MESSAGE, 
        message: translateCodes.PROMOTION_INVALID_ERROR_MESSAGE, 
    },
] as NotifyConstantsType[];
