import { Block, FlexBox, globalDrawerState, globalModalState, IconAppointmentReminder, IconCalendar, IconCancelledAppointment, IconCelebrateBirthdays, IconDidNotShowUp, IconMail, IconNewAppointment, IconReminderToRebook, IconRescheduledAppointment, IconRewardLoyalClients, IconThankYouForVisiting, IconWelcomeNewClients, Spacer, Text, useHttpCommand } from '@esg/ui';
import { Card, Switch, Tag } from 'antd';
import React from 'react';
import '../AutomationEvent.scss';
import { AutomationEventChannelType, AutomationEventType } from '@esg/shared/enum/AutomationEnum';
import { automationEventApi, AutomationEventGetAllResponseItem } from '@esg/business-success-platform';
import { AutomationEventUpdateRequest } from '@esg/business-success-platform/api/automation-event/automationEventUpdate';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { AutomationEventActiveConfirm } from './AutomationEventActiveConfirm';
import { isImmediately, renderDescriptionItem, renderName, renderReminderTimeString, renderReminderTimeTypeCelebrateBirthday } from '@/helpers';
import { AUTOMATION_URL } from '@/configs';
import { AutomationEventForm, AutomationEventFormValues } from '@/components/form/automation-event';

export interface AutomationEventItemProps {
    item: AutomationEventGetAllResponseItem;
    onSuccess?: () => void;
}
export const renderIcon = (type: AutomationEventType, height?: number, width?: number) => {
    const iconMap: Record<AutomationEventType, JSX.Element> = {
        [AutomationEventType.AppointmentReminder]: <IconAppointmentReminder height={height} width={width} />,
        [AutomationEventType.NewAppointment]: <IconNewAppointment height={height} width={width} />,
        [AutomationEventType.RescheduledAppointment]: <IconRescheduledAppointment height={height} width={width} />,
        [AutomationEventType.CancelledAppointment]: <IconCancelledAppointment height={height} width={width} />,
        [AutomationEventType.DidNotShowUp]: <IconDidNotShowUp height={height} width={width} />,
        [AutomationEventType.ThankYouForVisiting]: <IconThankYouForVisiting height={height} width={width} />,
        [AutomationEventType.ReminderToRebook]: <IconReminderToRebook height={height} width={width} />,
        [AutomationEventType.CelebrateBirthdays]: <IconCelebrateBirthdays height={height} width={width} />,
        [AutomationEventType.RewardLoyalClients]: <IconRewardLoyalClients height={height} width={width} />,
        [AutomationEventType.WelcomeNewClients]: <IconWelcomeNewClients height={height} width={width} />,
    };

    return iconMap[type] || null;
};
export function AutomationEventItem(props: AutomationEventItemProps) {
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
        
    const { mutateAsync: updateAutomationEvent } = useHttpCommand(automationEventApi.automationEventUpdate);
    const navigate = useNavigate(); 
    
    const handleNavigateDetail = async () => {
        navigate(AUTOMATION_URL+'/' + props.item.id, { state: { item: props.item} });
    };
    
    const onActiveAutomationEvent = React.useCallback(
        async (values: AutomationEventGetAllResponseItem, isActive: boolean) => {
            const newValues: AutomationEventUpdateRequest['body'] = {
                ...values,
                isActive: isActive,
                reminderTime: JSON.stringify(values.reminderTime),
                serviceValue: JSON.stringify(values.serviceValue),
                automationEventChannels: values.automationEventChannels?.filter(o=>o.type == AutomationEventChannelType.Mail).map((item) => ({
                    ...item,
                    isActive: isActive
                }))
            };

            await updateAutomationEvent({
                pathData: { id: newValues.id },
                body: newValues,
            },
            {
                onSuccess: props.onSuccess,
            }
            );

        },
        [props.onSuccess, updateAutomationEvent]
    );
    const onEdit = React.useCallback(
        (values: AutomationEventGetAllResponseItem) => {
            const defaultValues: AutomationEventFormValues = {
                ...values,
                reminderTime: values.reminderTime?.map(item =>
                    JSON.stringify({
                        reminderTime: item.reminderTime,
                        reminderTimeType: item.reminderTimeType,
                    })
                ) || [],
                serviceValue: values.serviceValue,
                isActive: true,
            };
    
            setDrawerState({
                isOpen: true,
                titleTransCode: 'Edit Automation',
                content: (
                    <AutomationEventForm onSuccess={()=>{
                        resetDrawerState();
                        props.onSuccess?.();
                    }} defaultValues={defaultValues} />
                )
            });
        },
        [props, resetDrawerState, setDrawerState]
    );

    const showChangeConfirm = React.useCallback((automationEvent: AutomationEventGetAllResponseItem, isActive: boolean) => {
        if(isActive && automationEvent.automationEventChannels?.some(o=>o.type == AutomationEventChannelType.Mail && o.isActive !==true)){
            onEdit(automationEvent);
        }
        else
        {
            setModalState({
                content: (
                    <AutomationEventActiveConfirm />
                ),
                isOpen: true,
                onOk: () => {
                    onActiveAutomationEvent(automationEvent, isActive);
                    resetModalState();
                },
                showModalConfirm: true,
            });
        }
       
    }, [onActiveAutomationEvent, onEdit, resetModalState, setModalState]);

    return (
        <Card className='automation-event-item'>
            <FlexBox gap={24} justifyContent='space-between'>
                {/* <Block cursor='pointer' onClick={() => onEdit(props.item)} width='90%'> */}
                <Block cursor='pointer' onClick={() => handleNavigateDetail()} width='90%'>
                    <FlexBox gap={16}>
                        {renderIcon(props.item.type, 80, 80)}
                        <FlexBox direction='column' gap={8}>
                            <FlexBox direction='column'>
                                <Text translateCode={renderName(props.item.type)} fontSize={18} fontWeight={700} />
                                <Text translateCode={renderDescriptionItem(props.item.type)} fontSize={14} fontWeight={400} color='#667085' />
                            </FlexBox>
                            <FlexBox alignItems='center'>
                                <Text translateCode='Reminder time' fontSize={12} fontWeight={400} color='#667085' />
                                <Spacer width={8} />
                                {props.item.reminderTime?.map((time, index) => (
                                    <Tag className='reminder-time-tag' key={index} style={{ height: 22 }}>{props.item.type === AutomationEventType.CelebrateBirthdays? renderReminderTimeTypeCelebrateBirthday(time): renderReminderTimeString(time)}</Tag>
                                ))}
                                {isImmediately(props.item.reminderType) && <Tag className='reminder-time-tag' style={{ height: 22 }}>Immediately</Tag>}
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Block>
                <Block>
                    <FlexBox height='95%' direction='column' justifyContent='space-between'>
                        <Block>
                            <Switch onChange={(v) => showChangeConfirm(props.item, v)} checked={props.item.isActive} />
                        </Block>

                        <Block>
                            <FlexBox gap={8} justifyContent='flex-end' alignItems='center'>
                                {props.item.automationEventChannels?.some(o=>o.type == AutomationEventChannelType.Mail && o.isActive ==true) &&<IconMail />}
                                {props.item.automationEventChannels?.some(o=>o.type == AutomationEventChannelType.Sms && o.isActive ==true) &&<IconCalendar />}
                            </FlexBox>
                        </Block>
                    </FlexBox>
                </Block>
            </FlexBox>
        </Card>
    );
}
