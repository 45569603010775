import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { AppointmentGetDetailResponse } from './getAppointmentDetail';

interface AppointmentGetAllRequest extends HttpRequestData { }

export const getAllAppointments = httpUtil.createHttpRequestMeta<
    AppointmentGetAllRequest,
    PaginationResponse<AppointmentGetDetailResponse>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'GET',
    path: '/api/v1/appointments',
    authentication: 'bearer'
});
