import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface AppointmentServiceEmployeeUpdateRequest extends HttpRequestData {
    body: {
        readonly id: string;
        readonly employeeId: string;
    };
}

export const updateAppointmentServiceEmployee = httpUtil.createHttpRequestMeta<AppointmentServiceEmployeeUpdateRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'PATCH',
    path: '/api/v1/appointment-service-employees/:id',
    authentication: 'bearer',
});
