import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { IntegrationEmailConfig, IntegrationStatus, IntegrationType, } from './Types';

interface IntegrationEmailGetAllRequest extends HttpRequestData { }

export type IntegrationEmailGetAllResponse = IntegrationEmailGetAllResponseItem[];

export interface IntegrationEmailGetAllResponseItem {
    readonly id: string;
    readonly type: IntegrationType;
    readonly status: IntegrationStatus;
    readonly configuration: IntegrationEmailConfig;
}

export const integrationEmailGetAll = httpUtil.createHttpRequestMeta<IntegrationEmailGetAllRequest, IntegrationEmailGetAllResponse>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'GET',
    path: '/api/v1/integration-settings/email',
    authentication: 'bearer',
});