import {
    ActionsColumn, AppButton, Block, ColumnAction, FlexBox, IconPen, IconRevert, IconTrash, TableCustom, globalDrawerState, globalModalState,
    translateCodes, useHttpCommand, useHttpQuery, usePagination, Text,
    Spacer,
    ListNameColumn
} from '@esg/ui';

import React, { useCallback } from 'react';
import { TableColumnsType, Tag, Image } from 'antd';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetAllEmailTemplateResponseItem, emailTemplateApi } from '@esg/business-success-platform';
import { useTranslation } from 'react-i18next';
import { StatusEmailTemPlate, TypeEmailTemplatesEnum } from '@esg/shared';
import Segmented, { SegmentedValue } from 'antd/es/segmented';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { EmailTemplateDeleteConfirm } from './EmailTemplateDeleteConfirm';
import { EmailTemplateForm } from '@/components/form/email-template/EmailTemplateForm';
import * as locales from '@/locales';
import { renderName } from '@/helpers';
import { PageLayout } from '@/layouts';
import { AUTOMATION_URL, CAMPAIGN_URL } from '@/configs';
import { resourceManagementTheme } from '@/pages/resource-management/@shared';

enum MenuTemplateTabs {
    Email,
    Sms,
}
interface EmailTemplateValues {
    id: string;
    type?: TypeEmailTemplatesEnum;
    subject?: string;
    status?: StatusEmailTemPlate;
    body?: string;
    bodyCustom?: string;
    SubjectCustom?: string;
}

export function EmailTemplate() {
    const { type } = useParams();
    const { t } = useTranslation();
    const { translateCodes: emailTranslateCodes } = locales;
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const [searchParams, setSearchParams] = usePagination();
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const [activeTab, setActiveTab] = React.useState<SegmentedValue>(MenuTemplateTabs.Email);
    const isSystemTemplate = type == 'system-template';
    const {
        data: emailTemplates,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(emailTemplateApi.emailTemplateGetAll, {
        query: { ...searchParams, statusEmailTemPlate: isSystemTemplate ? StatusEmailTemPlate.SystemTemPlate : StatusEmailTemPlate.CustomTemplate },

    });
    const { mutateAsync: updateEmailTemplate, } = useHttpCommand(
        emailTemplateApi.emailTemplateUpdate
    );
    const { mutateAsync: deleteEmailTemplate, } = useHttpCommand(
        emailTemplateApi.emailTemplateDelete
    );
    const onDelete = React.useCallback(
        async (values: EmailTemplateValues) => {
            await deleteEmailTemplate({
                pathData: { id: values.id },
            },
            {
                onSuccess() {
                    refetch();
                    resetModalState();
                },
            }
            );
            return;
        },
        [refetch, resetModalState, deleteEmailTemplate]
    );

    const onRevert = React.useCallback(
        async (values: EmailTemplateValues) => {
            await updateEmailTemplate({
                pathData: { id: values.id },
                body: {
                    ...values,
                    IsRevert: true
                },
            },
            {
                onSuccess() {
                    refetch();
                    resetModalState();
                },
            }
            );
            return;

        },
        [refetch, resetModalState, updateEmailTemplate]
    );
    const onEdit = React.useCallback(
        (values: GetAllEmailTemplateResponseItem) => {
            setDrawerState({
                isOpen: true,
                titleTransCode: renderName(values.type!),
                content: (
                    <EmailTemplateForm
                        onSuccess={() => {
                            refetch(),
                            resetDrawerState();
                        }
                        }
                        defaultValues={values}
                        emailTemplateSystems={emailTemplates?.items ?? []}
                        isSystemTemplate={isSystemTemplate}
                    />
                ),
            });
        },
        [setDrawerState, emailTemplates?.items, isSystemTemplate, refetch, resetDrawerState]
    );

    const onCreate = React.useCallback(
        () => {
            setDrawerState({
                isOpen: true,
                titleTransCode: 'Create new template',
                content: (
                    <EmailTemplateForm
                        onSuccess={() => {
                            refetch(),
                            resetDrawerState();
                        }
                        }
                        emailTemplateSystems={emailTemplates?.items ?? []}
                        isSystemTemplate={isSystemTemplate}
                    />
                ),
            });
        },
        [setDrawerState, emailTemplates?.items, isSystemTemplate, refetch, resetDrawerState]
    );

    const showRevertConfirm = React.useCallback((emailTemplate: GetAllEmailTemplateResponseItem) => {
        setModalState({
            content: (
                <EmailTemplateDeleteConfirm />
            ),
            isOpen: true,
            onOk: () => {
                onRevert(emailTemplate);
            },
            showModalConfirm: true,
        });
    }, [setModalState, onRevert]);

    const showDeleteConfirm = React.useCallback((emailTemplate: GetAllEmailTemplateResponseItem) => {
        setModalState({
            content: (
                <EmailTemplateDeleteConfirm />
            ),
            isOpen: true,
            onOk: () => {
                onDelete(emailTemplate);
            },
            showModalDelete: true,
        });
    }, [setModalState, onDelete]);

    const actionsTable = useCallback((emailTemplate: GetAllEmailTemplateResponseItem) => {
        const compareBody = emailTemplate.body === emailTemplate.bodyCustom;
        const compareSubject = emailTemplate.subject === emailTemplate.subjectCustom;

        const isRevertTemplate = (!compareBody || !compareSubject) && emailTemplate.status == StatusEmailTemPlate.SystemTemPlate;
        const isDeleteTemplate = emailTemplate.status != StatusEmailTemPlate.SystemTemPlate;

        let actions: ColumnAction[] = [
            {
                icon: <IconPen />,
                tooltipTitle: translateCodes.EDIT,
                onClick: () => onEdit(emailTemplate)
            },
        ];

        if (isRevertTemplate) {
            actions = [
                ...actions,
                {
                    icon: (
                        <AppButton
                            type='primary'
                            className='app-button-hover'
                            danger
                            icon={<IconRevert color='#D92D20' />}
                            onClick={() => showRevertConfirm(emailTemplate)}
                        />
                    ),
                    tooltipTitle: translateCodes.REVERT
                },
            ];
        }
        if (isDeleteTemplate) {
            actions = [
                ...actions,
                {
                    icon: <IconTrash onClick={() => showDeleteConfirm(emailTemplate)} />,
                    tooltipTitle: translateCodes.DELETE
                },
            ];
        }

        return actions;

    }, [onEdit, showDeleteConfirm, showRevertConfirm]);
    
    const templateColumns: TableColumnsType<GetAllEmailTemplateResponseItem> = [
        {
            title: t(emailTranslateCodes.EMAIL_TEMPLATE_SUBJECT),
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: `${isSystemTemplate ? 'Trigger event' : 'Type'}`,
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => {
                return renderName(record.type!);
            }
        },
        {
            title: 'Campaign assigned',
            dataIndex: 'campaignChannels',
            key: 'campaignChannels',
            render: (_: unknown, record: GetAllEmailTemplateResponseItem) => {
                const names = record.campaignChannels?.map((campaignChannel) => campaignChannel.campaign?.name);
                return <ListNameColumn names={names} limit={2} />;
            }
        },
        {
            title: t(emailTranslateCodes.STATUS),
            dataIndex: 'status',
            key: 'Status',
            render: (_, record) => {
                return record.status == StatusEmailTemPlate.SystemTemPlate
                    ? <Tag color='success'> {t(emailTranslateCodes.EMAIL_TEMPLATE_SYSTEM)}</Tag>
                    : <Tag color='blue'> {t(emailTranslateCodes.EMAIL_TEMPLATE_CUSTOM)}</Tag>;
            }
        },
        {
            width: 116,
            title: t(emailTranslateCodes.ACTION),
            render: (_, record) => {
                return (
                    <ActionsColumn
                        actions={actionsTable(record)}
                    />
                );
            },
        },
    ];

    const systemTemplateColumns = templateColumns.filter(c => c.key !== 'campaignChannels');

    const menuEmailSmsOptions = [
        {
            label: 'Email',
            value: MenuTemplateTabs.Email,
            content:
                <TableCustom
                    onPageChange={(page) =>
                        setSearchParams({
                            ...searchParams,
                            page: page.toString(),
                        })
                    }
                    onSearch={(value) =>
                        setSearchParams({ ...searchParams, search: value })
                    }
                    loading={isFetching || isLoading}
                    titleTableTransCode={emailTranslateCodes.EMAIL_TEMPLATE_LIST}
                    placeholderSearchTransCode={emailTranslateCodes.EMAIL_TEMPLATE_SEARCH}
                    columns={
                        isSystemTemplate ? systemTemplateColumns : templateColumns
                    }
                    dataSource={emailTemplates?.items ?? []}
                />
        },
        {
            label: 'SMS',
            value: MenuTemplateTabs.Sms,
            content:
                <>SMS</>,
        },
    ];

    return (
        <>
            <PageLayout>
                <FlexBox gap={20} >
                    <Link to={isSystemTemplate ? AUTOMATION_URL : CAMPAIGN_URL}>
                        <Text translateCode={isSystemTemplate ? 'Automation list' : 'Campaigns'} fontWeight={500} fontSize={14} color='#475467' />
                    </Link>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowRightIcon}
                        width={14}
                        height={14}
                    />
                    <Text translateCode='Manage Templates' fontWeight={500} fontSize={14} color='#0C6FF3' />
                </FlexBox>
                <Spacer height={20} />

                <FlexBox direction='column' gap={16}>
                    <FlexBox justifyContent='space-between'>
                        <Block width='10%'>
                            <Segmented
                                size='large'
                                value={activeTab}
                                options={menuEmailSmsOptions}
                                onChange={(value) => {
                                    setActiveTab(value);
                                }}
                                block
                            />
                        </Block>
                        {activeTab === MenuTemplateTabs.Email &&
                            <Block>
                                {!isSystemTemplate && <AppButton
                                    icon={<PlusOutlined />}
                                    translateCode='Create Template'
                                    type='primary'
                                    onClick={() => onCreate()}
                                />}
                            </Block>
                        }
                    </FlexBox>
                    {menuEmailSmsOptions.find((o) => o.value === activeTab)?.content}
                </FlexBox>
            </PageLayout>
        </>

    );
}
