import React, { useState } from 'react';
import {
    AppButton, Text, FlexBox, globalDrawerState,
    globalModalState, TableCustom, useHttpCommand, useHttpQuery,
    usePagination, ColumnActions,
    InternalLink,
    IconEditDraft,
    IconPause,
    IconResume,
    IconTemplate,
    Block,
    AppFilter,
    SelectField} from '@esg/ui';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { managementApis } from '@esg/business-management';
import {  Badge, TableColumnsType, Tag } from 'antd';
import {  CampaignChannelType, CampaignStatus, CampaignType, CreateCampaignRequest, GetCampaignResponseItem, SaveDraftCampaignRequest, UpdateCampaignRequest } from '@esg/business-management/api/campaign';
import { emailTemplateApi, GetAllEmailTemplateRequest } from '@esg/business-success-platform';
import { GetAllSegmentRequest } from '@esg/business-management/api/segment';
import { GetAllPromotionRequest } from '@esg/business-management/api/promotion';
import { requestAllItem, StatusEmailTemPlate } from '@esg/shared';
import { useNavigate } from 'react-router-dom';
import { viewRequests } from '@esg/framework';
import { campaignChannelOptions } from './children/CampaignContentRunningMetric';
import { PageLayout } from '@/layouts';
import { translateCodes } from '@/locales';
import { CampaignForm } from '@/components';
import { CAMPAIGN_URL, TEMPLATE_URL } from '@/configs';
import { campaignTypeOptions, ClientSegmentTable } from '@/components/form/campaign/steps';

export interface FilterCampaignParams {
    readonly type?: CampaignType;
    readonly channel?: CampaignChannelType;
    readonly status?: CampaignStatus;
}

export const campaignStatusOptions = [
    {label: CampaignStatus[CampaignStatus.Running] , value: CampaignStatus.Running},
    {label: CampaignStatus[CampaignStatus.Draft] , value: CampaignStatus.Draft},
    {label: CampaignStatus[CampaignStatus.Completed] , value: CampaignStatus.Completed},
    {label: CampaignStatus[CampaignStatus.InProgress] , value: CampaignStatus.InProgress},
    {label: CampaignStatus[CampaignStatus.Paused] , value: CampaignStatus.Paused},
    {label: CampaignStatus[CampaignStatus.Scheduled] , value: CampaignStatus.Scheduled},
];

export function CampaignOverview() {
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleNavigateDetail = async () => {
        navigate(`${TEMPLATE_URL}/custom-template`);
    };
        
    const [searchParams, setSearchParams] = usePagination();
    const [filterParams, setFilterParams] = useState<FilterCampaignParams>({});

    const {
        data: campaignResponse,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(managementApis.getAllCampaign, {
        query: {...searchParams, ...filterParams},
    });

    const {
        mutateAsync: segmentsResponse,
        isPending: isSegmentPending
    } = useHttpCommand(managementApis.getAllSegments);

    const {
        mutateAsync: promotionsResponse,
        isPending: isPromotionPending
    } = useHttpCommand(managementApis.getAllPromotion);

    const {
        mutateAsync: emailTemplates,
        isPending: isEmailPending
    } = useHttpCommand(emailTemplateApi.emailTemplateGetAll);
    
    const { mutateAsync: deleteCampaignAsync } = useHttpCommand(managementApis.deleteCampaign, {
        onSuccess(_) {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SAM_09),
            });
            resetModalState();
            resetDrawerState();
            refetch();
        },
    });
    const { mutateAsync: saveDraftCampaignAsync } = useHttpCommand(managementApis.saveDraftCampaign, {
        onSuccess: () => {
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const { mutateAsync: createCampaignAsync } = useHttpCommand(managementApis.createCampaign, {
        onSuccess: () => {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SAM_07),
            });
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const { mutateAsync: updateCampaignAsync } = useHttpCommand(managementApis.updateCampaign, {
        onSuccess: () => {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SAM_08),
            });
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const onDelete = React.useCallback(
        async (id: string) => {
            await deleteCampaignAsync({ pathData: { id: id } });
        },
        [deleteCampaignAsync]
    );

    const showModalDelete = React.useCallback((values: GetCampaignResponseItem) => {
        setModalState({
            content: (
                <FlexBox direction='column'>
                    <Text fontSize={18} fontWeight={700} translateCode={translateCodes.DELETE_CAMPAIGN}>?</Text>
                    <Text fontSize={14}>
                        {t(translateCodes.DELETE_CAMPAIGN_MSG)}
                        <Text color='#101828' fontWeight={600}>
                            {` ${values.name}?`}
                        </Text>
                    </Text>
                </FlexBox>
            ),
            isOpen: true,
            onOk: () => {
                onDelete(values.id || '');
            },
            showModalDelete: true,
        });
    }, [onDelete, setModalState, t]);

    const showModalViewClientCampaign = React.useCallback((clientSize: number, values: GetCampaignResponseItem) => {
        setModalState({
            content: (
                <ClientSegmentTable values={values} clientSize={clientSize}/>
            ),
            isOpen: true,
            footer: null,
            width: 1024,
            titleTransCode: 'Client list'
        });
    }, [setModalState]);

    const onEdit = React.useCallback(
        async (values: GetCampaignResponseItem, status?: CampaignStatus) => {
            const payload: UpdateCampaignRequest['body'] = {
                id: values.id,
                name: values.name,
                type: values.type,
                target: values.target,
                status: status!== undefined ? status : values.status,
                targetValue: values?.targetValue,
                promotionId: values.promotionId,
                dateApply: values.dateApply,
                timeApply: values.timeApply,
                campaignChannels: values.campaignChannels,
                scheduleType: values.scheduleType
            };
            await updateCampaignAsync({ body: payload, pathData: { id: values.id } });
        },
        [updateCampaignAsync]
    );

    const onCreate = React.useCallback(
        async (values: GetCampaignResponseItem) => {
            const payload: CreateCampaignRequest['body'] = {
                id: values.id,
                name: values.name,
                type: values.type,
                target: values.target,
                status: CampaignStatus.Scheduled,
                targetValue: values?.targetValue,
                promotionId: values.promotionId,
                dateApply: values.dateApply,
                timeApply: values.timeApply,
                campaignChannels: values.campaignChannels,
                scheduleType: values.scheduleType
            };
            await createCampaignAsync({ body: payload });
        }, [createCampaignAsync]
    );

    const onSaveDraft = React.useCallback(
        async (values: GetCampaignResponseItem) => {
            const payload: SaveDraftCampaignRequest['body'] = {
                id: values.id,
                name: values.name,
                type: values.type,
                target: values.target,
                status: CampaignStatus.Draft,
                targetValue: values?.targetValue,
                promotionId: values.promotionId,
                dateApply: values.dateApply,
                timeApply: values.timeApply,
                campaignChannels: values.campaignChannels,
            };
            await saveDraftCampaignAsync({ body: payload });
        }, [saveDraftCampaignAsync]
    );

    const onShowModalEdit = React.useCallback(async (values: GetCampaignResponseItem) => {
        
        if (values.status === CampaignStatus.Draft || values.status === CampaignStatus.Scheduled) {
            const [segments, promotions, emails] = await Promise.all([
                segmentsResponse({query: requestAllItem} as GetAllSegmentRequest),
                promotionsResponse({query: {...requestAllItem, isActive: true, isValid: true}} as GetAllPromotionRequest),
                emailTemplates({query: {...requestAllItem, statusEmailTemPlate : StatusEmailTemPlate.CustomTemplate}} as GetAllEmailTemplateRequest)
            ]);
            return setDrawerState({
                isOpen: true,
                titleTransCode: 'Edit Campaign',
                content: (
                    <CampaignForm
                        onSubmit={(values) => {onEdit(values, CampaignStatus.Scheduled);}}
                        refetch={refetch}
                        resetDrawerState={resetDrawerState}
                        defaultValues={values}
                        segments={segments?.items || []}
                        promotions={promotions?.items || []}
                        onSaveDraft={onSaveDraft}
                        emailTemplate={emails?.items || []}
                        showModalViewClientCampaign={showModalViewClientCampaign}
                    />
                ),
            });
        }

        if(values.status === CampaignStatus.Running)
        {
            setModalState({
                content: (
                    <FlexBox direction='column'>
                        <Text fontSize={18} fontWeight={700} translateCode={translateCodes.PAUSE_CAMPAIGN}>?</Text>
                        <Text fontSize={14}>
                            {t(translateCodes.PAUSE_CAMPAIGN_MSG)}
                            <Text color='#101828' fontWeight={700}>
                                {` ${values.name}?`}
                            </Text>
                        </Text>
                    </FlexBox>
                ),
                isOpen: true,
                onOk: () => {
                    onEdit(values, CampaignStatus.Paused);
                },
                showModalConfirm: true,
            });
        }

        if (values.status === CampaignStatus.Paused) {
            setModalState({
                content: (
                    <FlexBox direction='column'>
                        <Text fontSize={18} fontWeight={700} translateCode={translateCodes.RUN_CAMPAIGN}>?</Text>
                        <Text fontSize={14}>
                            {t(translateCodes.RUN_CAMPAIGN_MSG)}
                            <Text color='#101828' fontWeight={700}>
                                {` ${values.name}?`}
                            </Text>
                        </Text>
                    </FlexBox>
                ),
                isOpen: true,
                onOk: () => {
                    onEdit(values, CampaignStatus.Running);
                },
                showModalConfirm: true,
            });
        }
       
    }, [emailTemplates, onEdit, onSaveDraft, promotionsResponse, refetch, resetDrawerState, segmentsResponse, setDrawerState, setModalState, showModalViewClientCampaign, t]
    );

    const onShowModalCreate = React.useCallback(async () => {
        const [segments, promotions, emails] = await Promise.all([
            segmentsResponse({query: requestAllItem} as GetAllSegmentRequest),
            promotionsResponse({query: {...requestAllItem, isActive: true, isValid: true}} as GetAllPromotionRequest),
            emailTemplates({query: {...requestAllItem, statusEmailTemPlate : StatusEmailTemPlate.CustomTemplate}} as GetAllEmailTemplateRequest)
        ]);

        setDrawerState({
            isOpen: true,
            titleTransCode: 'Create new campaign',
            content: (
                <CampaignForm
                    onSubmit={onCreate}
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                    segments={segments?.items || []}
                    promotions={promotions?.items || []}
                    onSaveDraft={onSaveDraft}
                    emailTemplate={emails?.items || []}
                    showModalViewClientCampaign={showModalViewClientCampaign}
                />
            ),
        });
    }, [emailTemplates, onCreate, onSaveDraft, promotionsResponse, refetch, resetDrawerState, segmentsResponse, setDrawerState, showModalViewClientCampaign]
    );

    const renderTypeCampaign = (record: GetCampaignResponseItem) => {
        const label = record?.type === CampaignType.Promotion ? 'Promotion' : 'Notification';
        const color = record?.type === CampaignType.Promotion ? 'hwb(205 6% 9%)' : '#f50';
        return <Tag style={{borderRadius:6, margin: 0}} ><Badge color={color} text={label} className='badge-point-8'/></Tag>;
    };

    const renderChannelCampaign = (record: GetCampaignResponseItem) => {
        const activeMail = record?.campaignChannels?.find(c => c.type == CampaignChannelType.Mail)?.isActive;
        const activeSms = record?.campaignChannels?.find(c => c.type == CampaignChannelType.Sms)?.isActive;
        return <FlexBox gap={6}>
            {activeMail && <Tag style={{ borderRadius: 6 }}>Email</Tag>}
            {activeSms && <Tag style={{ borderRadius: 6 }}>Sms</Tag>}
        </FlexBox>;
    };

    const renderStatusCampaign = (record: GetCampaignResponseItem) => {
        if (record?.status == CampaignStatus.Running) {
            return <Tag color='success'>Running</Tag>;
        }
        if (record?.status == CampaignStatus.Completed) {
            return <Tag color='default'>Completed</Tag>;
        }
        if (record?.status == CampaignStatus.Draft) {
            return <Tag color='purple'>Draft</Tag>;
        }
        if (record?.status == CampaignStatus.Scheduled) {
            return <Tag color='blue'>Scheduled</Tag>;
        }
        if (record?.status == CampaignStatus.InProgress) {
            return <Tag color='blue'>In progress</Tag>;
        }
        return <Tag color='volcano'>Paused</Tag>;
    };
    
    const columns: TableColumnsType<GetCampaignResponseItem> = [
        {
            title: 'ID',
            dataIndex: 'code',
            key: 'code',
            sorter: (a, b) => (a.code || '').localeCompare(b.code || ''),
            render: (_, record) => {
                if(record.status === CampaignStatus.Draft) {
                    return <Text>{record.code}</Text>;
                }
                return <InternalLink href={`${CAMPAIGN_URL}/${record.id}`} >{record.code}</InternalLink>;
            },
        },
        {
            title: 'Campaign name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis>{record.name}</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                return renderStatusCampaign(record);
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => {
                return renderTypeCampaign(record);
            },
        },
        {
            title: 'Channels',
            dataIndex: 'channel',
            key: 'channel',
            render: (_, record) => {
                return renderChannelCampaign(record);
            },
        },
        {
            title: 'Delivered',
            dataIndex: 'delivered',
            key: 'delivered',
            render: (_, record) => {
                return <Text>{record.delivered}</Text>;
            },
        },
        {
            title: 'Failed',
            dataIndex: 'failed',
            key: 'failed',
            render: (_, record) => {
                return <Text>{record.failed}</Text>;
            },
        },
        // {
        //     title: 'Opened',
        //     dataIndex: 'opened',
        //     key: 'opened',
        //     render: (_, record) => {
        //         return <Text>{record.opened}</Text>;
        //     },
        // },
        {
            title: t(translateCodes.ACTION),
            key: 'action',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={()=>{showModalDelete(record);}}
                        onClickEdit={() => onShowModalEdit(record)}
                        disabledEdit={record.status === CampaignStatus.Completed || record.status === CampaignStatus.InProgress}
                        iconEdit={record.status === CampaignStatus.Draft 
                            ? <IconEditDraft/>
                            : record.status === CampaignStatus.Running
                                ? <IconPause/>
                                : record.status === CampaignStatus.Paused
                                    ? <IconResume/>
                                    : undefined
                        }
                        tooltipEdit={
                            record.status === CampaignStatus.Draft
                                ? 'Continue editing'
                                : record.status === CampaignStatus.Running
                                    ? 'Pause campaign'
                                    : record.status === CampaignStatus.Paused
                                        ? 'Run campaign'
                                        : 'Edit campaign'
                        }
                    />
                );
            },
        },
    ];

    const showFilterRender = () => {
        return (
            <AppFilter<FilterCampaignParams>
                defaultValues={filterParams}
                handleSubmit={setFilterParams}
                onCancel={() => { }}
            >
                {({ renderKey }) => (
                    <FlexBox direction='column' gap={16} key={renderKey}>
                        <SelectField
                            label='Campaign type'
                            placeholder='Select campaign type'
                            style={{ width: '100%' }}
                            name='type'
                            options={campaignTypeOptions}
                        />
                        <SelectField
                            label='Channels'
                            placeholder='Select channels'
                            style={{ width: '100%' }}
                            name='channel'
                            options={campaignChannelOptions}
                        />
                        <SelectField
                            label='Status'
                            placeholder='Select status'
                            style={{ width: '100%' }}
                            name='status'
                            options={campaignStatusOptions}
                        />
                    </FlexBox>
                )}
            </AppFilter>
        );
    };

    return (
        <PageLayout
            pageTitleTransCode={t(translateCodes.CAMPAIGNS)}
            rightHeaderPage={
                <Block>
                    <FlexBox gap={10}>
                        <AppButton
                            icon={<IconTemplate />}
                            onClick={() => handleNavigateDetail()}
                            translateCode='Templates'
                        />
                        <AppButton
                            icon={<PlusOutlined />}
                            translateCode={t(translateCodes.CREATE_CAMPAIGN)}
                            type='primary'
                            onClick={onShowModalCreate}
                        />
                    </FlexBox>
                </Block>
            }
        >
            <TableCustom
                showFilterDropdown={showFilterRender}
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                pageInfo={campaignResponse?.meta}
                loading={isFetching || isLoading || isEmailPending || isSegmentPending || isPromotionPending}
                titleTableTransCode='Campaign List'
                placeholderSearchTransCode='Search by campaign name'
                columns={columns}
                dataSource={campaignResponse?.items ?? []}
                emptyOption={{
                    title: t(translateCodes.ADD_CAMPAIGN),
                    description: t(translateCodes.EMPTY_CAMPAIGN_DESCRIPTION),
                    actionButton: (
                        <AppButton
                            icon={<PlusOutlined />}
                            translateCode={t(translateCodes.CREATE_CAMPAIGN)}
                            type='primary'
                            onClick={onShowModalCreate}
                        />
                    )
                }}
            />
        </PageLayout>
    );
}