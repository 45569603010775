import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';
import { GetDetailSaleResponse } from './getDetailSale';

interface CancelSaleRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        cancelReasonId?: string;
        cancelNote?: string;
    };
}

export const cancelSale = httpUtil.createHttpRequestMeta<
    CancelSaleRequest,
    GetDetailSaleResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/sales/:id/canceled',
    method: 'PATCH',
    authentication: 'bearer',
});
