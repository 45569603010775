import { HttpRequestData, httpUtil } from '@esg/framework';

import { Dayjs , getEnv } from '@esg/shared';

import { GetRoleDetailResponse } from '../roles';

export interface GetCurrentProfileRequest extends HttpRequestData {
}

export interface IBusinessHour {
    readonly isDayOff: boolean;
    readonly startTime: string | Dayjs;
    readonly endTime: string | Dayjs;
    readonly dayOfWeek: number;
    readonly dayOfWeekTitle: string;
    readonly breakTimes?: IBreakTime[];
}

export interface IBreakTime {
    readonly startTime: Dayjs;
    readonly endTime: Dayjs;
    readonly dayOfWeek: number;
    readonly dayOfWeekTitle: string;
    readonly isFixed?: boolean;
}

export interface GetCurrentProfileResponse {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly avatar: string;
    readonly phoneNumber: string;
    readonly createdAt: string;
    readonly loginMethods: string[];
    readonly roleId: string;
    readonly role: GetRoleDetailResponse;
    readonly organization: {
        readonly id: string;
        readonly name: string;
        readonly phoneNumber?: string;
    };
    readonly branchActive?: string;
}

export const getCurrentProfile = httpUtil.createHttpRequestMeta<
    GetCurrentProfileRequest,
    GetCurrentProfileResponse
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/profiles/me',
    method: 'GET',
    authentication: 'bearer'
});
