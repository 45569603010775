import { HttpRequestData, httpUtil } from '@esg/framework';
import { Dayjs } from 'dayjs';
import { getEnv } from '@esg/shared';
import { BranchGetAllResponseItem } from './getAllBranches';

export interface IWorkingTimeItem {
    readonly isDayOff: boolean;
    readonly startTime: string | Dayjs;
    readonly endTime: string | Dayjs;
    readonly dayOfWeek: number;
    readonly dayOfWeekTitle: string;
    readonly breakTimes?: IBreakTimes[];
}

export interface IBreakTimes {
    readonly startTime: Dayjs;
    readonly endTime: Dayjs;
    readonly dayOfWeek: number;
    readonly dayOfWeekTitle: string;
    readonly isFixed?: boolean;
}

export interface CreateBranchRequest extends HttpRequestData {
    readonly body: {
        readonly name: string;
        readonly logo?: string;
        readonly countryCode?: string;
        readonly phoneNumber?: string;
        readonly currency?: string;
        readonly tax?: string;
        readonly dateTimeFormat?: string;
        readonly timezone?: string;
        readonly languages?: string;
        readonly address: string;
        readonly city: string;
        readonly state: string;
        readonly postalCode: string;
        readonly country: string;
        readonly businessHour: IWorkingTimeItem[];
    };
}

export const createBranch = httpUtil.createHttpRequestMeta<
    CreateBranchRequest,
    BranchGetAllResponseItem
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/branches',
    method: 'POST',
    authentication: 'bearer',
});
