import { Form, FormItemProps } from 'antd';
import React, { useEffect, useRef } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useLoginState } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { Block } from '../../layouts';
import { FormConnector } from '../FormConnector';

interface EditorFieldProps {
    readonly name: string;
    readonly placeholder?: string;
    readonly rules?: UseControllerProps['rules'];
    readonly description?: string;
    readonly formItemProps?: FormItemProps;
    readonly onchange?: (data: ClassicEditor) => void;
    readonly value?: string;
}

export const EditorField = ({
    name,
    rules,
    formItemProps,
    onchange,
    value = '',
}: EditorFieldProps) => {
    const { t } = useTranslation();
    const [login] = useLoginState();
    const editorRef = useRef<ClassicEditor | null>(null);
    useEffect(() => {
        if (editorRef.current && value !== editorRef.current.getData()) {
            editorRef.current.setData(value);
        }
    }, [value]);

    const endpoint = '/api/v1/file-uploads';
    const action = getEnv().MANAGEMENT_SERVER + endpoint;
    const token = login?.token ?? '';
    return (
        <FormConnector>
            {(form) => {
                const { control } = form;

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const onRender = ({ field, fieldState }: any) => {
                    const { onBlur, onChange, value } = field;
                    const { error } = fieldState;

                    return (
                        <Form.Item
                            name={name}
                            validateStatus={error ? 'error' : undefined}
                            help={error && t(error?.message)}
                            {...formItemProps}
                        >
                            <CKEditor
                                config={{
                                    simpleUpload: {
                                        uploadUrl: action,
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    },
                                    toolbar: [
                                        'heading',
                                        '|',
                                        'bold',
                                        'italic',
                                        'link',
                                        'bulletedList',
                                        'numberedList',
                                        'blockQuote',
                                        '|',
                                        'imageUpload',
                                        'insertTable',
                                        'mediaEmbed',
                                        'undo',
                                        'redo',
                                    ],
                                }}
                                editor={ClassicEditor}
                                data={value || ''}
                                onChange={(_, editor) => {
                                    onChange(editor.getData());
                                    if (onchange) {
                                        onchange(editor);
                                    }
                                }}
                                onBlur={onBlur}
                                onReady={(editor) => {
                                    editorRef.current = editor;
                                }}
                            />
                        </Form.Item>
                    );
                };

                return (
                    <Block width='100%'>
                        <Controller
                            name={name}
                            control={control}
                            render={onRender}
                            rules={rules}
                        />
                    </Block>
                );
            }}
        </FormConnector>
    );
};
