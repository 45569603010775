import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { GetRoleDetailResponse } from './getRoleDetail';

interface GetAllRoleRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export const getAllRole = httpUtil.createHttpRequestMeta<
    GetAllRoleRequest,
    PaginationResponse<GetRoleDetailResponse>
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/roles',
    method: 'GET',
    authentication: 'bearer',
});
