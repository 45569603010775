import React from 'react';

import { IconProps } from '../../Types';

export const IconResume = (props: IconProps) => {
    return (
        <svg
            className={props.className}
            onClick={props.onClick}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_3331_110406)'>
                <path d='M10.0003 18.3346C14.6027 18.3346 18.3337 14.6037 18.3337 10.0013C18.3337 5.39893 14.6027 1.66797 10.0003 1.66797C5.39795 1.66797 1.66699 5.39893 1.66699 10.0013C1.66699 14.6037 5.39795 18.3346 10.0003 18.3346Z' stroke='#475467' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M7.91699 7.47241C7.91699 7.07468 7.91699 6.87581 8.00011 6.76478C8.07254 6.66803 8.18342 6.6075 8.30397 6.59889C8.44231 6.58901 8.6096 6.69655 8.94416 6.91163L12.878 9.44052C13.1683 9.62715 13.3135 9.72047 13.3636 9.83913C13.4074 9.94281 13.4074 10.0598 13.3636 10.1635C13.3135 10.2821 13.1683 10.3755 12.878 10.5621L8.94416 13.091C8.6096 13.3061 8.44231 13.4136 8.30397 13.4037C8.18342 13.3951 8.07254 13.3346 8.00011 13.2378C7.91699 13.1268 7.91699 12.9279 7.91699 12.5302V7.47241Z' stroke='#475467' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
            </g>
            <defs>
                <clipPath id='clip0_3331_110406'>
                    <rect width='20' height='20' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
