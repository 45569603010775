import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { GetDetailSaleResponse } from '@esg/business-pos/api/sale';
import { getEnv } from '@esg/shared';

interface GetRevenueServiceDetailRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
        readonly serviceId?: string;
        readonly employeeId?: string;
        readonly serviceCategoryId?: string;
    };
}

export const getRevenueServiceDetailReport = httpUtil.createHttpRequestMeta<
    GetRevenueServiceDetailRequest,
    PaginationResponse<GetDetailSaleResponse>
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/revenue-report/detail-by-service',
    authentication: 'bearer',
});