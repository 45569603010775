import { AutomationEventGetAllResponseItem } from '@esg/business-success-platform';
import { AutomationEventChannelType } from '@esg/shared';
import { FlexBox, TableCustom, Text, useLocalesFormat } from '@esg/ui';
import { Card, TableColumnsType, Tag } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { PromotionApplyResponse } from '@esg/business-management';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState } from 'recoil';
import { renderAddons, renderReminderTimeString } from '@/helpers';
import './AutomationEvenContentDetail.scss';

interface AutomationEvenContentDetailProps {
  automationEvent: AutomationEventGetAllResponseItem;
}

export const AutomationEvenContentDetail = (props: AutomationEvenContentDetailProps) => {
    const { automationEvent } = props;
    const { formatDate,  formatCurrencySymbol } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);

    const renderAutomationCardDetail = (title: string, value?: string) =>{
        return <FlexBox direction='column' gap={8}>
            <Text translateCode={title}/>
            <Text color='#101828' fontWeight={600}>
                {value}
            </Text>
        </FlexBox>;
    };

    const classNameItemTable = (promotionApplyId?: string, automationEventPromotionId?: string, index?: number) => {
        if(promotionApplyId === automationEventPromotionId && index === 0){
            return 'opacity-new-promotion';
        }
        return 'opacity-old-promotion';
    };

    const promotionColumns: TableColumnsType<PromotionApplyResponse> = [
        {
            title: 'Promotion name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record, index) => {
                return <Text className={classNameItemTable(automationEvent.promotionId, record.promotionId, index)} maxWidth={300} ellipsis
                >{record.promotionName}</Text>;
            }
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            render: (_, record, index) => {
                return <Text className={classNameItemTable(automationEvent.promotionId, record.promotionId, index)} >{`${record.value} ${record.unit === 0 ? '%': formatCurrencySymbol()}`}</Text>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, record, index) => {
                return <Text className={classNameItemTable(automationEvent.promotionId, record.promotionId, index)}>Service</Text>;
            },
        },
        {
            title: 'Effective date',
            dataIndex: 'effectiveDate',
            key: 'effectiveDate',
            render: (_, record, index) => {
                return <Text className={classNameItemTable(automationEvent.promotionId, record.promotionId, index)}>{formatDate(dayjs(record.effectiveDate), `${currentLocale?.format}`)} { }</Text>;
            },
        },
        {
            title: 'Expiration date',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: (_, record, index) => {
                const currentDate = dayjs().startOf('day');
                if (currentDate.add(22, 'year') < dayjs(record.expirationDate)) {
                    return <Text className={classNameItemTable(automationEvent.promotionId, record.promotionId, index)}>Ongoing</Text>;
                }
                return <Text className={classNameItemTable(automationEvent.promotionId, record.promotionId)}>{formatDate(dayjs(record.expirationDate), `${currentLocale?.format}`)} { }</Text>;
            },
        },
        {
            title: 'Added date',
            dataIndex: 'creationTime',
            key: 'creationTime',
            render: (_, record, index) => {
                const currentDate = dayjs().startOf('day');
                if (currentDate.add(22, 'year') < dayjs(record.creationTime)) {
                    return <Text className={classNameItemTable(automationEvent.promotionId, record.promotionId, index)}>Ongoing</Text>;
                }
                return <Text className={classNameItemTable(automationEvent.promotionId, record.promotionId, index)}>{formatDate(dayjs(record.creationTime), `${currentLocale?.format}`)} { }</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record, index) => {
                if(!record.isActive){
                    return <Tag color='default'>Inactive</Tag>;
                }
                const colorStatus = automationEvent.promotionId === record.promotionId && index === 0 ? 'success' : 'default';
                const currentDate = dayjs().startOf('day');
                if (currentDate > dayjs(record.expirationDate)) {
                    return <Tag color='red'>Expired</Tag>;
                }

                return <Tag color={colorStatus}>Active</Tag>;
            },
        }
    ];
    
    const renderAutomationCard = () => {

        const activeMail = automationEvent?.automationEventChannels?.find(c => c.type == AutomationEventChannelType.Mail)?.isActive;
        const activeSms = automationEvent?.automationEventChannels?.find(c => c.type == AutomationEventChannelType.Sms)?.isActive;
        const automationEventChannels = `${activeMail? 'Email': ''}${activeSms&&activeMail ? ', ': ''}${activeSms ? 'Sms': ''}`;
        const reminderTimeString = automationEvent.reminderTime?.map((o)=>{
            return `${ renderReminderTimeString(o)} ${renderAddons(automationEvent.type)} `;
        });
        return <Card>
            <FlexBox>
                <FlexBox direction='column' gap={24}>
                    {renderAutomationCardDetail('Automation ID', automationEvent.code)}
                    {renderAutomationCardDetail('Channels', automationEventChannels)}
                </FlexBox>
                <FlexBox direction='column' gap={24}>
                    {renderAutomationCardDetail('Send time', reminderTimeString?.join(', '))}
                    {renderAutomationCardDetail('Created date', formatDate(dayjs(automationEvent.creationTime)))}
                </FlexBox>
            </FlexBox>
        </Card>;
    };
    const renderPromotionCard = () => {
        return <FlexBox direction='column' gap={8}>
            <TableCustom
                titleTableTransCode='Promotion details'
                columns={promotionColumns}
                dataSource={automationEvent.promotionApplies}
            />
        </FlexBox>;
    };
    
    return (<FlexBox direction='column' gap={24}>
        {renderAutomationCard()}
        {automationEvent.promotion && renderPromotionCard()}
    </FlexBox>);
};

