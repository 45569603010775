import React from 'react';

import { IconProps } from '../../Types';

export const IconWelcomeNewClients = (props: IconProps) => {
    return (
        <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z' fill='#DCFAE6'/>
            <path d='M49.3337 52V44M45.3337 48H53.3337M40.0003 44H34.667C32.182 44 30.9395 44 29.9593 44.406C28.6525 44.9473 27.6143 45.9855 27.073 47.2924C26.667 48.2725 26.667 49.515 26.667 52M44.667 28.3877C46.6215 29.1789 48.0003 31.0951 48.0003 33.3333C48.0003 35.5716 46.6215 37.4878 44.667 38.279M42.0003 33.3333C42.0003 36.2789 39.6125 38.6667 36.667 38.6667C33.7215 38.6667 31.3337 36.2789 31.3337 33.3333C31.3337 30.3878 33.7215 28 36.667 28C39.6125 28 42.0003 30.3878 42.0003 33.3333Z' stroke='#079455' strokeWidth='1.67' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};
