import { translateCodes } from '@/locales';

export const campaignRules = {
    campaignName: {
        required: translateCodes.VAL_SAM_09,
        maxLength: {
            value: 255,
            message: translateCodes.MAX_LENGTH_PROMOTION
        },
    },
    valueRequire: {
        required: translateCodes.VAL_SAM_02,
    },
    promotion: {
        required: translateCodes.VAL_SAM_12,
    },
    emailTemplate: {
        required: translateCodes.VAL_SAM_14,
    },
    dayApply: {
        required: translateCodes.VAL_SAM_16,
    },
    timeApply: {
        required: translateCodes.VAL_SAM_17,
    },
    targetValue: {
        required: translateCodes.VAL_SAM_11,
    }
};
