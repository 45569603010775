import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';
import { CreateCustomerSourceResponse } from './createCustomerSource';

interface UpdateCustomerSourceRequest extends HttpRequestData {
    readonly body: {
        readonly id: string;
        readonly name: string;
        readonly active?: boolean;
    };
}

export const updateCustomerSource = httpUtil.createHttpRequestMeta<
    UpdateCustomerSourceRequest,
    CreateCustomerSourceResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-sources/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
