import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { GetUserDetailResponse } from './getUserDetail';

interface DeleteUserRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export const deleteUser = httpUtil.createHttpRequestMeta<
    DeleteUserRequest,
    GetUserDetailResponse
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/users/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
