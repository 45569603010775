import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

interface GetServiceAppointmentRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}
export interface GetServicesAppointmentItem {
    id: string;
    name: string;
    quantity: number;
    revenue: number;
}

export const getServicesAppointment = httpUtil.createHttpRequestMeta<
    GetServiceAppointmentRequest,
    PaginationResponse<GetServicesAppointmentItem>
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/appointments-report/services-summary',
    authentication: 'bearer',
});