import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface UpdateAppointmentSettingRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        timeSlotDuration: number;
        doubleAppointmentEnabled: boolean;
        doubleAppointmentMaxSlot: number;
        employeeSelectionRequired: boolean;
        autoNoShowEnabled: boolean;
        autoNoShowAfterTime: number;
        autoNoShowPromptEnabled: boolean;
    };
}

export interface UpdateAppointmentSettingResponse {
    id: string;
    timeSlotDuration: number;
    doubleAppointmentEnabled: boolean;
    doubleAppointmentMaxSlot: number;
    employeeSelectionRequired: boolean;
    autoNoShowEnabled: boolean;
    autoNoShowAfterTime: number;
    autoNoShowPromptEnabled: boolean;
}

export const updateAppointmentSetting = httpUtil.createHttpRequestMeta<
    UpdateAppointmentSettingRequest,
    UpdateAppointmentSettingResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/appointment-Settings/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
