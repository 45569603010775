import { GetAllCustomerSourceResponseItem, customerApi } from '@esg/business-crm';
import {
    AppButton,
    ColumnActions,
    FlexBox,
    TableCustom,
    globalModalState,
    useHttpQuery,
    usePagination,
    Text,
    useHttpCommand,
} from '@esg/ui';
import { TableColumnsType, Tag ,Image} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState ,useResetRecoilState } from 'recoil';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { PageLayout } from '@/layouts/page-layout/PageLayout';
import { translateCodes } from '@/locales';
import { CRM_URL } from '@/configs';
import { resourceManagementTheme } from '@/pages/resource-management/@shared';
import { CustomerSourceDeleteConfirm } from '@/components/common/customer/CustomerDeleteSourceConfirm';
import CreateSourceForm from '@/components/form/customer/children/customer-source/CreateSourceForm';

export const CustomerSourceList = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = usePagination();
    const [, setModalState] = useRecoilState(globalModalState);
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);

    const { mutateAsync: deleteCustomerSource, isPending: isLoadingDelete } = useHttpCommand(
        customerApi.deleteCustomerSource,
        
    );

    const {
        data: sources,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(customerApi.getAllCustomersSource, {
        query: {
            ...searchParams,
        }
    });

    const showModalCreateSource = (source?: GetAllCustomerSourceResponseItem) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode:source==null? translateCodes.NEW_CLIENT_SOURCE: translateCodes.EDIT_CLIENT_SOURCE,
            content: <CreateSourceForm
                source={source}
                onCancel={() => resetGlobalModal()}
                onSuccess={() => {
                    resetGlobalModal();
                    refetch();
                }}
            />
        });
    };

    const handleDelete = React.useCallback(
        (source: GetAllCustomerSourceResponseItem) => {
            const onSuccess = () => {
                resetGlobalModal();
                refetch();
            };
            setModalState({
                content: (
                    <CustomerSourceDeleteConfirm {...{ source: source }} />
                ),
                isOpen: true,
                onOk: async () => {
                    await deleteCustomerSource({ pathData: { id: source.id }}, {onSuccess});
                },
                showModalDelete: true,
            });
        },
        [deleteCustomerSource, refetch, resetGlobalModal, setModalState]
    );

    const columns: TableColumnsType<GetAllCustomerSourceResponseItem> = [
        {
            title: t(translateCodes.SOURCE_NAME),
            dataIndex: 'name',
            key: 'name',
            width: '80%',
        },
        {
            title: t(translateCodes.STATUS),
            dataIndex: 'active',
            key: 'active',
            render: (active) => {
                return <Tag color={active ? 'green' : 'default'}>{active ? t(translateCodes.ACTIVE) : t(translateCodes.INACTIVE)}</Tag>;
            },
        },
       
        {
            title: t(translateCodes.ACTION),
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={() =>  handleDelete(record)}
                        onClickEdit={() => showModalCreateSource(record)}
                    />
                );
            },
        },
    ];

    return (
        <PageLayout
            rightHeaderPage={
                <FlexBox alignItems='center'>
                    <FlexBox gap={20} >
                        <Link to={CRM_URL}>
                            <Text translateCode={translateCodes.SIMPLE_CRM} fontWeight={500} fontSize={14} color='#475467' /> 
                        </Link>
                        <Image
                            preview={false}
                            src={resourceManagementTheme.arrowRightIcon}
                            width={14}
                            height={14}
                        />
                        <Text translateCode={translateCodes.CLIENT_SOURCE} fontWeight={700} fontSize={14} color='#0C6FF3' />
                    </FlexBox>
                    <AppButton
                        translateCode={translateCodes.CREATE_NEW_SOURCE}
                        icon={<PlusOutlined />}
                        type='primary'
                        onClick={()=>showModalCreateSource()}
                    />
                </FlexBox>
            }
        >
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({
                        ...searchParams,
                        page: page.toString(),
                    })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                loading={isFetching || isLoading || isLoadingDelete}
                titleTableTransCode={translateCodes.SOURCE_LIST}
                placeholderSearchTransCode={translateCodes.SEARCH_SOURCE_PLACEHOLDER}
                columns={columns}
                dataSource={ sources?.items??[]}
                emptyOption={{
                    title: t(translateCodes.ADD_SOURCE),
                    description: t(translateCodes.EMPTY_SOURCE_DESCRIPTION),
                    actionButton: (
                        <AppButton
                            translateCode={translateCodes.CREATE_NEW_SOURCE}
                            icon={<PlusOutlined />}
                            type='primary'
                            onClick={()=>showModalCreateSource()}
                        />
                    )
                }}
            />
        </PageLayout>
    );
};
