import { GetCustomerDetailResponse } from '@esg/business-crm';
import { getAllCustomerActivity, GetAllCustomerActivityResponseItem } from '@esg/business-crm/api/customer-activity';
import { managementApis } from '@esg/business-management';
import {  AppointmentGetDetailResponse, getUpcomingAppointments } from '@esg/business-schedule';
import { FlexBox, Text, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { Card } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState } from 'recoil';
import { AppointmentUpcomingEmpty } from './AppointmentUpcomingEmpty';
import './CustomerDetailTable.scss';
import { AppointmentUpcomingItem } from './customer-table/AppointmentUpcomingItem';
import { ItemTreeCustomerActivity } from './ItemTreeCustomerActivity';
import { CustomerActivityDetail } from './CustomerActivityDetail';
import { crmEvents } from '@/event';

interface CustomerActivityProps {
    customer?: GetCustomerDetailResponse;
}

export function CustomerActivity({
    customer,
}: CustomerActivityProps) {
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);

    const {
        data: serviceResponse,
        isFetching: isServiceFetching,
    } = useHttpQuery(managementApis.getAllServices);

    const { 
        data: customerActivityResponse, 
        isLoading: isActivityLoading,
        refetch: refetchCustomerActivity 
    } = useHttpQuery(getAllCustomerActivity, {
        query: {
            customerId: customer?.id,
            pageSize: 1000,
        }
    });

    const {
        data: appointmentResponse,
        isLoading: isAppointmentLoading,
        refetch: refetchUpcomingAppointments
    } = useHttpQuery(getUpcomingAppointments,
        {
            query: {
                customerId: customer?.id,
                pageSize: 5,
            }
        }
    );

    const groupedActivities = useMemo(
        () =>
            customerActivityResponse?.items.reduce<Record<string, GetAllCustomerActivityResponseItem[]>>((acc, activity) => {
                if (activity.branchId) {
                    const date = formatDate(activity.creationTime);
                    if (!acc[date]) acc[date] = [];
                    acc[date].push(activity);
                }
                return acc;
            }, {}),
        [customerActivityResponse?.items, formatDate]
    );

    const treeData = useMemo(
        () =>
            Object.entries(groupedActivities ?? {}).map(([date, activities], dateIndex) => ({
                title: (
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                        {formatDate(date, `ddd, ${currentLocale?.format}`)}
                    </span>
                ),
                selectable: false,
                key: `day-${dateIndex}`,
                children: activities.map((activity, activityIndex) => ({
                    title: <ItemTreeCustomerActivity activity={activity} />,
                    key: `day-${dateIndex}-activity-${activityIndex}`,
                    selectable: false,
                })),
            })),
        [groupedActivities, formatDate, currentLocale?.format]
    );

    useEffect(() => {
        const refetchUpComingListen = crmEvents.onRefetchUpComingAppointment.listen(() => {
            refetchUpcomingAppointments();
            refetchCustomerActivity();
        });
        return () => { refetchUpComingListen?.(); };
    }, [refetchCustomerActivity, refetchUpcomingAppointments]);

    const renderAppointmentUpcomingItem = (appointments : AppointmentGetDetailResponse[]) => (
        <>
            {
                appointments?.map(o => (
                    <>
                        <AppointmentUpcomingItem
                            appointmentResponse={o}
                            serviceResponse={serviceResponse?.items ?? []}
                        />
                    </>
                )
                )

            }
        </>
    );
        
    const renderAppointmentUpcoming = ( appointments : AppointmentGetDetailResponse[])=>{

        if (appointments.length == 0) {
            return <AppointmentUpcomingEmpty customer={customer} />;
        }

        return renderAppointmentUpcomingItem(appointments);
    };

    return (
        <FlexBox direction='column' gap={20}>
            <Card loading={isAppointmentLoading || isServiceFetching}>
                <FlexBox direction='column' gap={12}>
                    <Text fontSize={18} fontWeight='bold'>Upcoming appointments ({appointmentResponse?.items.length})</Text>
                    {renderAppointmentUpcoming(appointmentResponse?.items || [])}
                </FlexBox>
            </Card>
            <CustomerActivityDetail
                isLoading={isActivityLoading}
                treeData={treeData}
            />
        </FlexBox>
    );
}

