import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { AppointmentGetDetailResponse } from './getAppointmentDetail';

interface AppointmentUpcomingGetRequest extends HttpRequestData {
    query?: {
        customerId?: string;
    };
}

export const getUpcomingAppointments = httpUtil.createHttpRequestMeta<
    AppointmentUpcomingGetRequest,
    PaginationResponse<AppointmentGetDetailResponse>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'GET',
    path: '/api/v1/appointments/upcoming',
    authentication: 'bearer'
});
