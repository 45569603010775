import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export enum ViewType {
    Hour = 0,
    Day = 1,
    Week = 2,
    Month = 3,
    Timeline = 4,
}

interface GetOverviewChartRevenueRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
        readonly viewType?: ViewType;
    };
}

export interface GetOverviewChartRevenueResponse {
    overviewChartInfos: RevenueReportOverview[];
}

export interface RevenueReportOverview {
    date: string;
    totalRevenue: number;
    totalSale: number;
}

export const getOverviewChartRevenueReport = httpUtil.createHttpRequestMeta<
    GetOverviewChartRevenueRequest,
    GetOverviewChartRevenueResponse
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/revenue-report/overview-chart',
    authentication: 'bearer',
});