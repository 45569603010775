import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export interface DeleteShiftByBreakTimeRequest extends HttpRequestData {
	body: {
		readonly id: string;
		readonly date?: string;
		readonly shiftId: string;
	};
}

export const deleteShiftByBreakTime = httpUtil.createHttpRequestMeta<
	DeleteShiftByBreakTimeRequest,
	void
>({
    method: 'PATCH',
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    authentication: 'bearer',
    path: '/api/v1/shifts/:id/delete-break',
});
