import { ExternalLink, urlUtil } from '@esg/ui';

import { getEnv } from '@esg/shared';

export const ToLoginPageLink = () => {
    const redirectParams = {
        client_id: getEnv().MANAGEMENT_CLIENT_ID,
        redirect_uri: getEnv().MANAGEMENT_LOGIN_CALLBACK_PAGE
    };

    return (
        <ExternalLink
            href={urlUtil.toRelativeUrl(getEnv().IDENTITY_LOGIN_PAGE, redirectParams)}
            translateCode='TO_LOGIN_PAGE'
        />
    );
};