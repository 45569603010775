import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { Dayjs, PaginationRequest , getEnv } from '@esg/shared';

export interface GetAllSegmentRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface SegmentConditionValue {
    totalPayment?: number;
    from?: Dayjs & string;
    to?: Dayjs & string;
    serviceId?: string;
    numberUsedService?: number;
    serviceGroupId?: string;
    numberOfUsed?: number;
    servicePackageId?: string;
    totalReferred?: number;
    gender?: number;
    clientType?: number;
    ageRangeFrom?: number;
    ageRangeTo?: number;
    productId?: string;
    numberOfProduct?: number;
    groupProductId?: string;
    membershipId?: string;
}

export interface SegmentConditionItem {
    id?: string;
    name?: string;
    code?: number;
    value?: SegmentConditionValue;
}

export interface GetSegmentResponseItem {
    readonly id: string;
    name: string;
    description: string;
    createBy?: string;
    creationTime?: string;
    creatorId?: string;
    numberOfClients?: number;
    isAutoUpdate?: boolean;
    operator?: number;
    segmentConditions?: SegmentConditionItem[];
    status?: number;
}

export const getAllSegments = httpUtil.createHttpRequestMeta<
    GetAllSegmentRequest,
    PaginationResponse<GetSegmentResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/segments',
    method: 'GET',
    authentication: 'bearer',
});
