import { GetDetailSaleResponse, UnitEnum } from '@esg/business-pos';
import { DrawerState, globalDrawerState, subDrawerState, useHttpCommand } from '@esg/ui';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetAllProductCategoryRequest, managementApis } from '@esg/business-management';
import { requestAllItem } from '@esg/shared';
import { GetAllServiceCategoryRequest } from '@esg/business-management/api/service-category';
import  SaleForm  from '@/components/form/sale/SaleForm';
import { currentSaleState } from '@/states';
import { saleEvents } from '@/event/saleEvent';

interface ShowSaleFormParams {
	subDrawer?: boolean;
	paidFromScheduler?: boolean;
}

export default function useOpenSaleFrom () {
    const {
        mutateAsync: serviceCategoryResponse,
        isPending: isServicePending
    } = useHttpCommand(managementApis.getAllServiceCategory);

    const {
        mutateAsync: productCategoryResponse,
        isPending: isProductPending
    } = useHttpCommand(managementApis.getAllProductCategory);

    const [, setCurrentSale] = useRecoilState(currentSaleState);
    const resetCurrentSale = useResetRecoilState(currentSaleState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const [,setDrawerState] = useRecoilState(globalDrawerState);
    const [,setSubDrawerState] = useRecoilState(subDrawerState);

    const onShowFormSale = async (
        sale: GetDetailSaleResponse,
        params?: ShowSaleFormParams
    ) => {

        const [serviceCategories, productCategories] = await Promise.all([
            serviceCategoryResponse({ query: requestAllItem } as GetAllServiceCategoryRequest),
            productCategoryResponse({ query: requestAllItem } as GetAllProductCategoryRequest),
        ]);

        setCurrentSale({
            ...sale,
            tipUnit: sale?.tipUnit ?? UnitEnum.Percent,
            paidFromScheduler: params?.paidFromScheduler,
        });

        const initDataSaleForm = {
            serviceCategories: serviceCategories?.items,
            productCategories: productCategories?.items,
        };

        const drawerState = {
            isOpen: true,
            onClose: () => {
                saleEvents.onRefetchSaleList.emit();
                saleEvents.onRefetchSaleActive.emit();
                saleEvents.onRefetchCustomerActive.emit();
                resetDrawerState();
                resetCurrentSale();
            },
            content: <SaleForm initDataSaleForm={initDataSaleForm} />,
            footer: null,
        } as DrawerState;

        if (params?.subDrawer) {
            setSubDrawerState(drawerState);
        } else {
            setDrawerState(drawerState);
        }
    };

    return {
        onShowFormSale,
        isLoadingInitData: isServicePending || isProductPending
    };
}
