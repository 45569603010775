import React, { useLayoutEffect } from 'react';
import { useRecoilState } from 'recoil';

import { managementProfileState, useHttpCommand, useLoginPage } from '@esg/ui';
import { getAllUser, GetAllUserRequest, getCurrentProfile } from '@esg/business-account';
import { convertOpenIdResponse, useExchangeToken, useTokenValidate } from '@esg/auth';
import { useLoginStore } from '@esg/ui/hooks/useExistingLogin';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { branchApis } from '@esg/business-organization';
import { DEFAULT_FORMAT_TIME, getEnv } from '@esg/shared';
import { BranchGetAllRequest } from '@esg/business-organization/api/branch/getAllBranches';
import { GetDetailBranchResponseItem } from '@esg/business-organization/api/branch/getDetailBranch';
import { useLoginState } from '@esg/framework';
import { RealtimeRoot } from '@/contexts/realtime-context';

interface InitializerProps {
    readonly children: React.ReactNode;
}

export function Initializer(props: InitializerProps) {
    const { children } = props;

    const [initialed, setInitialed] = React.useState(false);

    const [, setCurrentProfile] = useRecoilState(managementProfileState);
    const [, setCurrentLocale] = useRecoilState(currentLocaleState);

    const { mutateAsync: execGetCurrentProfile } = useHttpCommand(getCurrentProfile, {});
    const { mutateAsync: execGetCurrentAllBranch } = useHttpCommand(branchApis.getAllBranches, {});
    const { mutateAsync: execGetCurrentBranchMember } = useHttpCommand(getAllUser);
    const { exchangeToken, exchangeTokenPeriodically } = useExchangeToken();

    const tokenValidate = useTokenValidate();
    const {
        getData: getSavedLogin,
        setData: saveLogin,
    } = useLoginStore();

    const [, setLoginState] = useLoginState();
    const toLoginPage = useLoginPage();

    const restoreLoginState = React.useCallback(async () => {
        const savedLogin = getSavedLogin();
        if (!savedLogin) {
            return;
        }

        const tokenState = tokenValidate({
            token: savedLogin.token,
            expiresAt: savedLogin.expiresAt,
            refreshToken: savedLogin.refreshToken,
        });

        if (tokenState === true) {
            return savedLogin;
        }

        if (tokenState === 'invalid' || tokenState === 'expired') {
            return;
        }

        if (tokenState === 'need-refresh') {
            try {
                const tokenResponse = await exchangeToken(savedLogin.refreshToken!);
                return convertOpenIdResponse(tokenResponse);
            }
            catch {
                return;
            }
        }
    }, [exchangeToken, getSavedLogin, tokenValidate]);

    const initCurrentUser = React.useCallback(async () => {
        const [profile, branches, member] = await Promise.all([
            execGetCurrentProfile({}),
            execGetCurrentAllBranch({} as BranchGetAllRequest),
            execGetCurrentBranchMember({
                query: {
                    pageSize: 200
                }
            } as GetAllUserRequest)
        ]);

        const branch = branches.items!.find(c => c.id === profile.branchActive) as GetDetailBranchResponseItem;

        setCurrentProfile({
            ...profile,
            branch: branch!
        });

        setCurrentLocale({
            country: branch.country,
            currency: branch.currency,
            timezone: branch.timezone,
            language: branch?.languages,
            format: branch.dateTimeFormat ?? DEFAULT_FORMAT_TIME,
            countryCode: branch.countryCode,
            members: member.items
        });

    }, [execGetCurrentAllBranch, execGetCurrentBranchMember, execGetCurrentProfile, setCurrentLocale, setCurrentProfile]);

    useLayoutEffect(
        () => {
            if (location.pathname === '/auth/login-callback') {
                return void setInitialed(true);
            }

            // 1. Restore login state
            restoreLoginState()
                .then((savedLoginRes) => {

                    if (!savedLoginRes) {
                        throw new Error('Login state is invalid');
                    }

                    // Save to the browser storage
                    saveLogin(savedLoginRes);

                    // Set the login state globally
                    setLoginState(savedLoginRes);

                    // Exchange token periodically
                    exchangeTokenPeriodically({
                        getRefreshToken: () => getSavedLogin()?.refreshToken,
                        setTokens: (response) => {
                            const loginStateValue = convertOpenIdResponse(response);
                            saveLogin(loginStateValue);
                            setLoginState(loginStateValue);
                        },
                        interval: 1000 * 60 * 5, // 5 minutes
                    });
                })
                // 2. Initialize application data
                .then(async () => {
                    await initCurrentUser();
                })
                // 3. Finish the initialization
                .then(() => {
                    setInitialed(true);
                })
                .catch(() => {
                    console.error('Failed to initialize the application');
                    toLoginPage({
                        clientId: getEnv().MANAGEMENT_CLIENT_ID,
                        redirectUri: getEnv().MANAGEMENT_LOGIN_CALLBACK_PAGE
                    });
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    if (!initialed) {
        return null;
    }

    return (
        <RealtimeRoot>
            {children}
        </RealtimeRoot>
    );
}