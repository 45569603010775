import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv, OptionValueType, StatusEmailTemPlate } from '@esg/shared';
import { GetPromotionResponseItem } from '../promotion';
import { GetSegmentResponseItem } from '../segment';

interface GetConditionDetailCampaignRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export interface CampaignChannelResponse {
    id?: string;
    type: number;
    campaignId?: string;
    emailTemplateId?: string;
    emailTemplateApplyId?: string;
    emailTemplateApply?: EmailTemplateApplyResponse;
    isActive?: boolean;
}

export interface EmailTemplateApplyResponse {
    type?: OptionValueType;
    subject?: string;
    status?: StatusEmailTemPlate;
    body?: string;
    bodyCustom?: string;
    subjectCustom?: string;
    creationTime?: string; // ISO Date string
    creatorId?: string;
}

export const getConditionDetailCampaign = httpUtil.createHttpRequestMeta<
    GetConditionDetailCampaignRequest,
    GetConditionDetailCampaign
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/campaigns/condition/:id',
    method: 'GET',
    authentication: 'bearer',
});

export interface GetConditionDetailCampaign {
    promotion?: GetPromotionResponseItem;
    segments?: GetSegmentResponseItem[];
    campaignChannels?: CampaignChannelResponse[];
}