import React from 'react';

import { IconProps } from '../../Types';

export const IconPause = (props: IconProps) => {
    return (
        <svg 
            className={props.className}
            onClick={props.onClick} 
            width='20' 
            height='20' 
            viewBox='0 0 20 20' 
            fill='none' 
            xmlns='http://www.w3.org/2000/svg' 
            {...props}
        >
            <g clipPath='url(#clip0_3331_145124)'>
                <path d='M7.91699 12.5013V7.5013M12.0837 12.5013V7.5013M18.3337 10.0013C18.3337 14.6037 14.6027 18.3346 10.0003 18.3346C5.39795 18.3346 1.66699 14.6037 1.66699 10.0013C1.66699 5.39893 5.39795 1.66797 10.0003 1.66797C14.6027 1.66797 18.3337 5.39893 18.3337 10.0013Z' stroke='#475467' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
            </g>
            <defs>
                <clipPath id='clip0_3331_145124'>
                    <rect width='20' height='20' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

