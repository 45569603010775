import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface CreateProductCategoryRequest extends HttpRequestData {
    readonly body: {
        name: string;
        description?: string;
    };
}

export interface CreateProductCategoryResponse {
    readonly id: string;
    name: string;
    description: string;
}

export const createProductCategory = httpUtil.createHttpRequestMeta<
    CreateProductCategoryRequest,
    CreateProductCategoryResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/product-categories',
    method: 'POST',
    authentication: 'bearer',
});
