import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export interface TimeOffDeleteRequest extends HttpRequestData {
    readonly pathData: {
        id: string;
    };
}

export const deleteTimeOff = httpUtil.createHttpRequestMeta<TimeOffDeleteRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/time-offs/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
