import { CampaignStatus, GetCampaignResponseItem } from '@esg/business-management/api/campaign';
import React from 'react';
import { CampaignContentRunningMetric } from './CampaignContentRunningMetric';
import { CampaignContentScheduleMetric } from './CampaignContentScheduleMetric';

interface CampaignContentMetricProps {
  activeKey: string;
  campaign: GetCampaignResponseItem;
}

export const CampaignContentMetric = (props: CampaignContentMetricProps) => {
    const {campaign } = props;

    if(campaign.status !== CampaignStatus.Scheduled)
    {
        return <CampaignContentRunningMetric activeKey={props.activeKey} campaign={campaign}/>;
    }

    return (<CampaignContentScheduleMetric activeKey={props.activeKey} campaign={campaign}/>);
};

