import React, { useCallback } from 'react';
import { managementProfileState, Text, useHttpCommand } from '@esg/ui';
import { GetAllCustomerActivityResponseItem } from '@esg/business-crm/api/customer-activity';
import { theme } from 'antd';
import dayjs from 'dayjs';
import { ActivityAction, ActivityType } from '@esg/shared/enum/CustomerActivityEnum';
import { useRecoilState } from 'recoil';
import { getDetailSale } from '@esg/business-pos';
import { useFetchAppointment } from '@/contexts/realtime-context/@share/hooks/useFetchAppointment';
import { useAppointmentForm } from '@/hooks/useAppointmentForm';
import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';

interface ItemTreeCustomerActivityProps {
    activity: GetAllCustomerActivityResponseItem;
}

const { useToken } = theme;

export function ItemTreeCustomerActivity({ activity }: ItemTreeCustomerActivityProps) {
    const [currentUser] = useRecoilState(managementProfileState);
    const { token } = useToken();
    const fetchAppointment = useFetchAppointment();
    const showAppointmentForm = useAppointmentForm();
    const { onShowFormSale } = useOpenSaleFrom();
    const { mutateAsync: getCurrentSale } = useHttpCommand(getDetailSale);
    
    const getActivityAction = (action: ActivityAction) => {
        switch (action) {
        case ActivityAction.Appointment:
            return 'The appointment ';
        case ActivityAction.Sale:
            return 'The sale ';
        case ActivityAction.Customer:
            return 'The profile ';
        default:
            return '';
        }
    };
    const getCodeActivity = activity.activityType === ActivityType.Create ? '' : `#${activity.activityDetails.code}`;

    const getColorActivity = activity.activityType === ActivityType.Create ? token.colorText : token.colorPrimary;

    const handleOnClick = useCallback(async () => {
        if(activity.activityAction == ActivityAction.Appointment){
            const appointmentResult = await fetchAppointment(activity.activityDetails.objectId);
            showAppointmentForm({
                initialValues: appointmentResult,
            });
        }
        if(activity.activityAction == ActivityAction.Sale){
            const sale = await getCurrentSale({
                pathData: {
                    id: activity.activityDetails.objectId,
                }
            });
            onShowFormSale(sale);
        }
    },[activity.activityAction, activity.activityDetails.objectId, fetchAppointment, getCurrentSale, onShowFormSale, showAppointmentForm]);

    const currentUserFullName = ` ${currentUser?.firstName} ${currentUser?.lastName} `;
    return (
        <div >
            <Text>{getActivityAction(activity.activityAction)}</Text>
            <Text
                color={getColorActivity}
                fontSize={16}
                onClick={handleOnClick}
            >
                {getCodeActivity}
            </Text>
            <Text color='#344054' fontSize={16} fontWeight={400}>
                {` was ${ActivityType[activity.activityType].toString()} by `}
            </Text>
            <Text fontSize={16} fontWeight={600}>{currentUserFullName} </Text>
            <Text
                color='#344054'
                fontSize={16}
                fontWeight={400}
            >{` at ${dayjs(activity.creationTime).format('HH:mm A')}`}</Text>
        </div>
    );
}
