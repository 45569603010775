import { PRODUCT_TRACKER_PURCHASE_ORDER_URL, PRODUCT_TRACKER_STOCK_URL ,
    PRODUCT_TRACKER_INVENTORY_URL,
    // PRODUCT_TRACKER_PACKAGES_URL,
    // PRODUCT_TRACKER_PACKAGE_CATEGORY_URL,
    PRODUCT_TRACKER_PRODUCTS_URL,
    PRODUCT_TRACKER_PRODUCT_CATEGORY_URL,
    PRODUCT_TRACKER_SERVICES_URL,
    PRODUCT_TRACKER_SERVICE_CATEGORY_URL,
    RESOURCE_MANAGEMENT_COMMISSION_URL,
    RESOURCE_MANAGEMENT_FACILITY_LIST_URL,
    RESOURCE_MANAGEMENT_JOB_TYPE_URL,
    RESOURCE_MANAGEMENT_SHIFT_URL,
    RESOURCE_MANAGEMENT_EMPLOYEE_MEMBER_URL,
    SETTING_ACCOUNT_INFORMATION_URL,
    SETTING_ACCOUNT_PERMISSION_URL,
    SETTING_BRANCH_URL,
    SETTING_PAYMENT_METHOD_URL,
    SETTING_TAX_URL,
    SETTING_USER_ROLE_URL,
    SETTING_BOOKING_ONLINE_URL,
    SALE_ANALYZER_APPOINTMENT_URL,
    SALE_ANALYZER_REVENUE_URL,
    AUTOMATION_URL,
    // SETTING_INTEGRATIONS_URL,
    SALE_ANALYZER_CLIENT_URL,
    SEGMENT_URL,
    PROMOTION_URL,
    CAMPAIGN_URL,
    CLIENT_REVIEW_URL,
} from './../url';

import { IMenuItem } from '@/Types';

export const resourceManagementMenu: IMenuItem[] = [
    {
        label: 'Employees',
        children: [
            {
                url: RESOURCE_MANAGEMENT_SHIFT_URL,
                label: 'Shifts',
            },
            {
                url: RESOURCE_MANAGEMENT_EMPLOYEE_MEMBER_URL,
                label: 'Employees',
            },
            {
                url: RESOURCE_MANAGEMENT_JOB_TYPE_URL,
                label: 'Job Type',
            },
            {
                url: RESOURCE_MANAGEMENT_COMMISSION_URL,
                label: 'Commissions',
                disable: true
            },
        ],
    },
    {
        label: 'Facilities',
        children: [
            {
                url: RESOURCE_MANAGEMENT_FACILITY_LIST_URL,
                label: 'Facility List',
            },
        ],
    },
];

export const saleAnalyzerMenu: IMenuItem[] = [
    {
        url: SALE_ANALYZER_REVENUE_URL,
        label: 'Revenue',
    },
    {
        url: SALE_ANALYZER_APPOINTMENT_URL,
        label: 'Appointment',
    },
    {
        url: SALE_ANALYZER_CLIENT_URL,
        label: 'Client',
    }
];

export const salonMarketingMenu: IMenuItem[] = [
    {
        label: 'Salon Marketing',
        children: [
            {
                url: AUTOMATION_URL,
                label: 'Automations',
            },
            {
                url: SEGMENT_URL,
                label: 'Client Segments',
            },
            {
                url: PROMOTION_URL,
                label: 'Promotions',
            },
            {
                url: CAMPAIGN_URL,
                label: 'Campaigns',
            },
            {
                url: CLIENT_REVIEW_URL,
                label: 'Client Reviews',
            },
        ],
    },
];

export const productTrackerMenu: IMenuItem[] = [
    {
        label: 'Service',
        children: [
            {
                url: PRODUCT_TRACKER_SERVICES_URL,
                label: 'Service List',
            },
            {
                url: PRODUCT_TRACKER_SERVICE_CATEGORY_URL,
                label: 'Service Category',
            },
        ],
    },
    {
        label: 'Product',
        children: [
            {
                url: PRODUCT_TRACKER_PRODUCTS_URL,
                label: 'Product List',
            },
            {
                url: PRODUCT_TRACKER_PRODUCT_CATEGORY_URL,
                label: 'Product Category',
            },
        ],
    },
    // {
    //     label: 'Package',
    //     children: [
    //         {
    //             url: PRODUCT_TRACKER_PACKAGES_URL,
    //             label: 'Package List',
    //         },
    //         {
    //             url: PRODUCT_TRACKER_PACKAGE_CATEGORY_URL,
    //             label: 'Package Category',
    //         },
    //     ],
    // },
    {
        label: 'Inventory',
        disable: true,
        children: [
            {
                url: PRODUCT_TRACKER_INVENTORY_URL,
                label: 'Inventory',
                disable: true
            },
            {
                url: PRODUCT_TRACKER_STOCK_URL,
                label: 'Stock take',
                disable: true
            },
            {
                url: PRODUCT_TRACKER_PURCHASE_ORDER_URL,
                label: 'Purchase order',
                disable: true
            }
        ],
    },
];

export const settingMenu: IMenuItem[] = [
    
    {
        label: 'Branches Setting',
        children: [
            {
                url: SETTING_BRANCH_URL,
                label: 'Branch Information',
            },
            {
                url: SETTING_TAX_URL,
                label: 'Tax',
            },
            {
                url: SETTING_PAYMENT_METHOD_URL,
                label: 'Payment method',
            },
            {
                url: SETTING_BRANCH_URL,
                label: 'Branch',
                disable: true
            },
            // {
            //     url: SETTING_BANK_ACCOUNT_URL,
            //     label: 'Bank account',
            // },
            {
                url: SETTING_BOOKING_ONLINE_URL,
                label: 'Booking online',
            },
            // {
            //     url: SETTING_INTEGRATIONS_URL,
            //     label: 'Integrations',
            // },
        ],
    },
    {
        label: 'Account Setting',
        children: [
            {
                url: SETTING_USER_ROLE_URL,
                label: 'Users & Roles',
            },
            {
                url: SETTING_ACCOUNT_INFORMATION_URL,
                label: 'Account information',
                disable: true
            },
            {
                url: SETTING_ACCOUNT_PERMISSION_URL,
                label: 'Account permission',
                disable: true
            },
        ],
    },
];

