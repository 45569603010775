import { UserOutlined } from '@ant-design/icons';
import { managementApis } from '@esg/business-management';
import { GetExternalResponseItem } from '@esg/business-management/api/external';
import { dayjs } from '@esg/shared';
import { Block, FlexBox, useHttpQuery, usePagination, Text, useLocalesFormat, TableCustom, IconSentCampaign, IconMailCampaign, IconFailedCampaign } from '@esg/ui';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { Avatar, TableColumnsType, Tag } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';
import { AutomationEventGetAllResponseItem } from '@esg/business-success-platform';
import { AutomationEventMetricOverviewCard } from '../components/AutomationEventMetricOverviewCard';
import { renderCategoryExternalTransaction, renderCodeExternalTransaction } from '@/helpers';

interface AutomationEventDetailContentRunningMetricProps {
    activeKey: string;
    automationEvent: AutomationEventGetAllResponseItem;
}

export const AutomationEventDetailContentRunningMetric = (props: AutomationEventDetailContentRunningMetricProps) => {
    const { automationEvent } = props;
    const [searchParams, setSearchParams] = usePagination();
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);

    const categoryExternalTransaction = renderCategoryExternalTransaction(automationEvent.type);
    const codeExternalTransaction = renderCodeExternalTransaction(automationEvent.type);

    const {
        data: automationEventExternalResponse,
        isLoading,
    } = useHttpQuery(managementApis.getAllExternal, {
        query: searchParams,
        body: {
            sourceId: automationEvent.id,
            category: categoryExternalTransaction,
            code: codeExternalTransaction,
        }
    });

    const {
        data: automationOverviewExternalResponse,
        isLoading : isLoadingOverview,
    } = useHttpQuery(managementApis.getOverviewExternal, {
        body: {
            sourceId: automationEvent.id,
            category: categoryExternalTransaction,
            code:  codeExternalTransaction
        }
    });

    const renderExternalCard = () => {
        return <TableCustom
            loading={isLoading}
            columns={columns}
            dataSource={automationEventExternalResponse?.items}
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, page: page.toString() })
            }
            onSearch={(value) =>
                setSearchParams({ ...searchParams, search: value })
            }
            titleTableTransCode='Message Recipient List'
            placeholderSearchTransCode='Search by client name'
        />;
    };

    const columns: TableColumnsType<GetExternalResponseItem> = [
        {
            title: 'Client',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (_, record) => {
                return (<FlexBox alignItems='center' gap={8}>
                    <Avatar size={32} shape='circle' src={record?.customer?.avatar} icon={<UserOutlined />} />
                    <Block display='flex'>
                        <FlexBox direction='column'>
                            <Text maxWidth={300} ellipsis fontSize={14} lineHeight='20px' fontWeight={400}>{record?.customer?.fullName}</Text>
                            {
                                record?.customer?.customerLabel === 0
                                    ? <Text fontSize={14} lineHeight='20px' fontWeight={400} color='#079455'>New Client</Text>
                                    : record?.customer?.customerLabel === 1
                                        ? <Text fontSize={14} lineHeight='20px' fontWeight={400} color='#155EEF'>Returning Client</Text>
                                        : null
                            }
                        </FlexBox>
                    </Block>
                </FlexBox>);
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => {
                return <Text >{record?.customer?.email}</Text>;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => {
                return <Text >{record?.customer?.phone}</Text>;
            },
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: 'channel',
            render: (_, record) => {
                return <Tag style={{borderRadius:6}}>{record.type}</Tag>;
            },
        },
        {
            title: 'Date & time',
            dataIndex: 'creationTime',
            key: 'creationTime',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.handleTime || record.creationTime), `${currentLocale?.format}  HH:mm A`)}</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                return renderStatusExternal(record);
            },
        }
    ];

    const renderStatusExternal = (record: GetExternalResponseItem) => {
        if (record.status == 2) {
            return <Tag color='success'>Delivered</Tag>;
        }
        if (record.status == 3) {
            return <Tag color='red'>Failed</Tag>;
        }
        if (record.status == 1) {
            return <Tag color='blue'>In progress</Tag>;
        }
        return <Tag>Pending</Tag>;
    };

    return (<FlexBox direction='column' gap={24}>
        <FlexBox gap={8}>
            <AutomationEventMetricOverviewCard 
                total={automationOverviewExternalResponse?.totalSent || 0} 
                emailTotal={automationOverviewExternalResponse?.totalMailSent || 0} 
                smsTotal={automationOverviewExternalResponse?.totalSmsSent || 0} 
                label='Sent' 
                icon={<IconSentCampaign />} 
                isLoading={isLoadingOverview}
            />
            <AutomationEventMetricOverviewCard 
                total={automationOverviewExternalResponse?.totalDelivered || 0} 
                percentTotal={automationOverviewExternalResponse?.totalSent ? (automationOverviewExternalResponse?.totalDelivered / automationOverviewExternalResponse?.totalSent) * 100 : undefined}
                percentEmailTotal={automationOverviewExternalResponse?.totalDelivered ? (automationOverviewExternalResponse?.totalMailDelivered / automationOverviewExternalResponse?.totalDelivered) * 100 : undefined}
                percentSmsTotal={automationOverviewExternalResponse?.totalDelivered ? (automationOverviewExternalResponse?.totalSmsDelivered / automationOverviewExternalResponse?.totalDelivered) * 100 : undefined}
                emailTotal={automationOverviewExternalResponse?.totalMailDelivered || 0} 
                smsTotal={automationOverviewExternalResponse?.totalSmsDelivered || 0} 
                label='Delivered' 
                icon={<IconMailCampaign />} 
                isLoading={isLoadingOverview}
            />
            <AutomationEventMetricOverviewCard 
                total={automationOverviewExternalResponse?.totalFailed || 0} 
                percentTotal={automationOverviewExternalResponse?.totalSent ? (automationOverviewExternalResponse?.totalFailed / automationOverviewExternalResponse?.totalSent) * 100 : undefined}
                percentEmailTotal={automationOverviewExternalResponse?.totalFailed ? (automationOverviewExternalResponse?.totalMailFailed / automationOverviewExternalResponse?.totalFailed) * 100 : undefined}
                percentSmsTotal={automationOverviewExternalResponse?.totalFailed ? (automationOverviewExternalResponse?.totalSmsFailed / automationOverviewExternalResponse?.totalFailed) * 100 : undefined}
                emailTotal={automationOverviewExternalResponse?.totalMailFailed || 0} 
                smsTotal={automationOverviewExternalResponse?.totalSmsFailed || 0} 
                label='Failed' 
                icon={<IconFailedCampaign />} 
                isLoading={isLoadingOverview}
            />
        </FlexBox>
        {renderExternalCard()}
    </FlexBox>);
};

