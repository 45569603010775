import { translateCodes } from '@/locales';

export const promotionRules = {
    promotionName: {
        required: translateCodes.VAL_SAM_03,
        maxLength: {
            value: 255,
            message: translateCodes.MAX_LENGTH_PROMOTION
        },
    },
    description: {
        maxLength: {
            value: 255,
            message: translateCodes.MAX_LENGTH_PROMOTION_DESCRIPTION
        },
    },
    valueRequire: {
        required: translateCodes.VAL_SAM_02,
    },
    effectiveDate:{
        required: translateCodes.VAL_SAM_06,
    },
   
};

export const promotionConditionRules = {
    valueRequire: {
        required: translateCodes.VAL_SAM_02,
    }, 
    objectValue: {
        required: translateCodes.VAL_SAM_02,
    }
};