import React from 'react';
import { IconProps } from '../../Types';

export const IconPromotionTotal = (props: IconProps) => {
    return (
        <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0.400391 24C0.400391 10.7452 11.1456 0 24.4004 0C37.6552 0 48.4004 10.7452 48.4004 24C48.4004 37.2548 37.6552 48 24.4004 48C11.1456 48 0.400391 37.2548 0.400391 24Z' fill='#FEF0C7' />
            <path d='M24.4004 18V34M24.4004 18H20.8647C20.3437 18 19.8441 17.7893 19.4757 17.4142C19.1073 17.0391 18.9004 16.5304 18.9004 16C18.9004 15.4696 19.1073 14.9609 19.4757 14.5858C19.8441 14.2107 20.3437 14 20.8647 14C23.6147 14 24.4004 18 24.4004 18ZM24.4004 18H27.9361C28.4571 18 28.9567 17.7893 29.3251 17.4142C29.6934 17.0391 29.9004 16.5304 29.9004 16C29.9004 15.4696 29.6934 14.9609 29.3251 14.5858C28.9567 14.2107 28.4571 14 27.9361 14C25.1861 14 24.4004 18 24.4004 18ZM32.4004 23V30.8C32.4004 31.9201 32.4004 32.4802 32.1824 32.908C31.9907 33.2843 31.6847 33.5903 31.3084 33.782C30.8805 34 30.3205 34 29.2004 34L19.6004 34C18.4803 34 17.9202 34 17.4924 33.782C17.1161 33.5903 16.8101 33.2843 16.6184 32.908C16.4004 32.4802 16.4004 31.9201 16.4004 30.8V23M14.4004 19.6L14.4004 21.4C14.4004 21.9601 14.4004 22.2401 14.5094 22.454C14.6053 22.6422 14.7582 22.7951 14.9464 22.891C15.1603 23 15.4403 23 16.0004 23L32.8004 23C33.3604 23 33.6405 23 33.8544 22.891C34.0425 22.7951 34.1955 22.6422 34.2914 22.454C34.4004 22.2401 34.4004 21.9601 34.4004 21.4V19.6C34.4004 19.0399 34.4004 18.7599 34.2914 18.546C34.1955 18.3578 34.0425 18.2049 33.8544 18.109C33.6405 18 33.3604 18 32.8004 18L16.0004 18C15.4403 18 15.1603 18 14.9464 18.109C14.7582 18.2049 14.6053 18.3578 14.5094 18.546C14.4004 18.7599 14.4004 19.0399 14.4004 19.6Z' stroke='#DC6803' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

