import {
    Block, FlexBox, Text,
    usePagination,
    TableCustom,
    IconPromotionRevenue,
    IconPromotionTotal,
    IconPromotionUsed,
    IconPromotionUnused,
    IconPromotionExpired,
    useHttpQuery,
    useLocalesFormat
} from '@esg/ui';
import React from 'react';
import { Avatar, TableColumnsType, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { dayjs } from '@esg/shared';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { CustomerPromotionStatus } from '@esg/business-management/api/customer-promotion';
import { automationEventApi, AutomationEventGetAllResponseItem } from '@esg/business-success-platform';
import { AutomationGetCustomerPromotionItemResponse } from '@esg/business-success-platform/api/automation-event/automationEventCustomerPromotion';
import { AutomationEventPromotionOverviewCard } from '../components/AutomationEventPromotionOverviewCard';

interface AutomationEventPromotionProps {
    activeKey: string;
    automationEvent: AutomationEventGetAllResponseItem;
}

export interface FilterPromotionRecipientParams {
    readonly date?: string;
    readonly status?: CustomerPromotionStatus[];
}

export const AutomationEventPromotion = (props: AutomationEventPromotionProps) => {
    const { automationEvent } = props;
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [searchParams, setSearchParams] = usePagination();
    const { formatDate } = useLocalesFormat();

    const {
        data: campaignOverviewPromotionResponse,
        isLoading: isLoadingOverview,
    } = useHttpQuery(automationEventApi.getAutomationOverviewPromotion, {
        pathData: {
            id: automationEvent.id,
        },
        query: {
            ...searchParams,
        }
    });

    const {
        data: campaignCustomerPromotionResponse,
        isLoading: isLoadingPromotion,
    } = useHttpQuery(automationEventApi.getAutomationCustomerPromotion, {
        pathData: {
            id: automationEvent.id,
        },
        query: {
            ...searchParams,
        }
    });

    const renderPromotionCard = () => {
        return <TableCustom
            loading={isLoadingPromotion}
            columns={columns}
            dataSource={campaignCustomerPromotionResponse?.items ?? []}
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, page: page.toString() })
            }
            onSearch={(value) =>
                setSearchParams({ ...searchParams, search: value })
            }
            titleTableTransCode='Promotion Recipient List'
            placeholderSearchTransCode='Search by client name, phone number...'
        />;
    };

    const columns: TableColumnsType<AutomationGetCustomerPromotionItemResponse> = [
        {
            title: 'Client',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (_, record) => {
                return (<FlexBox alignItems='center' gap={8}>
                    <Avatar size={32} shape='circle' src={record?.customer?.avatar} icon={<UserOutlined />} />
                    <Block display='flex'>
                        <FlexBox direction='column'>
                            <Text maxWidth={300} ellipsis fontSize={14} lineHeight='20px' fontWeight={400}>{record.customer?.fullName}</Text>
                        </FlexBox>
                    </Block>
                </FlexBox>);
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => {
                return <Text >{record.customer?.email}</Text>;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => {
                return <Text >{record.customer?.phone}</Text>;
            },
        },
        {
            title: 'Granting date',
            dataIndex: 'creationTime',
            key: 'creationTime',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.creationTime), `${currentLocale?.format}`)} { }</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                if (record.status == CustomerPromotionStatus.Used) {
                    return <Tag>Used</Tag>;
                }

                const currentDate = dayjs().startOf('day');

                if (currentDate > dayjs(record.promotionApply?.expirationDate)) {
                    return <Tag color='red' >Expired</Tag>;
                }

                return <Tag color='success'>Unused</Tag>;
            },
        }
    ];

    return <FlexBox direction='column' gap={24}>
        <FlexBox gap={8}>
            <AutomationEventPromotionOverviewCard
                total={campaignOverviewPromotionResponse?.totalRevenue || 0}
                label='Total revenues'
                icon={<IconPromotionRevenue />}
                isLoading={isLoadingOverview}
            />
            <AutomationEventPromotionOverviewCard
                total={campaignOverviewPromotionResponse?.totalPromotion || 0}
                label='Total Promotions'
                icon={<IconPromotionTotal />}
                isLoading={isLoadingOverview}
            />
            <AutomationEventPromotionOverviewCard
                total={campaignOverviewPromotionResponse?.totalUsePromotion || 0}
                label='Used'
                icon={<IconPromotionUsed />}
                isLoading={isLoadingOverview}
            />
            <AutomationEventPromotionOverviewCard
                total={campaignOverviewPromotionResponse?.totalUnusedPromotion || 0}
                label='Unused'
                icon={<IconPromotionUnused />}
                isLoading={isLoadingOverview}
            />
            <AutomationEventPromotionOverviewCard
                total={campaignOverviewPromotionResponse?.totalExpiredPromotion || 0}
                label='Expired'
                icon={<IconPromotionExpired />}
                isLoading={isLoadingOverview}
            />
        </FlexBox>
        {renderPromotionCard()}
    </FlexBox>;
};

