import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
interface GetCurrentBookingOnlineSettingRequest extends HttpRequestData {
}

interface BookingOnlineDateOff {
    readonly id: string;
    readonly dateOff: string;
}

export interface GetCurrentBookingOnlineSettingResponse {
    readonly id: string;
    readonly branchId: string;
    readonly timeSlotDuration: number;
    readonly doubleAppointmentEnabled: boolean;
    readonly doubleAppointmentMaxSlot: number;
    readonly bookingFeatureLimit: number;
    readonly allowCustomerSelfCancel: boolean;
    readonly allowBookingSlotLimit: boolean;
    readonly bookingSlotLimit: number;
    readonly numberHoursBeforeCancel: number;
    readonly allowGroupAppointment: boolean;
    readonly requiredSelectEmployee: boolean;
    readonly showServicePrice: boolean;
    readonly confirmOnlineAppointment: boolean;
    readonly bookingOnlineDateOffs?: BookingOnlineDateOff[];

}

export const getCurrentBookingOnlineSetting = httpUtil.createHttpRequestMeta<
    GetCurrentBookingOnlineSettingRequest,
    GetCurrentBookingOnlineSettingResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/booking-online-settings/current',
    method: 'GET',
    authentication: 'bearer',
});
