export enum CampaignType {
    Promotion = 0,
    Notification = 1
}

export enum CampaignTarget {
    AllClient = 0,
    ReturnClient = 1,
    NewClient = 2,
    SegmentClient = 3
}

export enum CampaignScheduleType {
    Immediately = 0,
    Later = 1,
}

export enum CampaignStatus {
    Running = 0,
    Draft = 1,
    Scheduled = 2,
    Paused = 3,
    Completed = 4,
    InProgress = 5
}

export enum CampaignChannelType {
    Sms = 0,
    Mail = 1
}