import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface getOverviewClientSourceRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export interface getOverviewClientSourceResponse {
    newClient?: number;
    name?: number;
}

export const getOverviewClientSourceReport = httpUtil.createHttpRequestMeta<
    getOverviewClientSourceRequest,
    getOverviewClientSourceResponse[]
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/clients-report/overview-source',
    authentication: 'bearer',
});