import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';

import { PaginationRequest, TemplateVariableType , getEnv } from '@esg/shared';

interface GetAllTemplateVariableRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}
export interface GetAllTemplateVariaResponseItem {
    id: string;
    type?: TemplateVariableType;
    name?: string;
    exampleValue?: string;
}

export const templateVariableGetAll = httpUtil.createHttpRequestMeta<
    GetAllTemplateVariableRequest,
    PaginationResponse<GetAllTemplateVariaResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/template-variables',
    method: 'GET',
    authentication: 'bearer',
});
