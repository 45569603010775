import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetProductDetailClientDetailTotalRequest extends HttpRequestData {
    readonly query: {
        readonly productId: string;
        readonly customerId: string;
    };
}
export interface GetProductDetailClientDetailTotalResponse {
    productName?: string,
    latestSaleDate?: string;
    total?: number;
    totalAmount?: number;
}

export const getProductDetailClientDetailTotalReport = httpUtil.createHttpRequestMeta<
    GetProductDetailClientDetailTotalRequest,
    GetProductDetailClientDetailTotalResponse
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/clients-detail/detail-by-product-total',
    authentication: 'bearer',
});