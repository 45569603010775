import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';
import { GetAllCustomerGroupResponseItem } from '../customer-group';
import { GetAllCustomerSourceResponseItem } from '../customer-source';

interface GetCustomerDetailRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

interface CustomerRank {
    name: string;
    id: string;
}

export interface GetCustomerDetailResponse {
    readonly id: string;
    readonly fullName: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly avatar?: string;
    readonly customerCode: string;
    readonly email?: string;
    readonly phone?: string;
    readonly dateOfBirth?: string;
    readonly profileCreationTime: string;
    readonly customerGroup: GetAllCustomerGroupResponseItem;
    readonly customerRank: CustomerRank;
    readonly customerSource: GetAllCustomerSourceResponseItem;
    readonly gender?: number;
    readonly address?: string;
    readonly employeeReferral?: string;
    readonly customerReferral?: string;
    readonly note?: string;
    readonly customerLabel?: number;
    readonly lastAccess?: string;
    readonly totalAppointment: number;
    readonly totalSale : number;
    readonly totalRevenue : number;
}

export const getCustomerDetail = httpUtil.createHttpRequestMeta<GetCustomerDetailRequest, GetCustomerDetailResponse>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customers/:id',
    method: 'GET',
    authentication: 'bearer'
});