import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface DeleteCustomerNoteRequest extends HttpRequestData {
}

export const deleteCustomerNote = httpUtil.createHttpRequestMeta<
    DeleteCustomerNoteRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-notes/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
