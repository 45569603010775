import { managementApis } from '@esg/business-management';
import { CampaignChannelType, CampaignType, GetCampaignResponseItem } from '@esg/business-management/api/campaign';
import { CategoryExternalTransaction, CodeExternalTransaction, GetExternalResponseItem, StatusExternalTransaction } from '@esg/business-management/api/external';
import { dayjs } from '@esg/shared';
import { FlexBox, useHttpQuery, usePagination, Text, useLocalesFormat, TableCustom, IconSentCampaign, IconMailCampaign, IconFailedCampaign, IconInfoCircle, AppFilter, SelectField } from '@esg/ui';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { TableColumnsType, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { MetricOverviewCard } from './components';
import { CustomerColumnView } from '@/components';

interface CampaignContentMetricProps {
    activeKey: string;
    campaign: GetCampaignResponseItem;
}

export interface FilterCampaignContentParams {
    readonly channel?: CampaignChannelType;
    readonly status?: StatusExternalTransaction;
}

export const campaignChannelOptions = [
    {label: 'Email' , value: CampaignChannelType.Mail},
    {label: 'Sms' , value:  CampaignChannelType.Sms}
];

export const externalStatusOptions = [
    {label: 'Delivered' , value: StatusExternalTransaction.Success},
    {label: StatusExternalTransaction[StatusExternalTransaction.Scheduled] , value: StatusExternalTransaction.Scheduled},
    {label:  StatusExternalTransaction[StatusExternalTransaction.Failed] , value: StatusExternalTransaction.Failed},
    {label: 'In progress' , value: StatusExternalTransaction.Processing},
    {label: StatusExternalTransaction[StatusExternalTransaction.Pending] , value: StatusExternalTransaction.Pending}
];

export const CampaignContentRunningMetric = (props: CampaignContentMetricProps) => {
    const { campaign } = props;
    const campaignCode = campaign.type == CampaignType.Promotion ? CodeExternalTransaction.PromotionCampaign : CodeExternalTransaction.NotificationCampaign;
    const [searchParams, setSearchParams] = usePagination();
    const [filterParams, setFilterParams] = useState<FilterCampaignContentParams>({});
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);

    const {
        data: campaignExternalResponse,
        isLoading,
    } = useHttpQuery(managementApis.getAllExternal, {
        query: {...searchParams, ...filterParams},
        body: {
            sourceId: campaign.id,
            category: CategoryExternalTransaction.Campaign,
            code: campaignCode
        }
    });

    const {
        data: campaignOverviewExternalResponse,
        isLoading : isLoadingOverview,
    } = useHttpQuery(managementApis.getOverviewExternal, {
        body: {
            sourceId: campaign.id,
            category: CategoryExternalTransaction.Campaign,
            code: campaignCode
        }
    });

    const renderExternalCard = () => {
        return <TableCustom
            showFilterDropdown={showFilterRender}
            pageInfo={campaignExternalResponse?.meta}
            loading={isLoading}
            columns={columns}
            dataSource={campaignExternalResponse?.items}
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, page: page.toString() })
            }
            onSearch={(value) =>
                setSearchParams({ ...searchParams, search: value })
            }
            titleTableTransCode='Message Recipient List'
            placeholderSearchTransCode='Search by client name'
        />;
    };

    const columns: TableColumnsType<GetExternalResponseItem> = [
        {
            title: 'Client',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (_, record) => {
                return (<CustomerColumnView  customer={record.customer}/>);
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => {
                return <Text >{record?.customer?.email}</Text>;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => {
                return <Text >{record?.customer?.phone}</Text>;
            },
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: 'channel',
            render: (_, record) => {
                return <Tag style={{borderRadius:6}}>{record.type}</Tag>;
            },
        },
        {
            title: 'Date & time',
            dataIndex: 'creationTime',
            key: 'creationTime',
            render: (_, record) => {
                return <Text>{formatDate(dayjs(record.handleTime || record.creationTime), `${currentLocale?.format}  HH:mm A`)}</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                return renderStatusExternal(record);
            },
        }
    ];

    const renderStatusExternal = (record: GetExternalResponseItem) => {
        if (record.status == StatusExternalTransaction.Success) {
            return <Tag color='success'>Delivered</Tag>;
        }
        if (record.status == StatusExternalTransaction.Failed) {
            return <FlexBox alignItems='center'>
                <Tag color='red'>Failed</Tag>  
                <Tooltip
                    placement='top'
                    title ={record.errorMessage}
                >
                    <IconInfoCircle/>
                </Tooltip>
            </FlexBox>;
        }
        if (record.status == StatusExternalTransaction.Processing) {
            return <Tag color='blue'>In progress</Tag>;
        }
        return <Tag>Pending</Tag>;
    };

    const showFilterRender = () => {
        return (
            <AppFilter<FilterCampaignContentParams>
                defaultValues={filterParams}
                handleSubmit={setFilterParams}
                onCancel={() => { }}
            >
                {({ renderKey }) => (
                    <FlexBox direction='column' gap={16} key={renderKey}>
                        <SelectField
                            label='Channels'
                            placeholder='Select channel'
                            style={{ width: '100%' }}
                            name='channel'
                            options={campaignChannelOptions}
                        />
                        <SelectField
                            label='Status'
                            placeholder='Select status'
                            style={{ width: '100%' }}
                            name='status'
                            options={externalStatusOptions}
                        />
                    </FlexBox>
                )}
            </AppFilter>
        );
    };

    return (<FlexBox direction='column' gap={24}>
        <FlexBox gap={8}>
            <MetricOverviewCard 
                total={campaignOverviewExternalResponse?.totalSent || 0} 
                emailTotal={campaignOverviewExternalResponse?.totalMailSent || 0} 
                smsTotal={campaignOverviewExternalResponse?.totalSmsSent || 0} 
                label='Sent' 
                icon={<IconSentCampaign />} 
                isLoading={isLoadingOverview}
            />
            <MetricOverviewCard 
                total={campaignOverviewExternalResponse?.totalDelivered || 0} 
                percentTotal={campaignOverviewExternalResponse?.totalSent ? (campaignOverviewExternalResponse?.totalDelivered / campaignOverviewExternalResponse?.totalSent) * 100 : undefined}
                percentEmailTotal={campaignOverviewExternalResponse?.totalDelivered ? (campaignOverviewExternalResponse?.totalMailDelivered / campaignOverviewExternalResponse?.totalDelivered) * 100 : undefined}
                percentSmsTotal={campaignOverviewExternalResponse?.totalDelivered ? (campaignOverviewExternalResponse?.totalSmsDelivered / campaignOverviewExternalResponse?.totalDelivered) * 100 : undefined}
                emailTotal={campaignOverviewExternalResponse?.totalMailDelivered || 0} 
                smsTotal={campaignOverviewExternalResponse?.totalSmsDelivered || 0} 
                label='Delivered' 
                icon={<IconMailCampaign />} 
                isLoading={isLoadingOverview}
            />
            <MetricOverviewCard 
                total={campaignOverviewExternalResponse?.totalFailed || 0} 
                percentTotal={campaignOverviewExternalResponse?.totalSent ? (campaignOverviewExternalResponse?.totalFailed / campaignOverviewExternalResponse?.totalSent) * 100 : undefined}
                percentEmailTotal={campaignOverviewExternalResponse?.totalFailed ? (campaignOverviewExternalResponse?.totalMailFailed / campaignOverviewExternalResponse?.totalFailed) * 100 : undefined}
                percentSmsTotal={campaignOverviewExternalResponse?.totalFailed ? (campaignOverviewExternalResponse?.totalSmsFailed / campaignOverviewExternalResponse?.totalFailed) * 100 : undefined}
                emailTotal={campaignOverviewExternalResponse?.totalMailFailed || 0} 
                smsTotal={campaignOverviewExternalResponse?.totalSmsFailed || 0} 
                label='Failed' 
                icon={<IconFailedCampaign />} 
                isLoading={isLoadingOverview}
            />
        </FlexBox>
        {renderExternalCard()}
    </FlexBox>);
};

