import { HttpRequestData, httpUtil } from '@esg/framework';
import { AppointmentStatus , getEnv } from '@esg/shared';

export enum AppointmentType {
	Salon = 0,
	Online = 1,
}
export interface AppointmentServiceCreate {
	appointmentServiceDetails: AppointmentServiceDetailCreate[];
}

interface AppointmentServiceEmployee {
	employeeId?: string;
	timeStartWork?: string;
	timeEndWork?: string;
}

export interface AppointmentServiceDetailCreate {
	serviceId?: string;
	facilityId?: string;
	employeeIds?: string[];
	startTime?: string;
	endTime?: string;
	appointmentServiceEmployees?: AppointmentServiceEmployee[];
	isValid?: boolean;
}

export interface AppointmentCreateRequest extends HttpRequestData {
	body: {
		readonly id?: string;
		readonly customerId: string;
		readonly numberOfGuest: number;
		readonly appointmentDate: string;
		readonly notes?: string;
		readonly appointmentType: AppointmentType;
		readonly customer?: {
			id: string;
			fullName: string;
			firstName: string;
			phone?: string;
			email?: string;
			avatar?: string;
		};
		readonly appointmentServices: AppointmentServiceCreate[];
		readonly status?: AppointmentStatus;
	};
}

export const createAppointment = httpUtil.createHttpRequestMeta<AppointmentCreateRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'POST',
    path: '/api/v1/appointments',
    authentication: 'bearer',
});
