import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetProductsByCategoryRevenueRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export interface GetProductsByCategoryRevenueItem {
    id: string;
    name: string;
    quantity: number;
    revenue: number;
}

export const getProductsByCategoryRevenueReport = httpUtil.createHttpRequestMeta<
    GetProductsByCategoryRevenueRequest,
    PaginationResponse<GetProductsByCategoryRevenueItem>
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/revenue-report/product-category',
    authentication: 'bearer',
});