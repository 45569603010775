import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';
import { TimeOffType } from './@type';

export interface CreateTimeOffResponse {
    readonly id: string;
    readonly date: string;
    readonly endTime: string;
    readonly employeeId: string;
    readonly startTime: string;
    readonly type: TimeOffType;
    readonly repeatToDate?: string;
}

export interface CreateTimeOffRequest extends HttpRequestData {
    readonly body: {
        readonly date: string;
        readonly endTime: string;
        readonly employeeId: string;
        readonly startTime: string;
        readonly type: TimeOffType;
        readonly repeatToDate?: string;
    };
}

export const createTimeOff = httpUtil.createHttpRequestMeta<
    CreateTimeOffRequest,
    CreateTimeOffResponse
>({
    method: 'POST',
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    authentication: 'bearer',
    path: '/api/v1/time-offs',
});
