import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { EmployeeGetDetailResponse } from '.';

interface GetAllEmployeesRequest extends HttpRequestData {
    readonly query: PaginationRequest & {
        isIncludeRelationship: boolean;
    };
}

export const getAllEmployees = httpUtil.createHttpRequestMeta<
    GetAllEmployeesRequest,
    PaginationResponse<EmployeeGetDetailResponse>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/employees',
    method: 'GET',
    authentication: 'bearer',
});
