import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export interface CreateSegmentRequest extends HttpRequestData {
    readonly body: {
        name: string;
        description: string;
        isAutoUpdate?: boolean;
        operator?: number;
        segmentConditions?: SegmentConditionCreateItem[];
    };
}

export interface SegmentConditionCreateItem {
    id?: string;
    name?: string;
    code?: number;
    value?: string;
}

export const createSegment = httpUtil.createHttpRequestMeta<
    CreateSegmentRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/segments',
    method: 'POST',
    authentication: 'bearer',
});
