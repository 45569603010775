import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { FileUpload } from '../../../upload/api/file/createFile';
import { GetCurrentPageBookingSettingResponse } from './getCurrentPageBookingSetting';

export interface UpdatePageBookingSettingRequest extends HttpRequestData {
    readonly body: {
        readonly id?: string;
        readonly bookingSlug?: string;
        readonly allowBookingOnline?: boolean;
        readonly bookTabName?: string;
        readonly bookTabInformation?: string;
        readonly showTopBanner?: boolean;
        readonly topBannerUrl?: string;
        readonly showGallery?: boolean;
        readonly galleryTabName?: string;
        readonly galleryImages?: FileUpload[];
    };
}

export const updatePageBookingSetting = httpUtil.createHttpRequestMeta<
    UpdatePageBookingSettingRequest,
    GetCurrentPageBookingSettingResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/page-booking-settings/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
