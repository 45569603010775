import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/ui';
import { getEnv } from '@esg/shared';
import { ObjectMode, ObjectType } from '../promotion';
import { CustomerResponse, PromotionApplyResponse } from '../campaign';
import { CustomerPromotionStatus } from './enum';

export interface CustomerPromotionGetForSaleRequest extends HttpRequestData {
    pathData: {
        readonly id: string;
    };
    query: PaginationRequest;
}

export interface PromotionConditionResponse {
    id: string;
    objectType: ObjectType;
    numberOfTimes: number;
    objectMode: ObjectMode;
    objectValue?: string;
}

export interface CustomerPromotionResponseItem {
    id: string;
    sourceId?: string;
    sourceCode: string;
    promotionApplyId: string;
    promotionApply?: PromotionApplyResponse;
    branchId: string;
    customerId: string;
    status: CustomerPromotionStatus;
    customer?: CustomerResponse;
    creationTime: string;
}

export const getCustomerPromotionForSale = httpUtil.createHttpRequestMeta<
    CustomerPromotionGetForSaleRequest,
    PaginationResponse<CustomerPromotionResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-promotions/sale/:id',
    method: 'GET',
    authentication: 'bearer',
});