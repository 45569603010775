import { Paragraph, Spacer, Text } from '@esg/ui';
import React from 'react';
import { translateCodes } from '@/locales';

export const EmailTemplateDeleteConfirm = () => {
    return (
        <Spacer top='xs'>
            <Text fontWeight='bold' translateCode={translateCodes.DELETE_TEMPLATE} />
            <Spacer bottom='xs' />
            <Paragraph>
                <Text translateCode={translateCodes.EMAIL_TEMPLATE_DELETE_MESSAGE} />
            </Paragraph>
        </Spacer>
    );
};