import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';
import { GetDetailSaleResponse } from './getDetailSale';

interface CheckoutSaleRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        paymentMethodSettingId?: string;
    };
}

export const checkoutSale = httpUtil.createHttpRequestMeta<
    CheckoutSaleRequest,
    GetDetailSaleResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/sales/:id/checkout',
    method: 'PATCH',
    authentication: 'bearer',
});
