import React from 'react';

import { IconProps } from '../../Types';

export const IconRescheduledAppointment = (props: IconProps) => {
    return (
        <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z' fill='#D1E0FF'/>
            <path d='M53.228 41.686C53.2977 41.1336 53.3337 40.5707 53.3337 39.9993C53.3337 32.6356 47.3641 26.666 40.0003 26.666C32.6365 26.666 26.667 32.6356 26.667 39.9993C26.667 47.3631 32.6365 53.3327 40.0003 53.3327C40.5809 53.3327 41.1527 53.2956 41.7137 53.2236M40.0003 31.9993V39.9993L44.9848 42.4916M49.3337 53.3327V45.3327M45.3337 49.3327H53.3337' stroke='#155EEF' strokeWidth='1.67' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};
