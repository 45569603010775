import React, { useCallback, useMemo } from 'react';
import { FlexBox, Spacer, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { managementApis } from '@esg/business-management';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { appointmentActivityHelper } from '@/helpers/appointmentActivityHelper';
import { ActivityLogItem, ActivityInfo } from '@/components/activities';

interface AppointmentActivityProps {
    readonly appointmentId?: string
}

export default function AppointmentActivity(props: AppointmentActivityProps) {
    const {formatDate} = useLocalesFormat();
    const {t} = useTranslation();
    const [currentLocale] = useRecoilState(currentLocaleState);

    const formatDateTime = useCallback((date: string) => {
        return formatDate(date, `${currentLocale?.format}, HH:mm`);
    }, [currentLocale, formatDate]);

    const { data: appointmentActivities, isFetching } = useHttpQuery(
        managementApis.getAllAppointmentActivities,
        {
            pathData: {
                id: props.appointmentId,
            },
        }
    );
    
    const activitiesLogs = useMemo(()=> {
        const activities = appointmentActivities?.activities?.map((activity) => {
            const appointmentActivities = activity.filter(o => o.entityName === 'Appointment');
            const isCreateAppointment = appointmentActivities.some(o => o.action === 'Added');
    
            const actionTime = appointmentActivities.find(o => o.createdTime)?.createdTime;
            const actionTimeFormat = formatDateTime(actionTime!);
            const actionByName = appointmentActivities.find(o=> o.createByName!)?.createByName;
    
            if (isCreateAppointment) {
                const createById = appointmentActivities.find(o=> o.createById)?.createById;
                return {
                    actionName: 'Created',
                    rootEntityName: 'Appointment',
                    actionBy: actionByName,
                    actionTime: actionTimeFormat,
                    activityData: appointmentActivityHelper.getContentAppointmentCreate(
                        activity, 
                        formatDateTime, 
                        t
                    ),
                    doerId: createById
                };
            }

            const editById = appointmentActivities.find(o => o.action !== 'Added')?.createById;
    
            return {
                actionName: 'Edited',
                rootEntityName: 'Appointment',
                actionBy: actionByName,
                actionTime: actionTimeFormat,
                activityData: appointmentActivityHelper.getContentAppointmentEdit(
                    activity, 
                    formatDateTime, 
                    t
                ),
                doerId: editById
            };
        }) ?? [];

        return activities.filter(activity => activity.activityData !=='') as ActivityInfo[];
    }, 
    [appointmentActivities?.activities, formatDateTime, t]);
    
    return (
        <Spacer left_right={24} bottom={16}>
            <FlexBox direction='column' gap={16}>
                {activitiesLogs?.map((activity, index) => {
                    return (
                        <ActivityLogItem
                            loading={isFetching}
                            key={index}
                            activityInfo={activity}
                        />
                    );
                })}
            </FlexBox>
        </Spacer>
    );
}
