import React from 'react';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { TableCustom, useHttpQuery, useLocalesFormat, Text} from '@esg/ui';
import {  TableColumnsType, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { CustomerPromotionByCustomerResponseItem, CustomerPromotionStatus, getCustomerPromotionByCustomer } from '@esg/business-management';
import { UnitEnum } from '@esg/business-pos';
import { dayjs } from '@esg/shared';
import { translateCodes } from '@/locales';

import { usePaginationReport } from '@/pages/sale-analyzer/report-hooks';

interface CustomerPromotionTableProps {
    customer: GetCustomerDetailResponse;
}

export const CustomerPromotionTable = ({ customer }: CustomerPromotionTableProps) => {
    const { t } = useTranslation();
    const { formatDate, formatCurrency } = useLocalesFormat();
    const [searchParams, setSearchParams] = usePaginationReport({
        pageSizeDetail: '10',
    });

    const { data: customerPromotions, isFetching: isCustomerPromotionFetching } = useHttpQuery(getCustomerPromotionByCustomer, {
        pathData: {
            customerId: customer?.id,
        },
        query: {
            search: searchParams.searchByDetail,
            orderBy: searchParams.orderByDetail,
            orderDirection: searchParams.detailDirection,
            page: searchParams.pageDetail,
            pageSize: searchParams.pageSizeDetail,
        }
    });

    const columns: TableColumnsType<CustomerPromotionByCustomerResponseItem> = [
        {
            width: '40%',
            title: 'Promotion Name',
            dataIndex: 'promotionName',
            key: 'promotionName',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.detailDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, detailDirection: order });
                }
            }),
            render: (_, p) => (
                <>{p.promotionApply?.name?.replace(/\//g, '')}</>
            )
        },
        {
            width: '10%',
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            render: (_, p) => (
                <>{`${p.promotionApply?.unit === UnitEnum.Percent ? `${p.promotionApply?.value}%` : formatCurrency(p.promotionApply?.value || 0)}`}{' off'}</>
            )
        },
        {
            width: '20%',
            title: 'Effective date',
            key: 'Effective date',
            dataIndex: 'Effective date',
            render: (_, p) => {
                return formatDate(p.promotionApply?.effectiveDate ?? '');
            },
        },
        {
            width: '20%',
            title: 'Expiration date',
            key: 'Expiration date',
            dataIndex: 'Expiration date',
            render: (_, p) => {
                const currentDate = dayjs().startOf('day');
                if (currentDate.add(22, 'year') < dayjs(p.promotionApply?.expirationDate)) {
                    return <Text>Ongoing</Text>;
                }
                return formatDate(p.promotionApply?.expirationDate ?? '');
            },
        },
        {
            width: '20%',
            title: 'Granting date',
            key: 'Granting date',
            dataIndex: 'Granting date',
            render: (_, p) => {
                return formatDate(p.promotionApply?.effectiveDate ?? '');
            },
        },
        {
            width: '20%',
            title: 'Status',
            key: 'Status',
            dataIndex: 'Status',
            render: (_, p) => {
                switch (p.status) {
                case CustomerPromotionStatus.Used:
                    return <Tag>Used</Tag>;
                case CustomerPromotionStatus.Expired:
                    return <Tag color='red'>Expired</Tag>;
                default:
                    return <Tag color='success'>Unused</Tag>;
                }
            },
        },
    ];

    return (
        <TableCustom
            onPageChange={(page) =>
                setSearchParams({
                    ...searchParams,
                    pageDetail: page.toString(),
                })
            }
            onSearch={(value) =>
                setSearchParams({ ...searchParams, searchByDetail: value })
            }
            placeholderSearchTransCode={translateCodes.CLIENT_PLACEHOLDER_SEARCH}
            loading={isCustomerPromotionFetching}
            titleTableTransCode={translateCodes.PROMOTION_LIST}
            subTitleTable={<Tag color='processing'>{`${customerPromotions?.items.length} ${t(translateCodes.PROMOTIONS)}`}</Tag>}
            columns={columns}
            dataSource={customerPromotions?.items ?? []}
            pageInfo={customerPromotions?.meta}
        />
    );
};

