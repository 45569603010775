import React from 'react';
import { IconProps } from '../../Types';

export const IconPromotionRevenue = (props: IconProps) => {
    return (
        <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0.200195 24C0.200195 10.7452 10.9454 0 24.2002 0C37.455 0 48.2002 10.7452 48.2002 24C48.2002 37.2548 37.455 48 24.2002 48C10.9454 48 0.200195 37.2548 0.200195 24Z' fill='#D1E0FF' />
            <path d='M20.7002 26.6667C20.7002 27.9553 21.7449 29 23.0335 29H25.2002C26.5809 29 27.7002 27.8807 27.7002 26.5C27.7002 25.1193 26.5809 24 25.2002 24H23.2002C21.8195 24 20.7002 22.8807 20.7002 21.5C20.7002 20.1193 21.8195 19 23.2002 19H25.3669C26.6555 19 27.7002 20.0447 27.7002 21.3333M24.2002 17.5V19M24.2002 29V30.5M34.2002 24C34.2002 29.5228 29.723 34 24.2002 34C18.6773 34 14.2002 29.5228 14.2002 24C14.2002 18.4772 18.6773 14 24.2002 14C29.723 14 34.2002 18.4772 34.2002 24Z' stroke='#155EEF' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

