import { Dayjs , getEnv } from '@esg/shared';
import { HttpRequestData, httpUtil } from '@esg/framework';

import { SaleDetailGetAllResponseItem } from '../sale-detail';
import { GetDetailSaleResponse, UnitEnum } from './getDetailSale';

interface UpdateSaleRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        customerId?: string;
        branchId?: string;
        note?: string;
        code: string;
        date: Dayjs;
        totalAmount?: number;
        subTotal?: number;
        tip?: number;
        tipUnit?: UnitEnum;
        status: number;
        saleDetails?: SaleDetailGetAllResponseItem[];
    };
}

export const updateSale = httpUtil.createHttpRequestMeta<
    UpdateSaleRequest,
    GetDetailSaleResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/sales/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
