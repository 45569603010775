import { GetCampaignResponseItem } from '@esg/business-management/api/campaign';
import React from 'react';
import { CampaignTabKey } from '../enum';
import { CampaignContentDetail } from './CampaignContentDetail';
import { CampaignContentPromotion } from './CampaignContentPromotion';
import { CampaignContentMetric } from './CampaignContentMetric';

interface CampaignContentProps {
    activeKey: string;
    campaign: GetCampaignResponseItem;
}

export const CampaignContent = (props: CampaignContentProps) => {
    const { activeKey, campaign } = props;

    if (activeKey === CampaignTabKey.Detail) {
        return <CampaignContentDetail
            campaign={campaign}
        />;
    }

    if (activeKey === CampaignTabKey.Promotion) {
        return <CampaignContentPromotion
            campaign={campaign}
            activeKey={activeKey}
        />;
    }

    if (activeKey === CampaignTabKey.Metric) {
        return <CampaignContentMetric
            campaign={campaign}
            activeKey={activeKey}
        />;
    }

    return <div>Đây là nội dung của tab {activeKey}</div>;
};

