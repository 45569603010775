import {
    FormLayout,
    FlexBox,
    FormBase,
    InputField,
    useHttpCommand,
    Text,
    Spacer,
    EditorField,
    useHttpQuery,
    SelectField,
    globalModalState,
} from '@esg/ui';
import { Card, Radio, Tag } from 'antd';

import { MailOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { emailTemplateApi, GetAllEmailTemplateResponseItem } from '@esg/business-success-platform';
import { EmailTemplateUpdateResponseItem } from '@esg/business-success-platform/api/email-template/emailTemplateUpdate';
import './EmailTemplateForm.scss';
import { IntegrationEmailGetAllResponseItem, integrationEmailGetAll } from '@esg/business-setting/api/integration/integrationEmailGetAll';
import { EmailEncryptionType, IntegrationEmailService, IntegrationStatus, IntegrationType } from '@esg/business-setting/api/integration/Types';
import { useTranslation } from 'react-i18next';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { StatusEmailTemPlate, TypeEmailTemplatesEnum } from '@esg/shared';
import { EmailTemplateCreateResponseItem } from '@esg/business-success-platform/api/email-template/emailTemplateCreate';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { templateVariableGetAll } from '@esg/business-success-platform/api/template-variable.ts';
import { SendMailTestForm } from '../send-mail-test';
import { translateCodes } from '@/locales';
import { renderDescriptionTemplateVariable, renderTriggerEventOptions } from '@/helpers/emailTemplateHelper';
import { renderName } from '@/helpers';

interface EmailTemplateValues {
    id?: string;
    type?: TypeEmailTemplatesEnum;
    subject?: string;
    status?: StatusEmailTemPlate;
    body?: string;
    bodyCustom?: string;
    subjectCustom?: string;
}

interface EmailTemplateFormProps {
    readonly onSuccess?: (response?: EmailTemplateValues) => void;
    readonly onDelete?: () => void;
    readonly defaultValues?: EmailTemplateValues;
    readonly emailTemplateSystems?: GetAllEmailTemplateResponseItem[];
    readonly isSystemTemplate?: boolean;
}

interface typeOptionData {
    label: string;
    value: TypeEmailTemplatesEnum;
}
export const EmailTemplateForm = (props: EmailTemplateFormProps) => {
    const { t } = useTranslation();
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);
    const [bodyValue, setBodyValue] = useState<string>(props.defaultValues?.bodyCustom ?? '');
    const [subjectValue, setSubjectValue] = useState<string>(props.defaultValues?.subjectCustom ?? '');
    const [tabValue, setTabValue] = useState<string>('EDITOR');
    const [triggerEventValue, setTriggerEventValue] = useState<TypeEmailTemplatesEnum>(props.defaultValues?.type ?? TypeEmailTemplatesEnum.Notification);
    const [templateVariableLToOption, setTemplateVariableLToOption] = useState(() => renderTriggerEventOptions(triggerEventValue));
    const [editorInstance, setEditorInstance] = useState<ClassicEditor | undefined>();

    const { mutateAsync: updateEmailTemplate } = useHttpCommand(
        emailTemplateApi.emailTemplateUpdate
    );
    const { mutateAsync: createEmailTemplate, isPending: isLoadingCreate } = useHttpCommand(
        emailTemplateApi.emailTemplateCreate
    );
    const { data: integrations } = useHttpQuery(integrationEmailGetAll);
    const { data: templateVariables } = useHttpQuery(templateVariableGetAll,
        {
            query: {
                pageSize: 100
            }
        }
    );

    useEffect(() => {
        setTemplateVariableLToOption(renderTriggerEventOptions(triggerEventValue));
    }, [triggerEventValue]);

    const variableEvent = templateVariables?.items.filter(value => templateVariableLToOption.includes(value.type!));

    const typeOptions = useMemo(() => {
        const typeOptionData: typeOptionData[] = [
            {
                label: 'Notification',
                value: TypeEmailTemplatesEnum.Notification,
            },
            {
                label: 'Promotion',
                value: TypeEmailTemplatesEnum.Promotion,
            },
        ];
        if (!props.isSystemTemplate) {
            return typeOptionData.map((item) => ({
                label: item.label,
                value: item.value
            }));
        }
        if (props.isSystemTemplate) {
            return props.emailTemplateSystems?.map((item) => ({
                label: renderName(item.type!),
                value: item.type
            }));
        }
    }, [props.emailTemplateSystems, props.isSystemTemplate]);

    const replaceVariables = (template: string, variables: { [key: string]: string }) => {
        let replacedTemplate = template;

        for (const variable in variables) {
            if (Object.prototype.hasOwnProperty.call(variables, variable)) {
                const regex = new RegExp(`{{${variable}}}`, 'g');
                replacedTemplate = replacedTemplate.replace(regex, variables[variable]);
            }
        }

        return replacedTemplate;
    };

    const templateVariableMap = useMemo(() =>
        templateVariables?.items?.filter(item => item.name && item.exampleValue)?.map(item => [item.name, item.exampleValue]) || [],
    [templateVariables?.items]
    );

    const variables = Object.fromEntries(templateVariableMap);

    const { emailIntegration } = useMemo(() => {
        const emailIntegration =
            integrations?.find((it) => it.type === IntegrationType.Email) ?? (
                {
                    type: IntegrationType.Email,
                    configuration: {
                        service: IntegrationEmailService.Gmail,
                        smtpServer: 'smtp.gmail.com',
                        port: 465,
                        encryption: EmailEncryptionType.SSL,
                    },
                    status: IntegrationStatus.Disabled,
                } as Partial<IntegrationEmailGetAllResponseItem>
            );

        return {
            emailIntegration,
        };
    }, [integrations]);

    const onSubmit = React.useCallback(
        async (values: EmailTemplateValues) => {
            const onSuccessUpdate = (response?: EmailTemplateUpdateResponseItem) => {
                props.onSuccess?.(response);
            };
            const onSuccessCreate = (response: EmailTemplateCreateResponseItem) => {
                props.onSuccess?.(response as EmailTemplateUpdateResponseItem);
            };
            const isCreate = (!values.id);

            if (isCreate) {
                await createEmailTemplate(
                    {
                        body: {
                            ...values,
                            body: values.bodyCustom,
                            subjectCustom: values.subject,
                            status: StatusEmailTemPlate.CustomTemplate,
                        },

                    },
                    { onSuccess: onSuccessCreate }
                );
            } else {
                await updateEmailTemplate(
                    {
                        pathData: { id: props.defaultValues?.id },
                        body: {
                            ...values,
                            id: props.defaultValues?.id ?? '',
                        },
                    },
                    { onSuccess: onSuccessUpdate }
                );
            }
        },
        [createEmailTemplate, props, updateEmailTemplate]
    );

    const handlerSendMailTest = useCallback((emailTemplate: EmailTemplateUpdateResponseItem) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: 'Send a test message',
            content: <SendMailTestForm onCancel={resetGlobalModal} defaultValues={{
                email: '',
                emailTemplate: emailTemplate
            }}/>,
            onOk: () => {
                resetGlobalModal();
            },
            onCancel: () => {
                resetGlobalModal();
            }
        });
    }, [globalModal, resetGlobalModal, setGlobalModal]);

    const onAddVariableToBody = (value: string) => {
        if (!editorInstance) return;

        editorInstance.model.change((writer) => {
            const selection = editorInstance.model.document.selection;
            const position = selection.getFirstPosition();
            writer.insertText(value, position!);
        });

        setBodyValue(editorInstance.getData());
    };

    return (
        <FormBase onSubmit={onSubmit} defaultValues={props.defaultValues}>
            {(form)=>{
                const formValues = form.getValues();
                return (
                    <FormLayout
                        onDeleted={props.onDelete}
                        isLoadingButton={isLoadingCreate}
                        labelSubmitCode={translateCodes.SAVE}
                        width={1062}
                        onTestEvent={() => {
                            handlerSendMailTest({...formValues, id: props.defaultValues?.id ?? ''});
                        }}
                    >
                        <FlexBox direction='row' gap={16}>
                            <FlexBox direction='column' gap={16}>
                                <Radio.Group defaultValue={tabValue} buttonStyle='solid'>
                                    <Radio.Button onChange={(value) => { setTabValue(value.target.value); }} value='EDITOR'>{t(translateCodes.EMAIL_TEMPLATE_EDITOR)}</Radio.Button>
                                    <Radio.Button onChange={(value) => { setTabValue(value.target.value); }} value='PREVIEW'>{t(translateCodes.EMAIL_TEMPLATE_PREVIEW)}</Radio.Button>
                                </Radio.Group>
                                <Card>
                                    <InputField
                                        rules={{ required: true }}
                                        name='subject'
                                        label={t(translateCodes.EMAIL_TEMPLATE_SUBJECT)}
                                        placeholder={t(translateCodes.EMAIL_TEMPLATE_PREVIEW_PLACEHOLDER)}
                                        onChange={(value) => {
                                            setSubjectValue(value.target.value);
                                        }}
                                    />
                                </Card> 
                                <Card>
                                    <SelectField
                                        rules={{ required: true }}
                                        disabled={props.defaultValues?.id != null}
                                        name='type'
                                        label='Type'
                                        placeholder={t(translateCodes.EMAIL_TEMPLATE_PREVIEW_PLACEHOLDER)}
                                        onChange={(value) => {
                                            setTriggerEventValue(value);

                                            const newBodyValue = props.emailTemplateSystems?.find(o => o.type === value)?.bodyCustom ?? '';
                                            setBodyValue(newBodyValue);
                                            if (editorInstance) {
                                                editorInstance.setData(newBodyValue);
                                            }
                                        }}
                                        options={typeOptions}
                                    />
                                </Card>
                                <Card style={{ height: 'auto' }} title={t(translateCodes.EMAIL_TEMPLATE_AVAILABLE)}>
                                    <FlexBox direction='column' gap={8}>
                                        {variableEvent?.map((variable, index) =>
                                            <>
                                                <FlexBox direction='row' key={index}>
                                                    <FlexBox direction='column' gap={12}>
                                                        <a onClick={() => onAddVariableToBody(` {{${variable.name}}} `)}>
                                                            <Tag color='warning'>{variable.name}</Tag>
                                                        </a>
                                                    </FlexBox>
                                                    <FlexBox direction='column' gap={12}>
                                                        <Text translateCode={renderDescriptionTemplateVariable(variable.type!)} />
                                                    </FlexBox>
                                                </FlexBox>
                                            </>
                                        )}

                                    </FlexBox>
                                </Card>
                            </FlexBox>
                            <FlexBox direction='column' gap={10}>
                                {tabValue === 'EDITOR' && <FlexBox className='email-template-form'>
                                    <EditorField name='bodyCustom' onchange={(value) => {
                                        setBodyValue(value.getData());
                                        setEditorInstance(value);
                                    }}
                                    value={bodyValue}
                                    />
                                </FlexBox>}
                                {tabValue === 'PREVIEW' && <Card style={{ width: 600, borderRadius: 0 }} title={
                                    <div>
                                        <FlexBox gap={25}>
                                            <MailOutlined style={{ marginRight: -20 }} />
                                            <Text translateCode={emailIntegration.configuration?.sendingFrom} />
                                            <Text fontWeight={500} translateCode={subjectValue} />
                                        </FlexBox>
                                    </div>
                                }>
                                    <Card style={{ backgroundColor: '#F0F2F5' }} bordered={false} >
                                        <div>
                                            <div>
                                                <Text fontWeight={700}>{t(translateCodes.EMAIL_TEMPLATE_SUBJECT)}: </Text>
                                                <Text>{subjectValue}</Text>
                                            </div>
                                            <Spacer height={10} />
                                            <div dangerouslySetInnerHTML={{ __html: replaceVariables(bodyValue, variables) }} />
                                        </div>
                                    </Card>
                                </Card>}
                            </FlexBox>
                        </FlexBox>
                    </FormLayout>
                );
            }}
        </FormBase>
    );
};

EmailTemplateForm.defaultProps = {
    defaultValues: {
        type: TypeEmailTemplatesEnum.Notification
    },
};