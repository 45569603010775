import { UserOutlined } from '@ant-design/icons';
import { Block, FlexBox, IconCreated, IconDeleted, IconEdited, Text } from '@esg/ui';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { Avatar, Card } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';

export interface ActivityInfo {
    actionName?: 'Created' | 'Cancelled' | 'Edited';
    actionBy?: string;
    actionTime?: string;
    activityData?: string;
    rootEntityName?: string;
    doerId?: string;
}

interface ActivityLogItemProps {
    readonly loading?: boolean;
    readonly activityInfo: ActivityInfo;
}

export function ActivityLogItem(props: ActivityLogItemProps) {
    const { activityInfo, loading } = props;
    const [currentLocale] = useRecoilState(currentLocaleState);
    const members = currentLocale?.members || [];
    const activityUser = members.find(c => c.id === activityInfo.doerId);

    return (
        <Card className='activity-log' loading={loading}>
            <FlexBox gap={12} justifyContent='center' alignItems='center'>
                <Block position='relative'>
                    <Avatar size={64} shape='circle' src={activityUser?.avatar}  icon={<UserOutlined />}  />
                    <Block position='absolute' bottom={-8} right={-2}>
                        {activityInfo.actionName === 'Cancelled' && <IconDeleted />}
                        {activityInfo.actionName === 'Edited' && <IconEdited/>}
                        {activityInfo.actionName === 'Created' && <IconCreated/>}
                    </Block>
                </Block>
                <FlexBox direction='column'>
                    <Text fontWeight='bold'>{`${activityInfo.rootEntityName} ${activityInfo?.actionName}`}</Text>
                    <Text color='#475467' fontSize={14}>
                        {activityInfo.actionTime!}
                        &nbsp; by &nbsp;
                        {activityUser?.fullName || activityInfo?.actionBy}</Text>
                    <Text color='#475467' fontSize={12}>
                        <p dangerouslySetInnerHTML={{
                            __html: activityInfo?.activityData || ''
                        }} />
                    </Text>
                </FlexBox>
            </FlexBox>
        </Card>
    );
}
