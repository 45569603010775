import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { GetCampaignResponseItem } from './getAllCampaign';

interface GetCampaignDetailRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export const getDetailCampaign = httpUtil.createHttpRequestMeta<
    GetCampaignDetailRequest,
    GetCampaignResponseItem
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/campaigns/:id',
    method: 'GET',
    authentication: 'bearer',
});