import React from 'react';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { CustomerActivity } from './CustomerActivity';
import { CustomerAppointmentTable } from './customer-table/CustomerAppointmentTable';
import { CustomerSaleTable } from './customer-table/CustomerSaleTable';
import { CustomerProductTable } from './customer-table/CustomerProductTable';
import { CustomerPromotionTable } from './customer-table/CustomerPromotionTable';

interface CustomerDetailTabChildrenProps {
    activeKey: string;
    customer?: GetCustomerDetailResponse;
}

export const CustomerDetailTabChildren = (props: CustomerDetailTabChildrenProps) => {
    const { activeKey, customer } = props;

    if (activeKey === '1') {
        return <CustomerActivity
            customer={customer}
        />;
    }

    if (activeKey === '2') {
        return <CustomerAppointmentTable customer={customer} />;
    }

    if (activeKey === '3') {
        return <CustomerSaleTable customer={customer!}/>;
    }
    if (activeKey === '4') {
        return <CustomerProductTable customer={customer!}/>;
    }
    if (activeKey === '5') {
        return <CustomerPromotionTable customer={customer!} />;
    }

    return null;
};

