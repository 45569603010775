import { HttpRequestData, httpUtil } from '@esg/framework';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { AppointmentStatus , getEnv } from '@esg/shared';
import { GetDetailFacilitiesResponse, EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { GetAllServiceResponseItem } from '@esg/business-management';

import { AppointmentType } from './createAppointment';

interface AppointmentGetDetailRequest extends HttpRequestData {
}

export interface AppointmentService {
	id: string;
	guestCode: string;
	appointmentServiceDetails: AppointmentServiceDetail[];
}

export interface AppointmentServiceDetail {
	id: string;
	serviceId: string;
	facilityId?: string;
	facility?: GetDetailFacilitiesResponse;
	employeeIds?: string[];
	appointmentServiceEmployees: AppointmentServiceEmployee[];
	appointmentServiceId: string;
	service: GetAllServiceResponseItem,
	isValid?: boolean;
}
export interface AppointmentServiceEmployee {
	readonly appointmentId?: string;
	readonly appointmentServiceDetailId?: string;
	readonly appointmentServiceId?: string;
	readonly id?: string;
	readonly serviceId?: string;
	readonly employee?: EmployeeGetDetailResponse;
	readonly employeeId: string;
	readonly appointmentServiceDetail?: AppointmentServiceDetail;
	readonly service?: GetAllServiceResponseItem;
	readonly timeStartWork: string;
	readonly timeEndWork: string;
}

export interface AppointmentGetDetailResponse {
	readonly id: string;
	readonly appointmentCode: string;
	readonly customerId: string;
	readonly numberOfGuest: number;
	readonly appointmentDate: string;
	readonly appointmentType: AppointmentType;
	readonly notes?: string;
	readonly status: AppointmentStatus;
	readonly saleId?: string;
	readonly creationTime: string;
	readonly customer: GetCustomerDetailResponse;
	readonly appointmentServices: AppointmentService[];
}

export const getAppointmentDetail = httpUtil.createHttpRequestMeta<
	AppointmentGetDetailRequest,
	AppointmentGetDetailResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'GET',
    path: '/api/v1/appointments/:id',
    authentication: 'bearer',
});
