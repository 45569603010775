import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface DeleteFacilityRequest extends HttpRequestData { }

export const deleteFacility = httpUtil.createHttpRequestMeta<DeleteFacilityRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/facilities/:id',
    method: 'DELETE',
    authentication: 'bearer',
});