import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

export interface ShiftDisableCreateRequest extends HttpRequestData {
    body: {
        readonly date: string;
        readonly employeeId: string;
        readonly shiftId: string;
    };
}

export interface ShiftDisableGetDetailResponse {
    readonly date: string;
    readonly employeeId: string;
    readonly shiftId: string;
}

export const shiftDisableCreate = httpUtil.createHttpRequestMeta<ShiftDisableCreateRequest, void>({
    method: 'POST',
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    authentication: 'bearer',
    path: '/api/v1/shift-disables',
});