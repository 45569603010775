import React from 'react';

import { IconProps } from '../../Types';

export const IconAppointmentReminder = (props: IconProps) => {
    return (
        <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z' fill='#FEE4E2'/>
            <path d='M36.4726 51.9993C37.4127 52.8292 38.6477 53.3327 40.0003 53.3327C41.3529 53.3327 42.5879 52.8292 43.5281 51.9993M27.0589 31.7592C27.0397 29.8241 28.083 28.017 29.7685 27.066M52.9365 31.7592C52.9556 29.8241 51.9123 28.017 50.2269 27.066M48.0003 34.666C48.0003 32.5443 47.1575 30.5095 45.6572 29.0092C44.1569 27.5089 42.1221 26.666 40.0003 26.666C37.8786 26.666 35.8438 27.5089 34.3435 29.0092C32.8432 30.5095 32.0003 32.5443 32.0003 34.666C32.0003 38.7863 30.961 41.6073 29.7999 43.4732C28.8205 45.0472 28.3308 45.8342 28.3488 46.0537C28.3686 46.2968 28.4201 46.3895 28.616 46.5348C28.7929 46.666 29.5905 46.666 31.1855 46.666H48.8152C50.4102 46.666 51.2077 46.666 51.3846 46.5348C51.5805 46.3895 51.632 46.2968 51.6519 46.0537C51.6699 45.8342 51.1802 45.0472 50.2008 43.4732C49.0397 41.6073 48.0003 38.7863 48.0003 34.666Z' stroke='#D92D20' strokeWidth='1.67' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
    );
};
