import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetAllReviewSummaryRequest extends HttpRequestData {
}

export interface GetAllClientReviewSummaryResponse {
    items: GetAllClientReviewSummaryResponseItem[];
}
export interface GetAllClientReviewSummaryResponseItem {
    id: string;
    totalReviews: number;
    averageRating: number;
    fiveStarCount: number;
    fourStarCount: number;
    threeStarCount: number;
    twoStarCount: number;
    oneStarCount: number;
    branchId: string;
}
export const getAllClientReviewSummary = httpUtil.createHttpRequestMeta<
    GetAllReviewSummaryRequest,
    GetAllClientReviewSummaryResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/review-summaries',
    method: 'GET',
    authentication: 'bearer',
});
