import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface CreateBranchRequest extends HttpRequestData {
    readonly pathData: {
        id: string;
    };
}

export const deleteBranch = httpUtil.createHttpRequestMeta<
    CreateBranchRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/branches/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
