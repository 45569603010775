import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetSummaryAppointmentRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export interface GetSummaryAppointmentResponse {
    readonly totalAppointment: number;
    readonly totalServices: number;
    readonly totalClients: number;
    readonly totalClientsNoShow: number;
    readonly totalAppointmentValue: number;
}

export const getSummaryAppointment = httpUtil.createHttpRequestMeta<
    GetSummaryAppointmentRequest,
    GetSummaryAppointmentResponse
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/appointments-report/summary',
    authentication: 'bearer',
});