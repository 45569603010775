import { httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { BreakTimeUpdateRequest } from '../break-time';

export const updateShiftByBreakTime = httpUtil.createHttpRequestMeta<BreakTimeUpdateRequest, void>({
    method: 'PATCH',
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    authentication: 'bearer',
    path: '/api/v1/shifts/:id/update-break',
});
