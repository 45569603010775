import {
    FormBase,
    FormLayoutModal,
    translateCodes,
    useHttpCommand
} from '@esg/ui';
import React, { useCallback, useState } from 'react';
import { CustomerForCreateSegmentResponse, CustomerSegmentCreateItem } from '@esg/business-management/api/customer-segment';
import { debounce } from 'lodash';
import { managementApis } from '@esg/business-management';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { CustomerSegmentSelectField } from './children';

interface CustomerSegmentFormProps {
    readonly customers: CustomerForCreateSegmentResponse[];
    readonly segmentId: string;
    readonly onDelete?: () => void;
    readonly refetch: () => void;
    readonly resetModalState: () => void;
    readonly onSubmit?: (value: CustomerSegmentCreateItem) => void;
    readonly defaultValues?: CustomerSegmentCreateItem[];
}

export const CustomerSegmentForm = (props: CustomerSegmentFormProps) => {
    const { customers, segmentId } = props;
    const [ customerSegmentOptions, setCustomerSegmentOptions ] = useState(customers);
    const { mutateAsync: onSearchCustomer } = useHttpCommand(managementApis.getAllCustomerForCreateSegments, {
        onSuccess: (response) => {
            const newOptions = response.items?.map((customer) => ({
                ...customer,
                label: customer.fullName,
                value: customer.id,
            })).sort((a, b) => b.label.localeCompare(a.label));
            setCustomerSegmentOptions(newOptions);
        }
    });

    const onSearch = async (searchTerm: string, form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>) => {
        const currentValue =  form.watch('customerIds') as string[];
        await onSearchCustomer({
            body: {
                segmentId: segmentId || '',
                customerIds: currentValue
            },
            query: {
                pageSize: 20 + currentValue?.length,
                page: 1,
                order: '',
                orderBy: '',
                search: searchTerm
            }
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchOptions = useCallback(
        debounce((searchValue: string, form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>) => onSearch(searchValue, form), 600), // 500ms debounce
        []
    );

    const handleSearch = (searchValue: string, form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>) => {
        debouncedFetchOptions(searchValue, form);
    };
    
    return (
        <FormBase
            onSubmit={props.onSubmit}
            defaultValues={{...props.defaultValues}}
        >
            {
                (form) => (
                    <FormLayoutModal
                        useSubmitButton={true}
                        onCancel={props.resetModalState}
                        okTextCode={translateCodes.APPLY}
                    >
                        <CustomerSegmentSelectField
                            mode='multiple'
                            name='customerIds'
                            type='default'
                            label='Clients'
                            labelStyle={{ color: '#667085', fontWeight: 500, fontSize: 14 }}
                            value={form.watch('customerIds')}
                            customerSegmentOptions={customerSegmentOptions.map((customer) => ({
                                ...customer,
                                label: customer.fullName,
                                value: customer.id,
                            }))}
                            onSearch={(value) => handleSearch(value, form)}
                        />
                    </FormLayoutModal>
                )}
        </FormBase>
    );
};

CustomerSegmentForm.defaultProps = {
    defaultValues: {
        customerIds: []
    },
};

