import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { CreateFacilityResponse } from '@esg/business-resource-management';
import { GetAllTaxResponseItem } from '@esg/business-setting';

interface GetAllServiceRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

interface ServiceCategory {
    id: string;
    name: string;
    description?: string;
}

interface Employee {
    id: string;
    fullName: string;
}

interface EmployeeServices {
    readonly id?: string;
    readonly employeeId?: string;
    readonly serviceId?: string;
    readonly service?: GetAllServiceResponseItem;
    readonly employee: Employee;
}

interface ServiceBranch {
    readonly id: string;
    readonly branchId: string;
    readonly price: number;
    readonly taxSettingId?: string;
    readonly taxSetting?: GetAllTaxResponseItem;
    readonly serviceId?: string;
}

interface ServicesFacility {
    id?: string;
    facilityId?: string;
    serviceId?: string;
    service?: GetAllServiceResponseItem;
    facility?: CreateFacilityResponse;
}
export interface GetAllServiceResponseItem {
    readonly id: string;
    name: string;
    serviceCategoryId: string;
    duration: number;
    serviceBranches: ServiceBranch[];
    description: string;
    isAllowOnlineBooking: boolean;
    serviceCategory?: ServiceCategory;
    employeeServices?: EmployeeServices[];
    serviceFacilities?: ServicesFacility[];
}

export const getAllServices = httpUtil.createHttpRequestMeta<
    GetAllServiceRequest,
    PaginationResponse<GetAllServiceResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/services',
    method: 'GET',
    authentication: 'bearer',
});
