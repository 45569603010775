import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { GetDetailFacilitiesResponse } from '.';

interface GetAllFacilitiesRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export const getAllFacilities = httpUtil.createHttpRequestMeta<
    GetAllFacilitiesRequest,
    PaginationResponse<GetDetailFacilitiesResponse>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/facilities',
    method: 'GET',
    authentication: 'bearer',
});