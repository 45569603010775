import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
interface AppointmentServiceEmployeeDeleteRequest extends HttpRequestData { }

export const deleteAppointmentServiceEmployee = httpUtil.createHttpRequestMeta<AppointmentServiceEmployeeDeleteRequest, void>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    method: 'DELETE',
    path: '/api/v1/appointment-service-employees/:id',
    authentication: 'bearer',
});
