import React from 'react';

import { IconProps } from '../../Types';

export const IconDidNotShowUp = (props: IconProps) => {
    return (
        <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z' fill='#D1E0FF'/>
            <path d='M38.3239 30.7898C38.8658 30.7096 39.4248 30.6667 40.0006 30.6667C46.8072 30.6667 51.2737 36.6731 52.7743 39.0491C52.9559 39.3367 53.0467 39.4805 53.0975 39.7022C53.1357 39.8688 53.1356 40.1316 53.0975 40.2981C53.0466 40.5199 52.9551 40.6646 52.7722 40.9541C52.3725 41.5869 51.7629 42.4761 50.9553 43.4406M32.9658 32.9534C30.083 34.9089 28.1259 37.6258 27.2281 39.047C27.0457 39.3358 26.9545 39.4802 26.9037 39.702C26.8655 39.8685 26.8654 40.1313 26.9036 40.2978C26.9544 40.5196 27.0452 40.6634 27.2268 40.9509C28.7274 43.3269 33.1939 49.3333 40.0006 49.3333C42.7451 49.3333 45.1092 48.3568 47.0518 47.0355M28.0006 28L52.0006 52M37.1721 37.1716C36.4483 37.8954 36.0006 38.8954 36.0006 40C36.0006 42.2091 37.7914 44 40.0006 44C41.1051 44 42.1051 43.5523 42.829 42.8284' stroke='#155EEF' strokeWidth='1.67' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};
