import React from 'react';
import { AppButton, Block, IconTemplate } from '@esg/ui';
import { useNavigate } from 'react-router-dom';
import { AutomationEvent } from './automation-event/AutomationEvent';
import { PageLayout } from '@/layouts';
import { TEMPLATE_URL } from '@/configs';
import { translateCodes } from '@/locales/translateCodes';

export function Automation() {
    const navigate = useNavigate();

    const handleNavigateDetail = async () => {
        navigate(`${TEMPLATE_URL}/system-template`);
    };
    return (
        <>
            <PageLayout
                pageTitleTransCode={translateCodes.AUTOMATION}
                rightHeaderPage={
                    <AppButton
                        icon={<IconTemplate />}
                        onClick={() => handleNavigateDetail()}
                        translateCode='Templates'
                    />
                }
            >
                <Block >
                    <AutomationEvent/>
                </Block>
            </PageLayout>   
        </>
    );
}
