import './SaleManagement.scss';
import React, { useEffect, useState } from 'react';
import { Segmented, TableColumnsType, Image, Dropdown } from 'antd';
import { ActionsColumn, AppButton, ColumnAction, FlexBox, IconCancel, IconCharge, IconEye, IconFilter, IconPrintDailySale, ListNameColumn, Spacer, TableCustom, Text, globalModalState, useHttpQuery, useLocalesFormat, usePagination } from '@esg/ui';
import { GetDetailSaleResponse, SaleStatus, getAllSale, getSaleSummary, } from '@esg/business-pos';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useReactToPrint } from 'react-to-print';
import { SegmentedValue } from 'antd/es/segmented';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { dayjs } from '@esg/shared';
import CreateSaleBtn from './children/CreateSaleBtn';
import InitSaleMinimize from './children/InitSaleMinimize';
import useOpenSaleFrom from './hook/useOpenSaleFrom';
import FilterSaleForm from './children/FilterSaleForm';
import SaleEmployeeColumn from './children/SaleEmployeeColumn';
import SaleDateTimeColumn from './children/SaleDateTimeColumn';
import { PageLayout } from '@/layouts';
import { saleEvents } from '@/event/saleEvent';
import CancelSaleForm from '@/components/form/sale/CancelSaleForm';
import ConfirmPaySale from '@/components/form/sale/ConfirmPaySale';
import { currentSaleState } from '@/states';
import { translateCodes } from '@/locales';
import { resourceManagementTheme } from '@/pages/resource-management/@shared';
import { POS_URL } from '@/configs';
import { CustomerColumnView } from '@/components';

export interface FilterSaleParams {
    readonly employeeIds?: string[];
    readonly date?: string;
    readonly serviceIds?: string[];
}

export default function DailySaleManagement() {
    const {t} = useTranslation();
    const {formatCurrency} = useLocalesFormat();
    const [activeTabs, setActiveTabs] = useState<SegmentedValue>(SaleStatus.Unpaid);
    const [paginationParams, setPaginationParams] = usePagination({
        orderBy: 'creationTime'
    });
    const [filterParams, setFilterParams] = useState<FilterSaleParams>( {date: dayjs().startOf('day').toISOString()});
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [, setCurrentSale] = useRecoilState(currentSaleState);
    const resetGlobalModal = useResetRecoilState(globalModalState);
    const {onShowFormSale, isLoadingInitData} = useOpenSaleFrom();
    const [dropdownVisible, setDropdownVisible] = useState(false);
            
    const handleDropdownVisibleChange = (visible: boolean) => {
        setDropdownVisible(visible);
    };

    const { data: saleSummaryResponse, refetch: refetchSummary } = useHttpQuery(getSaleSummary,{
        query: {
            ...filterParams,
        },
    });

    const { data: saleResponse, isFetching, refetch } = useHttpQuery(getAllSale, {
        query: {
            status: activeTabs,
            ...paginationParams,
            ...filterParams,
        },
    });

    useEffect(() => {
        const clearRefetchSaleList = saleEvents.onRefetchSaleList.listen(() => {
            refetch();
            refetchSummary();
        });

        return () =>  { clearRefetchSaleList?.(); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saleTabsOptions = [
        { value: SaleStatus.Unpaid, label: `${t(translateCodes.UNPAID)} (${saleSummaryResponse?.numberSaleUnpaid ?? 0})` },
        { value: SaleStatus.Paid, label: `${t(translateCodes.PAID)} (${saleSummaryResponse?.numberSalePaid ?? 0})` },
        { value: SaleStatus.Cancelled, label: `${t(translateCodes.CANCELLED)} (${saleSummaryResponse?.numberSaleCancelled ?? 0})` },
    ];

    const handlePrint = useReactToPrint({
        documentTitle: t(translateCodes.PRINT_THIS_DOCUMENT),
        removeAfterPrint: true,
        content: () => document.getElementById('print-bill') as HTMLElement,
    });

    const saleActions = (sale: GetDetailSaleResponse) => {
        let actions: ColumnAction[] = [];
        const isDisableCheckout = (sale?.saleDetails?.length ?? 0) <= 0;

        if (activeTabs === SaleStatus.Unpaid) {
            actions = [
                {
                    icon: <IconCharge color={isDisableCheckout ? '#D0D5DD' : undefined}/>,
                    onClick: () => {
                        if (isDisableCheckout) return;
                        showModalConfirmPay(sale);
                    },
                    tooltipTitle: t(translateCodes.CHECK_OUT),
                },
                {
                    icon: <IconCancel />,
                    onClick: () => showModalConfirmCancel(sale),
                    tooltipTitle: t(translateCodes.CANCEL),
                },
            ];
        }

        if(activeTabs === SaleStatus.Paid) {
            actions = [
                {
                    icon: <IconCancel />,
                    onClick: () => showModalConfirmCancel(sale),
                    tooltipTitle: t(translateCodes.CANCEL),
                },
                {
                    icon: <IconPrintDailySale />,
                    onClick: async () => {
                        await setCurrentSale(sale);
                        handlePrint();
                    },
                    tooltipTitle: t(translateCodes.PRINT),
                },
            ];
        }

        if(activeTabs === SaleStatus.Cancelled) {
            actions = [
                {
                    icon: <IconEye />,
                    onClick: () => onShowFormSale(sale),
                    tooltipTitle: t(translateCodes.VIEW),
                }
            ];
        }

        return actions;
    };

    const showModalConfirmCancel = (sale: GetDetailSaleResponse) => {
        setGlobalModal({
            ...globalModal,
            titleTransCode: translateCodes.CANCEL_SALE,
            isOpen: true,
            footer: null,
            content: <CancelSaleForm
                sale={sale}
                onCancel={() => resetGlobalModal()}
                onSuccess={() => {
                    resetGlobalModal();
                    refetch();
                    refetchSummary();
                }}
            />
        });
    };

    const showModalConfirmPay = (sale: GetDetailSaleResponse) => {
        setGlobalModal({
            ...globalModal,
            titleTransCode:
                <FlexBox direction='column'>
                    <Text fontWeight={700} fontSize={18}>{t(translateCodes.CONFIRM_PAYMENT)}</Text>
                    <Text fontWeight={400} fontSize={14} color='#475467' >{t(translateCodes.DESCRIPTION_CONFIRM_PAYMENT)}</Text>
                </FlexBox>,
            isOpen: true,
            content: <ConfirmPaySale
                sale={sale}
                onCancel={() => resetGlobalModal()}
                onSuccess={() => {
                    resetGlobalModal();
                    refetch();
                }}
            />
        });
    };
    const showFilterDropdown = () => {
        return(
            <Dropdown
                dropdownRender={
                    () => <>
                        <div
                            onClick={(e) => e.stopPropagation()} 
                            onMouseDown={(e) => e.preventDefault()}
                            style={{
                                padding: '16px',
                                background: '#fff',
                                borderRadius: '4px',
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                            }}
                        >
                            <FilterSaleForm
                                defaultValues={filterParams}
                                handleSubmit={(value) => {
                                    setFilterParams(value);
                                    setDropdownVisible(false);
                                    refetch();
                                }}
                                onCancel={() => setDropdownVisible(false)}
                            />
                        </div>
                    </>
                }
                destroyPopupOnHide
                open={dropdownVisible}
                onOpenChange={handleDropdownVisibleChange}
                trigger={['click']}
            >
                <AppButton
                    translateCode='Filter'
                    icon={<IconFilter />}
                />
            </Dropdown>
        );
    };

    const columns: TableColumnsType<GetDetailSaleResponse> = [
        {
            title: 'Order ID',
            dataIndex: 'code',
            key: 'id',
            sorter: true,
            width: 200,
            render: (code, record) => <Text cursor='pointer'  onClick={() =>onShowFormSale(record)} fontWeight={500} fontSize={14} lineHeight='20px' color='#004EEB'>{code}</Text>,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = paginationParams.orderDirection === 'desc' ? 'asc' : 'desc';
                    setPaginationParams({ ...paginationParams, orderDirection: order });
                }
            }),
        },
        {
            title: 'Client',
            dataIndex: 'customer',
            key: 'customer',
            render: (customer) => <CustomerColumnView customer={customer} />
        },
        {
            title: 'Employee(s)',
            dataIndex: 'Employees',
            key: 'Employees',
            render: (_, record) => <SaleEmployeeColumn saleDetails={record.saleDetails ?? []}/>,
        },
        {
            title: 'Date & time',
            dataIndex: 'date',
            key: 'date',
            width: 180,
            render: (_, record) => <SaleDateTimeColumn createdAt={record.date} checkoutAt={record.checkoutAt}/>
        },
        {
            title: 'Service(s)',
            dataIndex: 'Services',
            key: 'Services',
            width: 250,
            render: (_, record) => {
                const names = record?.saleDetails?.map((item) => item?.objectName ?? '');
                return <ListNameColumn names={names} />;
            }
        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (totalAmount) => <Text fontWeight={600}>{formatCurrency(totalAmount ?? 0)}</Text>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return (
                    <ActionsColumn
                        actions={saleActions(record)}
                    />
                );
            },
        },
    ];

    const filteredColumns = activeTabs !== SaleStatus.Cancelled
        ? columns
        : columns.filter(column => column.key !== 'action');

    const leftDailySaleHeaderView = () => {
        return (
            <Spacer type='padding' top={10}>
                <FlexBox gap={20} >
                    <Link to={POS_URL}>
                        <Text translateCode='Fast POS' fontWeight={500} fontSize={14} color='#475467' />
                    </Link>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowRightIcon}
                        width={14}
                        height={14}
                    />
                    <Text translateCode={translateCodes.DAILY_SALE} fontWeight={600} fontSize={14} color='#0C6FF3' />
                </FlexBox>
            </Spacer>
        );
    };

    return (
        <PageLayout
            pageTitleTransCode='Fast POS'
            leftHeaderPage={leftDailySaleHeaderView()}
            className='sale-management'
            middleHeaderPage={
                <Segmented
                    size='large'
                    value={activeTabs}
                    options={saleTabsOptions}
                    onChange={(value) => {
                        setActiveTabs(value);
                    }}
                />
            }
        >
            <InitSaleMinimize isLoadingInitData={isLoadingInitData} onShowFormSale={onShowFormSale}/>
            <TableCustom
                showFilterDropdown={showFilterDropdown}
                titleTableTransCode={translateCodes.SALE_LIST}
                placeholderSearchTransCode={translateCodes.SEARCH_PLACEHOLDER}
                onPageChange={(page, pageSize) =>
                    setPaginationParams({ 
                        ...paginationParams, 
                        page: page.toString(),
                        pageSize: pageSize?.toString()
                    })
                }
                onSearch={(value) =>
                    setPaginationParams({ ...paginationParams, search: value })
                }
                loading={isFetching}
                columns={filteredColumns}
                dataSource={saleResponse?.items}
                pageInfo={saleResponse?.meta}
                emptyOption={{
                    title: t(translateCodes.ADD_SALE),
                    description: t(translateCodes.EMPTY_SALE_DESCRIPTION),
                    actionButton: (<CreateSaleBtn />)
                }}
            />
        </PageLayout>
    );
}