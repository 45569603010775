import {
    AppAvatar,
    ColumnActions,
    ColumnAvatarGroup,
    FlexBox,
    TableCustom,
    useHttpCommand,
    useHttpQuery,
    ListNameColumn,
    Text,
    useLocalesFormat,
    globalModalState,
    Spacer,
    Translate,
    usePagination,
} from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import React, { useCallback } from 'react';
import { AppointmentGetDetailResponse, AppointmentService, schedulerApis } from '@esg/business-schedule';
import { debounce, flatten } from 'lodash';
import { AppointmentStatus,  getAppointmentStatusAction, getAppointmentStatusLabel, getAppointmentTagColor, dayjs } from '@esg/shared';
import { useResetRecoilState, useRecoilState } from 'recoil';

import { managementApis } from '@esg/business-management';
import { EmployeeGetDetailResponse } from '@esg/business-resource-management';

import { useTranslation } from 'react-i18next';
import { getDetailSale } from '@esg/business-pos';
import { viewRequests } from '@esg/framework';
import { PlusOutlined } from '@ant-design/icons';
import { getAppointmentSummary } from '@/components/form/Appointment/@share/appointmentUtils';
import { translateCodes } from '@/locales';
import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';
import { CustomerLabelTag } from '@/components/common/customer/CustomerLabelTag';
import { useAppointmentForm } from '@/hooks/useAppointmentForm';
import { AppointmentDeleteConfirm } from '@/components/appointment/AppointmentDeleteConfirm';
import { AppointmentFormBtn } from '@/components';

interface AppointmentProps {
    readonly appointments: AppointmentGetDetailResponse[];
    readonly refetchAppointment: () => void;
    readonly loading?: boolean;
}

export const SchedulerTableList = (props: AppointmentProps) => {
    const [searchParams, setSearchParams] = usePagination({ orderBy: 'appointmentCode' });

    const { formatCurrency, formatDate } = useLocalesFormat();
    const showAppointmentForm = useAppointmentForm();

    const { onShowFormSale } = useOpenSaleFrom();
    const appointments = props.appointments;
    const { mutateAsync: DeleteAppointment } = useHttpCommand(schedulerApis.deleteAppointment);
    const { mutateAsync: getCurrentSale } = useHttpCommand(getDetailSale);
    const { data: serviceResponse } = useHttpQuery(managementApis.getAllServices);
    const countAppointment = appointments?.length || 0;
    const { mutateAsync: updateAppointmentStatus } = useHttpCommand(schedulerApis.changeAppointmentStatus, {
        onSuccess() {
            props.refetchAppointment();
        },
    });
    const { t } = useTranslation();
    const [, setModalGlobalState] = useRecoilState(globalModalState);
    const resetModal = useResetRecoilState(globalModalState);
    const changeAppointmentStatus = useCallback(
        async (appointment: AppointmentGetDetailResponse) => {
            if (appointment.status === AppointmentStatus.Completed 
                || appointment.status === AppointmentStatus.CheckedIn) {
                const sale = await getCurrentSale({
                    pathData: {
                        id: appointment.saleId!,
                    }
                });

                return void onShowFormSale(sale, { paidFromScheduler: true });
            }

            await updateAppointmentStatus({
                pathData: {
                    id: appointment.id
                },
                body: {
                    id: appointment.id,
                    status: getAppointmentStatusAction(appointment.status).nextValue
                },
            });
        },
        [getCurrentSale, onShowFormSale, updateAppointmentStatus]
    );

    const onDelete = React.useCallback(
        async (id: string) => {
            await DeleteAppointment(
                {
                    pathData: {
                        id: id,
                    },
                },
                {
                    onSuccess(_) {
                        props.refetchAppointment();
                        viewRequests.showNotification.send({
                            type: 'success',
                            message: t(translateCodes.NM_SC_03),
                        });
                    },
                }
            );
        },
        [DeleteAppointment, props, t]
    );

    const columns: TableColumnsType<AppointmentGetDetailResponse> = [
        {
            title: 'ID',
            dataIndex: 'appointmentCode',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.orderDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, orderDirection: order });
                }
            }),
        },
        {
            title: 'Client',
            width: 200,
            dataIndex: 'customer',
            render: (_, record) => {
                return (
                    <FlexBox alignItems='center' gap={5} width={200}>
                        <AppAvatar data={record.customer} />
                        <FlexBox direction='column'>
                            <Text maxLines={1} maxWidth={100} ellipsis>{record.customer ? t(record.customer.fullName) : ''}</Text>
                            <CustomerLabelTag customer={record.customer} isTag={false} />
                        </FlexBox>
                    </FlexBox>
                );
            },
        },
        {
            title: 'Client Email',
            dataIndex: 'customer',
            render: (_, record) => {
                return record.customer ? record.customer.email : '';
            },
        },
        {
            title: 'No. Of Guest',
            dataIndex: 'numberOfGuest',
            align: 'center',
            render: (_, record) => {
                return (record.numberOfGuest);
            },
        },
        {
            title: 'Employee(s)',
            dataIndex: 'appointmentServices',
            render: (appointmentServices: AppointmentService[]) => {
                const ListEmployees = flatten(appointmentServices.map(a => flatten(a.appointmentServiceDetails.map(f => flatten(f.appointmentServiceEmployees?.map(t => t.employee))))));
                return (ListEmployees?.length > 0 ?
                    <ColumnAvatarGroup
                        items={ListEmployees as (EmployeeGetDetailResponse)[]}
                        maxCount={2}
                        prefixEnd={ListEmployees?.length == 1 ? ListEmployees[0]?.fullName : `${ListEmployees?.length} employee`}
                    /> : <>---</>
                );
            },
        },
        {
            title: 'Facility',
            dataIndex: 'appointmentServices',
            render: (appointmentServices: AppointmentService[]) => {
                const listFacility = flatten(appointmentServices.map(a => flatten(a.appointmentServiceDetails.map(o => o.facility)))).filter(o => o?.name != undefined);
                const countFacility = listFacility.length || 0;
                const names = listFacility.map(o => o?.name);
                return (countFacility > 0 ?
                    <FlexBox direction='row'>
                        <ListNameColumn names={names} limit={2} />
                    </FlexBox> : <>---</>
                );
            },
        },
        {
            title: 'Time',
            dataIndex: 'appointmentServices',
            width: 200,
            render: (_, record) => {
                const createdAt = dayjs(record.creationTime);
                const createdTime = createdAt.format('hh:mm A');
                const createdDate = formatDate(createdAt);
                const serviceTime = flatten(record.appointmentServices.map(a => flatten(a.appointmentServiceDetails.map(o => o.service.duration))));
                const createdTimeEnd = createdAt.add(serviceTime.reduce((total, item) => total + item), 'm').format('hh:mm A');
                return (
                    <div>
                        <span>{createdTime} - {createdTimeEnd}</span>
                        <br />
                        <span>{createdDate}</span>
                    </div>
                );
            },
        },
        {

            title: 'Service(s)',
            dataIndex: 'appointmentServices',
            width: 200,
            render: (appointmentServices: AppointmentService[]) => {
                const ListService = flatten(appointmentServices.map(a => flatten(a.appointmentServiceDetails.map(o => o.service))));
                const names = ListService.map(o => o?.name);
                return <ListNameColumn names={names} limit={2} />;
            },
        },
        {
            title: t(translateCodes.TOTAL_AMOUNT),
            dataIndex: 'appointmentServices',
            render: (appointmentServices: AppointmentService[]) => {
                const { price } = getAppointmentSummary(appointmentServices, serviceResponse?.items ?? []);
                return <Text>{formatCurrency(price)}</Text>;
            },
        },
        {
            title: t(translateCodes.STATUS),
            dataIndex: 'status',
            render: (_, record) => {
                return (
                    <Tag color={getAppointmentTagColor(record.status)}>
                        {getAppointmentStatusLabel(record.status)}
                    </Tag>
                );
            },
        },
        {
            title: t(translateCodes.ACTION),
            align: 'left',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={() => {
                            showDeleteConfirm(record);
                        }}
                        onClickEdit={() => showAppointmentForm({initialValues: record, refetch: props.refetchAppointment})}
                        onChangeStatus={() => changeAppointmentStatus(record)}
                        status={getAppointmentStatusAction(record.status)?.label}
                    />
                );
            },
        },
    ];
    const showDeleteConfirm = (appointment: AppointmentGetDetailResponse) => {
        setModalGlobalState({
            content: <AppointmentDeleteConfirm item={appointment} />,
            isOpen: true,
            onOk: () => {
                if (appointment.id) {
                    onDelete(appointment.id);
                }
                resetModal();
            },
            showModalDelete: true,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandleSearch = useCallback(
        debounce((search: string) => {
            setSearchParams({ ...searchParams, search: search });
        }, 1000),
        []
    );

    return (
        <Spacer type='padding' top_bottom={16} left_right={0}>
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString() })
                }
                scroll={{ x: 2000 }}
                titleTableTransCode={translateCodes.APPOINTMENT_LIST}
                subTitleTable={<Tag color='processing'>{countAppointment} {countAppointment > 1 ? 'Appointments' : 'Appointment'}</Tag>}
                placeholderSearchTransCode='Search ID, Client, Phone, Email'
                showHeader={true}
                loading={props.loading}
                columns={columns}
                dataSource={appointments}
                onSearch={(value) => debouncedHandleSearch(value)}
                emptyOption={{
                    title: t(translateCodes.ADD_APPOINTMENT),
                    description: t(translateCodes.EMPTY_APPOINTMENT_DESCRIPTION),
                    actionButton: (
                        <AppointmentFormBtn type='primary'
                            icon={<PlusOutlined/>}
                        >
                            <Translate translateCode={translateCodes.ADD_APPOINTMENT} />
                        </AppointmentFormBtn>
                    )
                }}
            />
        </Spacer>
    );
};

