import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

interface GetCategoriesAppointmentReportRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetCategoriesAppointmentReportItem {
    id: string;
    name: string;
    quantity: number;
    revenue: number;
}

export const getCategoriesAppointmentReport = httpUtil.createHttpRequestMeta<
    GetCategoriesAppointmentReportRequest,
    PaginationResponse<GetCategoriesAppointmentReportItem>
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/appointments-report/categories-summary',
    authentication: 'bearer',
});