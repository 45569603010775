import {
    InputField,
    Text,
    Spacer,
    FlexBox,
    SelectField
} from '@esg/ui';
import React from 'react';
import { Card } from 'antd';
import { CampaignTarget, CampaignType } from '@esg/business-management/api/campaign';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { DefaultOptionType } from 'antd/es/cascader';
import { campaignRules } from '../../rules';

interface InfoCampaignFormProps {
    readonly isUpdate?: boolean;
    form: UseFormReturn<FieldValues, unknown, FieldValues | undefined>;
    segmentOptions?: DefaultOptionType[]; 
    promotionOptions?: DefaultOptionType[];
}

const targetOptions = [
    {label: 'All client' , value: CampaignTarget.AllClient},
    {label: 'New client' , value: CampaignTarget.NewClient},
    {label: 'Returning client' , value: CampaignTarget.ReturnClient},
    {label: 'Client Segment' , value: CampaignTarget.SegmentClient}
];

export const campaignTypeOptions = [
    {label: 'Promotion' , value:  CampaignType.Promotion},
    {label: 'Notification' , value: CampaignType.Notification},
];

export const InfoCampaignForm = (props: InfoCampaignFormProps) => {
    const {form, segmentOptions, promotionOptions} = props;
    const campaignTarget = form.watch('target') as number| undefined;
    const campaignType = form.watch('type') as number;

    return (
        <FlexBox direction='column' gap={8}>
            <Spacer bottom={24}/>
            <Text
                fontWeight={600}
                lineHeight='20px'
                color='#344054'
            >
                General information
            </Text>

            <Card className='card-body' >
                <InputField
                    name='name'
                    label='Campaign name'
                    placeholder='Enter segment name'
                    rules={campaignRules.campaignName}
                />
                <Spacer bottom={8} />
                <SelectField
                    label='Campaign type'
                    placeholder='Select campaign type'
                    options={campaignTypeOptions}
                    style={{ width: '100%' }}
                    name='type'
                    rules={campaignRules.valueRequire}
                />
            </Card>
            <Text
                fontWeight={600}
                lineHeight='20px'
                color='#344054'
            >
                Conditions
            </Text>
            <Card className='card-body'>
                <SelectField
                    label='Client type'
                    placeholder='Select client type'
                    options={targetOptions}
                    style={{ width: '100%' }}
                    name='target'
                    rules={campaignRules.valueRequire}
                    allowClear={false}
                    onChange={() => form.setValue('targetValue', [])}
                />
                {
                    campaignTarget === CampaignTarget.SegmentClient &&
                    <Spacer top={8}>
                        <SelectField
                            name='targetValue'
                            mode='multiple'
                            placeholder='Select segment'
                            options={segmentOptions}
                            rules={campaignRules.targetValue}
                        />
                    </Spacer>
                }
            </Card>
            {
                campaignType === CampaignType.Promotion && <Spacer top={8}>
                    <Text
                        fontWeight={600}
                        lineHeight='20px'
                        color='#344054'
                    >
                        Promotion
                    </Text>
                    <Spacer top={8} />
                    <Card className='card-body'>
                        <SelectField
                            name='promotionId'
                            label='Campaign promotion'
                            placeholder='Select Promotion'
                            options={promotionOptions}
                            rules={campaignRules.promotion}
                        />
                    </Card>
                </Spacer>
            }
        </FlexBox>
    );
};