import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface UpdateSaleActiveResponse extends HttpRequestData {
    id?: string;
    isActive: boolean;
}

interface UpdateSaleActiveRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        isActive: boolean;
        isDisplay?: boolean;
    };
}

export const updateSaleActive = httpUtil.createHttpRequestMeta<
    UpdateSaleActiveRequest,
    UpdateSaleActiveResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/sales/:id/active',
    method: 'PATCH',
    authentication: 'bearer',
});
