import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { CreateJobTypeResponse } from '.';

interface GetAllJobTypeRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export const getAllJobTypes = httpUtil.createHttpRequestMeta<
    GetAllJobTypeRequest,
    PaginationResponse<CreateJobTypeResponse>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/job-types',
    method: 'GET',
    authentication: 'bearer',
});
