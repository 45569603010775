import { Avatar } from 'antd';
import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { ICustomerAvatar } from './interface';

interface CustomerAvatarProps {
    customer?: ICustomerAvatar;
}

export const CustomerAvatar = (props: CustomerAvatarProps) => {
    const { customer } = props;
    const nameAvatar = customer?.firstName?.[0] + (customer?.lastName ? customer?.lastName?.[0] : customer?.firstName?.[1] ?? '');

    return (
        <Avatar
            key={customer?.id}
            src={customer?.avatar}
            icon={customer ? undefined : <UserOutlined />}
            style={{ backgroundColor: '#F2F4F7' }}
        >
            {customer ? nameAvatar : undefined}
        </Avatar>
    );
};
