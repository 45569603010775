import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { GetUserDetailResponse } from './getUserDetail';

export interface GetAllUserRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export const getAllUser = httpUtil.createHttpRequestMeta<
    GetAllUserRequest,
    PaginationResponse<GetUserDetailResponse>
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/users',
    method: 'GET',
    authentication: 'bearer',
});
