import './CustomerDetailTable.scss';
import { AppTab, FlexBox, Spacer, Text } from '@esg/ui';
import React from 'react';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { useTranslation } from 'react-i18next';
import { CustomerDetailTabChildren } from './CustomerDetailTabChildren';
import { translateCodes } from '@/locales';

interface CustomerDetailTableProps {
    customer?: GetCustomerDetailResponse;
}

export const CustomerDetailTab = ({ 
    customer
}: CustomerDetailTableProps) => {

    const { t } = useTranslation();

    const items = [
        {
            key: '1',
            render: <Text>{t(translateCodes.ACTIVITY)}</Text>,
        },
        {
            key: '2',
            render: <Text>{t(translateCodes.APPOINTMENTS)}</Text>,
        },
        {
            key: '3',
            render: <Text>{t(translateCodes.SALES)}</Text>,
        },
        {
            key: '4',
            render: <Text>{t(translateCodes.PRODUCTS)}</Text>,
        },
        {
            key: '5',
            render: <Text>{t(translateCodes.PROMOTIONS)}</Text>,
        },
    ];

    return (
        <FlexBox direction='column'>
            <AppTab
                className='custom-ant-tabs-nav'
                items={items}
            >
                {({ activeKey }) => (
                    <CustomerDetailTabChildren
                        activeKey={activeKey}
                        customer={customer}
                    />
                )}
            </AppTab>
            <Spacer height={16} />
        </FlexBox>

    );
};
