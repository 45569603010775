import {
    FlexBox,
    Text,
    managementProfileState,
    useLocalesFormat,
} from '@esg/ui';
import { Card } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { GetAllProductResponseItem } from '@esg/business-management/api/product';
import {
    CreateSaleDetailRequest,
    GetDetailSaleResponse,
} from '@esg/business-pos';

interface MenuProductItemProps {
    readonly sale: GetDetailSaleResponse;
    product?: GetAllProductResponseItem;
    onOrderItem?: (saleDetailValue: CreateSaleDetailRequest['body']) => void;
}

export default function MenuProductItem(props: MenuProductItemProps) {
    const [currentProfile] = useRecoilState(managementProfileState);
    const { formatCurrency } = useLocalesFormat();
    const { product, onOrderItem, sale } = props;
    const productBranch = useMemo(
        () =>
            product?.productBranches.find(
                (o) => o.branchId === currentProfile?.branch.id
            ),
        [currentProfile?.branch.id, product?.productBranches]
    );
    const priceProduct = productBranch?.sellingPrice ?? 0;

    const handleOrderItem = useCallback(
        (product?: GetAllProductResponseItem) => {
            if (!product) return;

            onOrderItem?.({
                objectId: product.id,
                objectType: 'PRODUCT',
                quantity: 1,
                objectPrice: priceProduct,
                objectPriceOrigin: priceProduct,
                objectName: product.name,
                taxValue: productBranch?.taxSetting?.value ?? 0,
                saleId: sale?.id
            });
        },
        [onOrderItem, priceProduct, productBranch?.taxSetting?.value, sale?.id]
    );

    return (
        <Card
            size='small'
            bordered={true}
            key={product?.id}
            onClick={() => handleOrderItem(product)}
            className='cursor-pointer menu-order-item-slide'
        >
            <FlexBox
                justifyContent='space-between'
                alignItems='center'
                flex={1}
            >
                <FlexBox direction='column' flex={1}>
                    <FlexBox gap={8}>
                        <Text
                            fontSize={14}
                            fontWeight={600}
                            textTransform='capitalize'
                        >
                            {product?.name}
                        </Text>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    direction='column'
                    alignItems='end'
                    gap={8}
                    flex='none'
                >
                    <Text fontWeight={600}>{formatCurrency(priceProduct)}</Text>
                </FlexBox>
            </FlexBox>
        </Card>
    );
}
