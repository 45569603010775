import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface UserUpdateBranchRequest extends HttpRequestData {
    body: {
        id: string;
        branchActive: string;
    };
}
interface UserUpdateBranchResponse {
    id: string;
    branchActive: string;
}

export const userUpdateBranch = httpUtil.createHttpRequestMeta<
    UserUpdateBranchRequest,
    UserUpdateBranchResponse
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/users/updateBranch',
    method: 'PATCH',
    authentication: 'bearer'
});
