import { Route, Routes } from 'react-router-dom';
import { CustomerList } from './customer/list/CustomerList';
import { CustomerGroupList } from './customer-group/list/CustomerGroupList';
import { CustomerSourceList } from './customer-source/CustomerSourceList';
import { CustomerInfo } from './customer/detail/children/CustomerInfo';

export const Crm = () => {

    return (
        <Routes>
            <Route path='/' Component={CustomerList} />
            <Route path='customer/:id' Component={CustomerInfo} />  
            <Route path='customer/groups' Component={CustomerGroupList} />  
            <Route path='customer/sources' Component={CustomerSourceList} />  
        </Routes>
    );
};