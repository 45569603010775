import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface DeleteSaleDetailRequest extends HttpRequestData {
}

interface DeleteSaleDetailResponse {
    readonly id: string;
}

export const deleteSaleDetail = httpUtil.createHttpRequestMeta<
    DeleteSaleDetailRequest,
    DeleteSaleDetailResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/sale-details/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
