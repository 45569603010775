import {
    AppButton,
    FlexBox,
    Spacer,
    Text,
    IconBorn,
    useHttpQuery,
    InternalLink,
} from '@esg/ui';
import {
    Flex,
    Image
} from 'antd';
import React, { useEffect } from 'react';
import { customerApi } from '@esg/business-crm';
import { getAllCustomerNotes } from '@esg/business-crm/api/customer-note';
import { useParams } from 'react-router-dom';
import { CustomerOverview } from './CustomerOverview';
import { CustomerInfoDetail } from './CustomerInfoDetail';
import { CustomerDetailTab } from './CustomerDetailTab';
import { CustomerNoteDetail } from './CustomerNoteDetail';
import { ActionCustomerDetail } from './ActionCustomerDetail';
import { translateCodes } from '@/locales';
import { useAppointmentForm } from '@/hooks/useAppointmentForm';
import { saleEvents } from '@/event/saleEvent';
import { CRM_URL } from '@/configs';
import { resourceManagementTheme } from '@/pages/resource-management/@shared';
import { crmEvents } from '@/event';
export const CustomerInfo = () => {

    const showAppointmentForm = useAppointmentForm();

    const { id: customerId } = useParams();

    const {
        data: customer,
        isLoading,
        refetch: refetchCustomer,
    } = useHttpQuery(customerApi.getCustomerDetail, {
        pathData: {
            id: customerId,
        },
    });

    const { 
        data: customerNotes, 
        refetch: refetchCustomerNote,
        isLoading: isCustomerNoteLoading
    } = useHttpQuery(getAllCustomerNotes, {
        query: {
            customerId: customerId,
        }
    });

    useEffect(() => {
        const clearOnRefetchCustomerActive = saleEvents.onRefetchCustomerActive.listen(() => {
            refetchCustomerNote();
            refetchCustomer();
        });
        return () => { clearOnRefetchCustomerActive?.(); };
    }, [refetchCustomer, refetchCustomerNote]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const refreshCrmPage = () => {
        refetchCustomerNote();
        refetchCustomer();
        crmEvents.onRefetchUpComingAppointment.emit();
    };

    return (
        <Spacer size={24} >
            <FlexBox className='customer-detail' direction='column' >
                <Spacer height={4} />
                <FlexBox gap={20} >
                    <InternalLink href={CRM_URL}>
                        <Text translateCode={translateCodes.SIMPLE_CRM} fontWeight={500} fontSize={14} color='#475467' />
                    </InternalLink>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowRightIcon}
                        width={14}
                        height={14}
                        alt='Arrow Right'
                    />
                    <InternalLink href={CRM_URL}>
                        <Text translateCode={translateCodes.CLIENT_LIST} fontWeight={500} fontSize={14} color='#475467' />
                    </InternalLink>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowRightIcon}
                        width={14}
                        height={14}
                        alt='Arrow Right'
                    />
                    <Text translateCode={customer?.customerCode} fontWeight={600} fontSize={14} color='#0C6FF3' />
                </FlexBox>
                <Spacer height={16} />
                <Spacer height={6} />
                <FlexBox direction='column'>
                    <FlexBox justifyContent='space-between' alignItems='center'>
                        <Text translateCode={translateCodes.CLIENT_DETAILS} fontWeight='bold' fontSize={18} />
                        <Flex align='center' gap={10}>
                            <ActionCustomerDetail
                                refetchCustomerNotes={refetchCustomerNote}
                                customer={customer}
                                refetch={refetchCustomer}
                                refetchCustomerActivity={crmEvents.onRefetchUpComingAppointment.emit}
                            />
                            <AppButton
                                type='primary'
                                onClick={() => showAppointmentForm({
                                    customerId: customerId,
                                    refetch: refreshCrmPage,
                                    customer: customer,
                                })}>
                                <FlexBox gap={6}>
                                    <IconBorn width={20} height={20} color='white' />
                                    <Text color='white' translateCode={translateCodes.BOOKING_NOW} />
                                </FlexBox>
                            </AppButton>
                        </Flex>
                    </FlexBox >
                    <Spacer height={16} />
                    <FlexBox gap={20}>
                        <FlexBox flex={10} direction='column'>
                            <CustomerOverview
                                customer={customer}
                                loading={isLoading}
                                reportOverview={customer}
                                lastAccessedOn={customer?.lastAccess}
                            />
                            <Spacer height={20} />
                            <CustomerDetailTab
                                customer={customer}
                            />
                        </FlexBox>
                        <FlexBox gap={20} flex={2} direction='column'>
                            <CustomerInfoDetail
                                customer={customer}
                                loading={isLoading}
                                reportOverview={customer}
                            />
                            {
                                customerNotes && !isCustomerNoteLoading && <CustomerNoteDetail
                                    refetchCustomerNotes={refreshCrmPage}
                                    customerNotes={customerNotes?.items ?? []}
                                    customer={customer}
                                    loading={isCustomerNoteLoading}
                                    reportOverview={customer}
                                />
                            }
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </Spacer>
    );
};
