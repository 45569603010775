import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

export interface CreateJobTypeResponse {
	readonly id: string;
	readonly name: string;
}

export interface CreateJobTypeRequest extends HttpRequestData {
	readonly body: {
		readonly name: string;
	};
}

export const createJobType = httpUtil.createHttpRequestMeta<CreateJobTypeRequest, CreateJobTypeResponse>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/job-types',
    method: 'POST',
    authentication: 'bearer',
});
