import React, { ReactNode, useState } from 'react';
import { Dropdown } from 'antd';
import { AppButton } from '../buttons';
import { IconFilter } from '../icons';
import { FormBase } from '../inputs';
import { FormModalFilter } from '../form';
import { Block } from '../layouts';
import './AppFilter.scss';

interface AppFilterProps<T extends object> {
    defaultValues?: T;
    handleSubmit?: (value: T) => void;
    onCancel?: () => void;
    children: (props: { renderKey: number }) => ReactNode;
}

export const AppFilter = <T extends object>({ defaultValues, handleSubmit, onCancel, children }: AppFilterProps<T>) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [resetKey, setResetKey] = useState(0);

    const handleDropdownVisibleChange = (visible: boolean) => {
        setDropdownVisible(visible);
    };

    return (
        <Dropdown
            dropdownRender={() => (
                <div
                    onClick={(e) => e.stopPropagation()}
                    onMouseDown={(e) => e.preventDefault()}
                    className='app-filter-wrapper'
                >
                    <FormBase
                        onSubmit={
                            (value: T) => {
                                setDropdownVisible(false);
                                handleSubmit?.(value);
                            }
                        }
                        defaultValues={defaultValues}
                    >
                        {(form) => (
                            <FormModalFilter
                                onCancel={() => {
                                    setDropdownVisible(false);
                                    onCancel?.();
                                }}
                                onReset={() => {
                                    form.reset(
                                        Object.keys(form.getValues()).reduce((acc, key) => {
                                            acc[key as keyof T] = undefined;
                                            return acc;
                                        }, {} as Partial<T>)
                                    );
                                    setResetKey(prev => prev + 1);
                                }}
                                titleTranslateCodes='Filter'
                            >
                                <Block>
                                    {children({renderKey: resetKey})}
                                </Block>
                            </FormModalFilter>
                        )}
                    </FormBase>
                </div>
            )}
            destroyPopupOnHide
            open={dropdownVisible}
            onOpenChange={handleDropdownVisibleChange}
            trigger={['click']}
        >
            <AppButton translateCode='Filter' icon={<IconFilter />} />
        </Dropdown>
    );
};
