import {
    AvatarUpload,
    Block,
    FlexBox,
    IconCamera,
    IconUploadAvatar,
    InputField,
    SelectField,
    Spacer,
    Text,
    useLocationOptions,
} from '@esg/ui';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { getTimeZoneByAddress } from '@esg/business-setting';
import { Card } from 'antd';
import { translateCodes } from '@/locales';
import { DateTimeFormatOptions } from '@/constants';

export const BranchGeneralSettingForm = () => {
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext();
    const firstRender = useRef(true);
    const currentCountry = watch('country');
    const currentStates = watch('state');
    const isUpdate = !!watch('id');

    const {
        citiesOption,
        countriesOption,
        statesOption,
        timezoneOption,
        phoneCodeOptions,
        currencyOptions
    } = useLocationOptions(currentCountry, currentStates);

    //Set recommend value for timezone and currency when change country and state
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        const country = countriesOption.find((o) => o.value === currentCountry);
        const state = statesOption.find((o) => o.value === currentStates);
        const address = `${state?.label ?? ''}, ${country?.label}`;

        getTimeZoneByAddress(address).then((timezoneRecommend) => {
            if (timezoneRecommend) {
                setValue('timezone', timezoneRecommend.timeZoneId);
            }
        });

        if (country) {
            setValue('currency', country.currency);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('country'), watch('state')]);

    return (
        <Block>
            <Text fontSize={18} fontWeight={600} translateCode={translateCodes.ORGANIZATION_PROFILE} />
            <Spacer height={16} />
            <Card>
                <Spacer>
                    <FlexBox
                        direction='column'
                        alignItems={isUpdate ? 'flex-start' : 'flex-middle'}
                    >
                        <Spacer bottom={6} left={0}>
                            <Text
                                fontWeight={600}
                                lineHeight='20px'
                                color='#344054'
                                translateCode={translateCodes.BRANCH_LOGO}
                            />
                        </Spacer>
                        <FlexBox direction='row' gap={16} alignItems='center'>
                            <AvatarUpload
                                translateCode={t(translateCodes.UPLOAD_PHOTO)}
                                name='logo'
                                shape='square'
                                icon={<IconUploadAvatar />
                                }
                                iconHover={<IconCamera width={20} height={20} />}
                            />
                            <FlexBox direction='column' gap={8}>
                                <Text
                                    fontWeight={600}
                                    fontSize={14}
                                    lineHeight='20px'
                                    color='#101828'
                                    translateCode={translateCodes.BRACH_LOGO_VALIDATE}
                                />
                                <Text
                                    fontWeight={500}
                                    fontSize={14}
                                    lineHeight='20px'
                                    color='#667085'
                                    translateCode={translateCodes.PREFERRED_IMAGE_DIMENSIONS}
                                />
                                <Text
                                    fontWeight={500}
                                    fontSize={14}
                                    lineHeight='20px'
                                    color='#667085'
                                    translateCode={translateCodes.MAXIMUM_FILE_SIZE}
                                />
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                    <Spacer bottom={16} />
                    <FlexBox justifyContent='space-between' gap={16}>
                        <InputField
                            name='name'
                            label={t(translateCodes.BRANCH_NAME)}
                            placeholder={translateCodes.BRANCH_NAME_PLACEHOLDER}
                            rules={{
                                required: t(translateCodes.BRANCH_NAME_IS_REQUIRED),
                                maxLength: 255,
                            }}
                        />
                        <SelectField
                            onSelect={() => {
                                setValue('city', null);
                                setValue('state', null);
                            }}
                            showSearch
                            allowClear
                            options={countriesOption}
                            name='country'
                            label={t(translateCodes.BRANCH_COUNTRY)}
                            placeholder={t(translateCodes.BRANCH_COUNTRY_PLACEHOLDER)}
                            rules={{
                                required: t(translateCodes.BRANCH_COUNTRY_IS_REQUIRED),
                            }}
                        />

                    </FlexBox>
                    <Spacer bottom={16} />
                    <FlexBox justifyContent='space-between' gap={16}>
                        <SelectField
                            options={citiesOption}
                            name='city'
                            showSearch
                            allowClear
                            label={t(translateCodes.BRANCH_CITY)}
                            placeholder={t(translateCodes.BRANCH_CITY_PLACEHOLDER)}
                            rules={{
                                required: t(translateCodes.BRANCH_CITY_IS_REQUIRED),
                            }}
                        />
                        <SelectField
                            onSelect={() => {
                                setValue('city', null);
                            }}
                            options={statesOption}
                            name='state'
                            showSearch
                            allowClear
                            label={t(translateCodes.BRANCH_STATE)}
                            placeholder={t(translateCodes.BRANCH_STATE_PLACEHOLDER)}
                            rules={{
                                required: t(translateCodes.BRANCH_STATE_IS_REQUIRED),
                            }}
                        />
                    </FlexBox>
                    <Spacer bottom={16} />
                    <FlexBox justifyContent='space-between' gap={16}>
                        <InputField
                            name='postalCode'
                            label={t(translateCodes.BRANCH_POSTCODE)}
                            placeholder={t(translateCodes.BRANCH_POSTCODE_PLACEHOLDER)}
                            rules={{
                                required: t(translateCodes.BRANCH_POSTCODE_IS_REQUIRED),
                                maxLength: 10,
                            }}
                        />
                        <InputField
                            addonBefore={
                                <SelectField
                                    showSearch
                                    allowClear
                                    style={{ width: 150 }}
                                    name='countryCode'
                                    options={phoneCodeOptions}
                                />
                            }
                            name='phoneNumber'
                            label={t(translateCodes.BRANCH_PHONE)}
                            placeholder={t(translateCodes.BRANCH_PHONE_PLACEHOLDER)}
                            type='number'
                            rules={{ maxLength: 11, value: Number }}
                        />
                    </FlexBox>
                    <Spacer bottom={16} />
                    <FlexBox justifyContent='space-between' gap={16} width='calc(50% - 8px)'>
                        <InputField
                            name='address'
                            label={t(translateCodes.BRANCH_ADDRESS)}
                            placeholder={t(translateCodes.BRANCH_ADDRESS_PLACEHOLDER)}
                            rules={{
                                required: t(translateCodes.BRANCH_ADDRESS_IS_REQUIRED),
                                maxLength: 255,
                            }}
                        />
                    </FlexBox>
                    <Spacer bottom={16} />
                </Spacer>
            </Card>
            <Spacer bottom={16} />
            <Text fontSize={18} fontWeight={600} translateCode={translateCodes.ORGANIZATION_FORMAT} />
            <Spacer bottom={16} />
            <Card>
                <FlexBox
                    justifyContent='space-between'
                    gap={16}
                >
                    <SelectField
                        allowClear
                        name='timezone'
                        label={t(translateCodes.BRANCH_TIMEZONE)}
                        placeholder={t(translateCodes.BRANCH_TIMEZONE_PLACEHOLDER)}
                        rules={{
                            required: t(translateCodes.BRANCH_TIMEZONE_IS_REQUIRED),
                        }}
                        options={timezoneOption}
                        showSearch
                    />
                    <SelectField
                        allowClear
                        name='dateTimeFormat'
                        label={t(translateCodes.BRANCH_DATE_TIME_FORMAT)}
                        placeholder={t(
                            translateCodes.BRANCH_DATE_TIME_FORMAT_PLACEHOLDER
                        )}
                        options={DateTimeFormatOptions}
                    />

                </FlexBox>
                <Spacer bottom={16} />
                <FlexBox justifyContent='space-between' gap={16}>
                    <SelectField
                        name='currency'
                        label={t(translateCodes.BRANCH_CURRENCY)}
                        placeholder={t(translateCodes.BRANCH_CURRENCY_PLACEHOLDER)}
                        options={currencyOptions}
                        showSearch
                        allowClear
                    />
                    <SelectField
                        name='languages'
                        allowClear
                        label={t(translateCodes.BRANCH_LANGUAGES)}
                        defaultValue='en'
                        options={[
                            {
                                value: 'en',
                                label: 'English',
                            },
                        ]}
                        placeholder={t(translateCodes.BRANCH_LANGUAGES_PLACEHOLDER)}
                    />
                </FlexBox>
                <Spacer bottom={21} />
            </Card>
            <Spacer bottom={21} />
        </Block>
    );
};
