import { FlexBox, IconPen, IconTrash, Text, useLocalesFormat } from '@esg/ui';
import { Card, Tag } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';
import { SaleDetailGetAllResponseItem } from '@esg/business-pos/api/sale-detail';
import { SaleStatus } from '@esg/business-pos';
import { useTranslation } from 'react-i18next';
import { currentSaleState } from '@/states';
import { translateCodes } from '@/locales';
import './OrderObjectItem.scss';

interface OrderProductItemProps {
    saleDetail?: SaleDetailGetAllResponseItem
    onEditOrderItem: () => void;
    onDeleteOrderItem: () => void;
    disabled?: boolean;
}

export default function OrderProductItem(props: OrderProductItemProps) {
    const { formatCurrency } = useLocalesFormat();

    const { onDeleteOrderItem, onEditOrderItem, saleDetail} = props;
    const {t} = useTranslation();
    const [currentSale]  = useRecoilState(currentSaleState);
    const salePromotion = currentSale?.salePromotions?.[0];
    const isCanUpdate = currentSale?.status === SaleStatus.Unpaid;

    const employeesName = saleDetail?.saleDetailEmployees?.map(o => o.employee?.fullName).join(', ');
    const subTotal = (saleDetail?.objectPrice??0) * (saleDetail?.quantity ??0);
    
    const showSubTotal = subTotal > (saleDetail?.totalAmount??0);

    const onHandleEditOrderItem = (event?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        event?.stopPropagation();
        onEditOrderItem();
    };

    const onHandleDeleteOrderItem = (event?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        event?.stopPropagation();
        onDeleteOrderItem();
    };
    
    return (
        <Card
            size='small'
            bordered={true}
            key={saleDetail?.id} 
            className={`cursor-pointer sale-detail-card-item ${props.disabled ? 'disabled' : ''}`}
            onClick={()=> !props.disabled && onEditOrderItem()}
        >
            <FlexBox justifyContent='space-between' alignItems='start' flex={1} className='order-service-wrapper'>
                <FlexBox direction='column' flex={1}>
                    <FlexBox gap={8}>
                        <Text fontSize={14} fontWeight={600} textTransform='capitalize'>
                            {saleDetail?.objectName} 
                        </Text>
                        {saleDetail && saleDetail.quantity > 1 && 
                            <Text fontSize={14} fontWeight={400}> x{saleDetail.quantity}</Text>
                        }
                    </FlexBox>
                    <FlexBox >
                        {employeesName && (
                            <Text>
                                {t(translateCodes.SOLD_BY)}&nbsp;
                            </Text>
                        )}
                        {employeesName && (
                            <Text maxLines={1} maxWidth={185} fontWeight={600} ellipsis>
                                {employeesName ?? '_'}
                            </Text>
                        )}
                    </FlexBox>
                </FlexBox>
                <FlexBox direction='column' alignItems='end' gap={8} flex='none'  className='order-service-content'>
                    <FlexBox gap={8} alignItems='center'>
                        {
                            (salePromotion && saleDetail?.promotionValue)
                                ? <Tag color='blue'><Text color='#175CD3' fontSize={12} fontWeight={500} ellipsis maxWidth={180}>{salePromotion.name}</Text></Tag>
                                : null
                        }
                        {showSubTotal && 
                            <Text textDecoration='line-through' fontSize={12}>{formatCurrency(subTotal)}</Text>
                        }
                        <Text fontWeight={600}>{formatCurrency(saleDetail?.totalAmount ?? 0)}</Text>
                    </FlexBox>
                    {isCanUpdate && (
                        <FlexBox gap={12}  className='order-service-action'>
                            <IconPen onClick={onHandleEditOrderItem} width={16} height={16}  cursor='pointer'/>
                            <IconTrash onClick={onHandleDeleteOrderItem} width={16} height={16}  cursor='pointer'/>
                        </FlexBox>
                    )}
                </FlexBox>
            </FlexBox>
        </Card>
    );
}
