import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { ProductUnitType } from '@esg/shared/enum';

interface GetAllProductUnitRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllProductUnitResponseItem {
    readonly id: string;
    name: string;
    type: ProductUnitType;
}

export const getAllProductUnits = httpUtil.createHttpRequestMeta<
    GetAllProductUnitRequest,
    PaginationResponse<GetAllProductUnitResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/product-units',
    method: 'GET',
    authentication: 'bearer',
});
