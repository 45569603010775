import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';

import { AutomationEventChannelType, AutomationEventType, PaginationRequest, ReminderTimeType, ReminderType, ServiceType, getEnv } from '@esg/shared';
import { GetPromotionResponseItem, PromotionApplyResponse } from '@esg/business-management';

interface GetAllEmailTemplateRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}
export interface AutomationEventGetAllResponseItem {
    id: string;
    code: string;
    type: AutomationEventType;
    reminderType: ReminderType;
    reminderTime?: ReminderTimeValue[];
    isActive: boolean;
    serviceType?: ServiceType;
    serviceValue?: string[];
    promotionId?: string;
    promotion?: GetPromotionResponseItem;
    spentAtLeast?: number;
    automationEventChannels?: AutomationEventChannelResponse[];
    creationTime?: string;
    promotionApplies?: PromotionApplyResponse[];
}

export interface AutomationEventChannelResponse {
    id?: string;
    type: AutomationEventChannelType;
    emailTemplateId?: string;
    isActive: boolean;
}

export interface ReminderTimeValue {
    reminderTime?: number;
    reminderTimeType?: ReminderTimeType;
}

export const automationEventGetAll = httpUtil.createHttpRequestMeta<
    GetAllEmailTemplateRequest,
    PaginationResponse<AutomationEventGetAllResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/automation-events',
    method: 'GET',
    authentication: 'bearer',
});
