import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';
import { GetCustomerNoteDetailResponse } from './getCustomerDetail';

interface UpdateCustomerNoteRequest extends HttpRequestData {
    readonly body: {
        readonly id: string;
        readonly customerId: string;
        readonly value?: string;
    };
}

export const updateCustomerNote = httpUtil.createHttpRequestMeta<
    UpdateCustomerNoteRequest,
    GetCustomerNoteDetailResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-notes/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
