import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface DeleteCustomerGroupRequest extends HttpRequestData {
}

export const deleteCustomerGroup = httpUtil.createHttpRequestMeta<
    DeleteCustomerGroupRequest,
    void
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/customer-groups/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
