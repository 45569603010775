import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface UpdateProductCategoryRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        description?: string;
    };
}

export interface UpdateProductCategoryResponse {
    readonly id: string;
    name: string;
    description: string;
}

export const updateProductCategory = httpUtil.createHttpRequestMeta<
    UpdateProductCategoryRequest,
    UpdateProductCategoryResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/product-categories/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
