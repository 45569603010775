import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { AppButton, useHttpCommand, managementProfileState } from '@esg/ui';
import { useRecoilState } from 'recoil';
import { createSale } from '@esg/business-pos';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';
import { saleEvents } from '@/event/saleEvent';

export const CreateFastSaleBtn = () => {
    const { t } = useTranslation();
    const [currentProfile] = useRecoilState(managementProfileState);

    const { mutateAsync: onCreateSale, isPending } = useHttpCommand(createSale, {
        onSuccess: () => {
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.MM_FP_04),
            });
            saleEvents.onRefetchSaleList.emit();
        }
    });

    const handleCreateSale = async () => {
        await onCreateSale({
            body: {
                branchId: currentProfile?.branch.id,
            }
        });
    };

    return (
        <AppButton
            loading={isPending}
            translateCode={translateCodes.NEW_SALE}
            icon={<PlusOutlined />}
            type='primary'
            onClick={() => handleCreateSale()}
            height={36}
        />
    );
};
