import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';
import { UnitEnum } from '@esg/business-pos';

import { ObjectType, ObjectMode } from './enum';

export interface GetAllPromotionRequest extends HttpRequestData {
    readonly query: PaginationRequest & {
        isValid?: boolean;
        isActive?: boolean;
    };
}

export interface PromotionConditionValue {
    objectType?: ObjectType;
    numberOfTimes?: number;
    objectMode?: ObjectMode;
    objectValue?: string;
}

export interface PromotionConditionItem {
    id?: string;
    objectType: ObjectType;
    numberOfTimes: number;
    objectMode: ObjectMode;
    objectValue?: string[];
}

export interface PromotionConditionRequest {
    id?: string;
    objectType: ObjectType;
    numberOfTimes: number;
    objectMode: ObjectMode;
    objectValue?: string[];
}

export interface GetPromotionResponseItem {
    readonly id: string;
    name: string;
    code: string;
    isActive: boolean;
    unit: UnitEnum;
    value: number;
    effectiveDate: string;
    expirationDate: string;
    createdBy?: string;
    creationTime?: string;
    promotionCondition?: PromotionConditionItem;
}

export const getAllPromotion = httpUtil.createHttpRequestMeta<
    GetAllPromotionRequest,
    PaginationResponse<GetPromotionResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/promotions',
    method: 'GET',
    authentication: 'bearer',
});