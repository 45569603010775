import { TranslateResources } from './translateCodes';

const translation: Partial<TranslateResources> = {
    //#region [common]
    CANCEL: 'Cancel',
    SAVE: 'Save',
    TYPE: 'Type',
    DATE: 'Date',
    EMPLOYEE_TIME: 'Employee time',
    END_TIME: 'End time',
    REPEAT: 'Repeat',
    MONDAY: 'Monday',
    MINS: 'mins',
    WITH: 'with',
    SAVE_CHANGE: 'Save change',
    FROM: 'from',
    TO: 'to',
    ALL: 'All',
    SEARCH: 'Search',
    REBOOK: 'Rebook',
    APT: 'Apt',
    //#endregion [common]

    //#region user and role
    USER_AND_ROLE: 'User And Role',
    ROLE: 'Role',
    CREATE_USER: 'Create User',
    CREATE_ROLE: 'Create Role',
    ROLE_LIST: 'Role List',
    SEARCH_ROLE_PLACEHOLDER: 'Search user role',
    NAME_ROLE_REQUIRED: 'The role name is required',
    ENTER_ROLE_NAME: 'Enter role name',
    ENTER_ROLE_DESCRIPTION: 'Enter role description',
    DELETE_ROLE_MSG: 'Are you sure you want to delete role',
    DELETE_ROLE: 'Delete role',
    EDIT_ROLE: 'Edit role',
    EMAIL_PLACEHOLDER_SHORT: 'Enter email',
    DELETE_USER: 'Delete user',
    DELETE_USER_MSG: 'Are you sure you want to delete user',
    EDIT_USER: 'Edit user',
    USER_LIST: 'User List',
    SEARCH_USER_PLACEHOLDER: 'Search ID, Name, Phone, Email,...',
    LAST_NAME_MAX_LENGTH: 'The last name is not over {{number}} characters',
    FIRST_NAME_MAX_LENGTH: 'The first name is not over {{number}} characters',
    ROLE_REQUIRED: 'Role is required',
    LOCATION_REQUIRED: 'Location is required',
    CANNOT_DELETE_ROLE_WITH_USERS: 'You cannot delete a role with employees assigned to it.<br/> Please remove the employees from the role before deleting it.',
    SELECT_LOCATION: 'Select the location',
    SELECT_ROLE: 'Select the user role',
    ASSIGNED: 'Assigned',
    ID: 'ID',
    NM_ACC_01: 'The user was created successfully!',
    NM_ACC_02: 'The user was updated successfully!',
    NM_ACC_03: 'The user was deleted successfully!',
    NM_ACC_04: 'The role was created successfully!',
    NM_ACC_05: 'The role was updated successfully!',
    NM_ACC_06: 'The role was deleted successfully!',
    EMAIL_ALREADY_EXISTS: 'The email already exists',
    //#end region user and role

    //#region [Marketing Role]
    NM_SAM_01: 'The segment was created successfully!',
    NM_SAM_02: 'The segment was updated successfully!',
    NM_SAM_03: 'The segment was deleted successfully!',
    NM_SAM_04: 'The promotion was created successfully!',
    NM_SAM_05: 'The promotion was updated successfully!',
    NM_SAM_06: 'The promotion was deleted successfully!',
    NM_SAM_07: 'The campaign was created successfully!',
    NM_SAM_08: 'The campaign was updated successfully!',
    NM_SAM_09: 'The campaign was deleted successfully!',
    NM_SAM_10: 'The event was actively successful!',
    NM_SAM_11: 'The template was updated!',
    NM_SAM_12: 'The template was reverted!',
    NM_SAM_13: 'The test mail was sent successfully!',
    NM_SAM_14: 'The template was deleted successfully!',
    NM_SAM_15: 'The template was created successfully!',
    //#endregion [Marketing Role]

    THIS_FIELD_IS_REQUIRED: 'This field is required',
    ACTIVITY_Log: 'Activity Log',
    ACTIVITY_HISTORY: 'Activity history',
    ACTIVITY_CHECKOUT_SALE: 'Checked out sale',
    ACTIVITY_ADD_CLIENT: 'Added client <b>{{clientName}}</b>',
    ACTIVITY_REMOVE_CLIENT: 'Removed client <b>{{clientName}}</b>',
    ACTIVITY_REMOVE_TIP: 'Removed tip',
    ACTIVITY_ADD_TIP: 'Added tip <b>{{value}}</b> for sale',
    ACTIVITY_CHANGE_TIP: 'Changed tip amount from <b>{{originValue}}</b> to <b>{{changedValue}}</b> for sale',
    ACTIVITY_CHANGE_TIP_UNIT: 'Changed tip unit',
    ACTIVITY_ADD_SALE_DETAIL: 'Added <b>{{objectName}}</b>',
    ACTIVITY_QUANTITY: 'Quantity <b>{{quantity}}</b>',
    ACTIVITY_ADD_PROMOTION: 'Add promotion <b>{{promotionName}}</b> to Sale',
    ACTIVITY_DELETED_PROMOTION: 'Remove promotion <b>{{promotionName}}</b>',
    ACTIVITY_DELETE_SALE_DETAIL: 'Deleted <b>{{objectName}}</b>',
    ACTIVITY_CHANGE_QUANTITY: 'Changed quantity from <b>{{originValue}}</b> to <b>{{changedValue}}</b>',
    ACTIVITY_CHANGE_PRICE: 'Changed price from <b>{{originValue}}</b> to <b>{{changedValue}}</b>',
    ACTIVITY_CHANGE_DISCOUNT: 'Changed discount from <b>{{originValue}}</b> to <b>{{changedValue}}</b> for <b>{{objectName}}</b>',
    ACTIVITY_ADD_EMPLOYEE: 'Assigned employee(s) <b>{{employees}}</b> to <b>{{objectName}}</b>',
    ACTIVITY_REMOVE_EMPLOYEE: 'Removed employee(s) <b>{{employees}}</b> from <b>{{objectName}}</b>',
    ACTIVITY_CHANGE_EMPLOYEE: 'Changed employee(s) of <b>{{objectName}}</b> from <b>{{originValue}}</b> to <b>{{changedValue}}</b>',
    ACTIVITY_REASON_CANCELLED_SALE: 'Reason: {{reason}}',

    APM_ACTIVITY_ADD_SERVICE: 'Added <b>service {{serviceName}}</b> to <b>{{guestCode}}</b>',
    APM_ACTIVITY_ADD_SERVICE_POSITION: 'Added service position <b>{{facilityName}}</b> to <b>service {{serviceName}}</b> for <b>{{guestCode}}</b>',
    APM_ACTIVITY_ADD_EMPLOYEE: 'Assigned employee <b>{{employeeName}}</b> to <b>service {{serviceName}}</b> for <b>{{guestCode}}</b>',
    APM_ACTIVITY_CHANGE_APPOINTMENT_DATE: 'Changed appointment date from <b>{{originValue}}</b> to <b>{{changedValue}}</b>',
    APM_ACTIVITY_CHANGE_NUMBER_OF_GUEST: 'Changed number of guest from <b>{{originValue}}</b> to <b>{{changedValue}}</b>',
    APM_ACTIVITY_CHANGE_CUSTOMER: 'Changed customer from <b>{{originValue}}</b> to <b>{{changedValue}}</b>',
    APM_ACTIVITY_CHANGE_STATUS: 'Changed status from <b>{{originValue}}</b> to <b>{{changedValue}}</b>',
    APM_ACTIVITY_DELETE_GUEST: 'Deleted <b>{{guestCode}}</b>',
    APM_ACTIVITY_CHANGE_SERVICE: 'Changed service from <b> service {{originValue}}</b> to <b> service {{changedValue}}</b> for <b>{{guestCode}}</b>',
    APM_ACTIVITY_CHANGE_SERVICE_POSITION: 'Changed service position from <b> {{originValue}}</b> to <b> {{changedValue}}</b> of <b>service {{serviceName}}</b> for <b>{{guestCode}}</b>',
    APM_ACTIVITY_REMOVE_SERVICE_POSITION: 'Removed service position <b>{{facilityName}}</b> from <b>service {{serviceName}}</b> for <b>{{guestCode}}</b>',
    APM_ACTIVITY_DELETE_SERVICE: 'Deleted <b>service {{serviceName}}</b> from <b>{{guestCode}}</b>',
    APM_ACTIVITY_DELETE_EMPLOYEE: 'Removed employee <b>{{employeeName}}</b> from <b>service {{serviceName}}</b> for <b>{{guestCode}}</b>',
    APM_ACTIVITY_CHANGE_EMPLOYEE: 'Changed employee from <b>employee {{originValue}}</b> to <b>employee {{changedValue}}</b> of <b>service {{serviceName}}</b> for <b>{{guestCode}}</b>',

    //#region [Layout]
    PLACE_HOLDER_SEARCH_HEADER: 'Search bookings, clients, or products',
    CREATE_BILL: 'Create Bill',
    ADD_APPOINTMENT: 'Add Appointment',
    EMPTY_APPOINTMENT_DESCRIPTION: 'Click "Add Appointment" button to get started in adding your first appointment to your salon.',
    //#endregion [Layout]

    //#region [common]
    DELETE: 'Delete',
    CONFIRM: 'Confirm',
    CREATE: 'Create',
    EDIT: 'Edit',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    CATEGORY: 'Category',
    CATEGORIES: 'Categories',
    FACILITY: 'Facility',
    EMPLOYEE: 'Employee',
    DURATION: 'Duration',
    PRICE: 'Price',
    ACTION: 'Action',
    VIEW: 'View',
    UNASSIGNED: 'Unassigned',
    //#endregion [common]

    //#region [rules service]
    SERVICE_REQUIRED: 'The Service is required',
    NAME_SERVICE_REQUIRED: 'Service name is required',
    NAME_SERVICE_MAX_LENGTH: 'Service name must be less than 50 characters',
    SERVICE_CATEGORY_REQUIRED: 'Service category is required',
    DURATION_SERVICE_REQUIRED: 'Service duration is required',
    PRICE_SERVICE_REQUIRED: 'Service price is required',
    PRICE_SERVICE_INVALID: 'Service price is invalid',
    DURATION_SERVICE_INVALID: 'Service duration is invalid',
    //#endregion [rules service]

    EDIT_TAX: 'Edit Tax',

    //#region [page service]
    TITLE_PAGE_SERVICE: 'Services',
    SERVICE: 'Service',
    TITLE_TABLE_SERVICE: 'Services',
    PLACE_HOLDER_SEARCH_SERVICE: 'Search Service name',
    CREATE_SERVICE: 'Create Service',
    UPDATE_SERVICE: 'Edit Service',
    MINUTE_DURATION: 'mins',
    GENERAL_INFO: 'General Info',
    EMPLOYEE_ALLOCATE: 'Employee Allocate',
    EMPLOYEE_ALLOCATION: 'Employee Allocation',
    GENERAL: 'General',
    DELETE_SERVICE: 'Delete Service',
    DELETE_SERVICE_CONFIRM: 'Are you sure you want to delete this service',
    CREATE_SERVICE_CATEGORY: 'Create Service Category',
    CREATE_TAX: 'Create Tax',
    ENTER_SERVICE_NAME: 'Enter service name',
    SELECT_CATEGORY: 'Select category',
    SERVICE_CATEGORY: 'Service category',
    ENTER_DURATION: 'Enter duration',
    DURATION_MINUTE: 'Duration (minute)',
    ENTER_PRICE: 'Enter price',
    SELECT_TAX: 'Select tax',
    TAX: 'Tax',
    ENTER_DESCRIPTION: 'Enter description',
    ALLOW_ONLINE_BOOKING: 'Allow online booking',
    DOUBLE_APPOINTMENT_MAX_SLOT_MAX_MESSAGE: 'The maximum number of double bookings per time slot',
    BOOKING_DATE_LIMIT_DESCRIPTION: 'The dates unavailable for online booking by customers',
    BOOKING_DATE_LIMIT: 'Booking date limit',
    ALLOW_ONLINE_BOOKING_DESCRIPTION: 'Allow this service to be booked online by client',
    SERVICE_CATEGORY_PLACEHOLDER: 'Search Category name',
    HAS_NO_SERVICE: 'Has no service!',
    SERVICE_LIST: 'Service List',
    //#endregion [page service]

    //#region [page product]
    TITLE_PAGE_PRODUCT: 'Products',
    PRODUCT: 'Product',
    SELL_BY: 'Sell by',
    SOLD_BY: 'Sold by',
    TITLE_TABLE_PRODUCT: 'Products',
    PLACE_HOLDER_SEARCH_PRODUCT: 'Search Product name',
    EDIT_PRODUCT: 'Edit product',
    CREATE_PRODUCT: 'Create Product',
    DELETE_PRODUCT: 'Delete Product',
    DELETE_PRODUCT_CONFIRM: 'Are you sure you want to delete this product',

    //#endregion [page product]

    //#region [page package]
    TITLE_PAGE_PACKAGE: 'packages',
    TITLE_TABLE_PACKAGE: 'packages',
    PLACE_HOLDER_SEARCH_PACKAGE: 'Search package',
    EDIT_PACKAGE: 'Edit package',
    CREATE_PACKAGE: 'Create package',
    DELETE_PACKAGE: 'Delete package',
    DELETE_PACKAGE_CONFIRM: 'Are you sure you want to delete this package',
    //#endregion [page package]

    //#region [page service category]
    EDIT_SERVICE_CATEGORY: 'Edit Service Category',
    NAME_SERVICE_CATEGORY_REQUIRED: 'Category name is required',
    ENTER_SERVICE_CATEGORY_NAME: 'Enter service category name',
    ENTER_SERVICE_CATEGORY_DESCRIPTION: 'Enter a description for this service',
    CREATE_SERVICE_CATEGORY_BUTTON: 'Create Category',
    TITLE_FORM_CREATE_SERVICE_CATEGORY: 'Create Service Category',
    DELETE_SERVICE_CATEGORY: 'Delete Service Category',
    DELETE_SERVICE_CATEGORY_CONFIRM: 'Are you sure you want to delete this service category',
    SERVICe_CATEGORY_LIST: 'Service Category List',
    MAX_LENGTH_NAME: 'The name is not over {{number}} characters',
    MAX_LENGTH_DESCRIPTION: 'The description is not over {{number}} characters',
    ALLOCATE: 'Allocate',
    ENABLE: 'Enable',
    //#endregion [page service category]

    //#region [page product category]
    EDIT_PRODUCT_CATEGORY: 'Edit Product Category',
    NAME_PRODUCT_CATEGORY_REQUIRED: 'Product category name is required',
    ENTER_PRODUCT_CATEGORY_NAME: 'Enter product category name',
    ENTER_PRODUCT_CATEGORY_DESCRIPTION: 'Enter product category description',
    CREATE_PRODUCT_CATEGORY_BUTTON: 'Create Category',
    TITLE_FORM_CREATE_PRODUCT_CATEGORY: 'Create Product Category',
    DELETE_PRODUCT_CATEGORY: 'Delete Product Category',
    DELETE_PRODUCT_CATEGORY_CONFIRM: 'Are you sure you want to delete this Product category',
    PRODUCT_CATEGORY: 'Product Category',
    PRODUCT_CATEGORY_REQUIRED: 'Product category name is required',
    PRODUCT_CATEGORY_PLACEHOLDER: 'Search Category name',
    PRODUCT_CATEGORY_LIST: 'Product Category List',
    PRODUCT_LIST: 'Product List',
    CREATE_UNIT: 'Create Unit',
    PRICE_INVALID: 'Price is invalid',
    ADD_PRODUCT: 'Add your products',
    ADD_PRODUCT_CATEGORY: 'Add your product Category',
    EMPTY_PRODUCT_DESCRIPTION: 'Click "Create Product" button to get started in adding your first product to your salon.',
    EMPTY_PRODUCT_CATEGORY_DESCRIPTION: 'Click "Create Category" button to get started in adding your first product category to your salon.',
    //#endregion [page product category]

    //#region [page package category]
    EDIT_PACKAGE_CATEGORY: 'Edit Package Category',
    NAME_PACKAGE_CATEGORY_REQUIRED: 'Name is Package category required',
    ENTER_PACKAGE_CATEGORY_NAME: 'Enter Package category name',
    ENTER_PACKAGE_CATEGORY_DESCRIPTION: 'Enter Package category description',
    CREATE_PACKAGE_CATEGORY_BUTTON: 'Create Package Category',
    TITLE_FORM_CREATE_PACKAGE_CATEGORY: 'Create Package Category',
    DELETE_PACKAGE_CATEGORY: 'Delete Package Category',
    DELETE_PACKAGE_CATEGORY_CONFIRM: 'Are you sure you want to delete this Package category',
    //#endregion [page package category]

    //#region [Employee member route]
    ADD_JOB_TYPE: 'Add Job Type',
    EMPTY_JOB_TYPE_DESCRIPTION: 'Click "Add Job Type" button to get started in adding your first job type to your salon.',
    SEARCH_PLACEHOLDER: 'Search ID, Customer, Phone, Email',
    EMPLOYEE_MEMBERS: 'Employee Members',
    EMPLOYEE_LIST: 'Employee list',
    CREATE_EMPLOYEE: 'Create employee',
    ACTIVE: 'Active',
    SEPARATED: 'Separated',
    EMPLOYEE_ID: 'Employee ID',
    EMAIL: 'Email',
    PHONE: 'Phone',
    CONTACT_INFORMATION: 'Contact Information',
    JOB_TYPE: 'Job Type',
    GENERAL_INFORMATION: 'General Information',
    UPLOAD_PHOTO: 'Upload photo',
    SERVICES: 'Services',
    WORKING_TIME: 'Working Time',
    FIRST_NAME: 'First name',
    FIRST_NAME_PLACEHOLDER: 'Enter first name',
    LAST_NAME: 'Last name',
    LAST_NAME_PLACEHOLDER: 'Enter last name',
    GENDER: 'Gender',
    GENDER_PLACEHOLDER: 'Select gender',
    DATE_OF_BIRTH: 'Date of birth',
    DATE_OF_BIRTH_PLACEHOLDER: 'Select date',
    PHONE_PLACEHOLDER: 'Enter your phone number',
    EMAIL_PLACEHOLDER: 'Enter your email',
    SHOW_MORE_DETAIL: 'Show more details',
    ADDRESS: 'Address',
    ADDRESS_PLACEHOLDER: 'Enter address',
    JOB_INFO: 'Job Info',
    SELECT_JOB_TYPE: 'Select job type',
    START_DATE: 'Start date',
    START_DATE_PLACEHOLDER: 'Select start date',
    STATUS: 'Status',
    STATUS_PLACEHOLDER: 'Select status',
    ALL_STATUS: 'All status',
    FILTER_STATUS: 'Filter status',
    LOCATION: 'Location',
    LOCATION_PLACEHOLDER: 'Select location',
    ALLOW_BOOKING_EMPLOYEE: 'Allow bookings with this employee',
    PHONE_NUMBER_IS_INVALID: 'The phone number is invalid, Please try again.',
    FIRST_NAME_REQUIRED: 'The first name is required.',
    LAST_NAME_REQUIRED: 'The last name is required.',
    EMAIL_REQUIRED: 'Email is required',
    EMAIL_INVALID: 'Email is invalid',
    PHONE_REQUIRED: 'Phone is required',
    //#endregion [Employee member route]

    //#region [Resource Management]
    SHIFTS: 'Shifts',
    THIS_WEEK: 'This week',
    ALL_EMPLOYEES: 'All employees',
    EDIT_SHIFTS: 'Edit shift',
    EDIT_SHIFTS_FOR: 'Edit shift for',
    ADD_BREAK: 'Add Break',
    BREAK: 'Break',
    ADD_TIME_OFF: 'Add time off',
    TEAM_MEMBER: 'Team member',
    TIME_OFF_TOTAL: 'Time off total',
    REPEAT_EACH_DAY_TILL: 'Repeat each day till',
    EDIT_WORKING_HOUR: 'Edit working hours',
    VIEW_EMPLOYEE_DETAIL: 'View employee details',
    EDIT_THIS_DAY: 'Edit this day',
    DELETE_TIME_OFF: 'Delete time off',
    DELETE_TIME_OFF_CONFIRM: 'Are you sure you want to delete this time off ?',
    DELETE_THIS_SHIFT: 'Delete this shift',
    SET_TIME_OFF_SUCCESS: 'A new time off has been set.',

    //#endregion [Resource Management]

    //#region [Customer route]
    SIMPLE_CRM: 'Simple CRM',
    CREATE_CUSTOMER: 'Create new',
    CREATE_NEW_CLIENT: 'Create new client',
    EDIT_CUSTOMER: 'Edit Client',
    CLIENT_LIST: 'Client List',
    SOURCE: 'Source',
    GROUP: 'Group',
    MEMBERSHIP: 'Membership',
    POINT: 'Point',
    CREATED_ON: 'Created On',
    CLIENT_GROUP: 'Client Group',
    CREATE_CLIENT_GROUP: 'Create new',
    CREATE_CLIENT_SOURCE: 'Create new',
    CLIENT_GROUP_PLACEHOLDER: 'Select client group',
    CLIENT_SOURCE: 'Client Source',
    CLIENT_SOURCE_PLACEHOLDER: 'Select client source',
    CLIENT: 'Client',
    FIND_EMPLOYEE: 'Find a employee',
    FIND_CLIENT: 'Find a client',
    NOTE: 'Note',
    NOTE_PLACEHOLDER: 'E.g. Allergy to shampoos with sodium',
    PERSONAL_INFO: 'Personal Information',
    ADDITIONAL_INFO: 'Additional Information',
    DELETE_CUSTOMER: 'Delete Client',
    DELETE_CUSTOMER_MSG: 'Are you sure you want to delete client ',
    DELETE_CUSTOMER_NOTE_MSG: 'Are you sure you want to delete note ? ',
    REFERRED_BY: 'Referred by',
    MAX_LENGTH_EMAIL: 'Email must be less than {{number}} characters',
    MAX_LENGTH_ADDRESS: 'The address is not over {{number}} characters.',
    MAX_LENGTH_LAST_NAME: 'The last name is not over {{number}} characters.',
    MAX_LENGTH_FIRST_NAME: 'The first name is not over {{number}} characters.',
    MAX_LENGTH_NOTE: 'The note is not over {{number}} characters.',
    FIRST_NAME_INVALID: 'The first name is invalid',
    START_ON: 'Start on',
    BORN_ON: 'Born on',
    LAST_NAME_MIN_LENGTH: 'Last Name must be more than 1 character.',
    FIRST_NAME_MIN_LENGTH: 'First Name must be more than 1 character.',
    LAST_NAME_INVALID: 'The last name is invalid',
    ADD_CUSTOMER: 'Add your client',
    EMPTY_CUSTOMER_DESCRIPTION: 'Click "Create Client" button to get started in adding your first client to your salon.',
    //#endregion [Customer route]

    //#region [Customer Detail route]
    TOTAL_SALE: 'Total Sales',
    ADD_SALE: 'Add your Sales',
    CONFIRM_PAYMENT: 'Confirm payment?',
    PAYMENT_SUCCESS: 'Payment Successfully!',
    ENTER_YOUR_NOTE: 'Enter your note',
    CANCEL_SALE: 'Cancel Sale',
    EDIT_SALE: 'Edit Sale',
    DESCRIPTION_CONFIRM_PAYMENT: 'This action cannot be undone. Are you sure to proceed?',
    CANCEL_REASON_REQUIRED: 'Cancel reason is required',
    CANCEL_SALE_SUCCESS: 'Cancelled sale successfully!',
    ADD_NOTE_SUCCESS: 'Note added successfully!',
    UPDATE_NOTE_SUCCESS: 'Note updated successfully!',
    DELETE_NOTE: 'Delete Note',
    CREATE_CANCEL_REASON: 'Create new reason',
    REVENUE: 'Revenue',
    LOYALTY_POINT: 'Loyalty Points',
    SALES: 'Sales',
    APPOINTMENTS: 'Appointments',
    PRODUCTS: 'Products',
    PACKAGES: 'Packages',
    PROMOTIONS: 'Promotions',
    PRE_PAY_CARD: 'Pre-pay Card',
    COMMUNICATIONS: 'Communications',
    REVIEW: 'Review',
    TRACKING_HISTORY: 'Tracking History',
    TIME: 'Time',
    IMMEDIATELY: 'Immediately',
    SERVICES_TIME: 'Service(s)',
    TOTAL_AMOUNT: 'Total amount',
    APPOINTMENT_LIST: 'Appointment List',
    SEARCH_APPOINTMENT: 'Search Appointment',
    CLIENT_PLACEHOLDER_SEARCH: 'Search ID, Client, Phone, Email',
    CLIENT_DETAIL_PLACEHOLDER_SEARCH: 'Search by ID, employee, service',
    SELECT_GROUP: 'Select group',
    SELECT_SOURCE: 'Select source',
    SELECT_DATE: 'Select date',
    RESELL_MSG: 'Do you want to resell this ID: ',
    RESELL: 'Resell',
    CREATE_CUSTOMER_SOURCE: 'Create Customer Source',
    CREATE_CUSTOMER_GROUP: 'Create Customer Group',
    ENTER_GROUP_NAME: 'Enter group name',
    ENTER_SOURCE_NAME: 'Enter source name',
    ID_SALE: 'ID Sale',
    DATE_AND_TIME: 'Date & Time',
    SALE_LIST: 'Sales List',
    EMPLOYEES: 'Employee(s)',
    EMPLOYEE_MORE: 'employees',
    PRODUCT_NAME: 'Product Name',
    QUANTITY: 'Quantity',
    ORDER_DATE: 'Order Date',
    LATEST_SALE_DATE: 'Latest sale date',
    EMPTY_SALE_DESCRIPTION: 'Click "Create Sale" button to get started in adding your first sale to your salon.',
    LAST_ACCESSED_ON : 'Last accessed on ',
    PROMOTION_INVALID_ERROR_MESSAGE: '<b>The promo code has already been used</b><br/>Please select a different promo code.',
    //#endregion [Customer Detail route]

    //#region [Job type route ]
    JOB_TYPE_PLACEHOLDER: 'Enter job type',
    JOB_TYPE_RULE: 'The name job type is not empty',
    DELETE_JOB_TYPE: 'Deleted job type',
    SEARCH_JOB_TYPE: 'Search Job Type',
    JOB_TYPE_LIST: 'Job Type List',
    JOB_TYPE_NAME: 'Job Type Name',
    DELETE_JOB_TYPE_MSG: 'Are you sure you want to delete this job type',
    //#endregion [Job type route ]

    //#region [Employee detail route]
    EMPLOYEE_DETAIL: 'Employee detail',
    DELETE_THIS_EMPLOYEE: 'Delete this employee',
    SET_AS_ACTIVE: 'Set as active',
    SET_AS_SEPARATED: 'Set as separated',
    BOOKING_ALLOWED: 'Booking allowed',
    EDIT_EMPLOYEE: 'Edit Employee General Info',
    //#region [Employee detail route]

    //#region [Facility route]
    FACILITY_PLACEHOLDER: 'Enter facility',
    FACILITY_RULE: 'The facility name is required.',
    DELETE_FACILITY: 'Delete facility',
    SEARCH_FACILITY: 'Search facility',
    ADD_FACILITY: 'Add facility',
    FACILITY_NAME: 'Facility name',
    FACILITY_NAME_PLACEHOLDER: 'Enter facility name',
    EDIT_FACILITY: 'Edit facility',
    FACILITY_DESCRIPTION: 'Facility description',
    FACILITY_DESCRIPTION_PLACEHOLDER: 'Enter facility description',
    DELETE_FACILITY_MSG: 'Are you sure you want to delete this facility',
    EMPTY_FACILITY_DESCRIPTION: 'Click "Create Service" button to get started in adding your first service to your salon.',
    //#endregion [Facility route]
    
    //#region [Schedule route]
    TOTAL: 'Total',
    CREATE_VS_CHECK_IN: 'Create & check in',
    ADD_SERVICE: 'Add service',
    EMPTY_SERVICE_DESCRIPTION: 'Click "Create Service" button to get started in adding your first service to your salon.',
    ADD_SERVICE_CATEGORY: 'Add yours service category',
    EMPTY_SERVICE_CATEGORY_DESCRIPTION: 'Click "Create Category" button to get started in adding your first service category to your salon.',
    GUEST: 'Guest',
    SELECT_SERVICE: 'Select service',
    SELECT_EMPLOYEE: 'Select employee',
    THE_EMPLOYEE_IS_REQUIRED: 'The employee is required',
    ADD_YOURS_EMPLOYEE: 'Add yours employee',
    EMPTY_EMPLOYEE_DESCRIPTION: 'Click "Create employee" button to get started in adding your first employee to your salon.',
    ACTIVITY: 'Activity',
    APPOINTMENT_NOTES: 'Appointment notes',
    AVAILABILITY: 'Availability',
    NOTES_PLACEHOLDER: 'Enter notes',
    NUMBER_OF_GUEST: 'Number Of Guest',
    SEARCH_CUSTOMER_PLACEHOLDER: 'Enter client name, email or phone number',
    DELETE_APPOINTMENT: 'Delete Appointment',
    DELETE_APPOINTMENT_CONFIRM_MESSAGE: 'Are you sure you want to delete this appointment',
    IS_NOT_AVAILABLE: 'isn\'t available',
    DOES_NOT_PROVIDER_THIS_SERVICE: 'Employee {{employeeName}} doesn\'t provide this service, please select another employee.',
    MOVE_SERVICE_TO_STAFF_CONFIRM: 'Are you sure you want to move {{serviceName}} for {{employeeName}} ?',
    FIRST_AVAILABLE: 'First available',
    SELECT_FACILITY: 'Select facility',
    ANY_RESOURCE: 'Any resource',
    BREAK_TIME: 'Break time',
    ADD_NOTE: 'Add a note',
    ADD_NOTE_PLACEHOLDER: 'Add a note here...',
    TIME_BLOCK: 'Time block',
    ADD_TIME_BLOCK: 'Add time block',
    DELETE_BREAK_TIME: 'Delete break time',
    UPDATE_BREAK_TIME: 'Update break time',
    DELETE_TIME_BLOCK: 'Delete time block',
    UPDATE_TIME_BLOCK: 'Update time block',
    UPDATE_BREAK_TIME_CONFIRM: 'Are you sure you want to update this break time ?',
    DELETE_BREAK_TIME_CONFIRM: 'Are you sure you want to delete this break time ?',
    DELETE_TIME_BLOCK_ONLY: 'Delete this time block only',
    DELETE_TIME_BLOCK_FUTURE: 'Delete this and future time block',
    DELETE_TIME_BLOCK_ALL: 'Delete all time block',
    UPDATE_TIME_BLOCK_ALL: 'Update all time block',
    UPDATE_TIME_BLOCK_FUTURE: 'Update this and future time block',
    UPDATE_TIME_BLOCK_ONLY: 'Update this time block only',
    DELETE_TIME_BLOCK_CONFIRM: 'Are you sure you want to delete this time ?',
    UPDATE_TIME_BLOCK_CONFIRM: 'Are you sure you want to update this time ?',
    REQUIRED_FIELD:'The field is required',
    APPOINTMENT_WARNING_CONFIRM: 'Employee <b>{{employeeName}}</b> has reached the booking limit for this time slot. Please choose a different time slot or reschedule with another employee.',
    //#endregion [Schedule route]

    //#region [profile route]
    USER_ROLE: 'User role',
    EDIT_PROFILE: 'Edit Profile',
    CREATE_PASSWORD: 'Create Password',
    PHONE_NUMBER: 'Phone number',
    UPDATE_PASSWORD: 'Update Password',
    PROFILE_DETAILS: 'Profile Details',
    CURRENT_PASSWORD: 'Current password',
    CURRENT_PASSWORD_NOT_MATCH: 'Current password does not match.',
    CONNECT_A_SOCIAL_ACCOUNT: 'Connect a social account',
    GOOGLE: 'Google',
    FACEBOOK: 'Facebook',
    NEW_PASSWORD: 'New password',
    CONFIRM_NEW_PASSWORD: 'Confirm new password',
    PASSWORD_REQUIRED: 'Password is required',
    PASSWORD_INVALID: 'Invalid password entered. Please try again.',
    PASSWORD_MIN_LENGTH: 'Password must be at least 8 characters',
    PASSWORD_MAX_LENGTH: 'Password must be at most 20 characters',
    VALIDATE_PASSWORD: 'Create a password with a mix of letters, numbers and symbols',
    VALIDATE_CONFIRM_PASSWORD: 'Create a confirm password with a mix of letters, numbers and symbols',
    
    PASSWORD_NOT_MATCH: 'New passwords and confirmations do not match. Please try again.',

    //#region [Branch setting route]
    BRANCH_SETTING_NAME: 'Branch Information',
    ORGANIZATION_PROFILE: 'Organization Profile',
    BRACH_LOGO_VALIDATE: 'This logo will be displayed in transaction and booking website.',
    PREFERRED_IMAGE_DIMENSIONS: 'Preferred image dimensions: PNG, JPG (max. 300x300 px)',
    MAXIMUM_FILE_SIZE: 'Maximum file size: 2MB',
    ORGANIZATION_FORMAT: 'Organization format',
    BRANCH_LOGO: 'Logo',
    BRANCH_NAME: 'Branch name',
    BRANCH_NAME_PLACEHOLDER: 'Enter branch name',
    BRANCH_NAME_IS_REQUIRED: 'Branch name is required!!',
    BRANCH_ADDRESS: 'Address',
    BRANCH_ADDRESS_PLACEHOLDER: 'Enter branch address',
    BRANCH_ADDRESS_IS_REQUIRED: 'Branch address is required!!',
    BRANCH_PHONE: 'Phone Number',
    BRANCH_PHONE_PLACEHOLDER: 'Enter phone number',
    BRANCH_CITY: 'City/District',
    BRANCH_CITY_PLACEHOLDER: 'Chose branch city/district',
    BRANCH_CITY_IS_REQUIRED: 'Branch city is required!!',
    BRANCH_STATE: 'State',
    BRANCH_STATE_PLACEHOLDER: 'Chose branch state',
    BRANCH_STATE_IS_REQUIRED: 'Branch state is required!!',
    BRANCH_POSTCODE: 'Postcode',
    BRANCH_POSTCODE_PLACEHOLDER: 'Enter branch postcode',
    BRANCH_POSTCODE_IS_REQUIRED: 'Branch postcode is required!!',
    BRANCH_COUNTRY: 'Country',
    BRANCH_COUNTRY_PLACEHOLDER: 'Enter branch country',
    BRANCH_COUNTRY_IS_REQUIRED: 'Branch country is required!!',
    BRANCH_CURRENCY: 'Currency',
    BRANCH_CURRENCY_PLACEHOLDER: 'Chose branch currency',
    BRANCH_TAX: 'Tax',
    BRANCH_TAX_PLACEHOLDER: 'Enter branch default tax',
    BRANCH_LANGUAGES: 'Languages',
    BRANCH_LANGUAGES_PLACEHOLDER: 'Select branch language',
    BRANCH_TIMEZONE: 'Timezone',
    BRANCH_TIMEZONE_PLACEHOLDER: 'Select branch timezone',
    BRANCH_TIMEZONE_IS_REQUIRED: 'Branch Timezone is required!!',
    BRANCH_DATE_TIME_FORMAT: 'Date and time formats',
    BRANCH_DATE_TIME_FORMAT_PLACEHOLDER: 'Enter date and time formats',
    BRANCH_INFORMATION: 'Branch Information',
    BRANCH_WORKING_TIME: 'Working Time',
    BRANCH_UPDATE_TITLE: 'Edit branch',
    BRANCH_UPDATE_SUCCESS: 'Update Branch Successful',
    BRANCH_UPDATE_FAILURE: 'Update Branch failure',
    BRANCH_UPDATE_DESCRIPTION: 'Branch information has been updated successfully',
    BRANCH_CREATE_SUCCESS: 'Create Branch Successful',
    BRANCH_CREATE_FAILURE: 'Create Branch failure',
    BRANCH_CREATE_DESCRIPTION: 'New branch has been Created',
    BRANCH_CHANGE_SUCCESS: 'Change Branch Successful',
    BRANCH_CHANGE_FAILURE: 'BRANCH_CREATE_FAILURE',
    //#endregion [Branch setting route]

    //#region [Setup BusinessHour page]
    BUSINESS_HOUR_NAME: 'Set up your branch business hours',
    //#endregion [Setup BusinessHour page]

    //#region [Salon Management route]
    SALON_INFORMATION: 'Salon Information',
    BRANCH_MANAGEMENT: 'Branch Management',
    BRANCH_CREATE: 'Create Branch',
    BRANCH_LIST: 'Branch list',
    SEARCH_BRANCH: 'Search branch name',
    DELETE_BRANCH: 'Delete branch',
    //#endregion [Salon Management route]
     
    PROFILE: 'Profile Details',

    //#region [Booking Setting route]
    BOOKING_ONLINE: 'Booking Online',
    BOOKING_NOW: 'Book Now',
    BOOKING_SETTING: 'Settings',
    PREFERENCES: 'Preferences',
    PAGES: 'Pages',
    TIME_SLOT_DURATION: 'Time slot duration',
    TIME_SLOT_DURATION_DESCRIPTION: 'Adjust how you want to display the time duration on the booking link.',
    DOUBLE_APPOINTMENT_ENABLED: 'Enable double booking',
    DOUBLE_APPOINTMENT_ENABLED_DESCRIPTION: 'Maximum double bookings per time',
    BOOKING_FEATURE_LIMIT: 'Booking future limit',
    BOOKING_FEATURE_LIMIT_DESCRIPTION: 'How far into the future availability is offered',
    ALLOW_CUSTOMER_SELF_CANCEL: 'Customer self cancel',
    ALLOW_CUSTOMER_SELF_CANCEL_DESCRIPTION: 'The minimum number of hours before a booking a customer can cancel it online.',
    ALLOW_BOOKING_SLOT_LIMIT: 'Limited the number of booking slots.',
    ALLOW_BOOKING_SLOT_LIMIT_DESCRIPTION: 'Limited the number of bookings per hour slot.',
    ALLOW_GROUP_APPOINTMENT: 'Group appointments',
    ALLOW_GROUP_APPOINTMENT_DESCRIPTION: 'Allow customers to book and manage appointments online for multiple people',
    GROUP_APPOINTMENT_PREFIX: 'Allow group appointment',
    REQUIRED_SELECT_EMPLOYEE: 'Employee selection requirement',
    REQUIRED_SELECT_EMPLOYEE_PREFIX: 'Require \'Select Employee\' for Each Appointment',
    SHOW_SERVICE_PRICE: 'Show service prices',
    SHOW_SERVICE_PRICE_PREFIX: 'Display service  on booking link',
    CONFIRM_ONLINE_APPOINTMENT: 'Confirm online appointments',
    CONFIRM_ONLINE_APPOINTMENT_PREFIX: 'Online appointments require manual confirmation',
    ONLINE_BOOKING: 'Online Booking',
    ONLINE_BOOKING_DESCRIPTION: 'Allow customers to book appointments online',
    BOOKING_URL: 'Booking URL',
    BOOKING_URL_DESCRIPTION: 'Customize your booking url',
    BOOK_TAB_NAME: 'Book tab name',
    BOOK_TAB_NAME_DESCRIPTION: 'Custom name for the booking page',
    BOOK_TAB_INFORMATION: 'Book tab information',
    BOOK_TAB_INFORMATION_DESCRIPTION: 'Custom description for the booking page',
    TOP_BANNER: 'Top banner',
    TOP_BANNER_DESCRIPTION: 'Change the top banner image',
    GALLERY: 'Gallery',
    GALLERY_IMAGE: 'Gallery',
    GALLERY_DESCRIPTION: 'Show a tab for gallery images online',
    GALLERY_IMAGE_DESCRIPTION: 'Photos to show on your booking website gallery page',
    CUSTOMIZE_SERVICE: 'Customize Services',
    CUSTOMIZE_SERVICE_DESCRIPTION: 'Allows you to adjust the services that will be displayed on the booking page',

    //#region [Sale route]
    FAST_POS: 'Fast POS',
    CREATE_SALE: 'Create Sale',
    NEW_SALE: 'New sale',
    DAILY_SALE: 'Daily sales',
    CHECKOUT: 'Checkout',
    CLOSE: 'Close',
    CART_NO_ITEM: 'Your cart is empty',
    PROCEED: 'Proceed',
    CART_NO_ITEM_DESC: 'Select an service, product or package to check out.',
    CART_NO_ITEM_DESC_FAST_SALE: 'Select an service, product or package to create sale.',
    WALK_IN: 'Walk-In',
    SELECT_CANCEL_REASON: 'Select reason',
    CANCEL_NOTE_PLACEHOLDER: 'Enter note...',
    //#endregion [Sale route]

    TO_LOGIN_PAGE: 'To login page',

    //#region [other page]
    UNIT: 'Unit',
    CAPACITY: 'Capacity',
    CAPACITY_PLACEHOLDER: 'Enter capacity',
    ORIGINAL_PRICE: 'Original Price',
    ORIGINAL_PRICE_PLACEHOLDER: 'Enter original price',
    ORIGINAL_PRICE_REQUIRED: 'Original price is required',
    SELLING_PRICE: 'Selling Price',
    SELLING_PRICE_PLACEHOLDER: 'Enter selling price',
    SELLING_PRICE_REQUIRED: 'Selling price is required',
    CAPACITY_PRICE_REQUIRED: 'Capacity is required',
    CAPACITY_INVALID: 'Capacity is invalid',
    TAX_REQUIRED: 'Tax is required',
    TAX_NAME_REQUIRED: 'The tax name is required.',
    TAX_PLACEHOLDER: 'Select tax',
    ADD_TAX: 'Add your tax',
    EMPTY_TAX_DESCRIPTION: 'Click "Create Tax" button to get started in adding your first tax to your salon.',
    OWNER: 'Owner',
    USER: 'User',
    CANCELATION_NOTE: 'Cancellation note',
    CANCELATION_REASON: 'Cancelation reason',
    SUB_TOTAL: 'Sub total',
    TIP: 'Tip',
    PROMO_CODE: 'Promo Code',
    FILTER: 'Filter',
    UNIT_REQUIRED: 'Unit is required',

    //#region [Message]
    UPDATE_BRANCH_SUCCESS: 'Update branch successfully',
    UPDATE_BRANCH_FAIL: 'Update branch failure',
    CREATE_BRANCH_SUCCESS: 'Create branch successfully',
    CREATE_BRANCH_FAILURE: 'Create branch failure',
    BRANCH_HAS_CREATED: 'New branch has been created',
    PLEASE_CHOOSE_CLIENT: 'Please choose client',
    CUSTOMER_ALREADY_EXIST: 'Customer already exists',
    EMAIL_ALREADY_EXIST: 'The email already exists',
    PHONE_ALREADY_EXIST: 'The phone already exists',
    PHONE_OR_EMAIL_REQUIRED: 'Phone or Email is required.',
    ENTER_NAME_METHOD: 'Enter name of method',
    SELECT_UNIT: 'Please select unit',
    SELECT_PRODUCT_CATEGORY: 'Select product category',
    PRODUCT_NAME_REQUIRED: 'Product name is required',
    DESCRIPTION_PACKAGE_PLACEHOLDER: 'Enter a description for this package',
    DESCRIPTION_PRODUCT_PLACEHOLDER: 'Enter a description for this product',
    DESCRIPTION_TAX_PLACEHOLDER: 'Enter a description for this tax',
    SELECT_PAYMENT_METHOD: 'Select payment method',
    PAYMENT_METHOD_REQUIRED: 'The name of method is required.',
    SELECT_PAYMENT_METHOD_CONTINUE: 'Select payment method to continue',
    PRINT_BILL_AFTER_PAYMENT: 'Print bill after payment',

    //#region [Print sale]
    PRINT_THIS_DOCUMENT: 'Print this document',
    PAYMENT_IS_PROCESSED: 'Payment is processing...',
    RECEIPT: 'Receipt',
    CLIENT_NAME: 'Client name',
    PAYMENT_TYPE: 'Payment type',
    SALE_ID: 'Sale ID',
    PRINT: 'Print',
    PAYMENT_MESSAGE: 'Your payment is processing, please do not close this window or navigate away from the page. This may take a few minutes...',
    VALUE: 'Value',
    VALUE_TAX_PLACEHOLDER: 'Enter tax value',
    TAX_RATE: 'Tax rate',
    TAX_RATE_PLACEHOLDER: 'Enter tax rate',
    TAX_RATE_REQUIRED: 'The tax rate is required.',
    NAME_UNIT_PLACEHOLDER: 'Enter unit name',
    NAME_UNIT_REQUIRED: 'Unit name is required',
    NAME_TAX_PLACEHOLDER: 'Enter tax name',
    TITLE: 'Title',
    TITLE_PLACEHOLDER: 'Enter title',
    REPEATS: 'Repeats',
    NOT_REPEAT: 'Doesn\'t repeats',
    REPEATS_PLACEHOLDER: 'Select repeats',
    EVERY_DAY: 'Every day',
    EVERY_WEEK: 'Every week',
    EVERY_MONTH: 'Every month',
    ALL_SERVICE: 'All service',
    CREATE_CUSTOMER_SUCCESS_MESSAGE: 'Create customer successfully',
    UPDATE_CUSTOMER_SUCCESS_MESSAGE: 'Update customer successfully',
    FILTER_SERVICE: 'Filter service',
    FILTER_EMPLOYEE: 'Filter employee',
    PAID: 'Paid',
    UNPAID: 'Unpaid',
    CANCELLED: 'Cancelled',
    CREATE_METHOD: 'Create Method',
    EDIT_METHOD: 'Edit Method',
    PAYMENT_METHOD: 'Payment method',
    DELETE_PAYMENT_METHOD: 'Delete payment method',
    DELETE_PAYMENT_METHOD_MSG: 'Are you sure you want to delete this payment method',
    YES: 'Yes',
    NO: 'No',
    CHECK_OUT: 'Check out',
    EDIT_JOB_TYPE: 'Edit job type',
    APPOINTMENT_NO_SHOW_DESC: 'The appointment scheduled {{noShowAfter}} minutes ago <b>hasn\'t been marked as \'Checked in\'</b>. The system will automated change the appointment to <b>\'No Show\'</b>. Please <b>contact the client</b> to confirm their arrival.',
    APPOINTMENT_CANCEL_DESC: 'The appointment scheduled {{time}} minutes ago is marked as no show. Would you like to <strong>cancel this appointment</strong> to free up the slot?',
    APPOINTMENT_BOOKED_DESC: 'Your Salon have a new appointment for the <b>{{serviceName}}</b> service with customer <b>{{customerName}}</b> for <b>{{appointmentDuration}}</b> minutes. Would you like to <b>confirm this appointment</b> ?',
    APPOINTMENT_CONFIRM_DESC: 'Your Salon have a new appointment for the <b>{{serviceName}}</b> service with customer <b>{{customerName}}</b> for <b>{{appointmentDuration}}</b> minutes.',

    //#region [Sale detail]
    ADD_EMPLOYEE: 'Add Employee(s)',

    //#region [Group and Source]
    GROUP_LIST: 'Group List',
    SOURCE_LIST: 'Source List',
    CREATE_NEW_GROUP: 'Create new',
    CREATE_NEW_SOURCE: 'Create new',
    SEARCH_GROUP_PLACEHOLDER: 'Search group name',
    SEARCH_SOURCE_PLACEHOLDER: 'Search source name',
    INACTIVE: 'Inactive',
    NEW_CLIENT_GROUP: 'New client group',
    NEW_CLIENT_SOURCE: 'New client source',
    EDIT_CLIENT_GROUP: 'Edit client group',
    EDIT_CLIENT_DETAIL: 'Edit client details',
    CLIENT_DETAILS: 'Client details',
    DELETE_CLIENT: 'Delete client',
    EDIT_CLIENT_SOURCE: 'Edit client source',
    DELETE_CLIENT_SUCCESS: 'The client  was deleted successfully!',
    DELETE_CLIENT_GROUP: 'Delete client group',
    DELETE_CLIENT_GROUP_MSG: 'Are you sure you want to delete this client group',
    DELETE_CLIENT_SOURCE: 'Delete client source',
    DELETE_CLIENT_SOURCE_MSG: 'Are you sure you want to delete this client source',
    GROUP_NAME: 'Group Name',
    ADD_SOURCE: 'Add your source',
    SOURCE_NAME: 'Source Name',
    ADD_GROUP: 'Add your group',
    GROUP_NAME_REQUIRED: 'The client group is required.',
    SOURCE_NAME_REQUIRED: 'The client source is required.',
    EMPTY_GROUP_DESCRIPTION: 'Click "Create new group" button to get started in adding your first group to your salon.',
    EMPTY_SOURCE_DESCRIPTION: 'Click "Create new source" button to get started in adding your first source to your salon.',
    //#region [Integration setting route]
    CONNECTED: 'Connected',
    CONNECT: 'Connect',
    DISCONNECTED: 'Disconnected',
    DISCONNECT: 'Disconnect',
    EMAIL_LIMIT_MESSAGE: 'The number of messages does not exceed 100 messages per day when sending emails.',
    CONNECTION_INFORMATION: 'Connection Information',
    INTEGRATION_EMAIL_DESC: 'Connect your Email account to allow send email message to your client.',
    MARKETING: 'Marketing',
    SMTP_SERVER: 'SMTP Server',
    PLACEHOLDER_PASSWORD: 'Enter your password',
    PLACEHOLDER_EMAIL: 'Enter your email',
    PLACEHOLDER_SMTP_SERVER: 'Enter SMTP Server',
    PLACEHOLDER_SALON_NAME: 'Enter salon name',
    PLACEHOLDER_USER_NAME: 'Enter your account',
    PLACEHOLDER_PORT: 'Enter Port',
    PORT: 'Port',
    ENCRYPTION: 'Encryption',
    USER_NAME: 'User Name',
    PASSWORD: 'Password',
    SENDING_EMAIL: 'Sending Email',
    SENDING_FORM: 'Sending Form',
    GMAIL: 'Gmail',
    OTHER: 'Other',
    PASSWORD_IS_REQUIRED: 'Password is required.',
    NEW_PASSWORD_REQUIRED: 'New password is required.',
    CURRENT_PASSWORD_REQUIRED: 'Current password is required.',
    CONFIRM_PASSWORD_REQUIRED: 'Confirm password is required.',
    SMTP_SERVER_IS_REQUIRED: 'SMTP Server is required.',
    PORT_IS_REQUIRED: 'Port is required.',
    USER_NAME_IS_REQUIRED: 'User Name is required.',
    SENDING_EMAIL_IS_REQUIRED: 'Sending Email is required.',
    //#endregion [Integration setting route]
    
    //#region [Sale Analyzer]
    CLIENT_REPORT: 'Client Report',
    TOTAL_CLIENT: 'Total Client',
    NEW_CLIENT: 'New Client',
    RETENTION_CLIENT:'Retention Client',
    WALK_IN_CLIENT: 'Walk-In Client',
    TOTAL_REVENUES: 'Total Revenues',
    DISCOUNT: 'Discount',
    BILL_LIST: 'Bill List',
    BILLS: 'Bills',
    NUMBER_OF_SERVICE: 'No.Service',
    NUMBER_OF_PRODUCT: 'No.Product',
    TOTAL_DISCOUNT: 'Total Discount',
    LINKS: 'Links',
    SALES_BY_CLIENT: 'Sales By Client',
    REVENUE_REPORT: 'Revenue Report',
    HIGHEST_SALE: 'Highest Sale',
    SUMMARY: 'Summary',
    VIEW_DETAIL: 'View Detail',
    PRODUCT_BY_EMPLOYEE: 'Products by employee',
    PRODUCTS_BY_CATEGORIES: 'Products by categories',
    PRODUCTS_SUMMARY: 'Products summary',
    SERVICE_BY_EMPLOYEE: 'Services by employee',
    SERVICES_BY_CATEGORIES: 'Services by categories',
    SERVICES_SUMMARY: 'Services Summary',
    TOTAL_APPOINTMENT_REVENUE: 'Total Appointment Revenue',
    TOTAL_PRODUCT_REVENUE: 'Total Product Revenue',
    TOTAL_SERVICE_REVENUE: 'Total Service Revenue',
    TOTAL_TIP: 'Total TIP',
    TOTAL_TAX: 'Total Tax',
    TOTAL_PAYMENT: 'Total Payment',
    //#endregion [Sale Analyzer]

    //#region [Automation email setting]
    INTERNAL_EMAIL_DEFAULTS: 'Internal email defaults',
    EMAILS_ARE_SENT_AUTOMATICALLY: 'Set which emails are sent automatically by default or require manual sending',
    SEND_EMAIL_CONFIRM_APPOINTMENT: 'Send Customer Confirm Appointment Email',
    SEND_EMAIL_CONFIRM_APPOINTMENT_DESCRIPTION: 'The default setting to send the customer an email when a appointment is confirmed (online bookings only)',
    SEND_EMAIL_SALE_CLOSE: 'Send Customer Follow Up Sale Email',
    SEND_EMAIL_SALE_CLOSE_DESCRIPTION: 'The default setting to send the customer an email when closing a sale.',
    //#endregion [Automation email setting]

    //#region [Automation Reminder]
    REMIND_EMAIL_APPOINTMENT: 'Appointment Reminders',
    REMIND_EMAIL_APPOINTMENT_DESCRIPTION: 'Send your customers automated messages to remind them of upcoming appointments.',
    REMIND_EMAIL_TIME: 'Reminders time',
    REMIND_EMAIL_TIME_DESCRIPTION: 'How long before the appointment should we send a reminder?',
    REMIND_EMAIL: 'Email Reminder',
    REMIND_EMAIL_ACTIVE_DESCRIPTION: 'Send customers an email reminder of their upcoming appointment',
    AUTOMATION: 'Automations',
    //#endregion [Automation Reminder]

    //#region [Email template]
    EMAIL_TEMPLATE_LIST: 'Template list',
    EMAIL_TEMPLATE_SEARCH: 'Search Template',
    EMAIL_TEMPLATE_SUBJECT: 'Subject',
    EMAIL_TEMPLATE_SYSTEM: 'System template',
    EMAIL_TEMPLATE_CUSTOM: 'Custom template',
    EMAIL_TEMPLATE_REVERT: 'Revert Email Template',
    DELETE_TEMPLATE: 'Delete Template',
    EMAIL_TEMPLATE_REVERT_MESSAGE: 'Are you sure revert this Email Template?',
    EMAIL_TEMPLATE_DELETE_MESSAGE: 'Are you sure you want to delete template?',
    EMAIL_TEMPLATE_EDITOR: 'Editor',
    EMAIL_TEMPLATE_PREVIEW: 'Preview',
    EMAIL_TEMPLATE_PREVIEW_PLACEHOLDER: 'Your reservation has been confirmed',
    EMAIL_TEMPLATE_AVAILABLE: 'Available For This Template',
    CUSTOMER_NAME: 'Customer Name',
    RESERVATION_LOCATION: 'Reservation location',
    RESERVATION_DATE: 'Reservation date',
    RESERVATION_BOOKING_TIME: 'Reservation booking time',
    BOOKING_SERVICE_NAME: 'Booking service name',
    BOOKING_EMPLOYEE_NAME: 'Booking employee name',
    BOOKING_SERVICE_DURATION: 'Booking service duration',
    STORE_LOCATION: 'Store location',

    //#region [Bank Account]
    ACCOUNT_NAME_REQUIRED: 'Account name is required',
    ACCOUNT_NUMBER_REQUIRED: 'Account number is required',
    BANK_NAME_REQUIRED: 'Bank name is required',
    //#endregion [Bank Account]

    //#region [Segment]
    CREATE_SEGMENT: 'Create Segment',
    ADD_SEGMENT: 'Add Segment',
    CLIENT_SEGMENTS: 'Client Segments',
    SEGMENT_LIST: 'Segment list',
    EMPTY_SEGMENT_DESCRIPTION: 'Click "Add Segment" button to get started in adding your first segment to your salon.',
    MAX_LENGTH_SEGMENT: 'Segment name must be less than 255 characters',
    MAX_LENGTH_SEGMENT_DESCRIPTION: 'Segment description must be less than 255 characters',
    DELETE_SEGMENT: 'Delete Segment?',
    DELETE_SEGMENT_MSG: 'Are you sure you want to delete segment',
    //endregion [Segment]

    //#region [Promotion]
    CREATE_PROMOTION: 'Create Promotion',
    ADD_PROMOTION: 'Add Promotion',
    CLIENT_PROMOTION: 'Client Promotion',
    PROMOTION_LIST: 'Promotion list',
    EMPTY_PROMOTION_DESCRIPTION: 'Click "Add Promotion" button to get started in adding your first promotion to your salon.',
    MAX_LENGTH_PROMOTION: 'Promotion name must be less than 255 characters',
    MAX_LENGTH_PROMOTION_DESCRIPTION: 'Promotion description must be less than 255 characters',
    DELETE_PROMOTION: 'Delete Promotion?',
    DELETE_PROMOTION_MSG: 'Are you sure you want to delete promotion',
    //endregion [Promotion]

    //#region [Campaign]
    CREATE_CAMPAIGN: 'Create Campaign',
    ADD_CAMPAIGN: 'Add Campaign',
    CAMPAIGNS: 'Campaigns',
    CLIENT_REVIEWS: 'Client Reviews',
    CAMPAIGN_LIST: 'Campaign list',
    EMPTY_CAMPAIGN_DESCRIPTION: 'Click "Add Campaign" button to get started in adding your first campaign to your salon.',
    MAX_LENGTH_CAMPAIGN: 'Campaign name must be less than 255 characters',
    MAX_LENGTH_CAMPAIGN_DESCRIPTION: 'Campaign description must be less than 255 characters',
    DELETE_CAMPAIGN: 'Delete Campaign',
    DELETE_CAMPAIGN_MSG: 'Are you sure you want to delete campaign',
    PAUSE_CAMPAIGN: 'Pause Campaign',
    PAUSE_CAMPAIGN_MSG: 'Do you want to pause this campaign',
    RUN_CAMPAIGN: 'Run Campaign',
    RUN_CAMPAIGN_MSG: 'Do you want to run this campaign',
    CONTINUE_EDIT_CAMPAIGN: 'Continue editing',
    EDIT_CAMPAIGN: 'Edit Campaign',
    CONTENT: 'Content',
    SETTING: 'Setting',
    SCHEDULE_AND_SEND: 'Schedule & Send',
    //endregion [Campaign]

    //#region [Message ID]

    //#region [Notification Messages]
    NM_PT_01: 'The service was created successfully!',
    NM_PT_02: 'The service category was created successfully!',
    NM_SM_01: 'The employee was created successfully!',
    NM_SM_02: 'The time off was created successfully!',
    NM_SC_01: 'The appointment was created successfully!',
    NM_SC_02: 'The appointment was updated successfully!',
    NM_SC_03: 'The appointment was deleted successfully!',
    NM_SC_04: 'The time block was created successfully!',
    NM_SC_05: 'The time block was updated successfully!',
    NM_SC_06: 'The time block was deleted successfully!',
    NM_SC_07: 'The appointment was canceled!',
    NM_SC_08: 'The promotion was applied successfully!',
    MM_FP_01: 'Payment successfully!',
    MM_FP_02: 'The sale was updated successfully!',
    MM_FP_03: 'The sale was Deleted successfully!',
    MM_FP_04: 'The sale was created successfully!',
    NM_CM_01: 'The client was created successfully!',
    NM_CM_02: 'The client category was created successfully!',
    NM_CM_03: 'The client category was created successfully!',
    NM_CM_04: 'The client category was deleted successfully!',
    NM_CM_05: 'The client was updated successfully!',
    NM_GM_01: 'Updated successfully!',
    //#endregion [Notification Messages]

    //#region [Error Messages]
    ER_SC_01: 'The appointment slots are full, please choose another service.',
    ER_SC_02: 'The selected time block is invalid due to a conflicting appointment. Please choose another time block.',
    //#endregion [Error Messages]
    
    //#region [Old Validation]
    VAL_PT_01: 'The service name is required.',
    VAL_PT_02: 'The service category is required.',
    VAL_PT_03: 'The service duration is required.',
    VAL_PT_04: 'Invalid duration entered. Please try again.',
    VAL_PT_05: 'The service price is required.',
    VAL_PT_06: 'Invalid price entered. Please try again.',
    VAL_PT_07: 'Invalid tax entered. Please try again.',
    VAL_PT_08: 'The service category name is required.',
    VAL_SM_01: 'The first name is required.',
    VAL_SM_02: 'An invalid first name was entered. Please try again.',
    VAL_SM_03: 'The last name is required.',
    VAL_SM_04: 'Invalid last name entered. Please try again.',
    VAL_SM_05: 'The job type is required.',
    VAL_SC_01: 'The note is not over 100 characters.',
    VAL_SC_02: 'Invalid entered, please try again!',
    VAL_SC_03: 'The title is not over 100 characters.',
    VAL_SC_04: 'The service is required.',
    VAL_SC_05: 'The client information is required.',
    VAL_SC_06: 'The employee is required.',
    VAL_SC_07: 'The number of overlapping appointments must not be lower than online booking.',
    VAL_SC_08: 'The number of overlapping appointments must not exceed the Smart Scheduler setting.',
    VAL_SC_09: 'Occurrences are required.',
    VAL_SC_10: 'Cannot be less than 1.',
    VAL_FP_01: 'The sale note is not over 100 characters.',
    VAL_FP_02: 'Invalid value entered, please try again.',
    VAL_CM_01: 'The client group is required!',
    VAL_CM_02: 'The client source is required!',
    VAL_CM_03: 'The client\'s note is not over 100 characters.',
    //#endregion [Old Validation]

    //#region [Marketing Validation]
    VAL_SAM_01: 'The segment name is required!',
    VAL_SAM_02: 'This field is required!',
    VAL_SAM_03: 'The promotion name is required!',
    VAL_SAM_04: 'The promotion code already exists.',
    VAL_SAM_05: 'The entered value is not valid.',
    VAL_SAM_06: 'The reminder time is required.',
    VAL_SAM_07: 'The channel is required.',
    VAL_SAM_08: 'The usage limit is required.',
    VAL_SAM_09: 'The campaign name is required.',
    VAL_SAM_10: 'The client type is required.',
    VAL_SAM_11: 'The client segment is required.',
    VAL_SAM_12: 'The promotion is required!',
    VAL_SAM_13: 'Please select a channel to send a message.',
    VAL_SAM_14: 'The email template is required.',
    VAL_SAM_15: 'The SMS template is required.',
    VAL_SAM_16: 'The date is required.',
    VAL_SAM_17: 'Time is required.',
    VAL_SAM_18: 'Please enter Who spent at least.',
    VAL_SAM_19: 'Feedback is not over 600 characters.',
    VAL_SAM_20: 'The subject is required.',
    //#endregion [Marketing Validation]
    
    //#region [Tooltip Messages]
    TT_SC_01: 'Employee name + isn\'t available, please select other employee.',
    TT_SC_02: 'Employee name + doesn\'t provide the selected service.',
    TT_SC_03: 'Employee name + doesn\'t provide this service.',
    TT_SC_04: 'Service position + isn\'t available, please select another position.',
    //#endregion [Tooltip Messages]

    //#region [Alert Messages]
    AL_SC_01: 'Employee name + doesn\'t provide this service. Please select another employee.',
    AL_SC_02: 'Employee name + isn\'t available, please select other employee.',
    //#endregion [Alert Messages]

    //#endregion [Message ID]
};

export const en = {
    translation,
};
