import { automationEventGetAll } from './automationEventGetAll';
import { automationEventUpdate } from './automationEventUpdate';
import { automationEventGetDetail } from './automationEventGetDetail';
import { getAutomationCustomerPromotion } from './automationEventCustomerPromotion';
import { getAutomationOverviewPromotion } from './automationEventOverviewPromotion';

export * from './automationEventGetAll';

export const automationEventApi = {
    automationEventGetAll,
    automationEventUpdate,
    automationEventGetDetail,
    getAutomationCustomerPromotion,
    getAutomationOverviewPromotion
};
