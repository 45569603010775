import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { GetRoleDetailResponse } from './getRoleDetail';

interface UpdateRoleRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
    readonly body: {
        readonly id: string;
        readonly name: string;
        readonly description?: string;
    };
}

export const updateRole = httpUtil.createHttpRequestMeta<
    UpdateRoleRequest,
    GetRoleDetailResponse
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/roles/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
