import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest , getEnv } from '@esg/shared';

import { CampaignChannelType, CampaignScheduleType, CampaignStatus, CampaignTarget, CampaignType } from './enum';

interface GetAllCampaignRequest extends HttpRequestData {
    readonly query: PaginationRequest; // Phân trang
}

export interface CampaignConditionValue {
    objectType?: number;
    numberOfTimes?: number;
    objectMode?: number;
    objectValue?: string;
}

export interface CampaignChannelItem {
    campaignId?: string;
    emailTemplateId?: string;
    isActive?: boolean;
    type?: CampaignChannelType;
    id?: string;
}

export interface CampaignConditionRequest {
    id?: string;
    objectType: number;
    numberOfTimes: number;
    objectMode: number;
    objectValue?: string;
}

export interface GetCampaignResponseItem {
    id?: string;
    name?: string;
    code?: string;
    type?: CampaignType;
    target?: CampaignTarget;
    status?: CampaignStatus;
    targetValue?: string[];
    promotionId?: string;
    createdBy?: string;
    creatorId?: string;
    creationTime?: string;
    dateApply?: string;
    timeApply?: string;
    delivered?: number;
    failed?: number;
    opened?: number;
    scheduleType?: CampaignScheduleType;
    campaignChannels?: CampaignChannelItem[];
}

export const getAllCampaign = httpUtil.createHttpRequestMeta<
    GetAllCampaignRequest,
    PaginationResponse<GetCampaignResponseItem>
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/campaigns',
    method: 'GET',
    authentication: 'bearer',
});