import { HttpRequestData, httpUtil } from '@esg/framework';
import { CustomerResponse } from '@esg/business-management';
import { getEnv, GoogleRatingStatus } from '@esg/shared';
import { GetDetailSaleResponse } from '@esg/business-pos';

interface GetAllClientReviewRequest extends HttpRequestData {
}

export interface GetAllClientReviewResponse {
    items: GetAllClientReviewResponseItem[];
}
export interface GetAllClientReviewResponseItem {
    readonly id?: string;
    readonly rating: number;
    readonly feedback?: string;
    readonly customerId?: string;
    readonly customer?: CustomerResponse;
    readonly googleRatingStatus?: GoogleRatingStatus;
    readonly saleId?: string;
    readonly sale?: GetDetailSaleResponse;
    readonly branchId?: string;
    readonly creationTime?: string;
}

export const getAllClientReview = httpUtil.createHttpRequestMeta<
    GetAllClientReviewRequest,
    GetAllClientReviewResponse
>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/reviews',
    method: 'GET',
    authentication: 'bearer',
});
