import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { AppointmentGetDetailResponse } from './../../../schedule/api/appointment/getAppointmentDetail';

interface GetAppointmentsDetailReportRequest extends HttpRequestData {
    readonly query: {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export const getAppointmentsDetailReport = httpUtil.createHttpRequestMeta<
    GetAppointmentsDetailReportRequest,
    PaginationResponse<AppointmentGetDetailResponse>
>({
    baseUrl: () => getEnv().REPORT_SERVER,
    method: 'GET',
    path: '/api/v1/appointments-report/detail',
    authentication: 'bearer'
});
