import { FlexBox, Text } from '@esg/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerAvatar } from './CustomerAvatar';
import { ICustomerColumn, ICustomerLabel } from './interface';
import { CustomerLabelTag } from '@/components/common/customer/CustomerLabelTag';
import { translateCodes } from '@/locales';

interface CustomerColumnViewProps {
    customer?: ICustomerColumn;
}

export const CustomerColumnView = (props: CustomerColumnViewProps) => {
    const { customer } = props;
    const { t } = useTranslation();

    return (
        <FlexBox gap={12} alignItems='center'>
            <CustomerAvatar customer={customer} />
            <FlexBox direction='column'>
                <Text maxLines={1} maxWidth={220} fontSize={14} fontWeight={600} lineHeight='20px'>
                    {customer?.fullName || t(translateCodes.WALK_IN)}
                </Text>
                {customer && <CustomerLabelTag customer={customer as ICustomerLabel} isTag={false} />}
            </FlexBox>
        </FlexBox>
    );
};
