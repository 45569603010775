import React from 'react';
import { IconProps } from '../../Types';

export const IconPromotionUsed = (props: IconProps) => {
    return (
        <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M0.600586 24C0.600586 10.7452 11.3458 0 24.6006 0C37.8554 0 48.6006 10.7452 48.6006 24C48.6006 37.2548 37.8554 48 24.6006 48C11.3458 48 0.600586 37.2548 0.600586 24Z' fill='#F2F4F7' />
            <path d='M28.6006 30L30.6006 32L34.6006 28M24.6006 27H20.6006C18.7368 27 17.8049 27 17.0699 27.3045C16.0897 27.7105 15.311 28.4892 14.9051 29.4693C14.6006 30.2044 14.6006 31.1362 14.6006 33M28.1006 15.2908C29.5665 15.8841 30.6006 17.3213 30.6006 19C30.6006 20.6787 29.5665 22.1159 28.1006 22.7092M26.1006 19C26.1006 21.2091 24.3097 23 22.1006 23C19.8914 23 18.1006 21.2091 18.1006 19C18.1006 16.7909 19.8914 15 22.1006 15C24.3097 15 26.1006 16.7909 26.1006 19Z' stroke='#667085' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};
