import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface GetSaleSummaryRequest extends HttpRequestData {
}

export interface GetSaleSummaryResponse {
    readonly numberSalePaid: number;
    readonly numberSaleUnpaid: number;
    readonly numberSaleCancelled: number;
}

export const getSaleSummary = httpUtil.createHttpRequestMeta<GetSaleSummaryRequest, GetSaleSummaryResponse>({
    baseUrl: () => getEnv().MANAGEMENT_SERVER,
    path: '/api/v1/sales/summary',
    method: 'GET',
    authentication: 'bearer'
});